<template>
  <div class="panel">
    <div
      class="panel__ham"
      @click="toggleNavAside"
    >
      <div />
      <div />
      <div />
    </div>
    <NavPrimary class="panel__nav" />
    <div class="panel__favorite">
      <Icon
        v-click-outside="closeWishlist"
        name="favorite_border"
        @click="getWishlist"
      />
      <!--      <var-->
      <!--        v-if="wishlist.items.length > 0"-->
      <!--        class="center"-->
      <!--      >-->
      <!--        {{ wishlist.items.length }}-->
      <!--      </var>-->
      <div v-if="isFavoritesOpen" class="panel__favorite__list">
        <Loader
          v-if="isFavoritesLoading"
          class="searcher__loader --center --show"
        />
        <div
          v-else
          class="wishlist-container"
        >
          <div
            v-for="(item, itemId) in wishlist.items"
            v-if="wishlist.items.length > 0"
            :key="itemId"
            class="panel__favorite__item"
          >
            <span
              class="panel__favorite__link"
              @click="$router.push({
                name: 'Product',
                params: { id: item.wishlistCurrentUser.model_id }
              })"
            >
              {{ $t('wishlist.product') }} (арт. {{ item.code }}
            </span>
            <span class="panel__favorite__remove">
              <Icon
                class="w-5 min-w-5"
                name="trash"
                @click.prevent.stop="removeFromWishlist(item.wishlistCurrentUser.id)"
              />
            </span>
          </div>
          <div
            v-else
            class="panel__favorite__item"
          >
            <em>{{ $t('wishlist.empty') }}</em>
          </div>
        </div>
      </div>
    </div>
    <div class="panel__notifications">
      <Icon name="bell" />
      <!--      <var class="center">3</var>-->
    </div>
    <span class="panel__notifications" @click="$router.push({ name: 'Instructions' })">
      <Icon name="instruction" />
      <!--      <var class="center">3</var>-->
    </span>

    <div
      v-if="user"
      v-click-outside="closeUserMenu"
      class="relative flex items-center cursor-pointer h-full max-w-max ml-4"
      @click="toggleUserMenu"
    >
      <div class="panel__user center">
        {{ getInitials(user.name) }}
      </div>
      <div class="px-3">
        {{ user.name }}
      </div>
      <div
        v-if="isUserMenu"
        class="panel__user-menu w-full"
      >
        <router-link
          class="panel__user-menu__item"
          :to="{
            name: 'User',
            params: { id: user.id }
          }"
        >
          <Icon
            class="panel__user-menu__item__icon"
            name="person"
          /> {{ $t('wishlist.account') }}
        </router-link>

        <div
          v-if="!user.multiuser"
          class="panel__user-menu__item"
          @click.stop.prevent="getPinCode"
        >
          <Icon
            class="panel__user-menu__item__icon"
            name="eye"
          />
          {{ isPincodeLoading ? $t('pinCode.title.load') : $t('pinCode.title.show') }}
        </div>

        <div
          v-if="!user.multiuser"
          class="panel__user-menu__item"
          @click.stop.prevent="updatePinCode"
        >
          <Icon
            class="panel__user-menu__item__icon"
            name="pin"
          />
          {{ isPincodeUpdating ? $t('pinCode.title.load') : $t('pinCode.title.update') }}
        </div>

        <div
          class="panel__user-menu__item"
          @click="userLogout"
        >
          <Icon
            class="panel__user-menu__item__icon"
            name="logout"
          /> {{ $t('wishlist.exit') }}
        </div>
      </div>
    </div>
    <div v-if="isActive" class="pin-code__overlay">
      <div class="pin-code__modal">
        <div class="pin-code__heading">
          Ваш пин-код
        </div>
        <div class="pin-code__input-group">
          <input
            v-for="(item, key) in inputsPinCodeArray"
            :key="key"
            type="text"
            class="pin-code__input"
            placeholder="0"
            :data-id="key"
            disabled
            :value="item"
          >
        </div>
        <Button
          size="sm"
          :mods="['bg-secondary']"
          @click="handleClickButtonCopy"
        >
          Скопировать
        </Button>
        <div class="w-full modal-default__buttons flex">
          <Button
            size="sm"
            :mods="['bg-gray']"
            @click="close"
          >
            {{ $t('button.close') }}
          </Button>
        </div>
      </div>
      <div class="pin-code__bg" @click="close" />
    </div>
  </div>
</template>
<script src="./Panel.ts" lang="ts"></script>
<style src="./Panel.scss" lang="scss"></style>
