import { createServices } from '@/utils/api/apiService';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.deliveryCompanies.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.deliveryCompanies.item,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.deliveryCompanies.list,
  },
  updateItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.deliveryCompanies.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.deliveryCompanies.item,
  },
  createPayment: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.deliveryCompanies.paymentMethods.create,
  },
  updatePayment: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.deliveryCompanies.paymentMethods.update,
  },
  deletePayment: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.deliveryCompanies.paymentMethods.delete,
  },
  createDelivery: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.deliveryCompanies.deliveryMethods.create,
  },
  updateDelivery: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.deliveryCompanies.deliveryMethods.update,
  },
  getFilters: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.deliveryCompanies.filters,
    properties: false,
  },
};

const apiService = createServices(apiMap);

export default apiService;
