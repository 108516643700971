<template>
  <Icon
    name="doc_tree"
    class="w-5 h-5 text-slate-400 cursor-pointer"
    @click="docTreeShow"
  />
  <Teleport to="body">
    <Modal
      :open="isModalShow"
      title="Связанные документы"
      :isStandardButtons="false"
      width="90%"
      height="90%"
      @action="modalAction"
    >
      <Loader :class="{ '--show': isLoading }" />
      <div
        v-if="Object.keys(docTree).length"
        class="h-full overflow-auto"
      >
        <TreeBranch
          :branch="docTree"
          :current="params.currentDoc"
          :name="docNames.get(docTree.type)"
          @actions="handlerBranchActions"
        />
      </div>
    </Modal>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import type { Ref } from 'vue';

import Icon from '@/components/share/Icon/Icon.vue';
import Modal from '@/components/share/Modal/Modal.vue';

import Loader from '../Loader/Loader.vue';
import TreeBranch from './TreeBranch.vue';

const props = defineProps({
  params: {
    type: Object,
    default: {},
  },
});

const emit = defineEmits(['action', 'actions']);

const docNames = new Map([
  ['document_buyer_orders', 'documentBuyerOrder'],
  ['document_inventory_of_goods', 'documentInventoryOfGoods'],
  ['document_internal_order_movement_products', 'documentInternalOrderMovementProducts'],
  ['document_movement_products', 'documentMovementProducts'],
  ['document_expenditure_orders', 'documentExpenditureOrders'],
  ['document_receipt_orders', 'documentReceiptOrders'],
  ['document_expense_invoices', 'documentExpenseInvoices'],
  ['document_return_requests', 'documentReturnRequest'],
  ['document_expense_invoices', 'documentReturnInvoice'],
  ['return_document_cash_checks', 'returnDocumentCashChecks'],
  ['document_cash_checks', 'documentCashChecks'],
  ['source_document_cash_check', 'sourceDocumentCashCheck'],
  ['document_write_off_goods', 'documentWriteOffGoods'],
  ['document_delivery_orders', 'documentDeliveryOrders'],
  ['document_payment_buyer_payment_cards', 'documentPaymentBuyerPaymentCards'],
]);

const isModalShow: Ref = ref(false);
const isLoading: Ref = ref(false);

const docTree: Ref = ref({});

watch(() => isModalShow.value, (value) => {
  if (value && props.params.docId) getDocTree(props.params.docId);
});

const getDocTree = async (id: string) => {
  isLoading.value = true;
  const res = await props.params.apiDoc.api.getItem({ id }, {
    params: {
      include: props.params.docInclude,
      relatablePerPage: 100,
    },
  });

  if (res.status === 200) {
    docTree.value = res.data;
  }
  isLoading.value = false;
};

const docTreeShow = () => {
  isModalShow.value = true;
};

const modalAction = (action: string) => {
  switch (action) {
    case 'cancel':
      isModalShow.value = false;
    default:
      break;
  }
};

const handlerBranchActions = (event: any) => {
  switch (event.type) {
    case 'clickDoc':
      isModalShow.value = false;
    default:
      break;
  }
};

</script>
