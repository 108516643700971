import { defineStore, StoreDefinition } from 'pinia';
import { Ref } from 'vue';

export const useBuyerOrder: StoreDefinition = defineStore('buyer-order', {
  persist: true,
  state: () => (
    { doc: {} as any }
  ),
  actions: {
    create(data: Ref): void {
      this.doc = data;
    },
    updateProperty(prop: string, value: any): void {
      this.doc[prop] = value;
    },
    remove(): void {
      this.doc = {};
    },
  },
});
