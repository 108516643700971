<script setup lang="ts">
import {
  ref,
  computed,
  watch,
  Ref,
  onMounted,
  onUnmounted,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import VueDatePicker from '@vuepic/vue-datepicker';

import { useNotifications } from '@/store/notifications';
import Field from '@/components/form/Field/Field.vue';
import Icon from '@/components/share/Icon/Icon.vue';
import Pagination from '@/components/share/Pagination/Pagination.vue';
import Loader from '@/components/share/Loader/Loader.vue';
import Status from '@/components/share/Status/Status.vue';
import Chips from '@/components/share/Chips/Chips.vue';

const props = defineProps([
  'headers',
  'items',
  'pagination',
  'table',
  'isSearch',
  'place',
  'isLoading',
  'infinityScroll',
  'params',
  'chips',
  'basket',
  'tableHeight',
]);
const emit = defineEmits(['action', 'filter']);
const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const notifications = useNotifications();
const windowHeight: Ref = ref(window.innerHeight);
const dataTable: Ref = ref(null);
const dataTableHeight: Ref = ref(720);
const search: Ref = ref('');
const headersTable: Ref = ref(props.headers);
const headersKeys = computed(() => headersTable.value.map((item: any) => item.key));
const basketItems = computed(() => (props.basket ? props.basket.map((b: any) => b.item.id) : []));
const basketProducts = computed(() => (props.basket ? props.basket.map((b: any) => b.id) : []));
const infinityScrollLoader = ref(props.infinityScroll?.loader);
const apiStatusesMap: any = {
  1: '--color-blue',
  2: '--color-black-grey',
  3: '--color-primary',
  4: '--color-dark-yellow',
  5: '--color-orange',
  6: '--color-dark-green',
  7: '--color-red',
  8: '--color-black',
  9: '--color-black-red',
};
const apiIconNameMap: any = {
  1: 'plus-circle',
  2: 'more-time',
  3: 'check-square',
  4: 'timer',
  5: 'verified',
  6: 'check-circle',
  7: 'rejected',
  8: 'x-circle',
  9: 'refunded',
};

const docNames = new Map([
  ['document_buyer_orders', { page: 'BuyerOrder', label: 'Заказ покупателя' }],
  [
    'document_internal_order_movement_products',
    { page: 'InternalOrder', label: 'Внутренний заказ' },
  ],
  [
    'document_movement_products',
    { page: 'MovementProduct', label: 'Перемещение товара' },
  ],
  ['document_expenditure_orders', { page: 'ExpenditureOrder', label: 'Расходный ордер' }],
  ['document_receipt_orders', { page: 'ReceiptOrder', label: 'Приходный ордер' }],
  ['document_expense_invoices', { page: 'ExpenseInvoice', label: 'Расходная накладная' }],
  ['document_cash_checks', { page: 'CashCheck', label: 'Чек ККМ' }],
  [
    'document_recalculation_products',
    { page: 'RecalculationProductsItem', label: 'Пересчет товаров' },
  ],
  ['document_write_off_goods', { page: 'WriteOffGood', label: 'Списание товаров' }],
  [
    'document_posting_of_goods',
    { page: 'PostingOfGood', label: 'Оприходование товаров' },
  ],
  ['document_return_invoices', { page: 'ReturnInvoice', label: 'Возвратная накладная' }],
  [
    'document_return_requests',
    { page: 'ReturnRequestItem', label: 'Заявление на возврат' },
  ],
  ['source-document-cash-check', { page: 'CashCheck', label: 'Чек ККМ источник' }],
  [
    'document_cash_check_use_certificates',
    { page: 'CashCheck', label: 'Чек' },
  ],
  [
    'document_cash_check_certificates',
    { page: 'CashCheck', label: 'Чек' },
  ],
]);

const handlerChipsAction = (event: any) => {
  switch (event.type) {
    case 'remove':
      emit('action', { type: 'chips-remove', group: event.group, chip: event.chip });
      break;

    default:
      break;
  }
};

const onClickButton = (tr: any, action: any) => {
  emit('action', { type: action, item: tr });
};

const onFieldSave = (tr: any, td: any) => {
  emit('action', { type: 'fieldSave', field: td, item: tr });
};
const onChangePage = (page: any) => {
  emit('action', { type: 'changePage', table: props.table, page });
};

const onClickCheckbox = (item: any) => {
  emit('action', {
    type: 'select', table: props.table, item, checked: !item.checked,
  });
};

const onClickCheckboxSingle = (item: any) => {
  emit('action', {
    type: 'select-single',
    items: props.items,
    item,
    checked: !item.checked,
  });
};

const onClickIcon = (item: any, action: string) => {
  emit('action', { type: action, table: props.table, item });
};

const onClickLink = (item: any, action: string) => {
  emit('action', { type: action, table: props.table, item });
};

const onAction = (event: any, itemId: number) => {
  event.itemId = itemId;
  emit('action', event);
};

const onClickInfo = (event: any) => {
  emit('action', { type: 'info', table: props.table, item: event.item });
};

const handlerChangeSelectField = (tr: any) => {
  emit('action', { type: 'change-select-field', table: props.table, item: tr });
};

const toggle = (tr: any) => {
  const item = props.items.find((item: any) => item.id === tr.id);

  item.isOpen = !item.isOpen;

  if (item.isOpen) {
    emit('action', { type: 'expand', id: tr.id, item: item.fullData });
  } else {
    emit('action', { type: 'expandClose', id: tr.id });
  }
};

const getTdStyle = (tr: any, td: any, index: string | number) => {
  let style = '';
  if (tr.tdAlign && tr.tdAlign.td === td) {
    style += `text-align:${tr.tdAlign.align};`;
  }
  if (props.headers[index].style) {
    style += props.headers[index].style;
  }
  if (props.headers[index].align) {
    style += `display:flex;justify-content:${props.headers[index].align};`;
  }
  if (tr.current) {
    style += props.headers[index].current;
  }
  return style;
};

const onClickTr = (item: any) => {
  emit('action', {
    type: 'click', table: props.table, item, current: !item.current,
  });
};

const onClickTd = (item: any, td: any) => {
  emit('action', {
    type: 'click', table: props.table, item, td, current: !item.current,
  });
};

const sortByField = (cell: any): void => {
  const sortColumn: any = cell.filterApi.sortColumn.split(' ');
  sortColumn[0] === '+' ? (sortColumn[0] = '-') : (sortColumn[0] = '+');
  cell.filterApi.sortColumn = sortColumn.join(' ');
  emit('action', { type: 'sort', sort: cell.filterApi.sortColumn });
};

const sortColumn = (column: any) => {
  emit('action', { type: 'sortColumn', column });
};

/**
 * Set CSS class
 *
 * @function
 * @param cssSelector
 * @return string
 */
const setCssClass = (cssSelector: string): string => {
  cssSelector = cssSelector.substring(1);
  return cssSelector;
};

/**
 * Set honest Status css class
 *
 * @function
 * @param arr
 * @return cssSelector
 */
const honestStatus = (arr: any): any => {
  const currentRoute = String(router.currentRoute.value.name);

  const relationArray = currentRoute !== 'CashChecks' && currentRoute !== 'CashierWorkplace'
    ? arr.slice(Math.max(arr.length - 2, 0)).filter((item: any) => item?.attributes.type_of_operation === 'acceptance' || item?.attributes.type_of_operation === 'shipment')
    : arr.filter((item: any) => item?.attributes.type_of_operation === 'withdrawal_from_turnover_by_check' || item?.attributes.type_of_operation === 'return_to_circulation');

  const statusAccepted = relationArray.length ? relationArray.every((el: any) => el.attributes.status === 'accepted') : false;

  const statusError = currentRoute !== 'CashChecks' && currentRoute !== 'CashierWorkplace'
    ? relationArray.some((el: any) => el.attributes.status === 'error')
    : relationArray.length ? relationArray.every((el: any) => el.attributes.status === 'error') : false;

  const statusAcceptedError = currentRoute !== 'CashChecks' && currentRoute !== 'CashierWorkplace'
    ? relationArray.some((el: any) => el.attributes.status !== 'error' || el.attributes.status !== 'accepted')
    : relationArray.some((el: any) => el.attributes.status !== 'accepted');

  const statusErrorProcessed = currentRoute !== 'CashChecks' && currentRoute !== 'CashierWorkplace'
    ? false
    : relationArray.some((el: any) => el.attributes.status === 'error');

  return statusAccepted ? 'green' : statusError ? 'red' : statusErrorProcessed ? 'yellow' : statusAcceptedError ? 'black' : 'gray';
};

/**
 * Get Honest Mark Tooltip
 *
 * @param colorStatus
 * @function
 * @return string
 */
const getHonestMarkTooltip = (colorStatus: string): string => {
  const tooltipMap: any = {
    green: t('honestMarks.status.green'),
    red: t('honestMarks.status.red'),
    yellow: t('honestMarks.status.yellow'),
    black: t('honestMarks.status.black'),
    gray: t('honestMarks.status.gray'),
  };

  return tooltipMap[colorStatus] || t('honestMarks.status.error');
};

/**
 * Close all filters
 *
 * @function
 * @return void
 */
const closeFilters = (): void => {
  const filters = headersTable.value
    .filter((f: any) => f.filterApi)
    .map((f: any) => f.filterApi);

  filters.forEach((f: any) => (f.show = false));
};

/**
 * Call filter
 *
 * @function
 * @param filter
 * @return Promise<void>
 */
const callFilter = async (filter: any | object): Promise<void> => {
  closeFilters();

  const setFilter = (data: any) => {
    filter.data = filter.dataInstance = data;

    filter.value.forEach((item: any) => {
      const foundItem = filter.data.find((f: any) => parseInt(f.id) === parseInt(item.id));

      if (foundItem) foundItem.check = true;
    });
  };

  if (filter.show) {
    filter.show = false;
    return;
  }

  // Open the filter and add the loader
  filter.loading = filter.show = true;

  // Creating an API service for a request
  if (['list', 'api'].includes(filter.type)) {
    const { service } = filter;
    const response = await service[filter.method](null, { params: filter.params });

    if (response.status === 200) {
      setFilter(response.data.map((d: any) => d.attributes));
    }
  } else if (filter.type === 'enum') {
    setFilter(filter.options);
  }

  filter.loading = false;
};

/**
 * Get a value with infinite nesting
 *
 * @function
 * @param searchKey
 * @param item
 * @return any | object
 */
const getValue = (searchKey: any, item: any): any | object => {
  if (searchKey.includes('.')) {
    const optionArray: string[] = searchKey.split('.');
    let result: any = null;

    optionArray.forEach((el: any) => {
      result = item[el] ? item[el] : result[el];
    });

    return result;
  }

  return item[searchKey];
};

const onClickDoc = (name: string, id: number) => {
  const page = docNames.get(name)?.page;

  if (page) router.push({ name: page, params: { id } });
  emit('action', { type: 'closeModal' });
};

const setDynamicHeight = () => {
  if (!dataTable.value) return;
  const rect = dataTable.value.getBoundingClientRect();
  dataTableHeight.value = props.tableHeight ? props.tableHeight : rect.height - 68;
};

const intersectionObserver = (cssSelector: string): void => {
  const target: HTMLBodyElement | null = document.querySelector(cssSelector);

  const callback: IntersectionObserverCallback = (entries: any) => {
    entries.forEach((entry: any) => {
      const intersecting: boolean = entry.isIntersecting;
      if (
        target
        && intersecting
        && props.infinityScroll.page < props.infinityScroll.lastPage
      ) {
        infinityScrollLoader.value = true;
        if (props.items.length) emit('action', { type: 'scroll-page', page: props.infinityScroll.page + 1 });
      } else {
        infinityScrollLoader.value = false;
      }
    });
  };
  const observer = new IntersectionObserver(callback);

  if (target) observer.observe(target);
};

const listSearch = (event: Event, filterApi: any): void => {
  const input: EventTarget | any = event.target;

  if (input && input.value.length > 0) {
    filterApi.data = filterApi.dataInstance.filter((el: any) => {
      const elValue: string = el[filterApi.keyData]
        ? el[filterApi.keyData].toLowerCase()
        : '';
      return elValue.includes(input.value.toLowerCase());
    });
  } else {
    filterApi.data = filterApi.dataInstance;
  }
};

/**
 * Search in the filter list
 *
 * @function
 * @param event
 * @param filterApi
 * @return : void
 */
const apiSearch = (event: Event, filterApi: any): void => {
  const input: EventTarget | any = event.target;

  input.value.length > 0 && filterApi.searchKey
    ? (filterApi.params.filters = btoa(
      JSON.stringify([
        {
          key: filterApi.searchKey,
          value: { query: encodeURIComponent(input.value) },
        },
      ]),
    ))
    : (filterApi.params.filters = '');

  filterApiRequest(filterApi);
};

/**
 * Get data for the filter params
 *
 * @function
 * @async
 * @param filterApi
 * @return Promise<void>
 */
const filterApiRequest = async (filterApi: any): Promise<void> => {
  filterApi.loading = filterApi.show = true;

  if (!filterApi.availableData && !filterApi.searchData) {
    const response = await filterApi.service.getList(null, { params: filterApi.params });

    if (response.status === 200) {
      filterApi.data = response.data.map((d: any) => d.attributes);
    } else {
      notifications.error('notifcation.error', response?.data?.message);
    }
  }
  filterApi.loading = false;
};


/**
 * Select a filter item
 *
 * @param item
 * @param filter
 */
const selectFilterItem = (item: any, filter: any): void => {
  const foundItem = filter.data.find((f: any) => (f.namespace
    ? f.id === item.id && f.namespace === item.namespace
    : f.id === item.id));

  foundItem.check = !foundItem.check;
  filter.value = filter.data.filter((f: any) => f.check === true);

  emit('filter');
};

const getColorStatus = (id: any): any | object => apiStatusesMap[id];

const getIconName = (id: any): any | object => apiIconNameMap[id];

/**
 * Emit a data then the vudatepicker is closing
 *
 * @function
 */
const onTimePickerClose = () => emit('filter');

const handlerComponentAction = (event: any, tr: any) => {
  emit('action', {
    type: event.type, item: event.item, tr, table: event.table,
  });
};

onMounted(() => {
  window.addEventListener('resize', () => {
    windowHeight.value = window.innerHeight;
  });
  emit('action', { type: 'loadData' });
});

onUnmounted(() => {
  window.removeEventListener('resize', () => {
    windowHeight.value = window.innerHeight;
  });
});

watch(
  () => props.infinityScroll?.lastPage,
  () => {
    if (props.infinityScroll?.element && props.infinityScroll?.observerTarget) {
      intersectionObserver(props.infinityScroll.observerTarget);
    }
  },
);

watch(
  () => search.value,
  (value) => {
    emit('action', { type: 'search', table: props.table, value });
  },
);

watch(
  () => windowHeight.value,
  () => {
    setDynamicHeight();
  },
);

watch(
  () => props.items.length,
  () => {
    setTimeout(() => setDynamicHeight(), 100);
  },
);
</script>

<template>
  <div class="flex items-center justify-between gap-3">
    <div>
      <Chips
        v-if="chips?.length"
        :chips="chips"
        @action="handlerChipsAction"
      />
    </div>
    <div
      v-if="isSearch"
      class="py-1"
    >
      <Field
        v-model="search"
        type="text"
        size="sm"
        :mods="['b-gray']"
        :edit="true"
        :required="false"
        class="search-modal"
        :placeholder="$t('search.placeholder')"
        iconLeft="magnifying-glass"
        iconRight="x"
      />
    </div>
  </div>
  <div
    ref="dataTable"
    class="h-full"
  >
    <div
      class="data-table-wrapper rounded overflow-auto w-full h-full"
      :style="`max-height: ${dataTableHeight}px`"
    >
      <Loader :class="{ '--show': isLoading }" />
      <table class="data-table w-full">
        <thead>
          <tr class="h-[40px]">
            <th
              v-for="th of headersTable"
              :key="th.value"
              class="p-2 min-w-fit"
              :style="th.width ? `width: ${th.width}px;` : ''"
            >
              <span
                class="min-w-max text-nowrap"
                :class="{
                  'th-filter': th.filterApi || th.sort,
                  '--show': th.filterApi?.show,
                }"
              >
                <span class="flex mr-1">
                  <Icon
                    v-if="th.filterApi?.sortColumn"
                    class="sort-btn"
                    name="sort"
                    @click.prevent.stop="sortByField(th)"
                  />
                  <Icon
                    v-else-if="th.sort"
                    class="h-4 text-gray-500"
                    name="sort"
                    @click.stop="sortColumn(th)"
                  />
                </span>
                {{ th.label }}
                <!-- API Filter -->
                <Icon
                  v-if="th.filterApi && th.filterApi.type !== 'date'"
                  class="w-7"
                  name="caret"
                  @click="callFilter(th.filterApi)"
                />
                <span
                  v-if="
                    th.filterApi
                      && th.filterApi.show === true
                      && th.filterApi.type !== 'date'
                  "
                  class="filter-data"
                >
                  <Loader :class="th.filterApi.loading ? '--show' : ''" />
                  <span class="filter-data__search">
                    <input
                      v-if="th.filterApi.type === 'api'"
                      type="text"
                      placeholder="Введите название для поиска"
                      @click.prevent.stop
                      @input.prevent.stop="apiSearch($event, th.filterApi)"
                    />
                    <input
                      v-if="th.filterApi.type === 'list'"
                      type="text"
                      placeholder="Введите название для поиска"
                      @click.prevent.stop
                      @input.prevent.stop="listSearch($event, th.filterApi)"
                    />
                  </span>
                  <span class="filter-data__list">
                    <span
                      v-for="(item, itemId) in th.filterApi.data"
                      :key="itemId"
                      class="filter-data__item"
                      :class="{
                        '--checked': item.check,
                      }"
                      @click="selectFilterItem(item, th.filterApi)"
                    >
                      {{ item[th.filterApi.keyData] }}
                    </span>
                  </span>
                </span>
                <!-- Date Filter -->
                <label
                  v-if="th.filterApi && th.filterApi.type === 'date'"
                  class="filter-data__calendar"
                  @click="callFilter(th.filterApi)"
                >
                  <Icon name="caret" />
                  <VueDatePicker
                    v-if="
                      th.filterApi
                        && th.filterApi.show === true
                        && th.filterApi.type === 'date'
                    "
                    v-model="th.filterApi.value"
                    :enableTimePicker="false"
                    class="field__calendar"
                    locale="ru"
                    autoApply
                    format="dd.MM.yyyy"
                    monthNameFormat="long"
                    position="left"
                    range
                    @update:model-value="onTimePickerClose"
                  />
                </label>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(tr, ind) of items"
            :key="tr?.id"
            :class="[
              basketItems.includes(tr?.id) || basketProducts.includes(tr?.id)
                ? 'bg-cyan-50'
                : 'odd:bg-white even:bg-slate-50 hover:bg-slate-100',
              {
                'red-row': tr?.typeOfOperation === 'Возврат',
                expand: !!tr?.expand,
                'cursor-pointer': params?.rowClicked,
                backlight: tr?.backlight
              }
            ]"
            @click="onClickTr(tr)"
          >
            <template v-if="tr?.expand && tr?.expand?.type === 'DataTable'">
              <td :colspan="headersKeys.length">
                <div class="pl-12 py-3">
                  <DataTable
                    :items="tr.expand.items"
                    :headers="tr.expand.headers"
                    :basket="basket"
                    table="products"
                    @action="onAction($event, tr.expand.itemId)"
                  />
                </div>
              </td>
            </template>
            <template v-else>
              <td
                v-for="(td, index) of headersKeys"
                :key="td.id"
                :style="getTdStyle(tr, td, index)"
                @click="headersTable[index].clicked && onClickTd(tr, td)"
              >
                <div v-if="td === 'component'">
                  <component
                    :is="headersTable[index].component"
                    :params="headersTable[index].method(tr)"
                    @action="handlerComponentAction($event, tr)"
                  />
                </div>
                <div
                  v-else-if="td === 'expand'"
                  class="flex items-center pl-3"
                >
                  <div
                    class=""
                    @click.stop="toggle(tr)"
                  >
                    <div class="toggle-btn">
                      <Icon
                        size="sm"
                        :class="{ 'rotate-90': !tr.isOpen }"
                        name="caret"
                      />
                    </div>
                  </div>
                  <div
                    v-if="tr.products_count"
                    class="text-xs"
                  >
                    {{ tr.products_count }}
                  </div>
                </div>
                <div
                  v-else-if="td === 'checkbox'"
                  class="py-2 px-2 custom-checkbox"
                >
                  <input
                    :id="tr.id"
                    v-model="tr.checked"
                    type="checkbox"
                    :disabled="tr.disabled"
                    @click.stop="onClickCheckbox(tr)"
                  />
                </div>
                <div
                  v-else-if="td === 'media'"
                  class="py-2 px-2"
                >
                  <div
                    v-if="tr[td]?.url"
                    class="media"
                  >
                    <img
                      v-if="tr[td]['mime_type'].includes('image')"
                      :src="`${tr[td].url}?h=50&fit=contain`"
                      alt=""
                      :style="headersTable[index].style"
                    />
                  </div>
                  <div v-else>
                    <Icon
                      name="no-photo"
                      class="w-7 text-gray-300"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="py-2 pl-2"
                  :class="headersTable[index].class ? headersTable[index].class : ''"
                >
                  <Icon
                    v-if="headersTable[index].type === 'icon'"
                    :name="tr[td].name"
                    class="h-4"
                    :class="tr[td].class"
                  />
                  <div
                    v-else-if="headersTable[index].type === 'imgUrl'"
                    class="imgUrl"
                  >
                    <img
                      v-if="tr[td]"
                      :src="tr[td]"
                      alt=""
                    />
                  </div>
                  <div
                    v-else-if="headersTable[index].type === 'image' && tr[td]"
                    class="imgUrl"
                  >
                    <svg
                      v-if="tr[td].mime_type.includes('svg')"
                      width="30px"
                      height="30px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <image
                        :xlink:href="tr[td].url"
                        x="0"
                        y="0"
                        height="30"
                        width="30"
                      />
                    </svg>
                    <img
                      v-else
                      :src="tr[td].url"
                      :alt="tr[td].original_name"
                    >
                  </div>
                  <div
                    v-else-if="headersTable[index].link"
                    class="link"
                    @click="
                      router.push({
                        name: headersTable[index].name,
                        params: { id: tr.id },
                      })
                    "
                  >
                    {{ tr[td] }}
                  </div>
                  <div
                    v-else-if="headersTable[index].info"
                    class="link"
                    @click="onClickInfo({
                      name: headersTable[index].name,
                      item: tr
                    })"
                  >
                    {{ tr[td] }}
                  </div>
                  <div
                    v-else-if="headersTable[index].nameValue"
                    class="link flex gap-1"
                    @click="onClickLink(tr, headers[index].action)"
                  >
                    {{ headersTable[index].value || tr[td] }}
                    <div v-if="tr[td] && headers[index].iconRight">
                      <Icon
                        class="h-4"
                        :name="headersTable[index].iconRight.name"
                      />
                    </div>
                  </div>
                  <div
                    v-else-if="headersTable[index].icon"
                    class="flex items-center justify-center gap-1"
                  >
                    <Icon
                      class="h-4"
                      :class="{
                        [headersTable[index].class]: !!headersTable[index].class,
                        [headersTable[index].modifer]: tr[td],
                      }"
                      :style="headersTable[index].style ? headersTable[index].style : ''"
                      :name="headersTable[index].icon"
                      :color="headersTable[index].color"
                      @click.stop="onClickIcon(tr, headersTable[index].action)"
                    />
                    <div v-if="!!headersTable[index].extend">
                      <Icon
                        v-if="
                          headersTable[index].extend.values.includes(
                            tr[headersTable[index].extend.field]
                          )
                        "
                        class="h-4"
                        :style="
                          headersTable[index].extend.style
                            ? headersTable[index].extend.style
                            : ''
                        "
                        :name="headersTable[index].extend.icon"
                        :color="headersTable[index].extend.color"
                        @click.stop="onClickIcon(tr, headersTable[index].extend.action)"
                      />
                    </div>
                  </div>
                  <div v-else-if="headersTable[index].type == 'statusIcon2'">
                    <Status :type="tr.status" />
                  </div>
                  <div v-else-if="headersTable[index].type == 'statusHonest'">
                    <div
                      class="tooltip-honest"
                      :class="honestStatus(tr.electronicDocuments)"
                    >
                      <Icon
                        name="honest-status"
                        class="honest-status"
                        :class="honestStatus(tr.electronicDocuments)"
                      />
                      <div
                        class="absolute z-10 inline-block px-3 py-2 text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm tooltip"
                      >
                        {{ getHonestMarkTooltip(honestStatus(tr.electronicDocuments)) }}
                        <div
                          class="tooltip-arrow"
                          data-popper-arrow
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else-if="headersTable[index].type == 'checkbox'">
                    <div class="py-2 px-2 custom-checkbox">
                      <input
                        :id="tr.id"
                        v-model="tr[td]"
                        type="checkbox"
                        disabled
                      />
                    </div>
                  </div>
                  <div v-else-if="headersTable[index].type == 'checkbox-single'">
                    <div class="py-2 px-2 custom-checkbox">
                      <input
                        v-model="tr.checked"
                        type="checkbox"
                        :checked="tr.checked"
                        @click.stop="onClickCheckboxSingle(tr)"
                      />
                    </div>
                  </div>
                  <div v-else-if="headersTable[index].type == 'index'">
                    {{ ind + 1 }}
                  </div>
                  <div
                    v-else-if="headersTable[index].type == 'button'"
                    class="flex items-center"
                  >
                    <button
                      v-if="tr[td]"
                      class="button --xs --bg-outline-secondary"
                      @click.stop="onClickButton(tr, headersTable[index].action)"
                    >
                      {{ tr[td] }}
                    </button>
                    <div v-else>
                      <div
                        v-if="headersTable[index].extend?.icon"
                        :class="headersTable[index].extend.class"
                      >
                        <Icon
                          v-if="
                            tr[headersTable[index].extend.field]
                              === headersTable[index].extend.value
                          "
                          :name="headersTable[index].extend.icon"
                          class="h-4"
                        />
                      </div>
                      <div v-if="
                          headersTable[index].extend?.type === 'loader'
                            && headersTable[index].extend.values.includes(
                              tr[headersTable[index].extend.field]
                            )
                        ">
                        <Icon
                          name="circle-notch"
                          class="text-cyan-500 animate-spin h-4"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="headersTable[index].edit?.type"
                      :class="{
                        'border border-cyan-300 rounded p-1':
                          headersTable[index].edit.type !== 'select',
                      }"
                    >
                      <input
                        v-if="headersTable[index].edit.type === 'number'"
                        v-model="tr[td]"
                        :type="headersTable[index].edit.type"
                        :min="headersTable[index].edit.min"
                        class="w-full outline-none"
                      />
                      <input
                        v-if="headersTable[index].edit.type === 'text'"
                        v-model="tr[td]"
                        :type="headersTable[index].edit.type"
                        :maxlength="headersTable[index].edit.length"
                        class="w-full outline-none"
                      />
                      <select
                        v-if="headersTable[index].edit.type === 'select'"
                        v-model="tr[td]"
                        class="table-product-select"
                        @change="handlerChangeSelectField(tr)"
                      >
                        <option
                          v-for="option in headersTable[index].edit.options"
                          :key="option.id"
                        >
                          {{ option[headersTable[index].edit.key] }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-else-if="headersTable[index].editable?.type"
                      class="flex items-center justify-between gap-1"
                    >
                      <div
                        v-if="tr[td].isEdit"
                        class="w-full border border-cyan-500 rounded-md overflow-hidden"
                      >
                        <input
                          v-if="headersTable[index].editable.type === 'number'"
                          v-model="tr[td].value"
                          :type="headersTable[index].editable.type"
                          :min="headersTable[index].editable.min"
                          class="w-full outline-none px-3 py-2"
                        />
                        <input
                          v-if="headersTable[index].editable.type === 'text'"
                          v-model="tr[td].value"
                          :type="headersTable[index].editable.type"
                          :maxlength="headersTable[index].editable.length"
                          class="w-full outline-none px-3 py-2"
                        />
                      </div>
                      <div
                        v-else
                        class="w-full"
                      >
                        {{ tr[td].value }}
                      </div>
                      <div class="cursor-pointer text-cyan-500">
                        <Icon
                          v-if="tr[td].isSaveIcon"
                          name="save"
                          class="h-7"
                          @click="onFieldSave(tr, td)"
                        />
                      </div>
                    </div>
                    <div v-else-if="td === 'honestMark'">
                      {{ tr[td]?.number }}
                    </div>
                    <div v-else-if="td === 'saleCertificate'">
                      <div
                        v-if="tr[td]?.attributes"
                        class="ml-2 text-cyan-500 cursor-pointer"
                        @click="
                          onClickDoc(tr[td].type, tr[td].attributes.document_cash_check_id)
                        "
                      >
                        {{ docNames.get(tr[td].type)?.label }} №
                        {{ tr[td].attributes.document_cash_check_id }}
                        {{ tr[td].attributes.date }}
                      </div>
                    </div>
                    <div v-else-if="td === 'historyCertificate'">
                      <div
                        v-for="(doc, index) in tr[td]"
                        class="flex mb-2 gap-3"
                      >
                        <div
                          class="ml-2 text-cyan-500 cursor-pointer"
                          @click="
                            onClickDoc(doc.type, doc.attributes.document_cash_check_id)
                          "
                        >
                          {{ docNames.get(doc.type)?.label }} №
                          {{ doc.attributes.document_cash_check_id }}
                          {{ doc.attributes.date }}
                        </div>
                        <div v-if="doc.type === 'document_cash_check_use_certificates'">
                          {{ `${doc.attributes.amount} ₽` }}
                        </div>
                      </div>
                    </div>
                    <div
                      v-else-if="headersTable[index].type === 'statusName'"
                      :style="'color:var(' + getColorStatus(tr[td].id) + ');'"
                    >
                      <div class="flex items-center flex-nowrap gap-1">
                        <div>
                          <Icon
                            :name="getIconName(tr[td].id)"
                            class="h-4 w-4"
                          />
                        </div>
                        <div>{{ tr[td]?.name }}</div>
                      </div>
                    </div>
                    <div v-else>
                      {{ tr[td] || getValue(td, tr)}}
                    </div>
                  </div>
                </div>
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <div
        v-if="infinityScroll?.observerTarget"
        :class="setCssClass(infinityScroll.observerTarget)"
      >
        <div
          v-if="infinityScrollLoader"
          class="loader__icon"
        >
          <Icon name="circle-notch" />
          <span class="loader__desc">Загрузка ...</span>
        </div>
      </div>
      <div
        v-if="pagination?.meta?.last_page > 1 && !props.infinityScroll?.page"
        class="p-3 pagination-holder"
      >
        <Pagination
          :meta="pagination.meta"
          :links="pagination.links"
          @change-page="onChangePage"
        />
      </div>
    </div>

  </div>
</template>

<style lang="scss">
.filter-data {
  width: 230px;
  min-height: 100px;
  position: absolute;
  top: 30px;
  right: 0;
  left: 0;
  border-radius: 4px;
  background: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  font-weight: 400;

  &__search {
    display: block;
    width: 100%;
    padding: 12px;
    border-bottom: 1px solid var(--color-gray);

    input {
      display: block;
      width: 100%;
      box-sizing: border-box;
      padding: 5px 12px;
      border: 1px solid var(--color-gray);
      border-radius: 4px;
    }
  }

  &__list,
  &__item {
    display: block;
  }

  &__list {
    height: 150px;
    overflow-y: scroll;
  }

  &__item {
    position: relative;
    padding: 5px 12px 5px 25px;
    border-bottom: 1px solid var(--color-gray);
    color: var(--color-black);
    cursor: pointer;

    &::before {
      display: block;
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: 6px;
      left: 7px;
      border: 1px solid var(--color-gray-hover);
      background: white;
    }

    &.--checked {
      &::before {
        border: none;
        background: var(--color-secondary);
      }

      &::after {
        display: block;
        content: '';
        width: 6px;
        height: 6px;
        position: absolute;
        top: 8px;
        left: 10px;
        border-bottom: 2px solid white;
        border-left: 2px solid white;
        transform: rotate(-45deg);
      }
    }
  }

  &__calendar {
    display: block;
    font-weight: normal;

    .icon {
      width: 18px;
    }

    .dp__input_wrap {
      display: none;
    }
  }
}

.data-table-wrapper {
  &::-webkit-scrollbar {
    width: 0.55em;
    height: 0.55em;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #767083;
    outline: 1px solid slategrey;
    border-radius: 20px;
  }

  input[type='checkbox'] {
    display: flex;
    justify-content: center;
    border: 2px solid var(--color-black-grey);
    border-radius: 4px;
    width: 18px;
    height: 18px;
    outline: none;
  }

  input[type='checkbox']:checked {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);

    &::before {
      content: '✔';
      font-size: 12px;
      color: white;
    }
  }

  table {
    text-align: left;
    background: #fff;
    font-size: 13px;
    color: var(--color-black);

    thead {
      z-index: 1;
      top: 0;
      position: sticky;

      tr {
        th {
          background: #f1f1f1;
          text-align: left;
          color: #767083;
          font-size: 12px;

          .th-filter {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            .icon {
              max-width: 20px;
              position: relative;
              top: -1px;
              margin-left: 5px;
              cursor: pointer;
            }

            .filter-data__calendar {
              font-weight: normal;
            }
          }
        }
      }
    }

    tbody {
      tr {
        &.expand {
          background-color: #f7f7f7;
        }
      }
    }
  }

  &__footer {
    position: relative;
    //bottom: 40px;
    height: 30px;

    .loader__icon {
      position: absolute;
      width: 130px;
      height: 30px;
      bottom: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      left: 0;

      .icon {
        width: 18px;
      }
    }

    .loader__desc {
      margin-left: 5px;
      color: black;
    }
  }

  .toggle-btn {
    cursor: pointer;
    width: 18px;
  }

  .link {
    cursor: pointer;
    color: #63a6bb;
  }
  .imgUrl{
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
}
.backlight{
  background: linear-gradient(90deg, rgba(206,242,254,1) 0%, rgba(227,206,255,1) 100%);
}
</style>
