<template>
  <div class="nav-primary" :class="navTabs.isDisabled ? '--disabled' : ''">
    <div class="nav-primary__links">
      <div
        v-for="(link, index) in links"
        :key="index"
        class="nav-primary__link"
        :class="link.current ? '--current' : ''"
        @click="goToTheLink(link)"
      >
        {{ link.title }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { onMounted } from 'vue';

import { useMenu } from '@/store/menu';
import { useNavTabs } from '@/store/nav-tabs';
import apiSystemServices from '@/utils/api/services/apiSystemServices';
import { links, findCurrentMenu } from '@/components/nav/NavPrimary/NavPrimaryLink/NavPrimaryLink';

const navTabs = useNavTabs();
const router: any = useRouter();
const menu: any = useMenu();

/**
 * Go to The Link
 *
 * @function
 * @async
 * @param link
 * @return Promise<void>
 */
const goToTheLink = async (link: any): Promise<void> => {
  links.value.forEach((item: any) => item.current = false);

  if (link.route.name === 'Main') {
    await router.push(link.route);
  } else {
    await getMenuLinks(link.keyMenu);
  }

  menu.setKey(link.keyMenu);
  link.current = true;
  localStorage.setItem('keyMenu', link.keyMenu);
};

/**
 * Get menu
 *
 * @function
 * @async
 * @param key
 * @return {Promise<void>}
 */
const getMenuLinks = async (key: string): Promise<void> => {
  if (key.length) {
    menu.updateState(true);

    const response = await apiSystemServices.getMenus({ key });

    if (response.status === 200) {
      const title = response.data.baseMenu?.title;
      const favoriteLinks = response.data.groups[0]?.items;
      const hiddenLinks = response.data.groups[1]?.items;
      const ungroupedLinks = response.data.noGroupItems;

      favoriteLinks.sort((a: any, b: any) => a.order - b.order);
      ungroupedLinks.sort((a: any, b: any) => a.order - b.order);
      hiddenLinks.sort((a: any, b: any) => a.order - b.order);

      menu.setMenu(favoriteLinks, ungroupedLinks, hiddenLinks, title);
      menu.updateState(false);
    }
  }
};

onMounted(() => {
  const currentMenu = menu.getMenu;

  findCurrentMenu(currentMenu.title);
  menu.setMenu(currentMenu.favorites, currentMenu.ungrouped, currentMenu.hidden, currentMenu.title);
});
</script>

<style lang="scss">
.nav-primary {
  &__links {
    display: flex;
    margin: 0 -30px;
    align-items: center;
  }

  &__link {
    position: relative;
    margin: 0 5px;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    //transition: .3s;

    &:first-child{
      margin-left: 0;
    }

    &.--current{
      border-radius: 4px;
      background: white;

      &:hover{
        background: white;
      }

    }

    &:hover{
      background: var(--color-gray-300);
    }

  }

  &.--disabled {
    .nav-primary {
      &__link {
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }
}
</style>
