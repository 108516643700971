import { defineStore, StoreDefinition } from 'pinia';

export const useConstans: StoreDefinition = defineStore('constans', {
  persist: true,
  state: () => ({ constans: [] as any }),
  actions: {
    setConstans(data: object[]): void {
      this.constans = data;
    },
  },
});
