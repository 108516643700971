<template>
  <div
    v-if="currentImage"
    style="width: 100%; height: 90%"
  >
    <img
      v-if="currentImage.mime_type.includes('image')"
      class="object-contain h-full w-full"
      :src="`${currentImage.url}?h=600&fit=contain`"
    >
  </div>
  <div
    v-if="currentImage"
    class="absolute top-2/4 left-0 right-0 flex justify-between"
  >
    <div
      class="rounded-full text-center text-2xl bg-white mx-3 p-2 cursor-pointer"
      @click="setCurrentImage(currentImage.index - 1)"
    >
      <Icon
        name="caret"
        class="rotate90 w-4 h-4"
      />
    </div>
    <div
      class="rounded-full text-center text-2xl bg-white mx-3 p-2 cursor-pointer"
      @click="setCurrentImage(currentImage.index + 1)"
    >
      <Icon
        name="caret"
        class="rotate-90 w-4 h-4"
      />
    </div>
  </div>
  <div
    v-if="currentImage"
    class="flex w-full justify-center gap-2 py-3"
  >
    <div
      v-for="(image, index) of images"
      :key="index"
      class="rounded-full w-3 h-3 cursor-pointer"
      :class="currentImage.index === index ? 'bg-cyan-500' : 'bg-white'"
      @click="setCurrentImage(index)"
    />
  </div>
</template>

<script setup lang="ts">
import {
  Ref,
  onMounted,
  ref,
} from 'vue';

import Icon from '@/components/share/Icon/Icon.vue';

const props = defineProps({
  images: {
    type: Array,
    default: [],
  },
});

const currentImage: Ref = ref(null);

const setCurrentImage = (n: number) => {
  if (n >= 0 && n < props.images.length) {
    currentImage.value = props.images[n];
    currentImage.value.index = n;
  }
};

onMounted(() => {
  setCurrentImage(0);
});
</script>
