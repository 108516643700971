import { defineStore } from 'pinia';

export const useTasks = defineStore('tasks', {
  state: () => ({
    filled: false as boolean,
    items: [] as object[],
  }),
  actions: {
    setItems(items: object[]): void {
      this.items = items;
      this.filled = true;
    },
    removeItems(): void {
      this.items = [];
      this.filled = false;
    },
  },
});
