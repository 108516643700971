<template>
  <div
    class="sections"
    :class="inTab ? 'rounded-b-lg px-2 pb-2' : 'rounded-b-lg rounded-tr-lg p-2'"
  >
    <div class="sections__wrap h-full">
      <slot />
    </div>
    <aside
      v-if="isRside"
      class="h-100 ml-2"
    >
      <slot name="rside" />
    </aside>
    <Loader
      class="sections__loader"
      :class="loading ? '--show' : ''"
    />
  </div>
</template>
<script src="./Sections.ts" lang="ts"></script>
<style src="./Sections.scss" lang="scss"></style>
