import { ComputedRef, Ref } from 'vue';
import { storeToRefs } from 'pinia';

import { TDynamicNavigationTab, useDynamicNavigationTabsStore } from '@/store/dynamicNavigationTabs';

type TUseDynamicNavigationTabs = {
  tabsBranch: Ref<Array<TDynamicNavigationTab>>,
  tabsStash: Ref<Array<TDynamicNavigationTab>>,
  activeTab: ComputedRef<TDynamicNavigationTab | null>,
  hasStashedTabs: ComputedRef<boolean>,
  hasBranchedTabs: ComputedRef<boolean>,

  removeTabFromBranch: (tab: TDynamicNavigationTab) => void,
  removeTabFromStash: (tab: TDynamicNavigationTab) => void,
  setTabsLineFull: (value: boolean) => void,
}

export const useDynamicNavigationTabs = (): TUseDynamicNavigationTabs => {
  const dynamicNavigationTabsStore = useDynamicNavigationTabsStore();

  const {
    removeTabFromStash,
    removeTabFromBranch,
    setTabsLineFull,
  } = dynamicNavigationTabsStore;

  const {
    tabsBranch,
    tabsStash,
    activeTab,
    hasStashedTabs,
    hasBranchedTabs,
  } = storeToRefs(dynamicNavigationTabsStore);

  return {
    tabsBranch,
    tabsStash,
    activeTab,
    hasStashedTabs,
    hasBranchedTabs,

    removeTabFromBranch,
    removeTabFromStash,
    setTabsLineFull,
  };
};
