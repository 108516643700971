<template>
  <div class="flex items-center gap-2">
    <H1
      class="head__title"
      :style="`color: ${color}`"
    >
      {{ title }}
    </H1>
    <Status
      v-if="status"
      :type="status"
    />
    <DocTree
      v-if="docTreeParams.isDocTree"
      :params="docTreeParams"
    />
    <NotificationsBell
      v-if="notificationsLength > 0"
      :notificationsLength="notificationsLength"
      @click="showItemNotifications"
    />
    <template v-if="titleButtons.length">
      <div
        v-for="button of titleButtons"
        :key="button.id"
      >
        <Button
          v-if="button.isShow"
          size="sm"
          :mods="['bg-secondary']"
          @click="onClickButton(button)"
        >
          {{ button.label }}
        </Button>
      </div>
    </template>
    <div
      v-if="$slots.controls"
      class="flex gap-2 ml-auto"
    >
      <slot name="controls" />
    </div>
  </div>
</template>

<script setup lang="ts">
import H1 from '@/components/typography/H1/H1.vue';
import Status from '@/components/share/Status/Status.vue';
import DocTree from '@/components/share/DocTree/DocTree.vue';
import Button from '@/components/form/Button/Button.vue';
import NotificationsBell from '@/components/share/NotificationsBell/NotificationsBell.vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  status: { type: Number },
  color: {
    type: String,
    default: '',
  },
  docTreeParams: {
    type: Object,
    default: {},
  },
  notificationsLength: {
    type: Number,
    default: 0,
  },
  titleButtons: {
    type: Array,
    default: [],
  } as any,
});

const emit = defineEmits(['action', 'showItemNotifications']);

const showItemNotifications = () => {
  emit('showItemNotifications', true);
};

const onClickButton = (event: any) => {
  emit('action', event);
};
</script>
