import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiMap: any = {
  generatePinCode: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.pinCode.generating,
  },
  login: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.pinCode.login,
  },
};

const apiPinCode = ApiGateway.createServices(apiMap);

export default apiPinCode;
