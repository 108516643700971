<template>
  <div
    class="button"
    :class="classes"
    @click="handleClick"
  >
    <div
      v-if="$slots.left"
      class="button__left"
    >
      <slot name="left" />
    </div>
    <div
      v-if="$slots.default"
      class="button__main"
    >
      <slot />
    </div>
    <div
      v-if="$slots.left"
      class="button__right"
    >
      <slot name="right" />
    </div>
    <div
      v-if="!!badge && badge.value"
      class="badge"
    >
      {{ badge.value }}
    </div>
  </div>
</template>
<script src="./Button.ts" lang="ts"></script>
<style src="./Button.scss" lang="scss"></style>
