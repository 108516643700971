export enum EToggleState {
  on = 'on',
  off = 'off',
  booleanFrom = 'booleanFrom',
  inverted = 'inverted',
  initial = 'initial',
}

export enum EViewState {
  idle = 'idle',
  loading = 'loading',
  done = 'done',
  withError = 'withError',
}

export enum ELocale {
  ru = 'ru',
}

export enum ESentryEnvironment {
  production = 'production',
  staging = 'staging',
}
