import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiStocksMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.stocks.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.stocks.item,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.stocks.list,
  },
  updateItem: {
    method: ApiRequestMethod.PUT,
    route: ApiRoutes.stocks.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.stocks.item,
  },
};

const apiStocksServices = ApiGateway.createServices(apiStocksMap);

export default apiStocksServices;
