import { defineStore, StoreDefinition } from 'pinia';

export const useFilters: StoreDefinition = defineStore('filters', {
  state: () => ({
    key: '' as string,
    clear: false as boolean,
  }),
  actions: {
    setKey(key: string) {
      this.key = key;
      this.clear = false;
    },
    clearKey() {
      this.key = '';
      this.clear = true;
    },
  },
  getters: {
    getFilter(): string {
      return this.key;
    },
  },
});
