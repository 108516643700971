<template>
  <NavAside :class="{ 'nav-aside__infinity-scroll': infinityScroll }" />
  <UIArea
    class="flex flex-col h-screen"
    :class="{
      'area__infinity-scroll': infinityScroll,
      'area__show-btn-to-top': showBtnToTop,
    }"
  >
    <slot />
  </UIArea>
  <teleport to="#nav-aside-links">
    <!--    <NavList-->
    <!--      v-if="keyMenu === 'tasks'"-->
    <!--      :links="links[linkType]"-->
    <!--    />-->
    <NavMenu />
  </teleport>
</template>

<script setup lang="ts">
import {
  Ref,
  ref,
  onMounted,
  onActivated,
} from 'vue';

import tt from '@/i18n/translateText';
import { useEnums } from '@/store/enums';
import { useTasks } from '@/store/tasks';
import { useProducts } from '@/store/products';
import apiEnumsServices from '@/utils/api/services/apiEnumsServices';
import apiTasks from '@/utils/api/services/apiTasks';
import NavMenu from '@/components/nav/NavMenu/NavMenu.vue';
import UIArea from '@/components/share/Area/index.vue';
import NavAside from '@/components/nav/NavAside/NavAside.vue';
import { useHoldPageEditDrafts } from './application/useHoldPageEditDrafts';

const tasks = useTasks();
const productsStore = useProducts();
const enumsStore = useEnums();
const state: Ref = ref({ isLoading: false });
const props = defineProps<{
  linkType?: string,
  keyMenu?: string,
  infinityScroll?: true,
  showBtnToTop?: boolean
}>();
const links: Ref = ref({
  linksDir: [
    {
      title: tt('organizations.title.list'),
      route: { name: 'Organizations' },
    },
    {
      title: tt('clients.title.list'),
      route: { name: 'Clients' },
    },
    {
      title: tt('contracts.title.list'),
      route: { name: 'Contracts' },
    },
    {
      title: tt('warehouses.title.list'),
      route: { name: 'Warehouses' },
    },
    {
      title: `${tt('products.title.list')}`,
      route: { name: 'Items' },
    },
    {
      title: tt('categories.title.list'),
      route: { name: 'Categories' },
    },
    {
      title: tt('prices.title.list'),
      route: { name: 'Prices' },
    },
    {
      title: tt('structures.title.list'),
      route: { name: 'Structures' },
    },
    {
      title: tt('retailMoneyStorages.list'),
      route: { name: 'RetailMoneyStorages' },
    },
    {
      title: tt('visitorCounterDevices.list'),
      route: { name: 'VisitorCounterDevices' },
    },
    {
      title: tt('colors.title'),
      route: { name: 'Multicolors' },
    },
    {
      title: tt('baseColors.title'),
      route: { name: 'BaseColors' },
    },
    {
      title: tt('sizes.title.list'),
      route: { name: 'Sizes' },
    },
    {
      title: tt('tags.title'),
      route: { name: 'Tags' },
    },
    {
      title: tt('deliveryRoutes.title.list'),
      route: { name: 'DeliveryRoutes' },
    },
    {
      title: tt('storageRows.title.list'),
      route: { name: 'StorageRows' },
    },
    {
      title: tt('storageRooms.title.list'),
      route: { name: 'StorageRooms' },
    },
    {
      title: tt('storageRacks.title.list'),
      route: { name: 'StorageRacks' },
    },
    {
      title: tt('storageShelves.title.list'),
      route: { name: 'StorageShelves' },
    },
    {
      title: tt('storagePlaces.title.list'),
      route: { name: 'StoragePlaces' },
    },
    {
      title: tt('deliveryCompanies.title.list'),
      route: { name: 'DeliveryCompanies' },
    },
    {
      title: tt('promocodes.title.list'),
      route: { name: 'Promocodes' },
    },
    {
      title: tt('certificates.title.list'),
      route: { name: 'Certificates' },
    },
    {
      title: tt('discounts.title.list'),
      route: { name: 'Discounts' },
    },
    {
      title: tt('reasonReturns.title.list'),
      route: { name: 'ReasonReturns' },
    },
    {
      title: tt('moneyStorage.title.list'),
      route: { name: 'MoneyStorage' },
    },
    {
      title: tt('coupon.title.list'),
      route: { name: 'Coupons' },
    },
    {
      title: tt('itemsName.title.list'),
      route: { name: 'itemsNames' },
    },
    {
      title: tt('banks.title.list'),
      route: { name: 'Banks' },
    },
    {
      title: tt('currencies.title.list'),
      route: { name: 'Currencies' },
    },
  ],
  linksDoc: [
    {
      title: tt('buyerOrders.title.list'),
      route: { name: 'BuyerOrders' },
    },
    {
      title: tt('supplierOrders.title.list'),
      route: { name: 'SupplierOrders' },
    },
    {
      title: tt('internalOrder.title.list'),
      route: { name: 'InternalOrders' },
    },
    {
      title: tt('dispatchProducts.title.list'),
      route: { name: 'DispatchProducts' },
    },
    {
      title: tt('priceSettings.title.list'),
      route: { name: 'PriceSettings' },
    },
    {
      title: 'Перемещение товаров',
      route: { name: 'MovementProducts' },
    },
    {
      title: tt('receiptInvoice.title.list'),
      route: { name: 'ReceiptInvoices' },
    },
    {
      title: tt('receiptOrders.title.list'),
      route: { name: 'ReceiptOrders' },
    },
    {
      title: tt('expenseInvoice.title.list'),
      route: { name: 'ExpenseInvoices' },
    },
    {
      title: tt('expenditureOrders.title.list'),
      route: { name: 'ExpenditureOrders' },
    },
    {
      title: tt('returnInvoice.title.list'),
      route: { name: 'ReturnInvoices' },
    },
    {
      title: tt('writeOffGoods.title.list'),
      route: { name: 'WriteOffGoods' },
    },
    {
      title: tt('postingOfGoods.title.list'),
      route: { name: 'PostingOfGoods' },
    },
    {
      title: tt('inventory.title.list'),
      route: { name: 'Inventory' },
    },
    {
      title: tt('documentCashChecks.title.list'),
      route: { name: 'CashChecks' },
    },

    // test link
    // {
    //   title: 'Infinity Scroll',
    //   route: {
    //     name: 'InfinityScroll'
    //   }
    // },

    {
      title: tt('cashOrders.list'),
      route: { name: 'CashOrders' },
    },
    {
      title: tt('movingBetweenStorageCells.title.list'),
      route: { name: 'MovingBetweenStorageCells' },
    },
    {
      title: tt('shiftClosures.title.list'),
      route: { name: 'ShiftClosures' },
    },
    {
      title: tt('routeSheets.title.list'),
      route: { name: 'RouteSheets' },
    },
    {
      title: tt('recalculationProducts.title.list'),
      route: { name: 'RecalculationProducts' },
    },
    {
      title: tt('confirmBalanceProducts.title.list'),
      route: { name: 'ConfirmBalance' },
    },
    {
      title: tt('returnRequests.title.list'),
      route: { name: 'ReturnRequests' },
    },
    {
      title: tt('paymentBuyerPaymentCards.title.list'),
      route: { name: 'PaymentBuyerPaymentCards' },
    },
    {
      title: tt('movementsCashBetweenMoneyStorages.list'),
      route: { name: 'MovementsCashBetweenMoneyStorages' },
    },
    {
      title: tt('salesPlan.title.list'),
      route: { name: 'SalesPlan' },
    },
  ],
  admin: [
    {
      title: tt('users.title.list'),
      route: { name: 'Users' },
    },
    {
      title: tt('roles.title.list'),
      route: { name: 'Roles' },
    },
    {
      title: tt('documentEditings.title'),
      route: { name: 'Document-Editings' },
    },
    {
      title: tt('directoriesBlocked.title'),
      route: { name: 'Model-Editings' },
    },
    {
      title: tt('constants.list'),
      route: { name: 'Constants' },
    },
    {
      title: tt('menu.title.list'),
      route: { name: 'Menu' },
    },
    {
      title: tt('staticPageSettings.title.list'),
      route: { name: 'InstructionSettings' },
    },
  ],
  tools: [
    {
      title: tt('fileManager.title'),
      route: { name: 'FileManager' },
    },
    {
      title: tt('cashierWorkplace.title'),
      route: { name: 'CashierWorkplace' },
    },
  ],
  siteSettings: [
    {
      title: tt('siteSettings.mainPageEditor.title'),
      route: { name: 'MainPageEditor' },
    },
    {
      title: tt('shopCategories.title.list'),
      route: { name: 'ShopCategories' },
    },
    {
      title: tt('staticPageSettings.title.list'),
      route: { name: 'StaticPageSettings' },
    },
  ],
  tasks: tasks.items,
});

/**
 * Get enums
 *
 * @function
 * @async
 * @return Promise<void>
 */
const getEnums = async (): Promise<void> => {
  if (Object.keys(enumsStore.enums).length) return;

  const response = await apiEnumsServices.getList();

  if (response.status === 200) {
    enumsStore.setEnums(response.data);
  }
};

/**
 * @function
 * @async
 * @return Promise<void>
 */
const getTasks = async (): Promise<void> => {
  state.value.isLoading = true;

  const response = await apiTasks.getList({}, { params: { include: 'user' } });

  if (response.status === 200) {
    const taskLinks = response.data.map((el: any) => ({
      title: el.attributes.title,
      route: {
        name: 'Task',
        params: { id: el.attributes.id },
      },
    }));

    links.value.tasks = taskLinks;
    tasks.setItems(taskLinks);
  }

  state.value.isLoading = false;
};

onMounted(async () => {
  await getEnums();

  if (!tasks.filled && props.linkType === 'tasks') {
    await getTasks();
  }

  // Стас, привет! =) Хорошо "убрал" эту строчку, прям умница =)
  productsStore.clearScannerCurrentData();
});

// TODO: Временный фикс для задачи https://tools4b.atlassian.net/browse/CFERP-2875
const { syncPageDraftsToStore } = useHoldPageEditDrafts();
onActivated(syncPageDraftsToStore);
</script>
