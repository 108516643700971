import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';

export type TDynamicNavigationTab = {
  title: string;
  fullPath: string;
  [key: string]: unknown;
}

export const useDynamicNavigationTabsStore = defineStore('dynamicNavigationTabs', () => {
  const route = useRoute();

  const tabsBranch = ref<Array<TDynamicNavigationTab>>([]);
  const tabsStash = ref<Array<TDynamicNavigationTab>>([]);
  const isTabsLineFull = ref<boolean>(false);
  const tabs = computed<Array<TDynamicNavigationTab>>(() => [...tabsBranch.value, ...tabsStash.value]);

  const hasBranchedTabs = computed(() => Boolean(tabsBranch.value.length));
  const hasStashedTabs = computed(() => Boolean(tabsStash.value.length));
  const activeTab = computed(() => tabs.value.find((tab: TDynamicNavigationTab) => tab.fullPath === route.fullPath) || null);

  function setTabsLineFull(isFull: boolean) {
    isTabsLineFull.value = isFull;
  }

  function findTab(tab: TDynamicNavigationTab) {
    return tabs.value.find((_: TDynamicNavigationTab) => _.title === tab.title);
  }

  function pushTabIntoBranch(tab: TDynamicNavigationTab) {
    if (findTab(tab)) return;
    tabsBranch.value.push(tab);
  }

  function removeTabFromBranch(tab: TDynamicNavigationTab) {
    const index = tabsBranch.value.findIndex((_: TDynamicNavigationTab) => _.title === tab.title);

    if (index >= 0) {
      tabsBranch.value.splice(index, 1);

      if (hasStashedTabs.value) {
        const tab = tabsStash.value.pop();

        if (tab) pushTabIntoBranch(tab);
      }
    }
  }

  function pushTabIntoStash(tab: TDynamicNavigationTab) {
    if (findTab(tab)) return;
    tabsStash.value.push(tab);
  }

  function removeTabFromStash(tab: TDynamicNavigationTab) {
    const index = tabsStash.value.findIndex((_: TDynamicNavigationTab) => _.title === tab.title);

    if (index >= 0) {
      tabsStash.value.splice(index, 1);
    }
  }

  function pushTabInto(tab: TDynamicNavigationTab) {
    if (isTabsLineFull.value) {
      pushTabIntoStash(tab);
    } else {
      pushTabIntoBranch(tab);
    }
  }

  return {
    tabsBranch,
    tabsStash,
    activeTab,
    hasStashedTabs,
    hasBranchedTabs,
    isTabsLineFull,

    findTab,
    pushTabIntoBranch,
    removeTabFromBranch,
    pushTabIntoStash,
    removeTabFromStash,
    setTabsLineFull,
    pushTabInto,
  };
}, { persist: { paths: ['tabsBranch', 'tabsStash'] } });
