import { createServices } from '@/utils/api/apiService';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiServicesMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.enums.list,
  },
};

const apiEnumstServices = createServices(apiServicesMap);

export default apiEnumstServices;
