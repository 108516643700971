<template>
  <div
    v-if="!infinityScroll"
    class="selected-params"
  >
    <div
      v-if="sort.desc.length > 0"
      class="selected-params__list"
    >
      <div class="selected-params__key">
        Сортировка
      </div>
      <div class="selected-params__item">
        {{ sort.desc }}
        <span
          class="selected-params__remove"
          @click="clearSort"
        >&times;</span>
      </div>
    </div>
    <div
      v-for="(item, keyId) in filterParams"
      :key="keyId"
      class="selected-params__list"
    >
      <div
        v-if="item.value.length > 0 && typeof item.value !== 'string'"
        class="selected-params__key"
      >
        {{ item.title }}
      </div>
      <div
        v-for="(itemFilter, itemId) in item.value"
        v-if="item.value.length > 0 && typeof item.value[0] === 'object' && item.value[0] !== null && item.filterName !== 'date'"
        :key="itemId"
        class="selected-params__item"
      >
        {{ itemFilter.name || itemFilter.title || itemFilter.attributes?.name || itemFilter.attributes?.title
          || itemFilter.attributes?.comment || itemFilter.id }}
        <span
          class="selected-params__remove"
          @click="clearFilter(item.filterName, itemFilter)"
        >&times;</span>
      </div>
      <div
        v-for="(itemFilter, itemId) in item.value"
        v-if="item.value.length > 0 && typeof item.value === 'string' && item.filterName !== 'date'"
        :key="itemId"
        class="selected-params__item"
      >
        {{ item.title }}
        <span
          class="selected-params__remove"
          @click="clearFilter(item.filterName, itemFilter)"
        >&times;</span>
      </div>
      <div
        v-if="item.value.length > 0 && typeof item.value[0] === 'object' && item.value[0] !== null && item.filterName === 'date'"
        class="selected-params__item"
      >
        {{ toDateFormatRU(item.value[0]) + ' - ' + toDateFormatRU(item.value[1]) }}
        <span
          class="selected-params__remove"
          @click="clearFilter(item.filterName)"
        >&times;</span>
      </div>
    </div>
  </div>
  <div class="list">
    <div class="list__body">
      <table class="list__table --overflow-auto">
        <thead>
          <tr>
            <th
              v-if="checks"
              width="38"
            >
              <Checkbox
                type="boolean"
                size="sm"
                :edit="true"
                :mods="['b-gray', 'bg-white']"
                :checked="checked.length === data.length"
                @click="handleClickListCheckbox"
              />
            </th>
            <th v-if="props.draggable" />
            <th
              v-for="(cell, index) in tableRef"
              :key="index"
              :width="cell.width"
            >
              <div
                v-if="cell.filterSortOnly"
                class="list__filter"
              >
                <span
                  class="list__filter__name flex-nowrap"
                >
                  <Icon
                    v-if="cell.sortColumn"
                    class="sort-btn"
                    name="sort"
                    @click.prevent.stop="sortByField(cell)"
                  />
                  {{ cell.title }}
                </span>
              </div>
              <div
                v-else-if="cell.filterApi || cell.filterAvailableData"
                class="list__filter"
              >
                <span
                  class="list__filter__name flex-nowrap"
                  :class="{
                    '--active': filterStore.key === cell.filterName
                  }"
                  @click.stop.prevent="filterApiRequest(cell)"
                >
                  <Icon
                    v-if="cell.sortColumn"
                    class="sort-btn"
                    name="sort"
                    @click.prevent.stop="sortByField(cell)"
                  />
                  {{ cell.title }}
                  <Icon
                    class="filter-icon"
                    name="filter-outline"
                  />
                </span>
                <div
                  v-if="cell.filterShow"
                  class="list__filter__list"
                >
                  <span
                    v-if="cell.filterShow"
                    class="list__filter__close"
                    @click.prevent.stop="closeFilter(cell)"
                  >
                    &times;
                  </span>
                  <Loader
                    v-if="cell.filterDataLoading"
                    class="searcher__loader --show"
                  />
                  <div
                    v-if="cell.isSearchInput ?? true"
                    class="list__filter__input-wrap"
                  >
                    <input
                      type="text"
                      class="list__filter__input"
                      :placeholder="$t('search.placeholder')"
                      @click.prevent.stop
                      @input.prevent.stop="listSearch($event, cell.filterSearchKey, cell)"
                    >
                  </div>
                  <div
                    v-if="cell.filterSearchData"
                    class="list__filter__input-wrap"
                  >
                    <input
                      type="text"
                      class="list__filter__input"
                      :placeholder="$t('search.placeholder')"
                      @click.prevent.stop
                      @input.prevent.stop="dataSearch($event, cell.filterKey, cell)"
                    >
                  </div>
                  <div class="list__filter__buttons --border-bottom">
                    <button
                      type="button"
                      class="list__filter__button --secondary"
                      @click.stop.prevent="selectItems(cell)"
                    >
                      Выделить всё
                    </button>
                    <button
                      type="button"
                      class="list__filter__button --grey"
                      @click.stop.prevent="unselectItems(cell)"
                    >
                      Очистить
                    </button>
                  </div>
                  <div
                    v-if="!cell.filterSearchData"
                    class="list__filter__data"
                    :class="{
                      '--blocked': cell.filterNullable?.active
                    }"
                  >
                    <div
                      v-for="(item, itemId) in cell.filterData"
                      v-if="cell.filterData.length > 0 && !cell.filterDataLoading"
                      :key="itemId"
                      class="list__filter__item"
                      @click.prevent.stop="selectItem(item, cell.filterName)"
                    >
                      <span
                        class="list__filter__checkbox"
                        :class="{ '--checked': isSelectedItem(item, cell.filterName) }"
                      />
                      <div v-if="cell.type == 'payment'">
                        <div class="flex">
                          <Icon
                            class="payment-icon mr-1"
                            :name="item.slug == 'prepaid' ? 'certificate' : item.slug"
                          />
                          {{ item.name || item.title || item.attributes?.name || item.attributes?.title || item.id
                            || item.attributes?.comment }}
                        </div>
                      </div>
                      <div v-else>
                        {{ cell.filterLabel ? item[cell.filterLabel].title || item[cell.filterLabel]
                          : item[cell.filterSearchKey] }}
                      </div>
                    </div>
                  </div>
                  <!--                  <button-->
                  <!--                    type="button"-->
                  <!--                    v-if="cell.filterNullable"-->
                  <!--                    :class="{-->
                  <!--                      '&#45;&#45;active': cell.filterNullable.active,-->
                  <!--                      'list__filter__nullable-btn': true,-->
                  <!--                    }"-->
                  <!--                    @click.stop.prevent="selectNullableFilter(cell)"-->
                  <!--                  >-->
                  <!--                    {{ cell.filterNullable.label }}-->
                  <!--                  </button>-->
                  <div class="list__filter__buttons">
                    <button
                      type="button"
                      class="list__filter__button --secondary"
                      @click.stop.prevent="applySelectedFilters(cell)"
                    >
                      Применить
                    </button>
                    <button
                      type="button"
                      class="list__filter__button --grey"
                      @click.stop.prevent="closeFilter(cell)"
                    >
                      Закрыть
                    </button>
                  </div>
                </div>
              </div>
              <span
                v-else-if="cell.filterDate"
                class="list__calendar"
              >
                <label
                  class="list__calendar__label"
                  @click="openDatePicker(cell)"
                >
                  <span class="list__filter__name">
                    <Icon
                      v-if="cell.sortColumn"
                      class="sort-btn"
                      name="sort"
                      @click.prevent.stop="sortByField(cell)"
                    />
                    {{ cell.title }}
                    <Icon name="caret" />
                  </span>
                  <span class="list__filter__calendar__picker">
                    <VueDatePicker
                      v-model="filterDate.value"
                      teleport="body"
                      :enableTimePicker="false"
                      class="field__calendar"
                      locale="ru"
                      autoApply
                      format="dd.MM.yyyy"
                      monthNameFormat="long"
                      position="left"
                      range
                      @update:model-value="onTimePickerClose"
                    />
                  </span>
                </label>
              </span>
              <span
                v-else-if="cell.sortDate"
                class="list__sort"
                @click="sortByDate"
              >
                {{ cell.title }}
                <Icon name="sort" />
              </span>
              <span v-else>{{ cell.title }}</span>
            </th>
            <th
              v-if="subtable || props.remove"
              width="30"
            />
          </tr>
        </thead>
        <Sortable
          v-if="props.draggable"
          :list="dataForDrag ? dataForDrag : data"
          itemKey="id"
          tag="tbody"
          :options="isMultiDrag ? multiDragOptions : dragOptions"
          @update="sortableEvent($event)"
        >
          <template #item="{ element }">
            <ListItem
              :draggable="true"
              :checks="checks"
              :table="table"
              :row="element"
              :page="page"
              :item="props.item"
              :subtable="subtable"
              :remove="props.remove"
              :link="productLink"
              @remove="removeItem"
              @openItemStock="openItemStock"
              @getRow="getRow"
              @action="handlerListItemAction"
            />
          </template>
        </sortable>
        <template v-else>
          <tbody>
            <ListItem
              v-for="(row, rowIndex) in data"
              :key="rowIndex"
              :class="{ 'red-row': row?.attributes?.type_of_operation === 'returns' }"
              :checks="checks"
              :table="table"
              :row="row"
              :item="props.item"
              :page="page"
              :subtable="subtable"
              :remove="props.remove"
              :link="productLink"
              @remove="removeItem"
              @openItemStock="openItemStock"
              @showItemNotifications="showItemNotifications"
              @action="handlerListItemAction"
              @getRow="getRow"
            />
            <tr
              v-if="props.result?.length"
              class="bg-slate-50"
              style="cursor: default;"
            >
              <td
                v-for="td of props.result"
                :key="td.cell"
                style="border-style: none;"
              >
                <div class="total-sum font-bold text-sm">
                  {{ td.result }}
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </table>
    </div>
    <div
      v-if="infinityScroll?.observerTarget"
      :class="setCssClass(infinityScroll.observerTarget)"
      :style="infinityScroll?.hideObserver ? 'display:none' : 'display:block'"
    >
      <div
        v-if="infinityScrollLoader && infinityScroll.lastPage !== infinityScroll.page"
        class="loader__icon"
      >
        <Icon name="circle-notch" />
        <span class="loader__desc">Загрузка ...</span>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <Modal
      :open="modal.isModalOpen"
      :title="modal.title"
      :buttonText="modal.buttonConfirm"
      :buttonCancel="modal.buttonCancel"
      :isStandardButtons="modal.isStandardButtons"
      :okDisabled="modal.okDisabled"
      :width="modal.width"
      :height="modal.height"
      :background="modal.background"
      @action="modalAction"
    >
      <div
        v-if="modal.text"
        class="text-base text-gray-900 mb-3"
      >
        {{ modal.text }}
      </div>
    </Modal>
  </Teleport>
  <div v-if="data?.length <= 0 && filterParams.some(el=> el.value?.length !== 0)" class="empty-data">
    В списке нет данных соотвествующих условию
  </div>
</template>
<script lang="ts" setup>
import {
  ref,
  Ref,
  watch,
  onActivated,
  onMounted,
  computed,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import SortableJs from 'sortablejs';
import { MultiDrag } from 'sortablejs/modular/sortable.core.esm';
import { Sortable } from 'sortablejs-vue3';

import { useNotifications } from '@/store/notifications';
import { useFilters } from '@/store/filters';
import { toDateFormatRU } from '@/utils/date/toDateFormat';
import ListItem from '@/components/share/List/ListItem/ListItem.vue';
import Checkbox from '@/components/share/Checkbox/Checkbox.vue';
import Icon from '@/components/share/Icon/Icon.vue';
import Loader from '@/components/share/Loader/Loader.vue';
import Modal from '@/components/share/Modal/Modal.vue';
// https://www.npmjs.com/package/sortablejs-vue3
// https://github.com/SortableJS/Sortable/tree/master/plugins/MultiDrag
try {
  SortableJs?.mount(new MultiDrag());
} catch (error) {

}

const dragOptions:Ref = ref({ handle: '.list__drag-handler' });

const sortableEvent = (event:any) => {
  emits('onDragEnd', event);
  for (const i in event.items) {
    SortableJs.utils.deselect(event.items[i]);
  }
};
const multiDragOptions:Ref = ref({
  multiDrag: true, // Enable the plugin
  selectedClass: 'sortable-selected', // Class name for selected item
  avoidImplicitDeselect: false, // true - if you don't want to deselect items on outside click
});
const props = defineProps([
  'table',
  'subtable',
  'searchValue',
  'searchApiKey',
  'data',
  'page',
  'item',
  'checks',
  'sortDate',
  'remove',
  'openItemStock',
  'productLink',
  'draggable',
  'multiDragKey',
  'infinityScroll',
  'isSearchInput',
  'result',
  'isMultiDrag',
  'dataForDrag',

]);
const emits = defineEmits(['getItem', 'showItemNotifications', 'remove', 'openItemStock', 'sortByDate', 'sortByField', 'filter', 'onDragEnd', 'updateList', 'action']);
const sortType: Ref<string> = ref('created_at:desc');
const checked: Ref = ref([]);
const { t } = useI18n();
const route = useRoute();
const notifications = useNotifications();
const filterStore: Ref = ref(useFilters());
const tableRef = computed(() => props.table);
const sort: Ref = ref({
  desc: '' as string,
  type: '' as string,
});
const filterParams: Ref = ref(
  props.table.filter((c: any) => c.filter).map((c: any) => ({
    title: c.title,
    filterName: c.filterName,
    filterNullable: c.filterNullable || null,
    key: c.filterKey,
    value: [],
  })),
);
const filterDate: Ref = ref(filterParams.value.find((f: any) => f.filterName === 'date'));
const infinityScrollLoader = ref(props.infinityScroll?.loader);
const infinityScrollAdditionalHeight = ref(props.infinityScroll?.additionalHeight);
const removeId: Ref = ref(null);
const modal: Ref = ref({
  isModalOpen: false,
  title: t('deleteModal.title'),
  isStandardButtons: true,
  okDisabled: false,
  buttonConfirm: t('deleteModal.buttons.confirm'),
  buttonCancel: t('deleteModal.buttons.cancel'),
  text: t('deleteModal.desc'),
  component: '',
  width: '540px',
  height: 'auto',
});

const handlerListItemAction = (event: any) => {
  switch (event.action) {
    case 'reprint':
      emits('action', { action: event.action, id: event.id });
      break;
    case 'dismiss-toggle':
      emits('action', { action: event.action, id: event.id });
      break;
    case 'dragTo':
      emits('action', { action: event.action, row: event.row });
      break;
    case 'getCellAndRow':
      emits('action', { action: event.action, value: event.value });
      break;

    default:
      break;
  }
};

const handleClickListCheckbox = (): void => {
  checked.value.length === props.data.length
    ? checked.value = []
    : props.data.forEach((item: any) => checked.value.push(item));
};

const showItemNotifications = (id: number): void => emits('showItemNotifications', id);

const removeItem = (id: number): void => {
  modal.value.isModalOpen = true;
  removeId.value = id;
  // emits('remove', id)
};

const modalAction = (event: any) => {
  if (event === 'ok' && removeId.value) {
    emits('remove', removeId.value);
  }
  modal.value.isModalOpen = false;
};

/**
 * Remove an item
 *
 * @function
 * @param id
 * @return void
 */
const openItemStock = (id: number): void => {
  emits('openItemStock', id);
};

/**
 * Sort data by selected date
 *
 * @function
 * @return void
 */
const sortByDate = (): void => {
  sortType.value = sortType.value === 'created_at:desc' ? 'created_at:asc' : 'created_at:desc';

  emits('sortByDate', sortType.value);
};

const sortByField = (cell: any): void => {
  const sortColumn: any = cell.sortColumn.split(' ');

  sortColumn[0] === '+' ? sortColumn[0] = '-' : sortColumn[0] = '+';

  cell.sortColumn = sortColumn.join(' ');

  sort.value.desc = cell.title;

  emits('sortByField', cell.sortColumn);
};

const clearSort = (): void => {
  sort.value.desc = '';

  emits('sortByField', sort.value.desc);
};

const selectItems = (cell: any) => {
  const filterArray: any = filterParams.value.find((f: any) => f.filterName === cell.filterName);

  cell.filterData.forEach((f: any) => filterArray.value.push(f));

  cell.isSelectAll = true;
};

const unselectItems = (cell: any) => {
  const filterArray: any = filterParams.value.find((f: any) => f.filterName === cell.filterName);

  if (filterArray) {
    filterArray.value = [];
  }
};

/**
 * Get data for the filter params
 *
 * @function
 * @async
 * @param cell
 * @param search
 * @return Promise<void>
 */
const filterApiRequest = async (cell: any, search: boolean = false): Promise<void> => {
  props.table.forEach((c: any) => {
    if (c.filterShow && c?.filterName !== 'date') {
      c.filterShow = false;
    }
  });

  if (cell.filterDataLoading) cell.filterDataLoading = true;
  cell.filterShow = true;

  if (!search && cell.filterParams) {
    cell.filterParams.filters = '';
  }

  if (!cell.filterAvailableData && !cell.filterSearchData) {
    const response = await cell.filterApi.getList(null, { params: cell.filterParams });

    response.status === 200
      ? cell.filterData = cell.filterDataInstance = cell.filterApiKey
        ? response.data[cell.filterApiKey]
        : response.data.map((item: any) => {
          item.attributes.id = item.attributes.id ? item.attributes.id : Number(item.id);
          return item.attributes;
        })
      : notifications.error('notification.error', response?.data?.message);
  }

  cell.filterDataLoading = false;
};

/**
 * Close a filter
 *
 * @function
 * @param cell
 * @return void
 * */
const closeFilter = (cell: any): void => {
  cell.filterDataLoading = cell.filterShow = false;

  if (cell.filterParams) cell.filterParams.filters = '';

  setTimeout(() => {
    closeAllTheFilters();
  });
};

/**
 * Select an item and emit the filter data
 *
 * @function
 * @param item
 * @param filterName
 * @return void
 */
const selectItem = (item: any, filterName: any): void => {
  const filterArray: any = filterParams.value.find((f: any) => f.filterName === filterName);

  if (isSelectedItem(item, filterName)) {
    const index: number = filterArray.value.findIndex((el: any) => parseInt(el.id) === parseInt(item.id));

    filterArray.value.splice(index, 1);
  } else {
    filterArray.value.push(item);
  }

  // emits('filter', filterParams.value)
};

const applySelectedFilters = (cell: any) => {
  filterStore.value.setKey(cell.filterName);

  const filterParam = filterParams.value.find((f: any) => f.filterName === cell.filterName);

  if (filterParam.value.length === 0) {
    filterStore.value.clearKey();
  }

  emits('filter', filterParams.value);

  closeAllTheFilters();

  setTimeout(() => {
    cell.filterShow = false;

    props.table.forEach((c: any) => {
      if (c.filterShow && c?.filterName !== 'date') {
        c.filterShow = false;
      }
    });
  }, 0);
};

const getRow = (item: any) => {
  emits('getItem', item, props.data);
};

/**
 * Nullable filter
 *
 * @function
 * @param cell
 * @return void
 */
const selectNullableFilter = (cell: any): void => {
  cell.filterNullable.active = !cell.filterNullable.active;

  const filter: any = filterParams.value.find((f: any) => f.filterNullable?.key === cell.filterNullable.key);

  emits('filter', cell.filterNullable.active ? filter : filterParams.value);
};

/**
 * Check if the item has been selected
 *
 * @function
 * @param item
 * @param filterName
 * @return any | object
 */
const isSelectedItem = (item: any, filterName: any): any | object => {
  const checkedArray: any = filterParams.value.find((f: any) => f.filterName === filterName);

  return checkedArray.value.find((el: any) => el?.id === item?.id);
};

/**
 * Open the vudatepicker
 *
 * @function
 * @param cell
 */
const openDatePicker = (cell: any) => cell.filterShow = true;

/**
 * Emit a data then the vudatepicker is closing
 *
 * @function
 */
const onTimePickerClose = () => emits('filter', filterParams.value);

/**
 * Search in the filter list
 *
 * @function
 * @param event
 * @param filterSearchKey
 * @param cell
 * @return Promise<void>
 */
const listSearch = async (event: Event, filterSearchKey: string, cell: any): Promise<void> => {
  const input: EventTarget | any = event.target;

  input.value.length > 0 && cell.filterDateSearchKey
    ? cell.filterParams.filters = btoa(JSON.stringify([
      {
        key: cell.filterDateSearchKey,
        value: { query: encodeURIComponent(input.value) },
      },
    ]))
    : cell.filterParams.filters = '';

  await filterApiRequest(cell, true);
};

/**
 * Data search
 *
 * @param event
 * @param filterSearchKey
 * @param cell
 * @return Promise<void>
 */
const dataSearch = async (event: Event, filterSearchKey: string, cell: any): Promise<void> => {
  const input: Event | any = event.target;
  const isFilterKey: any = filterParams.value.some((f: any) => f.key === cell.filterKey);

  if (input.value.length > 0) {
    if (isFilterKey) {
      filterParams.value.forEach((item: any) => {
        if (item.key == cell.filterKey) {
          item.value = input.value;
        }
      });
    } else {
      filterParams.value.push({
        filterName: 'searchValue',
        key: cell.filterKey,
        value: input.value,
      });
    }
    emits('filter', filterParams.value);
  }
};

/**
 * Clear data in the filter
 *
 * @function
 * @param key
 * @param item
 * @return : void
 */
const clearFilter = (key: string, item: any = false): void => {
  const filterParam = filterParams.value.find((f: any) => f.filterName === key);

  if (item) {
    const index = filterParam.value.findIndex((el: any) => el.id === item.id);

    filterParam.value.splice(index, 1);
  } else {
    filterParam.value = [];
  }

  emits('filter', filterParams.value);
};

/**
 * Get a value with infinite nesting
 *
 * @function
 * @param searchKey
 * @param item
 * @return any | object
 */
const getValue = (searchKey: any, item: any): any | object => {
  if (searchKey.includes('.')) {
    const optionArray: string[] = searchKey.split('.');
    let result: any = null;

    optionArray.forEach((el: any) => {
      result = item[el] ? item[el] : result[el];
    });

    return result;
  }

  return item[searchKey];
};

/**
 * Set CSS class
 *
 * @function
 * @param cssSelector
 * @return string
 */
const setCssClass = (cssSelector: string): string => {
  cssSelector = cssSelector.substring(1);
  return cssSelector;
};

/**
 * Set height to an HTML element
 *
 * @function
 * @param cssSelector
 * @param additionalHeightVal
 * @return void
 */
const setDynamicHeight = (cssSelector: string, additionalHeightVal: any | object = { height: 0, elementAdditional: '' }): void => {
  if (additionalHeightVal.height > 90) {
    throw t('storekeeperWorkplace.notification.height.error');
  }

  const element: HTMLBodyElement | null = document.querySelector(cssSelector);
  const createRoute = route.name?.toString().toLowerCase().includes('create');

  if (element && !route.params.id && !createRoute) {
    const mainSectionHeight = window.innerHeight - element.offsetTop;
    const elementAdditional: HTMLBodyElement | null = additionalHeightVal.elementAdditional.length > 0
      ? document.querySelector(additionalHeightVal.elementAdditional)
      : null;
    const elementAdditionalHeight = (mainSectionHeight / 100) * additionalHeightVal.height;

    element.style.height = `${mainSectionHeight - elementAdditionalHeight}px`;

    if (elementAdditional) {
      elementAdditional.style.height = `${elementAdditionalHeight}px`;
    }
  }
};

/**
 * intersectionObserver
 *
 * @function
 * @param cssSelector
 * @return void
 */
const intersectionObserver = (cssSelector: string): void => {
  const target: HTMLBodyElement | null = document.querySelector(cssSelector);
  const callback: IntersectionObserverCallback = (entries: any) => {
    entries.forEach((entry: any) => {
      const intersecting: boolean = entry.isIntersecting;

      if (target && intersecting && props.infinityScroll.page <= props.infinityScroll.lastPage) {
        infinityScrollLoader.value = true;
        props.infinityScroll.page += 1;
      }

      if (props.infinityScroll.page > props.infinityScroll.lastPage) {
        infinityScrollLoader.value = false;
      }
    });
  };
  const observer = new IntersectionObserver(callback);

  if (target) observer.observe(target);
};

/**
 * onActivated lifecycle hook to set infinityScroll element height
 */
onActivated(() => {
  if (props.infinityScroll?.element && props.infinityScroll?.observerTarget) {
    setDynamicHeight(props.infinityScroll.element, infinityScrollAdditionalHeight.value);
  }

  if (props.infinityScroll) {
    new ResizeObserver(() => {
      setDynamicHeight(props.infinityScroll.element, infinityScrollAdditionalHeight.value);
    }).observe(document.body);
  }
});

const closeAllTheFilters = (): void => {
  window.addEventListener('click', () => {
    const inputs = document.querySelectorAll('.list__filter__input');

    inputs.forEach((input: any) => {
      input.value = '';
    });

    props.table.forEach((c: any) => {
      if (c.filterShow && c?.filterName !== 'date') {
        c.filterShow = false;
      }
    });
  });
};

/**
 * onMounted lifecycle hook to set infinityScroll element height & IntersectionObserver
 */
onMounted(() => {
  if (props.infinityScroll?.element && props.infinityScroll?.observerTarget) {
    setDynamicHeight(props.infinityScroll.element, infinityScrollAdditionalHeight.value);
    intersectionObserver(props.infinityScroll.observerTarget);
  }

  closeAllTheFilters();
});

watch(() => props.searchValue, (value) => {
  const filterArray: any = filterParams.value.find((f: any) => f.filterName === 'searchValue');

  if (filterArray) {
    filterParams.value.forEach((item: any) => {
      if (item.filterName == 'searchValue') {
        item.value = value;
      }
    });
  } else {
    filterParams.value.push({
      filterName: 'searchValue',
      key: props.searchApiKey,
      value,
    });
  }
  emits('filter', filterParams.value);
});

watch(() => filterStore, () => {
  if (filterStore.value.key.length > 0) {
    const findFilter = props.table.find((item: any) => item.filterName === filterStore.value.key);

    filterApiRequest(findFilter);
  } else {
    setTimeout(() => {
      props.table.forEach((item: any) => item.filterShow = false);
      filterStore.value.clearKey();
    });
  }
}, { deep: true });
</script>
<style src="./List.scss" lang="scss"></style>
