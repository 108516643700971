import { defineStore } from 'pinia';

export const useNavAside = defineStore('nav-aside', {
  state: () => ({ navAsideIsShow: true }),
  actions: {
    toggleNavAside() {
      this.navAsideIsShow = !this.navAsideIsShow;
    },
  },
});
