import { defineComponent } from 'vue';

import Loader from '@/components/share/Loader/Loader.vue';

export default defineComponent({
  name: 'Sections',
  components: { Loader },
  props: {
    loading: { type: Boolean },
    isRside: {
      type: Boolean,
      default: false,
    },
    inTab: {
      type: Boolean,
      default: false,
    },
  },
});
