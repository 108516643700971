import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiProductsServicesMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.products.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.products.item,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.products.list,
  },
  updateItem: {
    method: ApiRequestMethod.PUT,
    route: ApiRoutes.products.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.products.item,
  },
  createImagesItem: {
    method: ApiRequestMethod.PUT,
    route: ApiRoutes.products.images.item,
  },
  removeImagesItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.products.images.item,
  },
  createPropertiesItem: {
    method: ApiRequestMethod.PUT,
    route: ApiRoutes.products.properties.item,
  },
  removePropertiesItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.products.properties.item,
  },
};

const apiProductsServices = ApiGateway.createServices(apiProductsServicesMap);

export default apiProductsServices;
