import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiWarehousesMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.warehouses.list,
    properties: false,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.warehouses.item,
    properties: true,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.warehouses.list,
    properties: true,
  },
  updateItem: {
    method: ApiRequestMethod.PUT,
    route: ApiRoutes.warehouses.item,
    properties: true,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.warehouses.item,
    properties: true,
  },
};

const apiWarehousesServices = ApiGateway.createServices(apiWarehousesMap);

export default apiWarehousesServices;
