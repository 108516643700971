<template>
  <input
    ref="inputRef"
    v-model="formattedValue"
    class="field__area focus:outline-none"
    type="text"
    :required="required"
    :readonly="!edit"
  />
</template>

<script lang="ts">
import logger from '@/logger';
import {
  computed,
  defineComponent,
  watch,
  toRefs,
} from 'vue';
import { useCurrencyInput } from 'vue-currency-input';

export default defineComponent({
  name: 'Currency',
  props: {
    modelValue: { required: true },
    required: {
      type: Boolean,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxNumber: {
      type: Number,
      default: 1000000,
    },
    minNumber: { type: Number },
    min: { type: Number },
    max: { type: Number },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput({
      currency: 'USD',
      currencyDisplay: 'hidden',
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      precision: 2,
      useGrouping: false,
      accountingSign: false,
    });

    const { modelValue: numberString } = toRefs(props);

    watch(
      () => numberString.value,
      (value) => {
        const normalizedValue = parseFloat(`${value}`.replace(',', '.'));
        setValue(normalizedValue);
      },
    );

    return { inputRef, formattedValue, setValue };
  },
});
</script>
