<template>
  <div
    v-if="state.isShow"
    class="searcher"
    :class="classes"
  >
    <div class="searcher__inner">
      <button
        type="button"
        class="searcher__close"
        @click="close"
      >
        &times;
      </button>
      <Field
        v-model="query"
        type="text"
        class="searcher__area"
        size="lg"
        :mods="['b-gray']"
        :placeholder="$t('search.placeholder')"
        iconLeft="magnifying-glass"
        :edit="true"
        :required="true"
      />
      <div class="searcher__wrap">
        <div
          v-if="searcher.component"
          class="searcher__result"
        >
          <List
            v-if="container.length > 0"
            :data="container"
            :table="entity[searcher.component].table"
            :subtable="entity[searcher.component].subtable"
            :page="searcher.page"
          />
          <div
            v-else-if="query.length !== 0"
            class="searcher__empty"
          >
            {{ $t('search.empty') }}
          </div>
          <div class="searcher__pagi-holder">
            <NavPagination
              v-model="paginationPage"
              :meta="meta"
            />
          </div>
        </div>
        <Loader
          class="searcher__loader --center"
          :class="state.isLoading ? '--show' : ''"
        />
      </div>
    </div>
    <div
      class="searcher__bg"
      @click="close"
    />
  </div>
</template>
<script src="./Searcher.ts" lang="ts"></script>
<style src="./Searcher.scss"></style>
