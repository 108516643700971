import { defineStore } from 'pinia';

export const useScanner = defineStore('scanner', {
  persist: true,
  state: () => ({
    products: [] as any,
    isShow: false,
    warehouseId: null as any,
    organizationId: null as any,
    params: {} as any,
  }),
  actions: {
    show() {
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
      this.products = [];
    },
    add(product: any) {
      this.products.push(product);
    },
    addWarehouse(id: any) {
      this.warehouseId = id;
    },
    addOrganization(id: any) {
      this.organizationId = id;
    },
    setParams(params: any) {
      this.params = params;
    },
  },
});
