<template>
  <div class="nav-pagination">
    <template v-for="(item, index) in meta.links" :key="item">
      <Button
        v-if="item.url || item.label == '...'"
        class="nav-pagination__item"
        :class="[item.active ? '--current' : '']"
        size="sm"
        :mods="[item.active ? 'bg-primary' : 'bg-gray']"
        @click="item.url ? $emit('update:modelValue', getPage(item.url)) : false"
      >
        <template v-if="(index == 0)">
          <Icon name="caret" class="rotate90" />
        </template>
        <template v-else-if="(index == meta.links.length - 1)">
          <Icon name="caret" class="rotate-90" />
        </template>
        <template v-else>
          {{ item.label }}
        </template>
      </Button>
    </template>
  </div>
</template>
<script src="./NavPagination.ts" lang="ts"></script>
<style src="./NavPagination.scss" lang="scss"></style>
