import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Button',
  data() {
    return { cls: { disabled: '--disabled' } };
  },
  computed: {
    classes() {
      return [
        `--${this.size}`,
        this.mods ? this.mods.map((mod) => `--${mod}`) : '',
        this.disabled ? this.cls.disabled : '',
      ];
    },
    handleClick() {
      if (this.route) {
        this.$router.push(this.route);
      }
    },
  },
  props: {
    disabled: { type: Boolean },
    size: {
      type: String,
      required: true,
    },
    mods: { type: Array<string> },
    route: { type: Object },
    badge: { type: Object },
  },
});
