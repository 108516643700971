import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiPriceTypesMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.priceTypes.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.priceTypes.item,
  },
};

const apiPriceTypesServices = ApiGateway.createServices(apiPriceTypesMap);

export default apiPriceTypesServices;
