<template>
  <div ref="field" class="field" :class="classes" @click="handleClick">
    <div v-if="title && type !== 'modalCall'" class="field__head" :class="ch">
      <div class="field__title">
        {{ $t(title) }}
      </div>
      <div
        v-if="value && value.length > 0"
        class="field__copy"
        @click="handleClickButtonCopy"
      >
        <Icon name="copy" />
      </div>
    </div>
    <div
      class="field__wrap"
      :class="{
        cw,
        relative: relative,
        calendar: type == 'datetime'
      }"
    >
      <div v-show="icon.left" class="field__icon-wrap --left">
        <Icon :name="icon.left" />
      </div>
      <template v-if="!isCustom">
        <input
          v-if="!edit"
          class="field__area focus:outline-none"
          :type="type"
          :readonly="!edit"
          :value="value ? value : '-'"
          :required="required"
        />
        <input
          v-else
          ref="area"
          v-model="value"
          class="field__area focus:outline-none"
          :type="type"
          :minlength="min"
          :maxlength="max"
          :max="type == 'number' ? maxNumber : ''"
          :min="type == 'number' ? minNumber : ''"
          :required="required"
          :readonly="!edit"
          :placeholder="placeholder"
          @input="handleInputArea"
        />
      </template>
      <template v-else>
        <template v-if="type === 'fieldEditor'">
          <field-editor
            v-model="value"
            :editorButtons="editorButtons"
            :isEditing="edit"
          />
        </template>
        <template v-if="type == 'phone'">
          <span class="field__phone-eager">+7</span>
          <input
            ref="area"
            v-model="value"
            v-maska
            class="field__area"
            type="text"
            :readonly="!edit"
            :minlength="min"
            :required="required"
            :placeholder="placeholder"
            data-maska="(###) ###-##-##"
          />
        </template>
        <template v-if="type == 'mask'">
          <input
            ref="area"
            v-model="value"
            v-maska
            class="field__area"
            type="text"
            :readonly="!edit"
            :minlength="min"
            :required="required"
            :placeholder="placeholder"
            :data-maska="mask"
            :data-maska-tokens="maskTokens"
             >
        </template>
        <template v-if="type == 'searcher'">
          <input
            ref="area"
            class="field__area focus:outline-none"
            type="text"
            :readonly="!edit"
            :placeholder="placeholder"
            :minlength="min"
            :required="required"
            :value="value[searcherParams.key]"
          />
        </template>
        <template v-if="type == 'datetime'">
          <VueDatePicker
            v-model="value"
            class="field__calendar"
            locale="ru"
            autoApply
            :format="dateFormat"
            monthNameFormat="long"
            position="left"
            :readonly="!edit"
            :required="required"
            :enableTimePicker="timePicker"
          />
        </template>

        <template v-if="type == 'datetimeLocal'">
          <input
            ref="area"
            v-model="value"
            class="field__area focus:outline-none"
            type="datetime-local"
            :minlength="min"
            :maxlength="max"
            :required="required"
            :readonly="!edit"
            :placeholder="placeholder"
          />
        </template>
        <template v-if="type == 'select'">
          <input
            ref="area"
            class="field__area focus:outline-none"
            type="text"
            :placeholder="placeholder"
            :minlength="min"
            :required="required"
            :readonly="!edit"
            :value="
              value
                ? value[optionKey]?.name || value[optionKey]?.title || value[optionKey]
                : null
            "
            @keydown.prevent
          />
          <div class="field__select">
            <div
              v-for="(option, index) in options"
              :key="index"
              class="field__option"
              :class="
                value && parseInt(value.id) === parseInt(option.id) ? '--current' : ''
              "
              @click.prevent.stop="handleClickOption(option)"
            >
              {{
                optionKey === 'name'
                  ? option[optionKey]
                  : option[optionKey].name || option[optionKey]
              }}
            </div>
          </div>
        </template>
        <template v-if="type == 'float'">
          <Currency
            v-model="value"
            class="field__area focus:outline-none"
            type="text"
            :required="required"
            :edit="edit"
          />
        </template>
        <template v-if="type == 'sum'">
          <input
            v-if="!edit"
            ref="area"
            class="field__area focus:outline-none"
            type="text"
            :placeholder="placeholder"
            :minlength="min"
            :readonly="!edit"
            :value="value"
            :required="required"
          />
          <input
            v-else
            ref="area"
            v-model="value"
            class="field__area focus:outline-none"
            type="number"
            min="0"
            :max="maxNumber"
            :minlength="min"
            :maxlength="max"
            :required="required"
            :readonly="!edit"
            :placeholder="placeholder"
          />
          <div class="field__select">
            <div
              v-for="(option, index) in options"
              class="field__option"
              :class="value?.id === option.id ? '--current' : ''"
              @click="value = option"
            >
              {{ option[optionKey] }}
            </div>
          </div>
        </template>
        <template v-if="type === 'modal'">
          <input
            ref="area"
            class="field__area focus:outline-none"
            type="text"
            :placeholder="placeholder"
            :minlength="min"
            :readonly="!edit"
            :required="required"
            :value="
              value
                ? Array.isArray(value)
                  ? value.map((item) => item[itemsKey]).join(', ')
                  : value[itemsKey]
                    ? value[itemsKey]
                    : ''
                : ''
            "
          />
        </template>
        <template v-if="type === 'modalCall'">
          <span class="field__btn-name">{{ $t(title) }}</span>
        </template>
      </template>
      <div v-if="icon.right" class="field__icon-wrap --right">
        <Icon
          v-if="icon.right === 'x'"
          :name="icon.right"
          :class="{ 'h-4 cursor-pointer': icon.right === 'x' }"
          @click.prevent.stop="clearSearcher"
        />
        <Icon v-else :name="icon.right" />
      </div>
      <Button v-if="group" class="field__btn-add" size="sm" :mods="['bg-secondary']">
        <Icon name="plus" />
      </Button>
    </div>
    <!--    <div class="field__list">-->
    <!--      <div v-for="(item, index) in group" :key="index" class="field__item">-->
    <!--        {{ item }}-->
    <!--      </div>-->
    <!--    </div>-->
    <Teleport to="body">
      <Modal
        :open="isModalOpen"
        :title="title ? $t(title) : tableParams.title ? tableParams.title : ''"
        :isStandardButtons="isStandardButtons"
        :buttonText="`OK`"
        width="50%"
        height="auto"
        @action="modalAction"
      >
        <div class="py-3 h-full">
          <DataTable
            :headers="tableParams.headers"
            :items="items"
            :isSearch="true"
            :pagination="tableParams.pagination"
            :table="tableParams.name ? tableParams.name : ''"
            :isLoading="tableParams.isLoading"
            :infinityScroll="tableParams.infinityScrollOptions"
            :chips="tableParams.chips ? tableParams.chips : []"
            @action="handlerTableAction"
          />
        </div>
      </Modal>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import VueDatePicker from '@vuepic/vue-datepicker';
import { vMaska } from 'maska';
import { useSearcher } from '@/store/searcher';
import { toDateFormatTime } from '@/utils/date/toDateFormat';
import Icon from '@/components/share/Icon/Icon.vue';
import Button from '@/components/form/Button/Button.vue';
import Modal from '@/components/share/Modal/Modal.vue';
import DataTable from '@/components/share/DataTable/DataTable.vue';
import FieldEditor from '@/components/share/FieldEditor/FieldEditor.vue';
import Currency from '@/components/share/Currency/Currency.vue';

export default defineComponent({
  name: 'Field',
  components: {
    FieldEditor,
    Icon,
    Button,
    VueDatePicker,
    Modal,
    DataTable,
    Currency
  },
  props: {
    modelValue: { required: true },
    searchValue: { type: String },
    type: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: true,
    },
    relative: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxNumber: {
      type: Number,
      default: 1000000,
    },
    minNumber: { type: Number },
    min: { type: Number },
    max: { type: Number },
    size: {
      type: String,
      required: true,
    },
    mask: {
      type: String,
    },
    maskTokens: {
      type: String,
    },
    iconLeft: { type: String },
    iconRight: { type: String },
    mods: { type: Array<string> },
    title: { type: String },
    placeholder: { type: String },
    group: { type: Array<string> },
    searcherParams: { type: Object },
    ch: { type: String },
    cw: { type: String },
    options: { type: Array },
    isStandardButtons: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as any,
      default: [],
    },
    tableParams: {
      type: Object,
      default: {},
    },
    optionKey: { type: String },
    itemsKey: { type: String },
    dateFormat: {
      type: String,
      default: 'dd.MM.yyyy HH:mm',
    },
    editorButtons: {
      type: Object,
      default: () => { },
    },
    onlyNumbers: { type: Boolean },
    timePicker: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue', 'action', 'searchValue'],
  setup() {
    const searcher = useSearcher();


    return { searcher };
  },
  data() {
    return {
      isCustom: false,
      value: '' as any,
      icon: {
        left: '',
        right: '',
      },
      cls: {
        required: '--required',
        editable: '--editable',
        focus: '--focus',
      },
      state: {
        isReadonly: false,
        isFocus: false,
      },
      isModalOpen: false,
      modalSelected: [] as any,
    };
  },
  computed: {
    classes() {
      return [
        `--${this.size}`,
        `--${this.type}`,
        this.edit ? this.cls.editable : '',
        this.required ? this.cls.required : '',
        this.state.isFocus && !this.disabled ? this.cls.focus : '',
        this.mods ? this.mods.map((mod) => `--${mod}`) : '',
      ];
    },
    date() {
      if (this.type !== 'datetime' || this.value == '') {
        return '';
      }
      return toDateFormatTime(this.value);
    },
  },
  directives: { maska: vMaska },
  watch: {
    items(value) {
      value.forEach((item) => {
        item.checked = !!this.modalSelected.find((s) => s.id === item.id);
      });
    },
    modelValue() {
      if (this.modelValue != this.value) {
        if (Array.isArray(this.modelValue)) {
          this.modalSelected = [...this.modelValue];
        }
        this.setValueFromModel();
      }
      if (this.type === 'modal') {
        this.icon.right = this.modelValue ? this.icon.right = 'x' : this.icon.right = 'dots-three-outline';
      }
    },
    value() {
      this.update();
    },
  },
  created() {
    const customType = ['searcher', 'area', 'datetime', 'select', 'sum', 'float', 'modal', 'modalCall', 'phone', 'fieldEditor', 'mask'];
    if (customType.includes(this.type)) {
      this.isCustom = true;

      if (this.type === 'searcher' || this.type === 'modalCall') {
        this.icon.left = 'dots-three-outline';
        this.icon.right = 'x';
      }

      if (this.type === 'modal') {
        this.icon.right = this.modelValue ? this.icon.right = 'x' : this.icon.right = 'dots-three-outline';
      }

      if (this.type === 'select') {
        this.icon.right = 'caret';
      }
    }

    if (this.iconLeft) {
      this.icon.left = this.iconLeft;
    }
    if (this.iconRight) {
      this.icon.right = this.iconRight;
    }

    this.setValueFromModel();

    document.addEventListener('click', (event) => {
      if (!event.target.closest('.field')) {
        this.state.isFocus = false;
      }
    });
  },
  methods: {
    setOnlyNumbers(): void {
      this.value = this.value.replace(/\D+/g, '');
    },
    setValueFromModel() {
      this.value = this.modelValue;
    },
    handleClickButtonCopy() {
      this.$refs.area.select();
      this.$refs.area.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(this.$refs.area.value);
      this.$refs.area.blur();
    },
    handleClick() {
      if (this.edit) {
        if (this.type == 'searcher') {
          this.openSearcher();
        }
        if (this.type == 'modal' || this.type == 'modalCall') {
          this.isModalOpen = !this.isModalOpen;
          if (this.isModalOpen) {
            this.$emit('action', { type: 'loadData' });
          }
        } else {
          this.state.isFocus = true;
          this.$refs?.area?.focus?.();
        }
      }
    },
    handleInputArea() {
      if (this.onlyNumbers) {
        this.setOnlyNumbers();
      }
      this.update();
    },
    handleClickOption(option: any) {
      this.value = option;
      this.update();
      this.state.isFocus = false;
      this.$emit('action', { type: 'change', value: option });
    },
    update() {
      this.$emit('update:modelValue', this.value);
      this.$emit('action', { type: 'updateValue', value: this.value });
    },
    // Universal method to get value from the multidimensional array|object

    // getOption(option: any, optionKey: string) {
    //   if (optionKey.includes('.')) {
    //     const optionArray: string[] = optionKey.split('.')
    //     let result: any = null
    //
    //     optionArray.forEach((el: any) => {
    //       result = option[el] ? option[el] : result[el]
    //     })
    //
    //     return result
    //   }
    //
    //   return option[optionKey]
    // },
    openSearcher() {
      this.searcher.component = this.searcherParams.component;
      this.searcher.itemEdit = this.searcherParams.itemEdit;
      this.searcher.page = this.searcherParams.page;
      this.searcher.isShow = true;

      this.searcher.searchParams = this.searcherParams.searchParams;

      const watch = this.$watch('searcher.isShow', () => {
        if (!this.searcher.isShow) {
          this.value = { ...this.searcher.value };
          this.update();

          watch();
        }
      });
    },
    clearSearcher() {
      this.value = '';
      this.searcher.value = '';
      this.isModalOpen = false;
    },
    handlerTableAction(event: any) {
      switch (event.type) {
        case 'changePage':
          this.$emit('action', { type: 'changePage', page: event.page });
          break;
        case 'select':
          if (this.multiselect) {
            const index = this.modalSelected.findIndex((item: any) => item.id === event.item.id);
            if (index >= 0) {
              this.modalSelected.splice(index, 1);
            } else {
              this.modalSelected.push(event.item);
            }
          } else {
            this.items.forEach((item: any) => {
              item.checked = item.id === event.item.id;
            });
            this.value = event.item;
            this.isModalOpen = false;
          }
          break;
        case 'click':
          this.$emit('action', {
            type: 'click', item: event.item, oldItem: this.modelValue, table: this.tableParams.table,
          });
          if (this.multiselect) break;
          this.items.forEach((item: any) => {
            if (item?.current) {
              item.current = item.id === event.item.id;
            }
          });
          this.value = event.item;
          this.isModalOpen = false;
          break;
        case 'search':
          this.$emit('action', { type: 'search', value: event.value });
          break;
        case 'scroll-page':
          this.$emit('action', { type: 'scroll-page', page: event.page });
          break;
        case 'sortColumn':
          this.$emit('action', { type: 'sortColumn', column: event.column });
          break;
        case 'chips-remove':
          this.$emit('action', event);
          break;
        default:
          break;
      }
    },
    modalAction(action: string) {
      if (action === 'ok') {
        this.$emit('action', { type: 'ok', selected: this.modalSelected });
        this.isModalOpen = false;
      }
      if (action === 'cancel') {
        this.$emit('action', { type: 'cancel' });
        this.isModalOpen = false;
      }
    },
  },
});

</script>

<style src="./Field.scss" lang="scss"></style>
