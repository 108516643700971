import { defineComponent } from 'vue';

import Icon from '@/components/share/Icon/Icon.vue';

export default defineComponent({
  name: 'Checkbox',
  components: { Icon },
  data() {
    return {
      cls: {
        checked: '--checked',
        editable: '--editable',
      },
      state: { isChecked: this.checked ? this.checked : false },
    };
  },
  computed: {
    classes() {
      return [
        `--${this.size}`,
        this.edit ? this.cls.editable : '',
        this.mods ? this.mods.map((mod) => `--${mod}`) : '',
        this.state.isChecked ? this.cls.checked : '',
      ];
    },
  },
  watch: {
    checked() {
      if (this.state.isChecked != this.checked) {
        this.state.isChecked = this.checked;
      }
    },
    modelValue() {
      if (this.modelValue != this.state.isChecked) {
        this.state.isChecked = this.modelValue;
      }
    },
  },
  methods: {
    handleClick() {
      if (!this.edit) {
        return false;
      }

      this.state.isChecked = !this.state.isChecked;
      // if (this.modelValue) {
      this.$emit('update:modelValue', this.state.isChecked);
      // }
    },
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Boolean, Number],
      required: false,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    toggle: { type: Boolean },
    checked: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      required: true,
    },
    mods: { type: Array<string> },
    title: { type: String },
  },
  emits: ['update:modelValue'],
});
