import {
  ComputedRef,
  computed,
} from 'vue';

import { TSetToggleStateAs, useToggleState } from '@/composables/useToggleState';

export type TUseView = {
  isTabsStashOn: ComputedRef<boolean>,
  canShowStash: ComputedRef<boolean>,
  setTabsStashToggleStateAs: TSetToggleStateAs,
};

export const useView = (hasStashedTabs: ComputedRef<boolean>): TUseView => {
  const {
    isOn: isTabsStashOn,
    setToggleStateAs: setTabsStashToggleStateAs,
  } = useToggleState(false);

  const canShowStash = computed(() => hasStashedTabs.value && isTabsStashOn.value);

  return {
    isTabsStashOn,
    canShowStash,
    setTabsStashToggleStateAs,
  };
};
