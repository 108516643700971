import { createServices } from '@/utils/api/apiService';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiPricesMap: object = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.reasonReturns.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.reasonReturns.item,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.reasonReturns.list,
  },
  updateItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.reasonReturns.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.reasonReturns.item,
  },
};

const apiServices = createServices(apiPricesMap);

export default apiServices;
