<template>
  <div v-if="productTable">
    <div class="dp__flex_display ">
      <Button
        size="sm"
        class="button --sm --bg-secondary"
        @click="createExel"
      >
        {{ $t('button.saveExel') }}
      </Button>
    </div>
    <div class="h1 modal-default__heading center">
      {{ warehouse.name }}
    </div>
  </div>
  <div v-if="!productTable" class="product-stocks">
    <div
      class="product-stocks__headers"
      :style="{ 'grid-template-columns': '4fr 2fr repeat(' + (props.productTable ? 7 : headers.length - 2) + ', 1fr)' }"
    >
      <div
        v-for="item of headers"
        class="header"
      >
        {{ item.value }}
      </div>
    </div>
    <div class="product-stocks__warehouses">
      <div
        v-for="row of props.products?.report?.warehouses"
        class="product-stocks__row"
        :style="{ 'grid-template-columns': '4fr 2fr repeat(' + (props.productTable ? 7 : headers.length - 2) + ', 1fr)' }"
      >
        <div
          class="product-stocks__warehouse"
          :style="{ 'grid-template-columns': '4fr 2fr repeat(' + (productSize.length + 1) + ', 1fr)' }"
        >
          <div class="warehouse__cell">
            {{ props?.products?.entities?.warehouses.find((item: any) => item.id === row?.warehouse).name }}
          </div>
          <div class="warehouse__cell" />
          <div v-for="size in productSize" class="warehouse__cell flex">
            {{ row.stocks.sizes.find((item: any) => size.id === item.size_id).stock }} ({{ row.stocks.sizes.find((item: any) => size.id === item.size_id).reserve }})
          </div>
          <div class="warehouse__cell font-bold">
            {{ row.stocks.sum.stock }} ({{ row.stocks.sum.reserve }})
          </div>
        </div>
        <div
          class="product-stocks__colors"
        >
          <div v-for="color of row.multicolors">
            <div
              class="color"
              :style="{ 'grid-template-columns': '4fr 2fr repeat(' + (productSize.length + 1) + ', 1fr)' }"
            >
              <div class="color__color">
                {{ props.products.entities.multicolors.find((item: any) => item.id === color.multicolor).name }}
              </div>
              <div class="color__color" />
              <div v-for="size in productSize" class="color__color">
                {{ color.stocks.sizes.find((item: any) => size.id === item.size_id).stock }} ({{ color.stocks.sizes.find((item: any) => size.id === item.size_id).reserve }})
              </div>
              <div class="color__color font-bold">
                {{ color.stocks.sum.stock }} ({{ color.stocks.sum.reserve }})
              </div>
            </div>
            <div
              v-for="color of color.storage_cells"
              class="color"
              :style="{ 'grid-template-columns': '4fr 2fr repeat(' + (productSize.length + 1) + ', 1fr)' }"
            >
              <div class="color__cell" />
              <div class="color__cell">
                {{ color.storage_cell }}
              </div>
              <div v-for="size in productSize" class="color__cell">
                {{ color.stocks.sizes.find((item: any) => size.id === item.size_id).stock }} ({{ color.stocks.sizes.find((item: any) => size.id === item.size_id).reserve }})
              </div>
              <div class="color__cell font-bold">
                {{ color.stocks.sum.stock }} ({{ color.stocks.sum.reserve }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div>
      <div class="product-stocks__products">
        <div
          class="products "
          :style="{ 'grid-template-columns': '2fr 3fr repeat(' + 3 + ', 1fr)' }"
        >
          <div v-for="header in headers" class="products__cell product-title-color">
            {{ header }}
          </div>
        </div>
      </div>
      <div class="product-stocks__products">
        <div
          class="products"
          :style="{ 'grid-template-columns': 'fr 2fr repeat(' + 5 + ', 1fr)' }"
        >
          <div class="products__sub-cell" />
          <div v-for="header in headersProduct" class="products__cell product-title-color">
            {{ header }}
          </div>
        </div>
      </div>
      <div
        v-for="product of products"
        class="product-stocks__products"
      >
        <div
          class="products"
          :style="{ 'grid-template-columns': '2fr 3fr repeat(' + 3 + ', 1fr)' }"
        >
          <router-link
            class="products__cell__link"
            :to="{
              name: 'Item',
              params: { id: product.id }
            }"
          >
            {{ product.article }}
          </router-link>
          <div class="products__cell">
            {{ product.title }}
          </div>
          <div class="products__cell">
            {{ product.stock }}
          </div>
          <div class="products__cell">
            {{ product.reserve }}
          </div>
          <div class="products__cell">
            {{ stock.name }}
          </div>
        </div>

        <div
          v-for="item in product.products"
          class="product-stocks__products"
        >
          <div
            class="products"
            :style="{ 'grid-template-columns': 'fr 2fr repeat(' + 5 + ', 1fr)' }"
          >
            <div class="products__sub-cell" />
            <div class="products__sub-cell">
              {{ item?.relationships?.barcodes[0]?.attributes?.barcode }}
            </div>
            <div class="products__sub-cell">
              {{ item?.relationships?.multicolors[0]?.attributes?.name }}
            </div>
            <div class="products__sub-cell">
              {{ item?.relationships?.size?.attributes?.value }}
            </div>
            <div class="products__sub-cell">
              {{ item?.relationships?.item?.attributes?.stock }}
            </div>
            <div class="products__sub-cell">
              {{ item?.relationships?.item?.attributes?.reserve }}
            </div>
            <div class="products__sub-cell">
              {{ item?.relationships?.prices.find((p: any) => p.relationships?.type?.attributes?.id === 3) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  ref,
  watch,
} from 'vue';
import xlsx from 'json-as-xlsx';

import Button from '@/components/form/Button/Button.vue';

const props = defineProps({
  place: String,
  selectType: {
    type: String,
    default: 'products',
  },
  products: {
    type: Array,
    default: [],
  },
  productTable: {
    type: Boolean,
    default: false,
  },
  stock: {
    type: Object,
    default: {},
  },
  warehouse: {
    type: Object,
    default: {},
  },
  pagination: {
    type: Object,
    default: {},
  },
});

const emit = defineEmits(['action']);

const headers: any = ref(!props.productTable ? ['МАГАЗИН', 'ЯЧЕЙКА'] : ['АРТИКУЛ', 'НАИМЕНОВАНИЕ', 'НАЛИЧИЕ', 'РЕЗЕРВ', 'ЯЧЕЙКА']);
const headersProduct: any = ref(['ШТРИХКОД', 'ЦВЕТ', 'РАЗМЕР', 'КОЛИЧЕСТВО', 'РЕЗЕРВ', 'ЦЕНА']);
const productSize: any = ref([]);
const items: any = ref([]);
const leftovers = ref(new Map());
const itemsHeaders: any = [{ value: 'article', label: 'Артикул' },
  { value: 'title', label: 'Наименование' },
  { value: 'barcode', label: 'Штрихкод' },
  { value: 'color', label: 'Цвет' },
  { value: 'size', label: 'Размер' },
  { value: 'stock', label: 'Наличие' },
  { value: 'reserve', label: 'Резерв' },
  { value: 'cell', label: 'Ячейка' },
];

watch(() => props.products, () => {
  setLeftovers();
});

async function createExel() {
  const content = props.products.map((item: any) => ({
    cell: props.stock.name,
    article: item.article,
    title: item.title,
    stock: item.stock,
    reserve: item.reserve,
    barcode: item?.products[0]?.relationships?.barcodes[0]?.attributes?.barcode,
    color: item?.products[0]?.relationships?.multicolors[0]?.attributes?.name,
    size: item?.products[0]?.relationships?.size?.attributes?.value,
  }));
  const data = [
    {
      sheet: props.warehouse.name,
      columns: itemsHeaders,
      content,
    },
  ];
  const settings = {
    fileName: 'WarhouseCell', // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://docs.sheetjs.com/docs/api/write-options
  };
  xlsx(data, settings);
}

function setLeftovers() {
  if (!props.productTable) {
    productSize.value = [];
    headers.value = [{ value: 'МАГАЗИН' }, { value: 'ЯЧЕЙКА' }];
    console.log(props.products);
    Array.prototype.push.apply(headers.value, props.products?.entities?.sizes);
    Array.prototype.push.apply(headers.value, [{ value: 'СУММА' }]);
    productSize.value = props.products?.entities?.sizes;
  }
}

onMounted(() => {
  setLeftovers();
});
</script>

<style lang="scss" scoped>

.product-stocks {
  width: 100%;
  max-width: 600px;

  &__headers {
    display: grid;
    grid-template-columns: 2fr repeat(7, 1fr);
    color: #767083;
    background-color: #F4F2F8;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 0.03em;

    .header {
      padding: 16px 6px;
      border: 1px solid #d9d9d9;
    }
  }

  &__warehouse {
    color: #333333;
    display: grid;
    grid-template-columns: 2fr repeat(6, 1fr);
    font-size: 12px;
    padding-top: 16px;

    .warehouse__cell {
      padding: 0 6px;
      border: 1px solid #d9d9d9;
      font-weight: 600;
      background: #bdbdbd;
    }

    &__warehouseTitle {
      color: #333333;
      display: grid;
      grid-template-columns: 2fr repeat(6, 1fr);
      font-size: 12px;
    }
  }

  &__colors {
    color: #8C8C8C;
    font-size: 10px;

    .color {
      display: grid;
      grid-template-columns: 2fr repeat(6, 1fr);

      &:nth-child(even) {
        //text-align: right;
        //background-color: #F9F9FA;
      }

      &__cell {
        &:nth-child(1) {
          text-align: right;
          //background-color: #F9F9FA;
        }

        background: #ececec;
        border: 1px solid #d2d2d2;
        padding: 4px 10px;
      }
      &__color {
        &:nth-child(1) {
          text-align: right;
          //background-color: #F9F9FA;
        }
        font-weight: 700;
        background: #ececec;
        color: #343434;
        border: 1px solid #d2d2d2;
        padding: 4px 10px;
      }
    }
  }

  &__products {
    color: #8C8C8C;
    font-size: 10px;

    .products {
      display: grid;
      grid-template-columns: 2fr repeat(6, 1fr);

      &:nth-child(even) {
        //text-align: right;
        //background-color: #F9F9FA;
      }

      &__cell {
        &:nth-child(1) {
          text-align: right;
          //background-color: #F9F9FA;
        }

        background: #ececec;
        border: 1px solid #d2d2d2;
        padding: 3px 6px;

        &__link {
          background: #ececec;
          text-align: right;

          border: 1px solid #d2d2d2;
          padding: 3px 6px;
          color: #31AAB1;
          cursor: pointer;
        }
      }

      &__sub-cell {
        &:nth-child(1) {
          text-align: right;
          //background-color: #F9F9FA;
        }

        background: white;
        border: 1px solid #d2d2d2;
        padding: 3px 6px;

        &__link {
          background: white;
          text-align: right;

          border: 1px solid #d2d2d2;
          padding: 3px 6px;
          color: #31AAB1;
          cursor: pointer;
        }
      }
    }
  }

  &__cell {
    color: #8C8C8C;
    font-size: 10px;

    .cell {
      display: grid;
      grid-template-columns: 2fr repeat(6, 1fr);

      &:nth-child(even) {
        //background-color: #F9F9FA;
      }

      &__cell {
        padding: 4px 16px;
        border: 1px solid #d9d9d9;
        background: #f8f8f8;
      }
    }
  }

}

.product-title-color {
  background: #bdbdbd !important;
  color: #767083 !important;
  padding: 1px 6px !important;

  &__sub {
    background: #ececec !important;
    color: #767083 !important;
    padding: 1px 6px !important;
  }
}
</style>
