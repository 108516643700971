import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';
import { createServices } from '@/utils/api/apiService';

const apiSystemMap: any = {
  me: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.system.me,
  },
  getInfo: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.system.getUserInfo,
  },
  login: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.system.login,
  },
  initApp: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.system.initApp,
  },
  getMenus: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.system.menus,
  },
  menusSync: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.system.menusSync,
  },
};

const apiSystemServices = createServices(apiSystemMap);

export default apiSystemServices;
