<template>
  <span class="info-fetch">
    <span class="info-fetch__icon" @mouseover="timeDifferenceInfo" @mouseleave="infoHover = false">i</span>
    <span v-if="infoHover" class="info-fetch__data">{{ infoHoverText }}</span>
  </span>
</template>

<script setup lang="ts">
import { ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const infoHover: Ref = ref(false);
const infoHoverText: Ref = ref('');
const fetchDate: Ref = ref(new Date());

/**
 * Calculate time difference
 *
 * @function
 * @return void
 */
const timeDifferenceInfo = (): void => {
  const currentDate: Date | any = new Date();
  const minutes = Math.floor(((Math.abs(currentDate - fetchDate.value)) / 1000) / 60);

  infoHoverText.value = `${t('button.infoTimeDifference')} ${minutes} минут(ы)`;
  infoHover.value = true;
};
</script>
