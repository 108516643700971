import { createServices } from '@/utils/api/apiService';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiUsersMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.usersOld.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.usersOld.item,
  },
};

const apiUsersServices = createServices(apiUsersMap);

export default apiUsersServices;
