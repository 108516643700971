import { createServices } from '@/utils/api/apiService';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.multicolorsV2.list,
    properties: false,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.multicolorsV2.item,
    properties: true,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.multicolorsV2.list,
  },
  updateItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.multicolorsV2.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.multicolorsV2.item,
  },
  attachColors: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.multicolorsV2.colors.attach,
  },
  detachColors: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.multicolorsV2.colors.detach,
  },
  syncCodes: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.multicolorsV2.codes.sync,
  },
  attachCodes: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.multicolorsV2.codes.attach,
  },
  detachCodes: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.multicolorsV2.codes.detach,
  },
  codeList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.multicolorsV2.codes.list,
    properties: false,
  },
  codeItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.multicolorsV2.codes.item,
    properties: true,
  },
  createCodeItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.multicolorsV2.codes.list,
  },
  updateCodeItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.multicolorsV2.codes.item,
  },
};

const apiMulticolorsServicesV2 = createServices(apiMap);

export default apiMulticolorsServicesV2;
