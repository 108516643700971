import { defineComponent } from 'vue';

import { useNotifications } from '@/store/notifications';
import Icon from '@/components/share/Icon/Icon.vue';

export default defineComponent({
  name: 'Notifications',
  components: { Icon },
  setup() {
    const store = useNotifications();

    return { store };
  },
  data() {
    return {
      list: this.store.list,
      icons: {
        info: 'check-circle',
        success: 'check-circle',
        warn: 'warning',
        error: 'x-circle',
      },
    };
  },
});
