<template>
  <AppMainLayout :keyMenu="menuKeys.DIR" :linkType="menuKeys.DIR">
    <Sections :loading="checkReport.isLoading">
      <Section>
        <Head :title="t('dashboard.title')">
          <template #controls>
            <Button
              size="sm"
              :mods="['bg-secondary']"
              @click="fetch"
            >
              {{ t('button.update') }}
            </Button>
          </template>
        </Head>
        <div>
          <Loader
            v-if="checkReport.isLoading"
            class="searcher__loader --show"
          />
          <div v-else>
            <div class="pb-6 border-b-2 mb-6">
              <div class="h1 head__title mb-6 ">
                Остатки в кассах
              </div>
              <div class="flex flex-wrap -m-2">
                <div
                  v-for="cash in retailMoneyStorages"
                  class="w-4/12"
                >
                  <div class="dashboard-holder ">
                    <div class="label font-bold pb-6 border-b-2">
                      {{ cash.structure.attributes.name }}
                    </div>
                    <div class="flex mt-3 justify-between">
                      <div class="label">
                        {{ t('dashboard.remainsCashier') }}
                      </div>
                      <div class="sum">
                        {{ moneyFormat(cash?.retail_money_storage_cash_balances_sum ? cash.retail_money_storage_cash_balances_sum : '-') }}
                      </div>
                    </div>
                    <div class="flex mt-3 justify-between">
                      <div class="label">
                        {{ t('dashboard.remainsMainCashier') }}
                      </div>
                      <!-- <div class="sum">{{ moneyFormat(cash?.sale_total ? cash.sale_total : '-') }}</div>-->
                      <div class="sum">
                        {{ moneyFormat(cash?.money_storage_cash_balances_sum ? cash.money_storage_cash_balances_sum : '-') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="pb-6">
              <div class="h1 head__title mb-6 ">
                {{ t('dashboard.planSales') }} {{ new Date().toLocaleString('default', { month: 'long' }) }} {{ new Date().getFullYear() }}
              </div>
              <div class="flex flex-wrap -m-2">
                <div
                  v-for="rep in plansReport"
                  class="w-4/12"
                >
                  <div class="dashboard-holder">
                    <div class="label font-bold pb-6 border-b-2">
                      {{ rep.name }}
                    </div>
                    <div class="flex mt-3 justify-between">
                      <div class="label">
                        {{ t('dashboard.plan') }}
                      </div>
                      <div class="sum">
                        {{ moneyFormat(rep.plans[0]?.attributes?.amount ? rep.plans[0]?.attributes?.amount : '-') }}
                      </div>
                    </div>
                    <div class="flex mt-3 justify-between">
                      <div class="label">
                        {{ t('dashboard.currentSales') }}
                      </div>
                      <div class="sum">
                        {{ moneyFormat(rep.reports?.amount ? rep.reports?.amount : '-') }}
                      </div>
                    </div>
                    <div class="flex mt-3 justify-between pb-3 border-b-2">
                      <div> {{ t('dashboard.percent') }}</div>
                      <div>{{ rep.plans[0]?.attributes?.amount ? Math.round(rep.reports?.amount / rep.plans[0]?.attributes?.amount * 100) + ' %' : '-' }}</div>
                    </div>
                    <div class="flex mt-3 justify-between ">
                      <div class="label">
                        {{ t('dashboard.salesForecast') }}
                      </div>
                      <div v-if="rep.reports?.amount" class="sum" :class="[predictionSum(rep.reports?.amount) > rep.plans[0]?.attributes?.amount ? 'green-row' : 'red-row']">
                        {{ predictionSum(rep.reports?.amount) ? moneyFormat(predictionSum(rep.reports?.amount)) : '-' }}
                      </div>
                    </div>
                    <div class="flex mt-3 justify-between pb-3 border-b-2">
                      <div>{{ t('dashboard.performanceForecast') }} </div>
                      <div>{{ rep.plans[0]?.attributes?.amount ? Math.round(predictionSum(rep.reports?.amount) / rep.plans[0]?.attributes?.amount * 100) + ' %' : '-' }}  </div>
                    </div>
                    <div v-for="visitors in rep.visitors" class="flex mt-3 justify-between">
                      <div>{{ t('dashboard.visitors') }} {{ visitors.name }}</div>
                      <div>{{ visitors.count_visitors }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Sections>
  </AppMainLayout>
</template>

<script setup lang="ts">
import {
  onActivated,
  Ref,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { useNavTabs } from '@/store/nav-tabs';
import { useNotifications } from '@/store/notifications';
import Head from '@/components/share/Head/Head.vue';
import Sections from '@/components/share/Sections/Sections.vue';
import Section from '@/components/share/Section/Section.vue';
import Button from '@/components/form/Button/Button.vue';
import Loader from '@/components/share/Loader/Loader.vue';
import apiReportServices from '@/utils/api/services/apiReportServices';
import apiPlansServices from '@/utils/api/services/apiPlansServices';
import menuKeys from '@/utils/menu/menuKeys';
import { toDateFormatMoscowTimeZone } from '@/utils/date/toDateFormat';

const { t } = useI18n();
const route = useRoute();
const navTabs = useNavTabs();
const notifications = useNotifications();
const checkReport: Ref = ref([]);
const retailMoneyStorages: Ref = ref([]);
const plansReport: Ref = ref([]);

const predictionSum = ((sum: any) => {
  const date = new Date();

  const currentSalesAmount = sum;
  const day = date.getDate();
  const currentDate = new Date();
  const ammountDay = currentSalesAmount / day;

  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  return Math.round(ammountDay * lastDayOfMonth.getDate());
});

/**
 * Get reports
 *
 *  @function
 *  @async
 *  @return void
 */
const getReports = async (id: number) => {
  const currentDate: string = new Date().toISOString().slice(0, 10);
  const { data, status } = await apiReportServices.getCountSales({}, { date: currentDate, structure_id: id });
  if (status === 200) {
    return data;
  }
};

const getVisitors = async (id: number) => {
  const currentDate = new Date();
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const { data, status } = await apiReportServices.getVisitsForStructures({}, {
    structure_id: id,
    from: toDateFormatMoscowTimeZone(firstDayOfMonth),
    to: toDateFormatMoscowTimeZone(lastDayOfMonth),
  });
  if (status === 200) {
    return data;
  }
};

const getCashBalance = async () => {
  const { data, status } = await apiReportServices.getCashBalance({}, {});
  if (status === 200) {
    return data;
  }
};
const hashString = (month: string, sructure_id: number): string => btoa(JSON.stringify([
  {
    key: 'plans-structure-eq-id-and-months',
    value: {
      month: encodeURIComponent(month),
      structure_id: sructure_id,
    },
  },
]));

/**
 * Get plans
 *
 *  @function
 *  @async
 *  @return void
 */
const getPlans = async (id: any) => {
  const date = new Date();
  const currentDate: string = new Date(date.getFullYear(), date.getMonth(), 2).toISOString().slice(0, 10);
  const { data, status } = await apiPlansServices.getList({}, { params: { filters: hashString(currentDate, id) } });
  if (status === 200) {
    return data;
  }
  notifications.error(t('notification.error'), data.message || t('receiptOrders.notification.create.error'));
};

const getService = async (varName: any, serviceName: any, methodName: any = 'getList', params: any = null): Promise<void> => {
  const response = await serviceName[methodName]({}, params);

  response.status.toString().startsWith('20')
    ? varName.value = response.data.map((item: any) => item.attributes)
    : notifications.error(t('notification.error'), response.data.message || t('notification.load.error'));
};

const moneyFormat = (num: number) => num.toLocaleString('ru-RU', {
  currency: 'RUB',
  minimumFractionDigits: 2,
}).replace(',', '.');

const fetch = async () => {
  checkReport.value.isLoading = true;

  plansReport.value = [];

  retailMoneyStorages.value = await getCashBalance();
  retailMoneyStorages.value = retailMoneyStorages.value.filter((p: any) => p.structure.attributes.is_shop && !p.structure.attributes.is_online_store);
  if (retailMoneyStorages.value.length) {
    const processingPromises = retailMoneyStorages.value.map(async (p:any) => {
      const plans = await getPlans(p.structure.id);
      const reports = await getReports(p.structure.id);
      const visitors = await getVisitors(p.structure.id);
      plansReport.value.push({
        visitors, plans, reports, name: p.structure.attributes.name,
      });
    });
    await Promise.all(processingPromises);
    checkReport.value.isLoading = false;
  } else {
    checkReport.value.isLoading = false;
  }
};

onActivated(async () => {
  navTabs.setTab({
    title: t('dashboard.title'),
    route,
  });
  await fetch();
});

</script>
