<script setup lang="ts">
import {computed, ref, Ref, watch, onMounted} from 'vue'
import Button from '@/components/form/Button/Button.vue'
import Icon from '@/components/share/Icon/Icon.vue'
import Range from '@/components/share/Range/Range.vue'
import Field from '@/components/form/Field/Field.vue'
import Loader from '@/components/share/Loader/Loader.vue';
import t from '@/i18n/translateText';

const props = defineProps(['filters', 'useFilters'])

const emit = defineEmits(['action'])

const priceType = ref({})
const isActive = ref(false)
const itemsInStock = ref(true)
const search: Ref = ref('');
const filtersState = ref(<string[]>["price","color","multicolor","stock","warehouses","categories","tags"])

const filterButtonBadge = computed(() => {
  return {
    value: Object.keys(props.useFilters).length
  }
})

const itemsInStockFilter = ref({})
const rangeFilter = ref({})
const priceTypeFilter = ref({})
const selectPropertyFilter = ref({})

watch(() => itemsInStock.value, (value) => {
  const checkedStock = props.filters[2].options.value[0] > 0 ? props.filters[2].options.value[0] : 1

  const inStock = itemsInStock.value ? checkedStock : 0
  props.filters[2].options.value = [inStock || 0, props.filters[2].options.max]
  itemsInStockFilter.value = {type: 'range', filterIndex: 2, range: [inStock || 0, props.filters[2].options.max]}
})

watch(() => priceType.value, (value) => {
  priceTypeFilter.value = {type: 'priceType', filterIndex: 0, value}

})

const toggleFilters = () => {
  isActive.value = !isActive.value
  if (isActive.value) emit('action', {type: 'filtersShow'})
}

const toggleFilter = (name: string) => {
  setTimeout(() => {
    priceType.value = props.filters[0].priceTypes.find((t: any) => t.id === 3)
    if (filtersState.value.includes(name)) {
      filtersState.value = filtersState.value.filter((item: string) => item !== name)
    } else {
      filtersState.value.push(name)
    }
  }, 200)
}

const onChangeRange = (event: any) => {
  const filterIndex = props.filters.findIndex((item: any) => item.id === event.filterId)
  if (filterIndex === 2) itemsInStock.value = !!event.range[0]
  rangeFilter.value = {type: 'range', filterIndex, range: event.range}
}

const selectProperty = (event: any) => {
  const filterIndex = props.filters.findIndex((item: any) => item.id === event.filterId)
  const itemIndex = props.filters[filterIndex].options.findIndex((item: any) => item.id === event.itemId)

  emit('action', {type: 'select', filterIndex, itemIndex})

}
const searchColor = (filterName: any,query: any) => {
  emit('action', {type: 'search'+filterName, query})
}
const resetFilters = () => {
  priceType.value = props.filters[0].priceTypes.find((t: any) => t.id === 3)
  itemsInStock.value = false
  emit('action', {type: 'reset-filters'})
}
const submitFilters = () => {
  emit('action', priceTypeFilter.value)
  emit('action', rangeFilter.value)
  emit('action', itemsInStockFilter.value)


  setTimeout(() => {
    emit('action', {type: 'submit'})
  }, 100);

}
onMounted(() => {
  toggleFilters()
})
</script>

<template>
  <div
    class="activate-icon mb-3"
    :class="{ '--open': isActive }"
  >
    <Button
      size="card-btn"
      :class="{ '--active': isActive }"
      :badge="filterButtonBadge"
      @click="toggleFilters"
    >
      <Icon name="filter"/>
    </Button>
  </div>
  <div
    v-if="isActive"
    class="filters-wrapper justify-between "
  >
    <div class="filters-wrap">
      <div class="flex custom-checkbox w-20 py-2">
        <input
          id="in-stock"
          type="checkbox"
          v-model="itemsInStock"
        />
        <label
          for="in-stock"
          class="pl-1 text-black/50 text-xs"
        >{{ $t('items.inStock') }}</label>
      </div>
      <div class="filters flex flex-col gap-2 w-full pr-2">

        <div
          v-for="filter of filters"
          :key="filter.id"
          class="filter"
        >
          <div
            class="header flex"
            @click="toggleFilter(filter.name)"
          >
            <Icon
              class="w-5 min-w-5"
              :class="{ 'rotate-90': !filtersState.includes(filter.name) }"
              name="caret"
            />
            <div class="filter-label ml-3">{{ filter.label }}</div>
          </div>
          <div
            v-if="filtersState.includes(filter.name)"
            class="expand"
          >
            <div
              v-if="filter.type === 'range'"
              class="range"
            >
              <Range
                :range="filter.options"
                @change-range="onChangeRange({ filterId: filter.id, range: $event })"
              />
              <div v-if="filter.name === 'price'">
                <div class="py-1 text-slate-500">{{ $t('prices.title.item') }}</div>
                <Field
                  ch="w-full z-10"
                  type="select"
                  title=""
                  size="sm"
                  :relative="false"
                  :mods="['col', 'b-gray']"
                  :required="false"
                  :edit="true"
                  :options="filter.priceTypes"
                  optionKey="title"
                  v-model="priceType"
                />
              </div>
            </div>
            <div
              v-if="filter.type === 'list'"
              class="list"
            >
              <div v-if="filter.search" class="search-filter">
                <Field
                  type="text"
                  size="sm"
                  :mods="['b-gray']"
                  :edit="true"
                  class="search-filter-input"
                  :required="false"
                  :placeholder="t('search.placeholder')"
                  iconLeft="magnifying-glass"
                  @input="searchColor(filter.name, $event.target.value)"
                />
              </div>
              <div v-if="filter.loader">
                <Loader class="searcher__loader --show"/>
              </div>
              <div
                v-if="filter.options.length"
                class="flex ml-8 my-2 gap-3"
                v-for="(item, index) of filter.options"
                :key="item.id"
              >
                <Icon
                  class="icon__checkbox-default"
                  :class="{ '--checked': filter.options[index].select }"
                  name="check"
                  @click="selectProperty({ filterId: filter.id, itemId: item.id })"
                />
                <span>{{ item.name }}</span>
              </div>
              <div
                v-else
                class="empty-filter-item"
              >
                {{ $t('table.empty') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex pt-2">
      <button
        v-if="filterButtonBadge.value"
        class="button --sm --width-100 --bg-gray mr-3"
        @click="resetFilters"
      >
        Очистить
      </button>
      <button
        type="submit"
        class="button --sm --width-100 --bg-secondary "
        @click="submitFilters"
      >{{ $t('button.apply') }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.activate-icon {
  width: 72px;

  .clear {
    cursor: pointer;
    font-size: 10px;
    text-align: center;
    color: #63a6bb;
  }
}
.filters-wrap{
  height: calc(100% - 50px);
  min-height: 500px;
  min-width: 270px;
  overflow: auto;
}
.filters-wrapper {

  height: calc(100% - 50px);
  min-height: 500px;
  min-width: 270px;
  position: relative;

  display: flex;
  flex-direction: column;
  .search-filter{
    position: sticky;
    top: 0;
    padding: 0 10px;
  }
  .search-filter-input{
    background: white;
  }
  .filters {
    height: calc(100% - 50px);
    //height: 90%;
    //position: absolute;
    top: 0;
    z-index: 2;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.55em;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #767083;
      outline: 1px solid slategrey;
      border-radius: 20px;
    }

    .filter {
      position: relative;

      .expand {
        &::-webkit-scrollbar {
          width: 0.55em;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #767083;
          outline: 1px solid slategrey;
          border-radius: 20px;
        }
        max-height: 320px;
        overflow-x: hidden;
        overflow-y: auto;
      }

      .header {
        cursor: pointer;
      }
    }
  }
}
.empty-filter-item{
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 50px 0;
}
</style>
