// TODO: Временный фикс для задачи https://tools4b.atlassian.net/browse/CFERP-2875

import { useRoute } from 'vue-router';

import { useProducts } from '@/store/products';
import { useEnums } from '@/store/enums';
import { useScanner } from '@/store/scanner';
import { useConstans } from '@/store/constans';
import { useItemSearcher } from '@/store/item-searcher';
import { useBuyerOrder } from '@/store/buyer-order';

const pagesWhereUse = {
  productsStore: [
    'CzkEmissionOrdersItem',
    'DeliveryOrder',
    'DispatchProduct',
    'CashCheck',
    'ReceiptInvoice',
    'InternalOrder',
    'InventoryItem',
    'MovementProduct',
    'MovingBetweenStorageCellsItem',
    'PostingOfGood',
    'PriceSetting',
    'RecalculationProductsItem',
    'ReceiptInvoice',
    'ReceiptOrder',
    'ReturnInvoice',
    'ReturnRequestItem',
    'RouteSheet',
    'DocumentSaleByDeliveryCompaniesItem',
    'SalesPlanItem',
    'DocumentHonestMarksFactoriesItem',
    'SupplierOrder',
    'WriteOffGood',
    'BuyerOrder',
  ],
  enumsStore: ['InternalOrder', 'MovementProduct', 'RouteSheet', 'ShiftClosure', 'BuyerOrder'],
  scannerStore: ['MovementProduct', 'RecalculationProductsItem', 'ReceiptInvoice'],
  constantsStore: ['PriceSetting'],
  itemsSearcherStore: ['ShopCategory'],
  byerOrderStore: ['BuyerOrder'],
};

const syncDraftToStore = (store) => {
  if (!store) return;

  let persistedStoreState: string | null = null;
  try {
    persistedStoreState = localStorage.getItem(store.$id || '');
    persistedStoreState = JSON.parse(persistedStoreState || '');
  } catch (err) {
    persistedStoreState = null;
  }

  if (persistedStoreState !== null) {
    setTimeout(() => {
      store.$state = persistedStoreState;
    }, 100);
  }
};

export const useHoldPageEditDrafts = () => {
  const route = useRoute();
  const productsStore = useProducts();
  const enumsStore = useEnums();
  const scannerStore = useScanner();
  const constantsStore = useConstans();
  const itemsSearcherStore = useItemSearcher();
  const byerOrderStore = useBuyerOrder();

  const syncPageDraftsToStore = () => {
    switch (true) {
      case pagesWhereUse.productsStore.includes(route.name as string):
        syncDraftToStore(productsStore);
        break;
      case pagesWhereUse.enumsStore.includes(route.name as string):
        syncDraftToStore(enumsStore);
        break;
      case pagesWhereUse.scannerStore.includes(route.name as string):
        syncDraftToStore(scannerStore);
        break;
      case pagesWhereUse.constantsStore.includes(route.name as string):
        syncDraftToStore(constantsStore);
        break;
      case pagesWhereUse.itemsSearcherStore.includes(route.name as string):
        syncDraftToStore(itemsSearcherStore);
        break;
      case pagesWhereUse.byerOrderStore.includes(route.name as string):
        syncDraftToStore(byerOrderStore);
        break;
      default:
        break;
    }
  };

  return { syncPageDraftsToStore };
};
