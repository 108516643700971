import { createServices } from '@/utils/api/apiService';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.tasks.list,
    properties: false,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.tasks.item,
    properties: true,
  },
};

const apiServices = createServices(apiMap);

export default apiServices;
