import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.plans.list,
  },
  getItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.plans.item,
  },
};

const apiServices = ApiGateway.createServices(apiMap);

export default apiServices;
