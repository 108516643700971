<template>
  <div class="field-editor">
    <div v-if="!isEditing" class="field-editor__lock" />
    <div
      v-if="isEditing"
      class="field-editor__buttons flex gap-2"
    >
      <button
        v-if="editorButtons.bold"
        class="button --sm --bg-secondary field-editor__button"
        :disabled="!editor?.can().chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor?.isActive('bold') }"
        @click="editor?.chain().focus().toggleBold().run()"
      >
        Ж
      </button>
      <button
        v-if="editorButtons.italic"
        class="button --sm --bg-secondary field-editor__button"
        :disabled="!editor?.can().chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor?.isActive('italic') }"
        @click="editor?.chain().focus().toggleItalic().run()"
      >
        К
      </button>
      <button
        v-if="editorButtons.strike"
        class="button --sm --bg-secondary field-editor__button"
        :disabled="!editor?.can().chain().focus().toggleStrike().run()"
        :class="{ 'is-active': editor?.isActive('strike') }"
        @click="editor?.chain().focus().toggleStrike().run()"
      >
        З
      </button>
      <button
        v-if="editorButtons.enter"
        class="button --sm --bg-secondary field-editor__button"
        @click="editor?.chain().focus().setHardBreak().run()"
      >
        Enter
      </button>
      <button
        v-if="editorButtons.textAligns"
        class="button --sm --bg-secondary field-editor__button"
        :class="{ 'is-active': editor?.isActive({ textAlign: 'left' }) }"
        @click="editor?.chain().focus().setTextAlign('left').run()"
      >
        L
      </button>
      <button
        v-if="editorButtons.textAligns"
        class="button --sm --bg-secondary field-editor__button"
        :class="{ 'is-active': editor?.isActive({ textAlign: 'center' }) }"
        @click="editor?.chain().focus().setTextAlign('center').run()"
      >
        C
      </button>
      <button
        v-if="editorButtons.textAligns"
        class="button --sm --bg-secondary field-editor__button"
        :class="{ 'is-active': editor?.isActive({ textAlign: 'right' }) }"
        @click="editor?.chain().focus().setTextAlign('right').run()"
      >
        R
      </button>
      <button
        v-if="editorButtons.textAligns"
        class="button --sm --bg-secondary field-editor__button"
        :class="{ 'is-active': editor?.isActive({ textAlign: 'justify' }) }"
        @click="editor?.chain().focus().setTextAlign('justify').run()"
      >
        J
      </button>
      <button
        v-if="editorButtons.fonts"
        class="button --sm --bg-secondary field-editor__button"
        :class="{ 'is-active': editor?.isActive('textStyle', { fontFamily: 'Lato' }) }"
        @click="editor?.chain().focus().setFontFamily('Lato').run()"
      >
        Lato
      </button>
      <button
        v-if="editorButtons.fonts"
        class="button --sm --bg-secondary field-editor__button"
        :class="{ 'is-active': editor?.isActive('textStyle', { fontFamily: 'Montserrat' }) }"
        @click="editor?.chain().focus().setFontFamily('Montserrat').run()"
      >
        Montserrat
      </button>
    </div>
    <EditorContent
      :editor="editor"
    />
  </div>
</template>

<script setup lang="ts">
// Дока тут
// https://tiptap.dev/docs/editor/examples/default

import { EditorContent, useEditor } from '@tiptap/vue-3';
import {
  ref,
  Ref,
  watch,
} from 'vue';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import FontFamily from '@tiptap/extension-font-family';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  isEditing: {
    type: Boolean,
    default: false,
  },
  classes: {
    type: String,
    default: '',
  },
  bold: {
    type: Boolean,
    default: false,
  },
  editorButtons: {
    type: Object,
    default: () => {},
  },

});

const emits = defineEmits(['update:modelValue']);
const text: Ref = ref(props.modelValue || null);

watch([text], () => {
  emits('update:modelValue', text.value);
});

watch([props], () => {
  text.value = props.modelValue;
  if (editor.value?.getHTML() !== text.value) {
    editor.value?.commands.setContent(text.value, false);
  }
}, { deep: true });

const editor = useEditor({
  extensions: [
    StarterKit,
    TextAlign.configure({ types: ['heading', 'paragraph'] }),
    FontFamily,
  ],
  onUpdate: () => {
    text.value = editor.value?.getHTML();
  },
});

</script>

<style lang="scss">
.field-editor{
  width: 100%;
  position: relative;
  &__buttons{
    margin-top: -58px;
    margin-bottom: 20px;
  }
  button{
    height: 34px!important;
    &.is-active{
      background: grey;
    }
  }
  &__lock{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
</style>
