import { defineComponent } from 'vue';

import Button from '@/components/form/Button/Button.vue';
import Icon from '@/components/share/Icon/Icon.vue';

export default defineComponent({
  name: 'NavPagination',
  components: {
    Button,
    Icon,
  },
  props: {
    meta: {
      type: Object,
      required: true,
    },
    modelValue: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    getPage(url: string) {
      return Number(new URL(url).searchParams.get('page'));
    },
  },
});
