import { defineComponent } from 'vue';

import { useSearcher } from '@/store/searcher';
import { useNotifications } from '@/store/notifications';
import apiClientsServices from '@/utils/api/services/apiClientsServices';
import apiItemsServices from '@/utils/api/services/apiItemsServices';
import Field from '@/components/form/Field/Field.vue';
import List from '@/components/share/List/List.vue';
import Loader from '@/components/share/Loader/Loader.vue';
import Button from '@/components/form/Button/Button';
import { useProducts } from '@/store/products';
import NavPagination from '@/components/nav/NavPagination/NavPagination.vue';
import apiWarehousesServices from '@/utils/api/services/apiWarehousesServices';
import apiDeliveryCompanies from '@/utils/api/services/apiDeliveryCompanies';
import apiStructuresServices from '@/utils/api/services/apiStructuresServices';
import Icon from '@/components/share/Icon/Icon.vue';
import apiReasonReturnsServices from '@/utils/api/services/apiReasonReturnsServices';
import apiOrganizationsServices from '@/utils/api/services/apiOrganizationsServices';
import apiUsersServicesOld from '@/utils/api/services/apiUsersServicesOld';
import apiEmployeesServices from '@/utils/api/services/apiEmployeesServices';

export default defineComponent({
  name: 'Searcher',
  components: {
    NavPagination,
    Button,
    Field,
    List,
    Loader,
    Icon,
  },
  setup() {
    const searcher = useSearcher();
    const notifications = useNotifications();
    const products = useProducts();

    const selectedProducts = products.products;

    return {
      searcher,
      products,
      selectedProducts,
      notifications,
    };
  },
  data() {
    // @ts-ignore
    return {
      query: '',
      paginationPage: 1,
      component: this.searcher.component,
      container: [],
      meta: '',
      entity: {
        clients: {
          table: [
            {
              title: this.$t('clients.name'),
              type: 'string',
              value: 'name',
              width: 370,
            },
            {
              title: this.$t('clients.email'),
              type: 'string',
              value: 'email',
              width: 300,
            },
            {
              title: this.$t('clients.phone'),
              type: 'phone',
              value: 'phone',
            },
            {
              title: this.$t('clients.delivery_address'),
              type: 'string',
              value: 'deliveryAddress',
            },
          ],
          services: apiClientsServices,
          params: {
            order: 'id:desc',
            paginate: import.meta.env.VITE_DEFAULT_PAGINATE,
            relationships: 'contracts',
          },
        },
        items: {
          table: [
            {
              title: this.$t('products.vendorCode'),
              type: 'string',
              value: 'article',
              width: 100,
            },
            {
              title: this.$t('products.name'),
              type: 'string',
              value: 'title',
              width: 300,
            },
            {
              title: this.$t('products.categories'),
              type: 'strings',
              value: 'categories',
              width: 120,
              prop: 'title',
            },
            {
              title: this.$t('products.properties'),
              type: 'properties',
              width: 300,
            },
            // {
            //   title: this.$t('products.stocks'),
            //   type: 'stocksTotal',
            //   value: 'products.stocks',
            //   width: 150,
            // },
          ],
          subtable: [
            {
              title: this.$t('products.vendorCode'),
              type: 'string',
              value: 'id',
              width: 100,
            },
            // {
            //   title: this.$t('products.image'),
            //   type: 'image',
            //   value: 'name',
            //   width: 100,
            // },
            {
              title: this.$t('products.barcode'),
              type: 'string',
              value: 'barcode',
              width: 100,
            },
            {
              title: this.$t('products.properties'),
              type: 'properties',
              width: 300,
            },
            {
              title: this.$t('products.prices'),
              type: 'prices',
              value: 'products.prices',
              width: 150,
            },
            {
              title: this.$t('products.stocks'),
              type: 'stocks',
              value: 'products.stocks',
              width: 150,
            },
          ],
          services: apiItemsServices,
          params: {
            order: 'id:asc',
            paginate: 10,
            relationships: 'products.item;products.properties;products.stocks;categories;products.stocks.warehouse;products.prices;products.prices.type',
          },
        },
        structures: {
          table: [
            {
              title: this.$t('clients.name'),
              type: 'string',
              value: 'name',
              width: 370,
            },
          ],
          services: apiStructuresServices,
          params: {
            order: 'id:desc',
            paginate: import.meta.env.VITE_DEFAULT_PAGINATE,
            // relationships: 'contracts'
          },
        },
        organizations: {
          table: [
            {
              title: this.$t('clients.name'),
              type: 'string',
              value: 'name',
              width: 370,
            },
          ],
          services: apiOrganizationsServices,
          params: {
            order: 'id:desc',
            paginate: import.meta.env.VITE_DEFAULT_PAGINATE,
            // relationships: 'contracts'
          },
        },
        warehouses: {
          table: [
            {
              title: this.$t('clients.name'),
              type: 'string',
              value: 'attributes.name',
              width: 370,
            },
            {
              title: this.$t('warehouses.article'),
              type: 'string',
              value: 'attributes.article',
              width: 300,
            },
          ],
          services: apiWarehousesServices,
          params: {
            order: 'id:desc',
            'page[size]': import.meta.env.VITE_DEFAULT_PAGINATE,
            display_in_erp: true,
            newApi: true,
          },
        },
        employees: {
          table: [
            {
              title: this.$t('clients.name'),
              type: 'string',
              value: 'attributes.name',
              width: 370,
            },
          ],
          services: apiEmployeesServices,
          params: {
            newApi: true,
            'page[size]': import.meta.env.VITE_DEFAULT_PAGINATE,
          },
        },
        deliveryCompanies: {
          table: [
            {
              title: this.$t('clients.name'),
              type: 'string',
              value: 'attributes.comment',
              width: 370,
            },
          ],
          services: apiDeliveryCompanies,
          params: {
            order: 'id:desc',
            'page[number]': 1,
            'page[size]': import.meta.env.VITE_DEFAULT_PAGINATE,
            newApi: true,
            service: 'apiDeliveryCompanies',
            newKey: 'comment',
            oldKey: 'comment',
          },
        },
        reasonReturns: {
          table: [
            {
              title: this.$t('clients.name'),
              type: 'string',
              value: 'attributes.name',
              width: 370,
            },
          ],
          services: apiReasonReturnsServices,
          params: {
            order: 'id:desc',
            'page[number]': 1,
            'page[size]': import.meta.env.VITE_DEFAULT_PAGINATE,
            newApi: true,
            service: 'apiReasonReturnsServices',
            newKey: 'name',
            oldKey: 'name',
          },
        },
        users: {
          table: [
            {
              title: this.$t('clients.name'),
              type: 'string',
              value: 'name',
              width: 370,
            },
          ],
          services: apiUsersServicesOld,
          params: {
            order: 'id:desc',
            paginate: import.meta.env.VITE_DEFAULT_PAGINATE,
            // relationships: 'contracts'
          },
        },
      },
      cls: { show: '--show' },
      state: {
        isLoading: false,
        isShow: this.searcher.isShow,
      },
    };
  },
  computed: {
    classes() {
      return [
        this.state.isShow ? this.cls.show : '',
      ];
    },
    fetchParams() {
      const params = { ...this.entity[this.searcher.component].params };

      // for refactor
      if (this.searcher.searchParams && this.searcher.searchParams.name === 'is_client') {
        params[`filters[${this.searcher.searchParams.name}]`] = `eq:${1}`;
      }

      if (this.searcher.searchParams && this.searcher.searchParams.name === 'is_supplier') {
        params[`filters[${this.searcher.searchParams.name}]`] = `eq:${1}`;
      }

      if (!params.newApi) params['filters[q]'] = this.query;
      params.page = this.paginationPage;

      return params;
    },
  },
  watch: {
    'searcher.component': function () {
      this.component = this.searcher.component;
    },
    'searcher.value': {
      handler() {
        this.close();
      },
      deep: true,
    },
    'searcher.isShow': function () {
      this.state.isShow = this.searcher.isShow;
      if (this.state.isShow) {
        this.fetch();
      } else {
        this.query = '';
      }
    },
    'searcher.itemEdit': function (value) {
      if (value) {
        this.entity[this.searcher.component].table.push({ type: 'itemEdit' });
      }
    },
    query: {
      handler() {
        this.paginationPage = 1;
        this.fetch();
      },
      deep: true,
    },
    paginationPage: {
      handler() {
        this.fetch();
      },
      deep: true,
    },
  },
  methods: {
    async fetch() {
      const component = this.entity[this.searcher.component];
      this.state.isLoading = true;

      const { data: container, status, meta } = await component.services.getList({}, { params: this.fetchParams });

      if (container) {
        this.container = container;
        this.meta = meta;

        if (component.params.service && component.params.newApi) {
          container.forEach((el: any) => el[component.params.newKey] = el.attributes[component.params.oldKey]);
          console.log(container);
        }
      } else {
        this.notifications.error(this.$t('notification.error'), this.$t('clients.error.loadList'));
      }

      this.state.isLoading = false;
    },
    close() {
      this.searcher.isShow = false;
    },
    testClick() {
      this.close();
      this.products.items = this.searcher.selectedProducts;
    },
  },
});
