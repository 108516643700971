import logger from '@/logger';
import { i18n } from '@/i18n';
import { TLocaleOptions } from '@/types';

import localeTextTranslationsRu from './translations/localeTextTranslationsRu';

const translateText = (
  text: TPathsOfObject<typeof localeTextTranslationsRu>,
  options: TLocaleOptions = { param: null },
): string => {
  const { t, te } = i18n.global;

  if (!te(text)) {
    logger.warn('Translation not found:', text);
  }

  if (!t(text)) {
    return 'No translated';
  }

  return options.param ? t(text, options.param) : t(text);
};

export default translateText;
