import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiItemsMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.itemsV2.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.itemsV2.item,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.itemsV2.list,
  },
  updateItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.itemsV2.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.itemsV2.item,
  },
  syncCategories: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.itemsV2.categories.sync,
  },
  createProperties: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.itemsV2.properties.create,
  },
  updateProperties: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.itemsV2.properties.update,
  },
  attachTags: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.itemsV2.tags.attach,
  },
  detachTags: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.itemsV2.tags.detach,
  },
  syncTags: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.itemsV2.tags.sync,
  },
};

const apiItemsServicesV2 = ApiGateway.createServices(apiItemsMap);

export default apiItemsServicesV2;
