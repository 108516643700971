enum MenuKeys {
  ADMIN = 'admin',
  MAIN = 'directories', // default type
  DIR = 'directories',
  DOCS = 'docs',
  REPORT = 'report',
  TASKS = 'tasks',
  TOOLS = 'tools',
  SITE_SETTINGS = 'site-settings'
}

export default MenuKeys;
