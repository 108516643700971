import { defineComponent } from 'vue';

import Icon from '@/components/share/Icon/Icon.vue';

export default defineComponent({
  name: 'Status',
  components: { Icon },
  props: {
    type: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      statuses: {
        1: {
          icon: '',
          cls: '',
        },
        2: {
          icon: 'file-dashed',
          cls: '--draft',
        },
        3: {
          icon: '',
          cls: '',
        },
        4: {
          icon: 'check-square',
          cls: '--success',
        },
        5: {
          icon: '',
          cls: '',
        },
      } as any,
    };
  },
  computed: {
    status() {
      return this.statuses[this.type];
    },
  },
});
