<template>
  <RouterView v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.fullPath" />
    </keep-alive>
  </RouterView>
  <Searcher />
  <ProductSearcher v-if="productSearcherStore.isShow" />
  <ItemSearcher v-if="itemSearcherStore.isShow" />
  <Notifications />
  <InternetConnection />
</template>

<script lang="ts">
import { RouterView } from 'vue-router';

import { useNavTabs } from '@/store/nav-tabs';
import { useProductSearcher } from '@/store/product-searcher';
import { useScanner } from '@/store/scanner';
import { useItemSearcher } from '@/store/item-searcher';
import Notifications from '@/components/share/Notifications/Notifications.vue';
import ProductSearcher from '@/components/share/ProductSearcher/ProductSearcher.vue';
import ItemSearcher from '@/components/share/ItemSearcher/ItemSearcher.vue';
import InternetConnection from '@/components/share/InternetConnection/InternetConnection.vue';

import useI18n from './application/useI18n';
import Searcher from '../components/share/Searcher/Searcher.vue';

export default {
  name: 'App',
  components: {
    InternetConnection,
    ItemSearcher,
    RouterView,
    Notifications,
    ProductSearcher,
    Searcher,
  },
  setup() {
    const navTabs = useNavTabs();
    const scanner = useScanner();
    const productSearcherStore = useProductSearcher();
    const itemSearcherStore = useItemSearcher();

    useI18n();

    return {
      scanner,
      productSearcherStore,
      itemSearcherStore,
      navTabs,
    };
  },
};
</script>
