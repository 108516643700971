<template>
  <div v-if="!show" class="toaster-tailwind fixed bottom-4 right-4 flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg dark:text-gray-400 dark:bg-gray-800" role="alert">
    <div
      v-if="!connection && !reload"
      class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200"
    >
      <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
      </svg>
      <span class="sr-only">Connection icon</span>
    </div>
    <div
      v-if="connection && !reload"
      class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200"
    >
      <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
      </svg>
      <span class="sr-only">Connection icon</span>
    </div>
    <div class="ms-3 text-sm font-normal">
      {{ message }}
      <div v-if="reload" class="flex items-center gap-1">
        <button
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 ml-4 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          @click="reloadApp"
        >
          Перезагрузить приложение
        </button>
        <div
          class="cursor-pointer bg-slate-200 rounded-full h-7 w-7 flex justify-center items-center"
          @click="cancel"
        >
          <Icon name="x" class="h-5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from 'vue';
import { useI18n } from 'vue-i18n';

import Icon from '@/components/share/Icon/Icon.vue';

const { t } = useI18n();
const show: Ref = ref(navigator.onLine);
const connection: Ref = ref(navigator.onLine);
const message: Ref = ref(t('internetConnection.message.lost'));
const reload: Ref = ref(false);

/**
 * Network Online
 *
 * @function
 * @return void
 */
const online = (): void => {
  message.value = t('internetConnection.message.connected');

  connection.value = true;

  setTimeout(() => {
    message.value = '';

    reload.value = true;
  }, 2000);
};

/**
 * Network Offline
 *
 * @function
 * @return void
 */
const offline = (): void => {
  connection.value = show.value = false;
};

/**
 * Cancel Reload Page
 *
 * @function
 * @return void
 */
const cancel = (): void => {
  connection.value = show.value = true;
};

/**
 * Reload Page
 *
 * @function
 * @return void
 */
const reloadApp = (): void => {
  window.location.reload();
};

/**
 * AddEventListener Ver.
 */
window.addEventListener('online', online);

/**
 * AddEventListener Ver.
 */
window.addEventListener('offline', offline);
</script>

<style scoped lang="scss">
.toaster-tailwind{
  box-shadow: 0 9px 30px rgba(0, 0, 0, .2);
  z-index: 999;
}
</style>
