import { defineStore, StoreDefinition } from 'pinia';

export const useMenu: StoreDefinition = defineStore('menu', {
  state: () => ({
    updated: false as boolean,
    key: '' as string,
    items: {
      title: '' as string,
      favorites: [] as object[],
      ungrouped: [] as object[],
      hidden: [] as object[],
    } as any,
  }),
  actions: {
    updateState(flag: boolean) {
      this.updated = flag;
    },
    setKey(key: string) {
      this.key = key;
    },
    setMenu(favorites: any, ungrouped: any, hidden: any, title: string) {
      this.items.title = title;
      this.items.favorites = favorites;
      this.items.ungrouped = ungrouped;
      this.items.hidden = hidden;
    },
  },
  getters: {
    getMenuState(): any {
      return this.updated;
    },
    getKey(): string {
      return this.key;
    },
    getMenu(): any {
      return this.items;
    },
  },
});
