<template>
  <div
    class="scroll-to-top"
    @click="scrollToTop"
  >
    &uarr;
  </div>
</template>

<script setup lang="ts">
// import {defineEmits, onMounted} from 'vue'

const emit = defineEmits(['action']);

const scrollToTop = () => {
  emit('action', true);
};
</script>

<style lang="scss">
.scroll-to-top{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  position: absolute;
  right: 60px;
  bottom: 30px;
  width: 50px;
  background: var(--color-secondary);
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: all .3s;
}

.area__show-btn-to-top .scroll-to-top{
  opacity: 0.6;
  &:hover{
    opacity: 1;
  }
}
</style>
