import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';

import { useNotifications } from '@/store/notifications';
import { useBuyerOrder } from '@/store/buyer-order';
import { useConstans } from '@/store/constans';
import apiStocksServices from '@/utils/api/services/apiStocksServices';
import { useUserSession } from '@/store/userSession';
import apiUsersServices from '@/utils/api/services/apiUsersServices';
import apiStorageCells from '@/utils/api/services/apiStorageCells';

export const useProducts = defineStore('products', {
  persist: true,
  state: () => ({
    buyerOrder: useBuyerOrder() as any,
    useConstans: useConstans() as any,
    items: [] as any,
    itemsCreate: {} as any,
    nds: 0 as number,
    checkboxes: [] as any,
    currentWarehouse: null,
    currentCell: {} as any,
    acceptanceMode: false,
    typeScanning: 0 as number,
    clearCheckedProducts: false as boolean,
    router: useRouter(),
    currentUser: JSON.parse(useUserSession().user),
    documentStatus: 0 as number,
    lastScannedEntity: {} as any,
  }),
  actions: {
    setLastScannedEntity(type: string, entity: any) {
      const oldVal = this.lastScannedEntity;

      this.lastScannedEntity = entity;
      this.lastScannedEntity.oldVal = oldVal;
      this.lastScannedEntity.type = type;
      // console.log('SCANNED ', this.lastScannedEntity.type, ': ', this.lastScannedEntity)
    },

    updateWarehouseCellsInHonestMarkInProductByCellScanning(type: string, cell: any) {
      if (type === 'cell' && cell.type === 'cell' && cell.oldVal?.id) {
        if (cell.oldVal.type !== 'cell' && cell.oldVal.type === 'honestMark') {
          let item: any = [];

          if (this.router.currentRoute.name === 'ReceiptInvoice' && this.items.length) {
            item = this.items?.find((el: any) => el.product_id === cell.oldVal.product_id);
          } else if (this.router.currentRoute.name === 'ReceiptInvoiceCreate' && this.itemsCreate?.ReceiptInvoiceCreate.length) {
            item = this.itemsCreate?.ReceiptInvoiceCreate?.find((el: any) => el.honestMark?.product_id === cell.oldVal.product_id);
          }
          if (!item) return;

          const honestMark = item.honestArray?.find((ele: any) => ele.honestMark.number == cell.oldVal.number);
          if (!honestMark) return;

          honestMark.honestMark.storageCell = cell.attributes;
        }
      }
    },

    setDocumentStatus(status: number) {
      this.documentStatus = status;
    },

    setClearCheckedProducts(flag: boolean) {
      this.clearCheckedProducts = flag;
    },

    setTypeScanning(num: number) {
      this.typeScanning = num;
    },

    addKeyToItemsCreate(routeKey: string) {
      if (!Array.isArray(this.itemsCreate[routeKey])) {
        this.itemsCreate[routeKey] = [];
      }
    },

    removeKeyFromItemsCreate(routeKey: string) {
      if (Array.isArray(this.itemsCreate[routeKey])) {
        delete this.itemsCreate[routeKey];
      }
    },

    removeItemsCreate(routeKey: string) {
      if (this.itemsCreate[routeKey]) {
        this.itemsCreate[routeKey] = [];
      }
    },

    findKeyInItemsCreate(routeKey: string) {
      return Array.isArray(this.itemsCreate[routeKey]);
    },

    addItemsToItemsCreate(routeKey: string, item: any) {
      this.itemsCreate[routeKey].push(item);
    },

    getTotalByStorageCellId(data: any, storageCellId: any) {
      for (const item of data) {
        if (item.attributes.storage_cell_id === storageCellId) {
          return item.attributes.total;
        }
      }
      return 0;
    },
    /**
     * Add a new item to the current store
     *
     * @param item
     * @param expandArray
     * @param notify
     */
    async addItem(item: any, notify: boolean = true, expandArray: boolean = false) {
      const notification: any = useNotifications();

      if (!item.total) item.total = 0;
      if (!item.receipt_quantity) item.receipt_quantity = 0;
      if (!item.count) item.count = 0;
      if (!item.color) item.color = item?.relationships?.multicolors[0]?.attributes?.name || '';
      if (item.priceNew === undefined) item.priceNew = item?.price || 0;
      if (!item.printCount) item.printCount = 1;
      if (!item.receipt_quantity && this.acceptanceMode) item.receipt_quantity = 1;
      if (item.price) item.total = item.price * (item.count ? item.count : 1);
      if (this.router.currentRoute.name === 'ReturnRequestItem') {
        if (!this.currentUser.relationships?.warehouse?.attributes?.id) {
          await this.getUser();
        }
        if (!item.warehouse_id) item.warehouse_id = this.currentUser.relationships.warehouse.attributes;
      } else if (!item.warehouse_id) item.warehouse_id = null;

      if (!item.storage_cell_id) item.storage_cell_id = null;

      if (this.currentCell && !item.storage_cell_id && item.isCreate) {
        if (this.router.currentRoute.name === 'ReturnRequestItem') {
          const res = await apiStorageCells.getList(null, { 'page[size]': 15, warehouse_id: this.currentUser.relationships?.warehouse?.attributes?.id });
          const isWarhouseCell = res.data.some((el) => el.id === this.currentCell.attributes.id);
          if (isWarhouseCell) {
            item.storage_cell_id = this.currentCell.attributes;
          } else {
            notification.error('Ошибка', 'В текущем складе такой ячейки нет', 2000);
          }
        } else {
          item.storage_cell_id = this.currentCell.attributes;
        }
      }
      if (item.storageCell?.id) {
        item.storage_cell_id = item.storageCell.id;
      }
      if (!item.totalAfterVat) item.totalAfterVat = 0;
      if (!item.vat) item.vat = '13%';
      if (!item.vatSum) item.vatSum = 0;
      if (this.nds) {
        item.nds = this.nds;
        item.sumNds = item.price ? (item.price * this.nds / (100 + this.nds) * item.count).toFixed(2) : '';
      }
      if (!item.oldPrice && (this.router.currentRoute.name == 'PriceSettingCreate' || this.router.currentRoute.name == 'PriceSetting')) {

        const oldPriceId = this.useConstans.constans.find((c: any) => c.key === 'old_price_id');
        if (item?.prices) {
          item.oldPrice = item?.prices.find((el: any) => el.id  == oldPriceId.value || el.price_type_id == oldPriceId.value)?.price;
        } else if (item?.relationships?.prices) {
          item.oldPrice = item?.relationships?.prices.filter((el: any) => el.attributes.price_type_id == oldPriceId.value)[0]?.attributes?.price;
        }
      }
      if (item.storage_cell_id && item?.stocksArray?.length) {
        item.countInventory = this.getTotalByStorageCellId(item.stocksArray, item.storage_cell_id);
      }
      if (!item.countDiscrepancy && (
        this.router.currentRoute.name == 'RecalculationProductsCreate'
        || this.router.currentRoute.name == 'RecalculationProductsItem'
        || this.router.currentRoute.name == 'InventoryItem'
        || this.router.currentRoute.name == 'InventoryCreate'
      )) {
        item.countDiscrepancy = item.countInventory - item.count;
      }
      if (item.isCreate && item.isBuyerOrder || item.isBuyerOrderCreate) {
        const { doc } = this.buyerOrder;

        item.stock_warehouse_id = doc.stock_warehouse_id?.id || doc.stock_warehouse_id;
        item.shipping_warehouse_id = doc.shipping_warehouse_id?.id || doc.shipping_warehouse_id;
        item.delivery_method_id = doc.delivery_method_id?.id || doc.delivery_method_id;
        item.delivery_company_id = doc.delivery_company_id?.id || doc.delivery_company_id;
        item.delivery_address_id = doc.deliveryAddress?.id || doc.delivery_address_id;
        item.delivery_address = doc.deliveryAddress?.full_address || doc.deliveryAddress?.value;
        item.delivery_date = doc.deliveryDate;
        item.delivery_time_range = doc.deliveryTimeRange?.id;
      }
      if (['BuyerOrder', 'BuyerOrderCreate'].includes(this.router.currentRoute.name)) {
        item.warehousesOptions = item.relationships.stocks?.length
          ? item.relationships.stocks
            .filter((f: any) => f.relationships.warehouse.attributes.display_in_erp && f.attributes.total > 0)
            .map((m: any) => {
              const warehouse = m.relationships.warehouse.attributes;
              warehouse.name = `${warehouse.name} (${m.attributes.total}/${m.attributes.reserve})`;
              return warehouse;
            })
          : [];
      }

      if (this.router.currentRoute.name === 'BuyerOrderCreate') {
        item.document_buyer_order_row_status_id = 1;
      }

      const currentRoute: string = String(this.router.currentRoute.name);

      const itemsComponentHonest = [
        'ReceiptInvoice',
        'ReceiptInvoiceCreate',
        'LabelPrinting',
        'MovementProductsCreate',
        'RecalculationProductsCreate',
        'RecalculationProductsItem',
        'MovementProduct',
        'ExpenditureOrder',
        'ExpenditureOrderCreate',
        'ReceiptOrder',
        'ReceiptOrderCreate',
      ];

      if (itemsComponentHonest.includes(currentRoute) || expandArray) {
        const currentItems = this.findKeyInItemsCreate(String(this.router.currentRoute.name))
          ? this.itemsCreate[currentRoute]
          : this.items;
        const itemsHonestArrays = currentItems.filter((p: any) => p.honestArray.length > 0);
        const findTheSameItem = itemsHonestArrays.find((h: any) => parseInt(h.item.id) === parseInt(item.item.id) && h.color === item.color && h.size === item.size);


        if (item?.honestMark?.id && findTheSameItem) {
          findTheSameItem.count = findTheSameItem?.count + item.count;
          findTheSameItem.quantity_received = findTheSameItem?.quantity_received + item.quantity_received;
          findTheSameItem.quantity_shipped = findTheSameItem?.quantity_shipped + item.quantity_shipped;
          findTheSameItem.countInventory = findTheSameItem?.countInventory + item.countInventory;
          findTheSameItem.countDiscrepancy = findTheSameItem?.countInventory - findTheSameItem?.count;
          findTheSameItem?.honestArray.push(item);

          return;
        }
      }

      this.findKeyInItemsCreate(String(this.router.currentRoute.name))
        ? this.addItemsToItemsCreate(String(this.router.currentRoute.name), itemsComponentHonest.includes(currentRoute) || expandArray
          ? this.expandItem(item, true)
          : item,
        )
        : this.items.push(itemsComponentHonest.includes(currentRoute) || expandArray ? this.expandItem(item, false) : item);

      if (notify) {
        notification.success('Добавлен!', `Товар ${item.item?.article || item?.article} успешно добавлен в список`, 2000);
      }
    },
    clearScannerCurrentData() {
      this.currentCell = null;
      this.currentWarehouse = null;
    },
    setCurrentWarehouse(warehouse: any) {
      this.currentWarehouse = warehouse.attributes.id;
    },
    setCurrentCell(cell: any) {
      this.currentCell = cell;
    },
    expandItem(item: any, open: any) {
      return {
        ...item,
        isOpen: open,
        honestArray: item.honestMark?.id ? [item] : [],
      };
    },
    async updateItemFromStock(cell: any) {
      const response = await apiStocksServices.getList({}, {
        params: {
          include: 'product.items,product.size,product.multicolors,product.barcodes',
          filters: btoa(JSON.stringify([
            {
              key: 'stock-current-storage-cell-filter-filter',
              value: { id: cell.id },
            },
          ])),
        },
      });
      response.data.forEach((p: any) => {
        const foundProduct = this.items.find((item: any) => item.product_id === p.attributes.product_id);
        const count = 1;
        const product = {
          isFromAnotherDoc: true,
          isCreate: true,
          isRemoved: false,
          size: p?.relationships?.product?.relationships?.size?.attributes?.value || '',
          color: p?.relationships?.product?.relationships?.multicolors[0]?.attributes?.name || '',
          item: {
            attributes: {
              title: p.relationships.product.relationships?.item?.attributes?.title,
              article: p.relationships.product.relationships?.item?.attributes?.article,
            },
          },
          barcodes: p?.relationships?.product?.relationships?.barcodes || [],
          properties: p?.relationships?.product?.relationships?.properties || [],
          id: p.attributes.id,
          product_id: p.attributes.product_id,
          count,
          countInventory: p.attributes.quantity_actual || p.attributes.total || 0,
          storage_cell_id: p.attributes.storage_cell_id,
          storageCell: cell.attributes,
        };

        if (foundProduct) {
          if (foundProduct.isRemoved) {
            foundProduct.isRemoved = false;
            foundProduct.isUpdate = true;
            foundProduct.count = count;
          } else {
            foundProduct.count += count;
          }
        } else {
          this.addItem(product, false);
        }
      });
    },
    async updateCurrentWarehouseByScanner(cell: any) {
      if (this.currentWarehouse != cell.attributes.warehouse_id) {
        this.updateAllItemsByNewWarehouse(cell.attributes.id);
      } else {
        this.updateItemsByCell(cell.attributes.id);
      }
      if (this.currentCell === null && (this.router.currentRoute.name == 'RecalculationProductsCreate' || this.router.currentRoute.name == 'RecalculationProductsItem')) {
        await this.updateItemFromStock(cell);
      }
      this.currentWarehouse = cell.attributes.warehouse_id;
      this.currentCell = cell;
    },
    updateItemsByCell(cellId: any) {
      const currentRoute = String(this.router.currentRoute.name);

      if (this.findKeyInItemsCreate(currentRoute)) {
        if (this.itemsCreate[currentRoute].length > 0) {
          this.itemsCreate[currentRoute].forEach((el: any) => {
            if (!el.storage_cell_id) {
              el.storage_cell_id = cellId;
            }
          });
        }
      } else if (this.items.length > 0) {
        this.items.forEach((el: any) => {
          if (!el.storage_cell_id) {
            el.storage_cell_id = cellId;
          }
        });
      }
    },
    async getUser() {
      const { data, status } = await apiUsersServices.getItem({ id: this.currentUser.id }, { params: { include: 'warehouse' } });
      if (status === 200) {
        this.currentUser = data;
      }
    },
    updateAllItemsByNewWarehouse(cellId: any) {
      const currentRoute = String(this.router.currentRoute.name);

      if (this.findKeyInItemsCreate(currentRoute)) {
        if (this.itemsCreate[currentRoute].length > 0) {
          this.itemsCreate[currentRoute].forEach((el: any) => {
            el.storage_cell_id = cellId;
          });
        }
      } else if (this.items.length > 0) {
        this.items.forEach((el: any) => {
          el.storage_cell_id = cellId;
        });
      }
    },

    clearItemsCell(cell: string = 'storageCell') {
      const currentRoute = String(this.router.currentRoute.name);

      if (this.findKeyInItemsCreate(currentRoute)) {
        if (this.itemsCreate[currentRoute].length > 0) {
          this.itemsCreate[currentRoute].forEach((el: any) => {
            el[cell] = null;
          });
        }
      } else if (this.items.length > 0) {
        this.items.forEach((el: any) => {
          el[cell] = null;
        });
      }
    },

    updateItemsStorageValue(fromValue: any, toValue: any) {
      const currentRoute = String(this.router.currentRoute.name);

      if (this.findKeyInItemsCreate(currentRoute)) {
        this.itemsCreate[currentRoute].forEach((el: any) => {
          el.from_storage_cell_id = fromValue?.id ? fromValue.id : '';
          el.to_storage_cell_id = toValue?.id ? toValue.id : '';
          el.fromStorageCell = fromValue;
          el.toStorageCell = toValue;
        });
      } else {
        this.items.forEach((el: any) => {
          el.from_storage_cell_id = fromValue?.id ? fromValue.id : '';
          el.to_storage_cell_id = toValue?.id ? toValue.id : '';
          el.fromStorageCell = fromValue;
          el.toStorageCell = toValue;
        });
      }
    },

    pushToItem(item: any) {
      const currentRoute = String(this.router.currentRoute.name);

      this.findKeyInItemsCreate(currentRoute)
        ? this.itemsCreate[currentRoute].push(item)
        : this.items.push(item);
    },
    findItem(search: any): any {
      return this.items.find((item: any) => search.id === item.id);
    },

    /**
     * Find any product with the same property
     *
     * @param search
     * @param product
     */
    findPresence(search: any, product: any) {
      const prop: string = `is${search}`;

      if (this.items.length === 0) {
        product[prop] = true;
      }
      if (this.items.find((item: any) => item.hasOwnProperty(prop))) {
        product[prop] = true;
      }
    },

    /**
     * Search a duplicate product in the current store
     *
     * @param search
     */
    isDuplicate(search: any) {
      return this.findKeyInItemsCreate(String(this.router.currentRoute.name))
        ? this.itemsCreate[String(this.router.currentRoute.name)].map((e: any) => e.barcode).indexOf(search.barcode)
        : this.items.map((e: any) => e.barcode).indexOf(search.barcode);
    },

    /**
     * Find the product index in the current store
     *
     * @param search
     * @return number
     */
    findItemIndex(search: any): any {
      return this.findKeyInItemsCreate(String(this.router.currentRoute.name))
        ? this.itemsCreate[String(this.router.currentRoute.name)].map((e: any) => e.id).indexOf(search.id)
        : this.items.map((e: any) => e.id).indexOf(search.id);
    },

    /**
     * Set the product price in the current store
     *
     * @param search
     * @param float
     * @param price
     */
    setItemPrice(search: any, price: number): void {
      const index = this.findItemIndex(search);

      if (index >= 0) {
        this.findKeyInItemsCreate(String(this.router.currentRoute.name))
          ? this.itemsCreate[String(this.router.currentRoute.name)][index].price =  price
          : this.items[index].price =  price;
      }
    },

    /**
     * Set the product old price in the current store
     *
     * @param search
     */
    setOldPrice(search: any) {
      const index = this.findItemIndex(search);

      if (index >= 0) {
        this.findKeyInItemsCreate(String(this.router.currentRoute.name))
          ? this.itemsCreate[String(this.router.currentRoute.name)][index].show_old_price = this.items[index].show_old_price ? 0 : 1
          : this.items[index].show_old_price = this.items[index].show_old_price ? 0 : 1;
      }
    },

    /**
     * Set the product count in the current store
     *
     * @param search
     * @param count
     */
    setItemCount(search: any, count: number): any {
      const index = this.findItemIndex(search);

      if (index >= 0) {
        this.findKeyInItemsCreate(String(this.router.currentRoute.name))
          ? this.itemsCreate[String(this.router.currentRoute.name)][index].count = count
          : this.items[index].count = count;
      }
    },

    /**
     * Add product quantity
     *
     * @param product_id
     * @param quantity
     */
    addProductQuantity(product_id: number, field: string, quantity: number): any {
      const index = this.products.findIndex((p: any) => p.product_id === product_id);

      if (index > -1) {
        const currentItems = this.findKeyInItemsCreate(String(this.router.currentRoute.name))
          ? this.itemsCreate[String(this.router.currentRoute.name)]
          : this.items;

        currentItems[index][field] += quantity;
      }
    },
    addHonestMarkQuantityToExpand(product_id: number, honestMarkIndex: any, field: string, quantity: number): any {
      this.products.map((p: any) => {
        if (p.product_id == product_id) {
          p.expand[honestMarkIndex][field] += quantity;
        }
      });
    },
    addHonestMarkQuantityToExpandWithCells(productIndex: number, honestMarkIndex: any, field: string, quantity: number): any {
      this.products[productIndex].expand[honestMarkIndex][field] += quantity;
    },
    addHonestMarkQuantityToExpandWithoutCells(productIndex: number, honestMarkIndex: any, field: string, quantity: number): any {
      this.products[productIndex].expand[honestMarkIndex][field] += quantity;
    },

    /**
     * Add product quantity
     *
     * @param product_id
     * @param quantity
     */
    addProductQuantityToExpandStorageCell(expandStorageCellId: number, expandStorageCellIndex: number, product: any, field: string, quantity: number): any {
      const index = this.products.findIndex((p: any) => p.product_id === product.product_id);
      if (index > -1) {
        if (this.findKeyInItemsCreate(String(this.router.currentRoute.name))) {
          const expandIndex = this.itemsCreate[String(this.router.currentRoute.name)][index].expand
            .findIndex((e: any) => e.product_id === product.product_id && e.storage_cell_id === expandStorageCellId && !e.honest_mark_id);
          if (expandIndex > -1) {
            this.itemsCreate[String(this.router.currentRoute.name)][index].expand[expandIndex][field] += quantity;
          } else {
            product[field] = 1;
            this.itemsCreate[String(this.router.currentRoute.name)][index].expand.splice(expandStorageCellIndex + 1, 0, product);
          }
        } else {
          const expandIndex = this.items[index].expand
            .findIndex((e: any) => e.product_id === product.product_id && e.storage_cell_id === expandStorageCellId && !e.honest_mark_id);
          if (expandIndex > -1) {
            this.items[index].expand[expandIndex][field] += quantity;
          } else {
            product[field] = 1;
            this.items[index].expand.splice(expandStorageCellIndex + 1, 0, product);
          }
        }
      }
    },

    addProductQuantityToExpandProductWithoutCell(scannedBarcodeType: string, product: any, field: string, quantity: number): any {
      const index = this.products.findIndex((p: any) => p.product_id === product.product_id);
      if (index > -1) {
        this.items[index].expand = this.items[index].expand ? this.items[index].expand : [];
        const expandIndex = this.items[index].expand
          .findIndex((e: any) => e.product_id === product.product_id && e.scannedBarcodeType == scannedBarcodeType);
        if (expandIndex > -1) {
          this.items[index].expand[expandIndex][field] += quantity;
        } else {
          product[field] = 1;
          this.items[index].expand.splice(0, 0, product);
        }
      }
    },

    addProductQuantityToExpandProduct(scannedBarcodeType: string, expandStorageCellId: number, expandStorageCellIndex: number, product: any, field: string, quantity: number): any {
      const index = this.products.findIndex((p: any) => p.product_id === product.product_id);
      if (index > -1) {
        const expandIndex = this.items[index].expand
          .findIndex((e: any) => e.product_id === product.product_id && e.storage_cell_id === expandStorageCellId && e.scannedBarcodeType == scannedBarcodeType);
        if (expandIndex > -1) {
          this.items[index].expand[expandIndex][field] += quantity;
        } else {
          product[field] = 1;
          this.items[index].expand.splice(expandStorageCellIndex + 1, 0, product);
        }
      }
    },

    /**
     * Insert Honest Mark
     *
     * @param product_id
     * @param honestMark
     */
    insertHonestMark(product_id: number, honestMark: any): any {
      const index = this.products.findIndex((p: any) => p.product_id === product_id);
      const obj = {
        honestMark,
        storageCell: honestMark.storageCel,
        quantity_shipped: honestMark.quantity_shipped,
        quantity_received: honestMark.quantity_received,
      };
      if (index > -1) {
        this.findKeyInItemsCreate(String(this.router.currentRoute.name))
          ? this.itemsCreate[String(this.router.currentRoute.name)][index].honestArray.push(obj)
          : this.items[index].honestArray?.push(obj);
      }
    },

    insertHonestMarkToExpandArray(product_id: number, honestMark: any): any {
      const index = this.products.findIndex((p: any) => p.product_id === product_id);

      if (index > -1 && honestMark) {
        if (this.items[index].expand?.length) {
          this.items[index].expand?.push(honestMark);
        } else {
          this.items[index].expand = [honestMark];
        }
      }
    },

    /**
     * Insert expand
     *
     * @param product_id
     * @param expand
     */
    insertToExpand(product_id: number, expand: any): any {
      const index = this.products.findIndex((p: any) => p.product_id === product_id);
      if (index > -1) {
        if (this.findKeyInItemsCreate(String(this.router.currentRoute.name))) {
          if (!this.itemsCreate[String(this.router.currentRoute.name)][index].expand) {
            Object.assign(this.itemsCreate[String(this.router.currentRoute.name)][index], { expand: [] });
          }
          this.itemsCreate[String(this.router.currentRoute.name)][index].expand.push(expand);
        } else {
          if (!this.items[index].expand) {
            Object.assign(this.items[index], { expand: [] });
          }
          this.items[index].expand.push(expand);
        }
      }
    },

    updateProductFieldInProductStore(productIndex: number, field: string, val: any) {
      if (productIndex > -1 && field) {
        this.products[productIndex][field] = val;
      }
    },

    updateProductExpandStorageCells(product_id: number, oldItem: any, newItem: any) {
      const index = this.products.findIndex((p: any) => p.product_id === product_id && p.expand?.length);
      if (index > -1) {
        const currentItems = this.findKeyInItemsCreate(String(this.router.currentRoute.name))
          ? this.itemsCreate[String(this.router.currentRoute.name)]
          : this.items;
        currentItems[index].expand.forEach((item: any) => {
          if (item.storage_cell_id === oldItem.id) item.storage_cell_id = newItem.id;
        });
      }
    },

    /**
     * Insert expand storage cell group
     *
     * @param product_id
     * @param expand
     */
    insertToExpandStorageCellGroup(product_id: number, index: number, expand: any): any {
      const productIndex = this.products.findIndex((p: any) => p.product_id === product_id);
      if (productIndex > -1) {
        if (this.findKeyInItemsCreate(String(this.router.currentRoute.name))) {
          if (!this.itemsCreate[String(this.router.currentRoute.name)][productIndex].expand) {
            Object.assign(this.itemsCreate[String(this.router.currentRoute.name)][productIndex], { expand: [] });
          }
          this.itemsCreate[String(this.router.currentRoute.name)][productIndex].expand.splice(index + 1, 0, expand);
        } else {
          if (!this.items[productIndex].expand) {
            Object.assign(this.items[productIndex], { expand: [] });
          }
          this.items[productIndex].expand.splice(index + 1, 0, expand);
        }
      }
    },

    /**
     * Insert expand storage cell group
     *
     * @param product_id
     * @param expand
     */
    insertHonestMarkToExpandWithoutCells(product_id: number, expand: any): any {
      const productIndex = this.products.findIndex((p: any) => p.product_id === product_id);
      if (productIndex > -1) {
        if (this.findKeyInItemsCreate(String(this.router.currentRoute.name))) {
          if (!this.itemsCreate[String(this.router.currentRoute.name)][productIndex].expand) {
            Object.assign(this.itemsCreate[String(this.router.currentRoute.name)][productIndex], { expand: [] });
          }
          this.itemsCreate[String(this.router.currentRoute.name)][productIndex].expand.splice(0, 0, expand);
        } else {
          if (!this.items[productIndex].expand) {
            Object.assign(this.items[productIndex], { expand: [] });
          }
          this.items[productIndex].expand.splice(0, 0, expand);
        }
      }
    },

    /**
     * Insert storage cell
     *
     * @param product_id
     * @param storageCellName
     */
    insertStorageCell(product_id: number, storageCellName: any): any {
      const index = this.products.findIndex((p: any) => p.product_id === product_id);
      if (index > -1) {
        this.findKeyInItemsCreate(String(this.router.currentRoute.name))
          ? this.itemsCreate[String(this.router.currentRoute.name)][index].selectedStorageCell = { name: storageCellName }
          : this.items[index].selectedStorageCell = { name: storageCellName };
      }
    },

    /**
     * Set the product sum in the current store
     *
     * @param search
     * @param price
     * @param count
     * @param float
     */
    setTotal(search: any, price: number, count: number, float: boolean = false): any {

      console.log('🚀 ~ price:', price);
      const index = this.findItemIndex(search);
      const currentRoute: string = String(this.router.currentRoute.name);

      if (index >= 0) {

        if (this.findKeyInItemsCreate(String(this.router.currentRoute.name))) {
          this.itemsCreate[currentRoute][index].total = float
            ? (parseFloat(price.replace(',', '.'))) * count
            : Math.round(price * count);
          this.itemsCreate[currentRoute][index].vatSum = float
            ? this.itemsCreate[currentRoute][index].total * 0.13
            : Math.round(this.itemsCreate[currentRoute][index].total * 0.13);
          this.itemsCreate[currentRoute][index].totalAfterVat = float
            ? this.itemsCreate[currentRoute][index].total - (this.itemsCreate[currentRoute][index].total * 0.13)
            : Math.round(this.itemsCreate[currentRoute][index].total - (this.itemsCreate[currentRoute][index].total * 0.13));
        } else {
          this.items[index].total = float
            ? typeof price === 'number' ? price * count : (parseFloat(price?.replace(',', '.'))) * count
            : Math.round(price * count);
          this.items[index].vatSum = float
            ? this.items[index].total * 0.13
            : Math.round(this.items[index].total * 0.13);
          this.items[index].totalAfterVat = float
            ? this.items[index].total - (this.items[index].total * 0.13)
            : Math.round(this.items[index].total - (this.items[index].total * 0.13));
        }
      }
    },

    /**
     * Set the product sum nds in the current store
     *
     * @param search
     * @param price
     * @param count
     */
    setSumNds(search: any, price: number, count: number): any {
      const index = this.findItemIndex(search);
      const currentRoute = String(this.router.currentRoute.name);

      if (index >= 0) {
        this.findKeyInItemsCreate(currentRoute)
          ? this.itemsCreate[currentRoute][index].sumNds = (price * this.itemsCreate[currentRoute][index].nds / (100 + this.itemsCreate[currentRoute][index].nds) * count).toFixed(2)
          : this.items[index].sumNds = (price * this.items[index].nds / (100 + this.items[index].nds) * count).toFixed(2);
      }
    },

    clearProductExpand(product: any) {

      const mapClearField = {
        6: 'quantity_shipped',
        7: 'quantity_received',
      };

      const index = this.products.findIndex((p: any) => p.product_id === product.product_id && p.expand?.length);

      const currentItems = this.findKeyInItemsCreate(String(this.router.currentRoute.name))
        ? this.itemsCreate[String(this.router.currentRoute.name)]
        : this.items;

      currentItems[index].expand.forEach((e: any) => e.isRemoved = true);
      currentItems[index].selectedStorageCell = {};

      if (mapClearField[product.document_movement_product_row_status_id])
        currentItems[index][mapClearField[product.document_movement_product_row_status_id]] = 0;

    },

    /**
     * Remove an item from the current store
     *
     * @param item
     */
    removeItem(item: any) {
      const notification: any = useNotifications();
      const indexCreate = this.itemsCreate[String(this.router.currentRoute.name)]?.map((e: any) => e.id).indexOf(item.id);

      if (this.findKeyInItemsCreate(String(this.router.currentRoute.name)) || (this.router.currentRoute.name === 'CashierWorkplace' || indexCreate)) {
        this.itemsCreate[String(this.router.currentRoute.name)].forEach((e: any) => {
          if (e.id === item.id) {
            e.isUpdate = false;
            e.isRemoved = true;
          }
        });
      }

      if (this.items.length) {
        this.items.forEach((e: any) => {
          if (e.id === item.id) {
            e.isUpdate = false;
            e.isRemoved = true;
          }
        });
      }

      notification.info('Товар Удален', `Товар ${item.item.article || item.item.attributes.article} удален из списка`, 2000);
    },
    removeHonestItem(item: any, product: any) {
      const notification: any = useNotifications();
      item.isRemoved = true;
      if (product.honestArray.every((el: any) => el.isRemoved)) { product.isRemoved = true; }

      notification.info('Товар Удален', `Товар ${item.item.article || item.item.attributes.article} удален из списка`, 2000);
    },

    deleteHonestMarkFromExpand(el: any) {
      const notification: any = useNotifications();
      const currentItems = this.findKeyInItemsCreate(String(this.router.currentRoute.name))
        ? this.findKeyInItemsCreate(String(this.router.currentRoute.name))
        : this.items;

      const itemIndex = currentItems.findIndex((item: any) => item.product_id === el.product_id && item.expand?.length);

      const honsetMarkIndex = itemIndex > -1
        ? currentItems[itemIndex].expand.findIndex((item: any) => item.honest_mark_id === el.honest_mark_id)
        : -1;

      if (honsetMarkIndex > -1) {
        currentItems[itemIndex].expand.splice(honsetMarkIndex, 1);
        notification.info('Товар Удален', 'Товар удален из списка', 2000);
      }
    },

    /**
     * Set the checkbox in the current store
     *
     * @param item
     */
    setCheckbox(item: object) {
      this.checkboxes.push(item);
    },

    /**
     * Set all the show_old_price files in the current store
     *
     */
    toggleOldPrice(flag: number) {
      this.findKeyInItemsCreate(String(this.router.currentRoute.name))
        ? this.itemsCreate[String(this.router.currentRoute.name)].forEach((item: any) => item.show_old_price = flag)
        : this.items.forEach((item: any) => item.show_old_price = flag);
    },

    /**
     * Remove the checkbox from the current store
     *
     * @param item
     */
    removeCheckbox(item: any) {
      const index = this.checkboxes.map((e: any) => e.id).indexOf(item.id);

      this.checkboxes.splice(index, 1);
    },

    /**
     * Clear the current store
     *
     */
    remove() {
      this.items = [];
      this.itemsCreate = {};
      this.checkboxes = [];
      this.documentStatus = 0;
    },

    /**
     * Clear the all checkboxes from the current store
     *
     */
    removeCheckboxes() {
      this.checkboxes = [];
    },
    addStorageCellsToProducts(product_id: number, cells: string[]) {
      const index = this.items.findIndex((p: any) => p.product_id === product_id);
      this.items[index].productInStorageCells = cells;
    },
  },
  getters: {
    products(): any {
      return this.items.length >= 0 ? this.items : this.itemsCreate[String(this.router.currentRoute.name)];
    },
    selectedItems(): any {
      return this.checkboxes;
    },
  },
});
