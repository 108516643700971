<template>
  <!--  <div class="nav-list">-->
  <!--    <div-->
  <!--      v-if="state.isLoading"-->
  <!--      class="loader__icon"-->
  <!--    >-->
  <!--      <Icon name="circle-notch" />-->
  <!--    </div>-->
  <!--    <div-->
  <!--      v-if="!state.isLoading"-->
  <!--      class="nav-list__favorites"-->
  <!--      @dragover="preventDefaults"-->
  <!--      @dragover.prevent="onDropOverItemList($event)"-->
  <!--      @drop.prevent="onDropToFavorites($event)"-->
  <!--    >-->
  <!--      <div-->
  <!--        class="nav-list__heading"-->
  <!--        :class="{-->
  <!--          '&#45;&#45;mb-5': favoriteLinks.length > 0-->
  <!--        }"-->
  <!--      >-->
  <!--        {{ $t('wishlist.title') }}-->
  <!--      </div>-->
  <!--      <div-->
  <!--        v-for="(item, index) in favoriteLinks"-->
  <!--        :key="index"-->
  <!--        @click="$router.push(item.app_action)"-->
  <!--        @dragstart="startDrag($event, item)"-->

  <!--        @dragover.prevent="onDropOverItem($event, item)"-->
  <!--        @drop.prevent="onDropSort($event)"-->
  <!--        :draggable="state.isEdit"-->
  <!--        :data-id="item.id"-->
  <!--        class="dragging-item"-->
  <!--      >-->
  <!--        <div-->
  <!--          class="nav-list-item nav-list__item &#45;&#45;favorites"-->
  <!--          :class="{-->
  <!--            '&#45;&#45;draggable': state.isEdit,-->
  <!--            '&#45;&#45;fake': item.id === 777-->
  <!--          }"-->
  <!--        >-->
  <!--          {{ item.title }}-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div-->
  <!--      v-if="!state.isLoading"-->
  <!--      class="nav-list__ungrouped"-->
  <!--       @dragover="preventDefaults"-->
  <!--       @dragleave.stop.prevent-->
  <!--       @drop.prevent="onDropToUngrouped($event)"-->
  <!--    >-->
  <!--      <div-->
  <!--        class="nav-list__heading &#45;&#45;mb-5"-->
  <!--        :class="{-->
  <!--          '&#45;&#45;mb-5': Array.from(ungroupedLinks).length > 0-->
  <!--        }"-->
  <!--      >-->
  <!--        {{ $t('menu.title.main') }}-->
  <!--      </div>-->
  <!--      <div-->
  <!--        class="nav-list-item nav-list__item &#45;&#45;ungrouped"-->
  <!--        :class="{-->
  <!--        '&#45;&#45;draggable': state.isEdit-->
  <!--      }"-->
  <!--        v-for="(item, index) in ungroupedLinks"-->
  <!--        :key="index"-->
  <!--        @click="$router.push(item.app_action)"-->
  <!--        @dragstart="startDrag($event, item)"-->
  <!--        @dragleave.prevent-->
  <!--        @dragover.prevent-->
  <!--        :draggable="state.isEdit"-->
  <!--      >-->
  <!--        {{ item.title }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <div class="nav-list">
    <div
      v-if="!state.isLoading"
      class="flex flex-wrap justify-end buttons-group"
    >
      <Button
        v-if="!state.isEdit"
        size="sm"
        :mods="['bg-secondary', 'bg-white-outline', 'btn-edit']"
        @click.stop.prevent="state.isEdit = !state.isEdit"
      >
        <Icon name="config" />
      </Button>
      <Button
        v-if="state.isEdit"
        size="sm"
        :mods="['bg-secondary', 'bg-white-outline', 'btn-save']"
        @click.stop.prevent="menuSync"
      >
        <span>{{ $t('button.save') }} </span>
      </Button>
    </div>
    <div
      v-if="state.isLoading"
      class="loader__icon"
    >
      <Icon name="circle-notch" />
    </div>
    <div
      v-if="!state.isLoading"
      class="nav-list__heading flex flex-wrap max-w-auto"
      :class="{
        '--mb-5': favoriteLinks.length > 0,
        'mb-6 toggled': !state.favoritesShow,
      }"
      @click="state.favoritesShow = !state.favoritesShow"
    >
      <span>{{ $t('wishlist.title') }}</span>
      <Icon name="caret" />
    </div>
    <draggableComponent
      v-if="!state.isLoading && state.favoritesShow"
      class="nav-list__favorites"
      :class="{
        'mt-2': favoriteLinks.length === 0,
        '--edit': state.isEdit,
      }"
      :list="favoriteLinks"
      :disabled="!state.isEdit"
      group="menu"
      itemKey="title"
      @change="sortChanged"
    >
      <template #item="{ element, index }">
        <div
          class="dragging-item"
          @click="goToTheLink(element)"
        >
          <div class="nav-list-item nav-list__item --favorites">
            {{ element.title }}
          </div>
        </div>
      </template>
    </draggableComponent>

    <div
      v-if="!state.isLoading"
      class="nav-list__heading flex flex-wrap"
      :class="{
        '--mb-5': ungroupedLinks.length > 0,
        'mb-6 toggled': !state.ungroupedShow,
      }"
      @click="state.ungroupedShow = !state.ungroupedShow"
    >
      <span>{{ title }}</span>
      <Icon name="caret" />
    </div>
    <draggableComponent
      v-if="!state.isLoading && state.ungroupedShow"
      class="nav-list__ungrouped"
      :class="{
        'mt-2': ungroupedLinks.length === 0,
        '--edit': state.isEdit,
      }"
      :list="ungroupedLinks"
      :disabled="!state.isEdit"
      group="menu"
      itemKey="title"
      @change="sortChanged"
    >
      <template #item="{ element, index }">
        <div
          class="dragging-item"
          @click="goToTheLink(element)"
        >
          <div class="nav-list-item nav-list__item --ungrouped">
            {{ element.title }}
          </div>
        </div>
      </template>
    </draggableComponent>

    <div
      v-if="!state.isLoading && state.isEdit"
      class="nav-list__heading flex flex-wrap mt-6 --mb-5"
      :class="{
        'mb-6 toggled': !state.hiddenShow,
      }"
      @click="state.hiddenShow = !state.hiddenShow"
    >
      <span>{{ $t('menu.title.hidden') }}</span>
      <Icon name="caret" />
    </div>
    <draggableComponent
      v-if="!state.isLoading && state.isEdit && state.hiddenShow"
      class="nav-list__ungrouped"
      :class="{
        'mt-2': hiddenLinks.length === 0,
        '--edit': state.isEdit,
      }"
      :list="hiddenLinks"
      :disabled="!state.isEdit"
      group="menu"
      itemKey="title"
      @change="sortChanged"
    >
      <template #item="{ element, index }">
        <div
          class="dragging-item"
          @click="goToTheLink(element)"
        >
          <div class="nav-list-item nav-list__item --hidden">
            {{ element.title }}
          </div>
        </div>
      </template>
    </draggableComponent>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  ComputedRef,
  onMounted,
  ref,
  Ref,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';
import draggableComponent from 'vuedraggable';

import { useMenu } from '@/store/menu';
import { useNavTabs } from '@/store/nav-tabs';
import apiSystemServices from '@/utils/api/services/apiSystemServices';
import Icon from '@/components/share/Icon/Icon.vue';
import Button from '@/components/form/Button/Button.vue';
import { findCurrentMenu } from '@/components/nav/NavPrimary/NavPrimaryLink/NavPrimaryLink';
import menuKeys from '@/utils/menu/menuKeys';

const props = defineProps<{
  menu?: string
}>();
const router = useRouter();
const navTabs = useNavTabs();
const store = useMenu();
const menuState: ComputedRef = computed(() => store.getMenuState);
const state: Ref = ref({
  isLoading: false,
  isEdit: false,
  favoritesShow: false,
  hiddenShow: false,
  ungroupedShow: true,
});
const title: Ref<string> = ref('');
const favoriteLinks: Ref = ref([]);
const hiddenLinks: Ref = ref([]);
const ungroupedLinks: Ref = ref([]);

/**
 * Go to the link
 *
 * @function
 * @async
 * @param element
 * @return {Promise<boolean | void>}
 */
const goToTheLink = async (element: any): Promise<boolean | void> => {
  if (state.value.isEdit === true) return false;

  await router.push({ path: `/${element.app_action}` });

  const currentRoute: any = router.currentRoute.value;

  currentRoute.title = element.title;

  navTabs.setTab(currentRoute);
};

/**
 * Get menu
 *
 * @function
 * @async
 * @param key
 * @return {Promise<void>}
 */
const getMenuLinks = async (key: string): Promise<void> => {
  if (key.length) {
    state.value.isLoading = true;

    const response = await apiSystemServices.getMenus({ key });

    if (response.status === 200) {
      title.value = response.data.baseMenu?.title;
      favoriteLinks.value = response.data.groups[0]?.items;
      hiddenLinks.value = response.data.groups[1]?.items;
      ungroupedLinks.value = response.data.noGroupItems;

      favoriteLinks.value.sort((a: any, b: any) => a.order - b.order);
      ungroupedLinks.value.sort((a: any, b: any) => a.order - b.order);
      hiddenLinks.value.sort((a: any, b: any) => a.order - b.order);

      state.value.favoritesShow = favoriteLinks.value.length > 0;
      state.value.ungroupedShow = ungroupedLinks.value.length > 0;

      store.setMenu(favoriteLinks.value, ungroupedLinks.value, hiddenLinks.value, title.value);
      findCurrentMenu(title.value);
    }
  }

  state.value.isLoading = false;
};

/**
 * Sync menu
 *
 * @function
 * @return {Promise<void>}
 */
const menuSync = async (): Promise<void> => {
  state.value.isLoading = true;

  const menuSync: any[] = [];

  favoriteLinks.value.forEach((item: any, index: number) => {
    menuSync.push({
      menu_item_id: item.id,
      menu_group_id: 1,
      order: ++index,
    });
  });

  hiddenLinks.value.forEach((item: any, index: number) => {
    menuSync.push({
      menu_item_id: item.id,
      menu_group_id: 2,
      order: ++index,
    });
  });

  ungroupedLinks.value.forEach((item: any, index: number) => {
    menuSync.push({
      menu_item_id: item.id,
      menu_group_id: null,
      order: ++index,
    });
  });

  const key: any = localStorage.getItem('keyMenu');
  const response = await apiSystemServices.menusSync({ key }, menuSync);

  if (response.status.toString().startsWith('20')) {
    store.setKey('');
    store.setMenu(favoriteLinks.value, ungroupedLinks.value);
    await getMenuLinks(store.getKey);
  }

  state.value.isLoading = state.value.isEdit = false;
};

/**
 * Change Observer
 *
 * @function
 * @return void
 */
const sortChanged = (): void => {
  state.value.isEdit = true;
};

onMounted(async () => {
  const menu = store.getMenu;

  if (!menu.title.length) {
    const key: any = localStorage.getItem('keyMenu') || menuKeys.DOCS;
    await getMenuLinks(key);
  } else {
    title.value = menu.title;
    ungroupedLinks.value = menu.ungrouped;
    favoriteLinks.value = menu.favorites;
    hiddenLinks.value = menu.hidden;

    state.value.favoritesShow = favoriteLinks.value.length > 0;
    state.value.ungroupedShow = ungroupedLinks.value.length > 0;
  }
});

/**
 * Get an updated menu if menu has been updated
 */
watch(store, async (val) => {
  if (val) {
    const currentMenu = val.getMenu;

    title.value = currentMenu.title;
    ungroupedLinks.value = currentMenu.ungrouped;
    favoriteLinks.value = currentMenu.favorites;
    hiddenLinks.value = currentMenu.hidden;

    state.value.favoritesShow = favoriteLinks.value.length > 0;
    state.value.ungroupedShow = ungroupedLinks.value.length > 0;
  }
});

watch(menuState, (val) => {
  state.value.isLoading = val;
});
// НЕ СТИРАТЬ! ИДЕЯ БЫЛА ХОРОШАЯ, НО ДЕДЛАЙН ПОХОРОНИЛ НАДЕЖДУ...

//
// /**
//  * Define default events for drag'n'drop
//  *
//  * @function
//  * @param e
//  * @return void
//  */
// const preventDefaults: any = (e: Event): void => {
//   e.preventDefault()
//   e.stopPropagation()
// }
//
//
// /**
//  * Start drag'n'drop
//  *
//  * @function
//  * @param e
//  * @param item
//  * @return void
//  */
// const startDrag = (e: DragEvent | any, item: any): void => {
//   e.srcElement.classList.add('--dragging')
//   dragNDropItem.value = item
// }
//
// // TODO combine onDropToFavorites & onDropToUngrouped into one func
// /**
//  * Drop to fav
//  *
//  * @function
//  * @param e - Event
//  * @retun void
//  */
// const onDropToFavorites = (e: DragEvent | any): void => {
//   const findFavoritesIndex: any = favoriteLinks.value.findIndex((item: any) => item.id === dragNDropItem.value.id)
//   const findUngroupedIndex: any = ungroupedLinks.value.findIndex((item: any) => item.id === dragNDropItem.value.id)
//   const findHiddenIndex: any = hiddenLinks.value.findIndex((item: any) => item.id === dragNDropItem.value.id)
//   const findFake = favoriteLinks.value.find((item: any) => item.id === 777)
//   const findFakeIndex = favoriteLinks.value.findIndex((item: any) => item.id === 777)
//
//   if (findUngroupedIndex >= 0) {
//     ungroupedLinks.value.splice(findUngroupedIndex, 1)
//   }
//
//   if (findHiddenIndex >= 0) {
//     hiddenLinks.value.splice(findHiddenIndex, 1)
//   }
//
//   // Add an element to the favorites
//   if (findFavoritesIndex < 0) {
//     dragNDropItem.value.order = findFake.order
//     favoriteLinks.value.push(dragNDropItem.value)
//   }
//
//   // Remove the fake
//   if (findFakeIndex >= 0) {
//     favoriteLinks.value.splice(findFakeIndex, 1)
//   }
//
//   favoriteLinks.value.sort((a: any, b: any) => a.order - b.order)
// }
//
//
//
// /**
//  * Drop to ungrouped
//  *
//  * @function
//  * @param e - Event
//  * @retun void
//  */
// const onDropToUngrouped = (e: DragEvent | any): void => {
//   const findFavoritesIndex: any = favoriteLinks.value.findIndex((item: any) => item.id === dragNDropItem.value.id)
//   const findUngroupedIndex: any = ungroupedLinks.value.findIndex((item: any) => item.id === dragNDropItem.value.id)
//   const findHiddenIndex: any = hiddenLinks.value.findIndex((item: any) => item.id === dragNDropItem.value.id)
//
//   if (findFavoritesIndex >= 0) {
//     favoriteLinks.value.splice(findFavoritesIndex, 1)
//   }
//
//   if (findHiddenIndex >= 0) {
//     hiddenLinks.value.splice(findHiddenIndex, 1)
//   }
//
//   if (findUngroupedIndex < 0) {
//     ungroupedLinks.value.push(dragNDropItem.value)
//   }
// }
//
//
// const onDropSort = (e: DragEvent | any): void => {
//   const target = favoriteLinks.value.find((item: any) => item.id.toString() === e.target.closest('.dragging-item').dataset.id)
//   const findFake = favoriteLinks.value.find((item: any) => item.id === 777)
//
//   console.log(findFake, target, dragNDropItem.value)
//   //createFake.value = false
// }
//
// const onDropLiveItemList = (e: DragEvent | any) => {
//   // const findIndexFake = favoriteLinks.value.findIndex((item: any) => item.id === 777)
//   //
//   // if (findIndexFake >= 0){
//   //   favoriteLinks.value.splice(findIndexFake, 1)
//   // }
// }
//
// const onDropOverItemList = (e: DragEvent | any) => {
//   const findFake = favoriteLinks.value.find((item: any) => item.id === 777)
//
//   if (!findFake) {
//     const fakeEl = {
//       'id': 777,
//       'title': '',
//       'app_action': '/',
//       'order': favoriteLinks.value.length
//     }
//
//     favoriteLinks.value.push(fakeEl)
//
//     const fakeElDom = document.querySelector('[data-id="777"]')
//
//     fakeElDom?.classList.add('--fake-el')
//   }
//
//   favoriteLinks.value.sort((a: any, b: any) => a.order - b.order)
// }
//
//
// const onDropOverItem = (e: DragEvent | any, item: any): void => {
//   const targetEl = e.target.closest('.dragging-item')
//   const findTarget = favoriteLinks.value.find((item: any) => item.id === parseInt(targetEl.dataset.id))
//   const findReal = favoriteLinks.value.find((item: any) => item.id === dragNDropItem.value.id)
//   const findFake = favoriteLinks.value.find((item: any) => item.id === 777)
//   let fakeOrder = findFake ? findFake.order : findReal.order
//
//   if (findFake){
//     findFake.order = item.order
//   }
//
//   item.order = fakeOrder
//
//   if (item.id === 777) {
//     //findReal.order = item.order
//   }
//
//   // console.log(findReal, item.order)
//   // console.log(favoriteLinks.value)
//
//   favoriteLinks.value.sort((a: any, b: any) => a.order - b.order)
// }
</script>

<style scoped lang="scss">
.nav-list{
  position: relative;

  &__ungrouped{
    padding: 0;
    background: none;

    &.--edit{
      margin-bottom: 10px;
      padding: 12px 12px 6px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 8px;
    }

  }

  &__ungrouped,
  &__favorites{
    margin-bottom: 10px;
  }

  &__favorites{
    background: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    padding: 12px 12px 6px;
  }

  &__ungrouped,
  &__favorites{

    &.--edit{

      .dragging-item{
        position: relative;

        &::before{
          display: block;
          content: '';
          width: 12px;
          height: 6px;
          position: absolute;
          top: 15px;
          left: -34px;
          border-top: 1px solid white;
          border-bottom: 1px solid white;
        }

      }

    }
  }

  &__heading{
    position: relative;
    font-size: 13px;
    color: #fff;
    cursor: pointer;

    &.--mb-5{
      margin-bottom: 5px;
    }

    .icon{
      width: 14px;
      margin-left: 7px;
      transform: rotate(180deg);
      transition: all .1s;
    }

    &.toggled{

      .icon{
        transform: rotate(0);
      }

    }

  }

  &__item{
    position: relative;
    margin-bottom: 8px;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 18px;
    color: #fff;
    background: rgba(199,208,233,.5);
    border-radius: 8px;
    transition: .3s;

    &.--hidden,
    &.--ungrouped,
    &.--favorites{
      background: var(--color-menu-item);

      &.--draggable{
        position: relative;

        &::before{
          left: -32px;
        }

      }

      &:hover{
        background: var(--color-menu-item-hover);
      }

    }

    &.--fake{
      min-height: 34.8px;
      background: rgba(255,255,255,.2);
      background-image: radial-gradient(circle at 1px 1px, cornflowerblue 1px, transparent 0);
      background-size: 5px 5px;
    }

  }

}

.loader{

  &__icon{

    .icon{
      color: white;
    }
  }

}

.menu-edit{
  margin-bottom: 15px;

  &__btn-edit{
    width: auto;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 12px;
    font-size: 13px;
    border: 1px solid white;
    border-radius: 8px;
    color: white;
    cursor: pointer;

    .icon{
      width: 22px;
    }

    &.--white{
      margin-left: 7px;
      background: rgba(255,255,255,.3);
    }

  }

}

.buttons-group{
  position: absolute;
  top: -9px;
  right: 0;
}

.--btn-save{
  padding: 8px;
  height: 28px;
  font-size: 12px;
}
.--btn-edit{
  padding: 0;
  position: relative;
  border: none;
  background: none;
  box-shadow: none;
  height: 28px;
  font-size: 13px;

  .icon{
    width: 22px;
  }
}

.max-w-auto{
  width: auto;
  max-width: max-content;
}
</style>
