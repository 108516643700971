import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiClientsMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.clients.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.clients.item,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.clients.list,
  },
  updateItem: {
    method: ApiRequestMethod.PUT,
    route: ApiRoutes.clients.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.clients.item,
  },
};

const apiClientsServices = ApiGateway.createServices(apiClientsMap);

export default apiClientsServices;
