/**
 * TODO: У нас уже есть стор store/dynamicNavigationTabs.ts.
 *       Но избавится от store/nav-tabs.ts не получается быстро.
 *       Ключевой экшн setTab используется во всех страницах для установки табов.
 *       Просто копи-паст.
 *
 *       Пока что принято решение затащить стор store/dynamicNavigationTabs.ts
 *       в store/nav-tabs.ts, в его setTab экшн.
 *
 *       Если вам нужны правки в этом файле, пингуйте Aleksei Kosianov.
 *       Есть риск нечаянно сломать работу табов навигации.
 * 
 *      Таск - https://tools4b.atlassian.net/browse/CFERP-2850
 */
import { defineStore } from 'pinia';
import { ref } from 'vue';

import router from '@/router';

import { useDynamicNavigationTabsStore } from './dynamicNavigationTabs';

export const useNavTabs = defineStore('nav-tabs', () => {
  const dynamicNavigationTabsStore = useDynamicNavigationTabsStore();
  const tabs = ref([]);
  const current = ref(null);
  const isDisabled = ref(false);
  const isBlocked = ref(false);

  function blockTab(block: boolean) {
    isBlocked.value = !!block;
  }

  function findTab(tab: any): any {
    return tabs.value.find((tabsItem: any) => {
      if (tab?.fullPath === tabsItem.fullPath || tab?.name === tabsItem.routeName) {
        return tabsItem;
      }
    });
  }

  function setTab(tab: any, cashed: boolean = false): void | false {
    if (isDisabled.value) return false;

    const routeTab = {
      title: tab.title,
      fullPath: tab?.route?.fullPath || tab.fullPath,
      routeName: tab?.route?.name || tab.name,
    };
    function findTab2(tab: any): any {
      return tabs.value.find((tabsItem: any) => {
        if (tab?.fullPath === tabsItem.fullPath || tab?.name === tabsItem.routeName) {
          return tabsItem;
        }
      });
    }
    const findTab: any = findTab2(routeTab);

    if (!findTab && !cashed) {
      tabs.value.push(routeTab);
      dynamicNavigationTabsStore.pushTabInto(routeTab);
      setCurrent(routeTab);
    } else {
      setCurrent(findTab);
    }
  }

  function setCurrent(tab: any): void {
    // eslint-disable-next-line no-restricted-syntax
    for (const tabItem of tabs.value) {
      tabItem.isCurrent = false;
    }

    tab?.isCurrent ? tab.isCurrent = true : tab.isCurrent = true;
    current.value = tab;

    router.push(tab.fullPath);
  }

  function setLast() {
    setTab(tabs.value.at(0));
  }

  function getCurrentTab() {
    return tabs.value.find((t: any) => t.isCurrent);
  }

  function removeTab(tab: any, setCurrent: boolean = true): void {
    function findTab3(tab: any): any {
      return tabs.value.find((tabsItem: any) => {
        if (tab?.fullPath === tabsItem.fullPath || tab?.name === tabsItem.routeName) {
          return tabsItem;
        }
      });
    }
    function setCurrent2(tab: any): void {
      // eslint-disable-next-line no-restricted-syntax
      for (const tabItem of tabs.value) {
        tabItem.isCurrent = false;
      }

      tab?.isCurrent ? tab.isCurrent = true : tab.isCurrent = true;
      current.value = tab;

      router.push(tab.fullPath);
    }
    const findTab = findTab3(tab);
    const indexTab = tabs.value.indexOf(findTab);

    if (tabs.value.length > 0) {
      tabs.value.splice(indexTab, 1);

      if (setCurrent) {
        const prevTabIndex = indexTab === 0 ? 0 : indexTab - 1;

        tabs.value.length === 0 ? router.push({ name: 'Main' }) : setCurrent2(tabs.value[prevTabIndex]);
      }
    }
  }

  function removeLast() {
    // this.tabs.splice(this.tabs.length - 1, 1);
  }

  function removeCurrent() {
    tabs.value.splice(tabs.value.length - 1, 1);
  }

  function goBack(page: string) {
    removeCurrent();
    // this.isDisabled = false;
    // this.setLast();
    router.push({ name: page });
  }

  function goToTheFirstTab() {
    if (tabs.value.length > 0) {
      const found = tabs.value.find((el: any) => el.isCurrent);
      router.push(found ? found.fullPath : tabs.value[0].fullPath);
    }
  }

  function getCurrent() {
    return tabs.value.find((t: any) => t.isCurrent);
  }

  function remove() {
    tabs.value = [];
    current.value = null;
    isDisabled.value = false;
  }

  return {
    remove,
    blockTab,
    findTab,
    setTab,
    setCurrent,
    setLast,
    getCurrentTab,
    removeTab,
    removeLast,
    removeCurrent,
    goBack,
    getCurrent,
    goToTheFirstTab,
    tabs,
    current,
    isDisabled,
    isBlocked,

  };
});

// export const useNavTabs = defineStore('nav-tabs', {
//   state: () => ({
//     tabs: getStorageTabs() as any,
//     current: null as any,
//     isDisabled: false,
//     isBlocked: false,
//   }),
//   actions: {
//     blockTab(block: boolean) {
//       this.isBlocked = block;
//     },
//     setTab(tab: any, cashed: boolean = false): void | false {
//       if (this.isDisabled) return false;
//       const routeTab = {
//         title: tab.title,
//         fullPath: tab?.route?.fullPath || tab.fullPath,
//         routeName: tab?.route?.name || tab.name,
//       };
//       const findTab: any = this.findTab(routeTab);
//       if (!findTab && !cashed) {
//         this.tabs.push(routeTab);
//         this.setCurrent(routeTab);
//       } else {
//         this.setCurrent(findTab);
//       }
//     },
//     setCurrent(tab: any): void {
//       for (const tabItem of this.tabs) {
//         tabItem.isCurrent = false;
//       }
//       tab?.isCurrent ? tab.isCurrent = true : tab.isCurrent = true;
//       this.current = tab;

//       router.push(tab.fullPath);
//     },
//     setLast() {
//       this.setTab(this.tabs.at(0));
//     },
//     getCurrentTab() {
//       return this.tabs.find((t: any) => t.isCurrent);
//     },
//     removeTab(tab: any, setCurrent: boolean = true): void {
//       const findTab = this.findTab(tab);
//       const indexTab = this.tabs.indexOf(findTab);

//       if (this.tabs.length > 0) {
//         this.tabs.splice(indexTab, 1);
//         if (setCurrent) {
//           const prevTabIndex = indexTab === 0 ? 0 : indexTab - 1;

//           this.tabs.length === 0 ? router.push({ name: 'Main' }) : this.setCurrent(this.tabs[prevTabIndex]);
//         }
//       }
//     },
//     removeLast() {
//       // this.tabs.splice(this.tabs.length - 1, 1);
//     },
//     removeCurrent() {
//       this.tabs.splice(this.tabs.length - 1, 1);
//     },
//     goBack(page: string) {
//       this.removeCurrent();
//       // this.isDisabled = false;
//       // this.setLast();
//       router.push({ name: page });
//     },

//     goToTheFirstTab() {
//       if (this.tabs.length > 0) {
//         const found = this.tabs.find((el: any) => el.isCurrent);
//         router.push(found ? found.fullPath : this.tabs[0].fullPath);
//       }
//     },

//     getCurrent() {
//       return this.tabs.find((t: any) => t.isCurrent);
//     },

//     findTab(tab: any): any {
//       return this.tabs.find((tabsItem: any) => {
//         if (tab?.fullPath === tabsItem.fullPath || tab?.name === tabsItem.routeName) {
//           return tabsItem;
//         }
//       });
//     },
//     remove() {
//       this.tabs = [];
//       this.current = null;
//       this.isDisabled = false;
//     },
//   },
// });
