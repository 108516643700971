<template>
  <div class="item-searcher">
    <div class="item-searcher__modal">
      <div class="item-searcher__container">
        <div
          class="item-searcher__data"
          :class="{
            '--active': !showItems,
            '--md': showItems,
            '--sm': showItems,
          }
          "
        >
          <div class="item-searcher__controls">
            <Button
              class="--bg-gray"
              size="md"
              @click="close"
            >
              Отмена
            </Button>
            <Button
              class="--bg-secondary"
              size="md"
              @click="addToStore"
            >
              Добавить в список
            </Button>
          </div>

          <Items
            place="select"
            selectType="items"
            @action="handlerItemsAction"
          />
        </div>

        <div
          class="item-searcher__card"
          :class="{ '--active': showItems }"
        >
          <div class="item-searcher__card__controls">
            <Button
              size="card-btn"
              :class="{ '--active': showItems }"
              @click="showItems = !showItems"
            >
              <Icon name="card" />
              <span
                v-if="items.length > 0"
                class="item-searcher__card__count"
              >{{ items.length }}</span>
            </Button>
          </div>

          <div class="item-searcher__card__container">
            <div
              v-if="showItems"
              class="item-searcher__card__heading"
            >
              Товары <span v-if="items.length > 0">{{ items.length }}</span>
            </div>
            <div
              v-if="showItems"
              class="item-searcher__card__clear"
              @click="removeAll"
            >
              Очистить список
            </div>

            <div
              v-if="items.length > 0 && showItems"
              class="item-searcher__card__list"
            >
              <div
                v-for="item of items"
                :key="item.id"
                class="item-searcher__card__item flex gap-2 w-full"
              >
                <div class="shrink w-full">
                  <div
                    class="item-searcher__card__item__close"
                    @click="removeItem(item)"
                  >
                    &times;
                  </div>
                  <div class="item-searcher__card__item__title">
                    {{ item.title }}
                  </div>
                  <div class="item-searcher__card__item__props">
                    <div class="item-searcher__card__item__props-desc">
                      Артикль
                    </div>
                    <div class="item-searcher__card__item__props__value">
                      {{ item.article }}
                    </div>
                  </div>
                  <div class="item-searcher__card__item__props">
                    <div class="item-searcher__card__item__props-desc">
                      Категории
                    </div>
                    <div class="item-searcher__card__item__props__value">
                      {{ item.categories }}
                    </div>
                  </div>
                </div>
                <div class="w-100 flex justify-end">
                  <img
                    v-if="item.image"
                    :src="`${item.image}?h=100&fit=contain`"
                    class="object-contain h-24 w-24"
                  >
                </div>
              </div>
              <div class="count-searcher border border-gray-300 rounded-lg p-3">
                <div class="font-bold text-base">
                  {{ `${$t('products.finalTotal')}` }}
                </div>
                <div class="flex justify-between text-sm pt-2">
                  <div>{{ `${$t('products.quantity')}: ${items.length}` }}</div>
                </div>
              </div>
            </div>

            <Button
              v-if="items.length > 0 && showItems"
              class="--bg-secondary"
              size="md"
              @click="close"
            >
              Внести товары
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="item-searcher__bg"
      @click="close"
    />
  </div>
</template>
<script
  src="./ItemSearcher.ts"
  lang="ts"
></script>
<style src="./ItemSearcher.scss"></style>
