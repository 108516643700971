<template>
  <div class="product-searcher">
    <div class="product-searcher__modal">
      <div class="product-searcher__container">
        <div
          class="product-searcher__data"
          :class="{
            '--active': !blocksState.card,
            '--md': blocksState.card || blocksState.productInfo,
            '--sm': blocksState.card || blocksState.productInfo,
          }
          "
        >
          <div class="product-searcher__controls">
            <Button
              class="--bg-gray"
              size="md"
              @click="close"
            >
              Отмена
            </Button>
            <Button
              class="--bg-secondary"
              size="md"
              @click="addToCard"
            >
              Добавить в корзину
            </Button>
          </div>

          <Items
            place="select"
            :basket="card"
            @action="handlerItemsAction"
          />
        </div>

        <div
          class="product-searcher__card"
          :class="{ '--active': blocksState.card || blocksState.productInfo }"
        >
          <div class="product-searcher__card__controls">
            <Button
              size="card-btn"
              :class="{ '--active': blocksState.productInfo }"
              @click="toggleBlocks('productInfo')"
            >
              <Icon name="info" />
            </Button>
            <Button
              size="card-btn"
              :class="{ '--active': blocksState.card }"
              @click="toggleBlocks('card')"
            >
              <Icon name="card" />
              <span
                v-if="card.length > 0"
                class="product-searcher__card__count"
              >{{ card.length }}</span>
            </Button>
          </div>

          <div class="product-searcher__card__container">
            <div
              v-if="!blocksState.productInfo && blocksState.card"
              class="product-searcher__card__heading"
            >
              Корзина <span v-if="card.length > 0">{{ card.length }}</span>
            </div>
            <div
              v-if="!blocksState.productInfo && blocksState.card"
              class="product-searcher__card__clear"
              @click="removeCard"
            >
              Очистить корзину
            </div>

            <div
              v-if="card.length > 0 && blocksState.card"
              class="product-searcher__card__list"
            >
              <div
                v-for="item of card"
                :key="item.id"
                class="product-searcher__card__item flex gap-2 w-full"
              >
                <div class="shrink w-full">
                  <div class="product-searcher__card__item__barcode">
                    {{ item.barcode ? item.barcode : '---' }}
                  </div>
                  <div class="product-searcher__card__item__title">
                    {{ item.item.title }}
                  </div>
                  <div class="product-searcher__card__item__props">
                    <div class="product-searcher__card__item__props-desc">
                      Размер
                    </div>
                    <div class="product-searcher__card__item__props__value">
                      {{ item.size }}
                    </div>
                  </div>
                  <div class="product-searcher__card__item__props">
                    <div class="product-searcher__card__item__props-desc">
                      Цвет
                    </div>
                    <div class="product-searcher__card__item__props__value">
                      {{ item.color }}
                    </div>
                  </div>
                  <div class="product-searcher__card__item__props">
                    <div class="product-searcher__card__item__props-desc">
                      Цена
                    </div>
                    <div class="product-searcher__card__item__props__value">
                      {{ item.price }}
                    </div>
                  </div>
                  <div class="flex align-end">
                    <div class="product-searcher__card__item__props">
                      <div class="product-searcher__card__item__props-desc">
                        Количество
                      </div>
                      <div class="product-searcher__card__item__props__value flex ">
                        <div class="counter-item" @click="item.count > 1 ? item.count-- : ''">
                          -
                        </div>
                        <input
                          v-model="item.count"
                          type="number"
                          min="1"
                          class="w-8 counter-input"
                        >
                        <div class="counter-item" @click="item.count++">
                          +
                        </div>
                      </div>
                    </div>
                    <div
                      class="product-searcher__card__item__close"
                      @click="removeItemCard(item)"
                    >
                      <Icon name="trash" class="text-red-400" />
                    </div>
                  </div>
                </div>
                <div class="w-100 flex justify-end">
                  <img
                    v-if="item.imageUrl"
                    :src="`${item.imageUrl}?h=100&fit=contain`"
                    class="object-contain h-24 w-24"
                  >
                </div>
              </div>
              <div class="count-searcher border border-gray-300 rounded-lg p-3">
                <div class="font-bold text-base">
                  {{ `${$t('products.finalTotal')}` }}
                </div>
                <div class="flex justify-between text-sm pt-2">
                  <div>{{ `${$t('products.quantity')}: ${quantityTotal}` }}</div>
                  <div>{{ `${$t('products.sum')}: ${sumTotal} ₽` }}</div>
                </div>
              </div>
            </div>

            <Button
              v-if="card.length > 0 && blocksState.card"
              class="--bg-secondary"
              size="md"
              @click="addItemsCardToStore"
            >
              Внести товары
            </Button>

            <div
              v-if="productInfo && blocksState.productInfo"
              class="product-searcher__card__product"
            >
              <div class="product-searcher__card__product__image">
                <img
                  v-if="productInfo.images.length === 0"
                  src="/uploads/images/photo-empty.png"
                  alt="empty"
                >
                <img
                  v-else
                  :src="productInfo.images[0].attributes.link"
                  alt=""
                >
              </div>
              <div class="product-searcher__card__product__name">
                {{ productInfo.title }}
              </div>
              <div
                v-if="productInfo.prices.length > 0"
                class="product-searcher__card__product__heading"
              >
                Цены:
              </div>
              <div
                v-for="item of productInfo.prices"
                v-if="productInfo.prices.length > 0"
                :key="item.id"
                class="product-searcher__card__product__price"
              >
                <div>{{ item.title }}:</div>
                <div>
                  <span>({{
                    new Date(Date.parse(item.created_at)).toLocaleString('ru', {
                      year: 'numeric',
                      month:
                        'numeric',
                      day: 'numeric',
                    })
                  }})</span>
                  {{ Number(item.price).toLocaleString('ru') }} &#x20bd;
                </div>
              </div>
              <div
                v-if="productInfo.consist"
                class="product-searcher__card__product__heading"
              >
                Состав:
              </div>
              <div
                v-if="productInfo.consist"
                class="product-searcher__card__product__consist"
              >
                <div>{{ productInfo.consist }}</div>
              </div>
              <div
                v-if="productInfo.color"
                class="product-searcher__card__product__heading"
              >
                Цвет:
              </div>
              <div
                v-if="productInfo.color"
                class="product-searcher__card__product__sizes"
              >
                <div>{{ productInfo.color }}</div>
              </div>
              <div
                v-if="productInfo.size"
                class="product-searcher__card__product__heading"
              >
                Размер:
              </div>
              <div class="product-searcher__card__product__sizes">
                <div>{{ productInfo.size }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="product-searcher__bg"
      @click="close"
    />
  </div>
  <div
    class="modal-default  --hide index"
    :class="{ '--show': blocksState.isShowModalRequired }"
  >
    <div class="modal-default__bg" />
    <div class="modal-default__form --mod-stock">
      <div class="modal-default__heading --mb --flex --fw-n ">
        <div class="warning-modal">
          <Icon name="warning" />
        </div>
        <div class="modal-default__warning">
          {{ $t('cashierWorkplace.info.attention') }}
        </div>
      </div>
      <div class="flex flex-wrap -m-2">
        <div class="w-full p-2">
          <div class="w-12/12 p-2">
            <div class="modal-default__warning-text" v-html="blocksState.currentText" />
            <div class="flex modal-default__buttons">
              <button
                type="button"
                size="sm"
                class="button --sm --bg-secondary"
                @click="closeModalRequired"
              >
                {{ $t('button.close') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script
  src="./ProductSearcher.ts"
  lang="ts"
></script>
<style src="./ProductSearcher.scss"></style>
