<template>
  <div
    class="checkbox"
    :class="classes"
    @click="handleClick"
  >
    <div v-if="title" class="checkbox__title">
      {{ $t(title) }}
    </div>
    <div class="checkbox__wrap">
      <input v-model="checked" class="checkbox__input" type="checkbox">
      <div class="checkbox__icon-wrap">
        <Icon class="checkbox__icon" name="check" />
      </div>
      <div v-if="$slots.default" class="checkbox__content">
        <slot />
      </div>
    </div>
  </div>
</template>
<script src="./Checkbox.ts" lang="ts"></script>
<style src="./Checkbox.scss" lang="scss"></style>
