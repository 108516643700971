import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiUsersMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.users.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.users.item,
    properties: true,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.users.list,
  },
  updateItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.users.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.users.item,
  },
  filters: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.users.filters,
  },
};

const apiUsersServices = ApiGateway.createServices(apiUsersMap);

export default apiUsersServices;
