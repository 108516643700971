<script setup lang="ts">
import {
  onMounted,
  ref,
  reactive,
  watch,
  Ref,
  computed,
} from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useNotifications } from '@/store/notifications';
import { useWishlist } from '@/store/wishlist';
import { useUserSession } from '@/store/userSession';
import { useProducts } from '@/store/products';
import apiItemsServicesV2 from '@/utils/api/services/apiItemsServicesV2';
import apiPriceTypesServices from '@/utils/api/services/apiPriceTypesServices';
import apiProductsServicesV2 from '@/utils/api/services/apiProductsServicesV2';
import apiMulticolorsServicesV2 from '@/utils/api/services/apiMulticolorsServicesV2';
import apiCategoriesServicesV2 from '@/utils/api/services/apiCategoriesServicesV2';
import apiWarehousesServices from '@/utils/api/services/apiWarehousesServices';
import apiWishlistServices from '@/utils/api/services/apiWishlistServices';
import apiUsersServices from '@/utils/api/services/apiUsersServices';
import apiReportServices from '@/utils/api/services/apiReportServices';
import apiColorServicesV2 from '@/utils/api/services/apiColorServicesV2';
import Head from '@/components/share/Head/Head.vue';
import Button from '@/components/form/Button/Button.vue';
import Field from '@/components/form/Field/Field.vue';
import ItemStocks from '@/pages/items/ItemStocks.vue';
import DataTable from '@/components/share/DataTable/DataTable.vue';
import Loader from '@/components/share/Loader/Loader.vue';
import ItemsFilter from '@/components/share/ItemsFilter/ItemsFilter.vue';
import TimeDifference from '@/components/share/TimeDifference/TimeDifference.vue';
import List from '@/components/share/List/List.vue';
import Chips from '@/components/share/Chips/Chips.vue';
import Modal from '@/components/share/Modal/Modal.vue';
import MediaSlider from '@/components/share/MediaSlider/MediaSlider.vue';
import WorkWithTags from '@/components/share/Items/inc/workWithTags.vue';
import apiTagsServices from '@/utils/api/services/apiTagsServices';
import ToggleSwitch from '@/components/share/ToggleSwitch/ToggleSwitch.vue';

const route = useRoute();
const { t } = useI18n();
const wishlist = useWishlist();
const notifications = useNotifications();
const props = defineProps({
  place: String,
  selectType: {
    type: String,
    default: 'products',
  },
  basket: {
    type: Array,
    default: [],
  },
});

enum SelectType {
  Products = 'products',
  Items = 'items'
}

const emit = defineEmits(['action']);

const items: Ref = ref([]);
const modalProduct: Ref = ref([]);
const isLoading: Ref = ref(false);
const isOpenModal: Ref = ref(false);
const isOpenModalPrice: Ref = ref(false);
const search: Ref = ref('');

const itemsHeaders: Ref = ref([
  {
    key: 'article', label: t('products.vendorCode'), link: 'Item', name: 'Item', width: 150, sort: 'article', sortOrder: '',
  },
  { key: 'itemType', label: t('products.itemType'), width: 100 },
  {
    key: 'title', label: t('products.name'), width: 300, sort: 'title', sortOrder: '',
  },
  { key: 'additional_title', label: t('products.additionalTitle'), width: 300 },
  { key: 'categories', label: t('products.categories'), width: 120 },
  {
    key: 'stock', label: t('products.stocks'), width: 150, sort: 'stock_total', sortOrder: '',
  },
  { key: 'reserve', label: t('products.reserve') },
  {
    key: '',
    label: '',
    value: t('products.showBalance'),
    nameValue: true,
    action: 'openItemStock',
    link: false,
  },
]);
const priceHistoryList: Ref = ref([]);
const priceHistoryItem: Ref = ref({});
const user: Ref = ref({});
const productsWithImage: Ref = ref(false);
const productsHeaders: Ref = ref([
  { key: 'barcode', label: 'Штрихкод' },
  { key: 'color', label: 'Цвет', name: 'Product' },
  { key: 'size', label: 'Размер' },
  { key: 'stock', label: 'Количество' },
  { key: 'reserve', label: 'Резерв' },
  {
    key: 'price',
    label: 'Цена',
    action: 'historyPrice',
    nameValue: true,
    iconRight: { name: 'question-circle' },
  },
  { key: 'priceBase', label: 'Базовая цена' },
  { key: 'show_old_price', type: 'checkbox', label: 'Показывать старую цену' },
  {
    key: '',
    label: '',
    value: 'Показать остаток',
    nameValue: true,
    action: 'openItemStock',
    link: false,
  },
  {
    key: 'wishlist',
    label: '',
    icon: 'favorite',
    action: 'toggleWishlist',
    align: 'end',
    class: 'icon__checkbox-favorite',
    modifer: '--checked',
  },
]);
const historyHeader: Ref = ref([
  {
    title: t('docs.number'),
    type: 'string',
    value: 'attributes.document_id',
    width: 80,
  },
  {
    title: t('postingOfGoods.date'),
    type: 'date',
    value: 'attributes.date',
    width: 170,
  },
  {
    title: t('prices.title.item'),
    type: 'string',
    filter: true,
    filterApi: apiPriceTypesServices,
    filterParams: { 'page[size]': 999 },
    filterName: 'priceType',
    filterKey: 'product-price-history-in-price-type-ids',
    filterShow: false,
    filterDataLoading: false,
    filterData: [],
    filterDataInstance: [],
    filterLabel: 'title',
    value: 'relationships.priceType.attributes.title',
    width: 200,
  },
  {
    title: t('products.price'),
    type: 'string',
    value: 'attributes.price',
    width: 200,
  },
  {
    title: t('table.user'),
    type: 'string',
    value: 'relationships.user.attributes.name',
    width: 200,
  },
]);
const getItemsOptions: Ref = ref({
  include: 'categories,extraData,itemType,tags,images,country',
  'page[size]': 20,
  'page[number]': 1,
  filters: '',
});
const getProductsOptions: Ref = ref({ include: 'item.country,tags,multicolors,size,barcodes,prices.type,priceHistory.user,priceHistory.priceType,stocks.warehouses,item.properties,images,multicolors.codes' });
const getMulticolorsOptions: Ref = ref({
  'page[size]': 2000,
  'page[number]': 1,
  filters: '',
  include: 'codes',
  sort: 'name',
});

const getColorsOptions: Ref = ref({
  'page[size]': 2000,
  'page[number]': 1,
  filters: '',
  include: 'codes',
  sort: 'name',
});
const getWarehousesOptions: Ref = ref({
  'page[size]': 100,
  'page[number]': 1,
  display_in_erp: true,
});
const getCategoriesOptions: Ref = ref({
  'page[size]': 100,
  'page[number]': 1,
  sort: 'title',
});
const infinityScrollOptions = reactive({
  element: route.name === 'Items' ? '.sections' : '.data-table',
  observerTarget: '.data-table-wrapper__footer',
  scrollToTarget: '.overflow-x-auto',
  page: 1,
  sort: getItemsOptions.value.sort,
  lastPage: 1,
  loader: false,
});
const filters: Ref = ref([
  {
    id: 1,
    label: 'Цена',
    name: 'price',
    type: 'range',
    key: 'item-product-price-betweens',
    priceTypes: [],
    priceTypeId: 3,
    options: {
      min: 0,
      max: 30000,
      step: 1,
      value: [1, 30000],
    } as object,
  },
  {
    id: 2,
    label: 'Базовые цвета',
    name: 'color',
    type: 'list',
    search: true,
    searchValue:'',
    key: 'item-product-multicolor-base-colors-ids',
    options: [] as object[],
  },
  {
    id: 3,
    label: 'Мультицвета',
    name: 'multicolor',
    type: 'list',
    search: true,
    searchValue:'',
    key: 'item-product-multicolors-ids',
    options: [] as object[],
  },
  {
    id: 4,
    label: 'Наличие',
    name: 'stock',
    type: 'range',
    key: 'item-stock-betweens',
    options: {
      min: 0,
      max: 3000,
      step: 1,
      value: [1, 3000],
    } as any,
  },
  {
    id: 5,
    label: 'Склады',
    name: 'warehouses',
    type: 'list',
    key: 'item-product-stock-warehouse-ids',
    options: [] as object[],
  },
  {
    id: 6,
    label: 'Категории',
    name: 'categories',
    type: 'list',
    search: true,
    searchValue:'',
    key: 'item-categories-ids',
    options: [] as object[],
  },
  {
    id: 7,
    label: 'Теги',
    name: 'tags',
    type: 'list',
    key: 'item-tags-eq-ids',
    options: [] as object[],
  },

]);
const useFilters: Ref = ref( {}) as any;
const useSorts: Ref = ref([]) as any;
const filtersTimeout: Ref = ref(null);
const modal: Ref = ref({
  title: '',
  isStandardButtons: false,
  okDisabled: false,
  button: '',
  text: '',
  component: '',
  width: '',
});
const isModalOpen: Ref = ref(false);
const sliderImages: Ref = ref([]);

const toggleSwitchParams: Ref = ref({
  label: t('cashierWorkplace.productsWithImage'),
  state: false,
});

const itemsHeight = computed(() => {
  let height = '';
  if (props.place === 'select') {
    height = useFiltersChips.value.length ? '190px' : '170px';
  } else {
    height = useFiltersChips.value.length ? '120px' : '60px';
  }
  return `calc(100% - ${height})`;
});
const storeUser = computed(() => JSON.parse(useUserSession().user));
const productsStore = useProducts();
const productsStoreCheckedItems = computed(() => productsStore.clearCheckedProducts);
const useFiltersChips: Ref = ref([]);

const toggleSwitchAction = (event: any) => {
  productsWithImage.value = event.state;
};

/**
 * Handler modal actions
 *
 * @function
 * @param event
 */
const modalAction = (event: any) => {
  switch (event) {
    case 'ok':
      closeModal();
      break;
    case 'cancel':
      closeModal();
      break;
    default:
      break;
  }
};

/**
 * Close modal
 *
 * @function
 */
const closeModal = () => {
  modal.value = {};
  isModalOpen.value = false;
};

/**
 *  Get filters hash
 *
 *  @function
 *  @param fs
 *  @return string
 */
const getFiltersHash = ((fs: any) => {
  const arr: object[] = [];
  Object.keys(fs).forEach((filter: string) => {
    const index = filters.value.findIndex((item: any) => item.name === filter);
    const { key } = filters.value[index];
    switch (filters.value[index].type) {
      case 'range':
        const obj = {
          key,
          value: {
            from: fs[filter][0],
            to: fs[filter][1],
          },
        };
        if (key === 'item-product-price-betweens') {
          Object.assign(obj.value, { price_type_id: filters.value[index].priceTypeId });
        }
        arr.push(obj);
        break;
      case 'list':
        arr.push({
          key,
          value: { ids: fs[filter] },
        });
        break;
      default:
        break;
    }
  });
  if (search.value) {
    arr.push({
      key: 'item-search-filter',
      value: { query: encodeURIComponent(search.value.split('/')[0]) },
    });
  }
  return filterHash(arr);
});

/**
 * Create filters hash
 *
 *  @function
 *  @param arr
 *  @return string
 */
const filterHash = (arr: object[]) => {
  let hash: string = '';
  hash = btoa(JSON.stringify(arr));
  return hash;
};

/**
 * Get items
 *
 *  @function
 *  @async
 *  @return void
 */
const getItems = async () => {
  isLoading.value = true;
  const res = await apiItemsServicesV2.getList({}, { params: getItemsOptions.value });

  if (res) {
    items.value = res.data.map((item: any) => {
      const ret = item.attributes;

      if (props.selectType === SelectType.Items) {
        const prices: number[] = [];

        item.relationships?.products?.forEach((product: any) => {
          product.relationships?.prices?.forEach((price: any) => {
            if (price.attributes?.price) {
              prices.push(price.attributes?.price);
            }
          });
        });

        let pricesLabel = '';
        if (prices.length > 0) {
          const min = Math.min(...prices);
          const max = Math.max(...prices);
          if (min === max) {
            pricesLabel = `${min}`;
          } else {
            pricesLabel = `${Math.min(...prices)} - ${Math.max(...prices)}`;
          }
        }
        ret.prices = pricesLabel;
      }
      if (item.relationships.categories?.length) {
        ret.categories = item.relationships.categories.map((category: any) => category.attributes.title).join(', ');
      }
      item.relationships.itemType
        ? ret.itemType = item.relationships.itemType.attributes.name
        : '';
      ret.tags = item.relationships?.tags;
      ret.properties = item.relationships.properties?.length ? item.relationships.properties : [];
      const { products_count, stocks_total, stocks_reserve } = item.relationships.extraData;
      Object.assign(ret, { products_count, stock: stocks_total, reserve: stocks_reserve });
      ret.media = item.relationships?.images[0] || null;
      ret.country = item.relationships?.country?.attributes?.name;
      ret.isOpen = res.data.length === 1 ? getProducts(ret.id) : false;
      ret.backlight = search.value  && (ret.article === search.value || ret.title === search.value)

      return ret;
    });
    infinityScrollOptions.lastPage = res.meta?.last_page;
    isLoading.value = false;
  }
};

/**
 * Get products or add products in items list
 *
 *  @function
 *  @async
 *  @return void
 */
const getProducts = async (id: number) => {
  isLoading.value = true;
  const item = items.value.find((i: any) => i.id === id);
  Object.assign(getProductsOptions.value, {
    item_id: id,
    'page[size]': item?.products_count,
  });

  const res = await apiProductsServicesV2.getList({}, { params: getProductsOptions.value });

  if (res) {
    const wl = wishlist.items.map((item: any) => item.id);
    const index = items.value.findIndex((item: any) => item.id === id);
    const products = res.data.map((product: any) => {
      let stock = 0;
      let reserve = 0;
      if (product.relationships.stocks.length) {
        product.relationships.stocks.forEach((elem: any) => {
          if (!!elem.relationships.warehouse && elem.relationships.warehouse.attributes.display_in_erp) {
            stock += elem.attributes.total;
            reserve += elem.attributes.reserve;
          }
        });
      }
      const retailPrice = product.relationships.prices?.length
        ? product.relationships.prices.find((p: any) => p.relationships.type.attributes.id === 3)
        : null;
      const basePrice = product.relationships.prices?.length
        ? product.relationships.prices.find((p: any) => p.relationships.type.attributes.id === 6)
        : null;
      const findConsist = product.relationships?.item?.relationships?.properties?.find((prop: any) => prop.attributes.name === 'consist');
      const codes = product.relationships.multicolors.length ? product.relationships.multicolors[0].relationships.codes.map(entry => entry.attributes.code).join(', '): '';

      return {
        id: product.attributes.id,
        media: product.relationships?.images?.length ? product.relationships.images[0].attributes : null,
        item: {
          id: item?.id,
          title: item?.title,
          article: item?.article,
          item_type_id: item?.item_type_id,
          country: item?.country,
        },
        relationships: product.relationships,
        prices: product.relationships.prices.length ? product.relationships.prices : '',
        price: retailPrice ? retailPrice.attributes.price : '',
        priceNew: 0,
        priceBase: basePrice ? basePrice.attributes.price : '',
        show_old_price: product.attributes.show_old_price,
        barcode: product.relationships.barcodes.length ? product.relationships.barcodes[0].attributes.barcode : '',
        barcodes: [{
          barcode: product.relationships.barcodes.length ? product.relationships.barcodes[0].attributes.barcode : '',
          attributes: product.relationships.barcodes.length ? product.relationships.barcodes[0].attributes : '',
        }],
        color: product.relationships.multicolors.length ? codes+" "+ product.relationships.multicolors[0].attributes.name : '',
        size: product.relationships.size ? product.relationships.size.attributes.value : '',
        consist: findConsist ? findConsist.pivots?.value : '',
        storage_cell: product.relationships.stocks ? product.relationships.stocks[0]?.attributes.storage_cell_id : '',
        priceHistory: product.relationships.priceHistory,
        stock,
        reserve,
        wishlist: wl.includes(product.attributes.id),
        checked: product.checked ? product.checked : false,
        backlight: search.value && !!product.relationships.barcodes.length && product.relationships.barcodes[0].attributes.barcode.includes(search.value),
      };
    });

    const expandData = {
      type: 'DataTable',
      itemId: id,
      headers: productsHeaders.value,
      items: products,
    };

    items.value.splice(index + 1, 0, { expand: expandData });
  }

  isLoading.value = false;
};

const filterChips = () => {
  const chips = [] as any;
  for (const key in useFilters.value) {
    const filter = filters.value.find((f: any) => f.name === key);
    let chipsItems = [] as any;
    switch (filter.type) {
      case 'list':
        chipsItems = filter.options
          .filter((o: any) => useFilters.value[key].includes(o.id) && o.select)
          .map((o: any) => {
            o.content = o.name || o.title;
            o.isRemove = true;
            return o;
          });
        break;
      case 'range':
        chipsItems = [{ content: useFilters.value[key].join(' - '), isRemove: true }];
        break;
      default:
        break;
    }
    chips.push({
      type: filter.type,
      name: filter.name,
      label: filter.label,
      items: chipsItems,
    });
  }
  if (useSorts.value.length) {
    const sortItems: any = [];
    useSorts.value.forEach((s: any) => {
      sortItems.push({
        name: s.key,
        content: s.sortOrder === 'asc' ? `↓ ${s.label}` : `↑ ${s.label}`,
        isRemove: true,
        direction: s.sortOrder,
      });
    });
    if (sortItems.length) {
      chips.push({
        type: 'sort',
        label: t('products.sort'),
        items: sortItems,
      });
    }
  }
  useFiltersChips.value = chips;
};

const getExpandIndex = (id: number): number => items.value.findIndex((item: any) => !!item.expand && item.expand.itemId === id);

/**
 * Remove products from items list
 *
 *  @function
 *  @param event
 *  @return void
 */
const selectProducts = async (event: any) => {
  if (event.table === 'items') {
    const index = items.value.findIndex((item: any) => item.id === event.item.id);
    items.value[index].isOpen = event.checked;
    items.value[index].checked = event.checked;
    if (event.checked) {
      let expandIndex = getExpandIndex(event.item.id);
      if (expandIndex === -1) await getProducts(event.item.id);
      expandIndex = getExpandIndex(event.item.id);
      items.value[expandIndex].expand.items.forEach((product: any) => {
        product.checked = event.checked;
      });
    } else {
      const expandIndex = getExpandIndex(event.item.id);
      items.value.splice(expandIndex, 1);
    }
  } else if (event.table === 'products') {
    const itemIndex = items.value.findIndex((item: any) => !!item.expand && item.expand.itemId === event.itemId);
    const productIndex = items.value[itemIndex].expand.items.findIndex((product: any) => Number(product.id) === event.item.id);
    items.value[itemIndex].expand.items[productIndex].checked = event.checked;
  }
  const selectedProducts: object[] = [];
  items.value.forEach((item: any) => {
    if (item.expand) {
      item.expand.items.forEach((product: any) => {
        if (product.checked) {
          const prices = product.relationships.prices?.length ? product.relationships.prices.map((price: any) => ({
            id: price.relationships.type.attributes.id,
            title: price.relationships.type.attributes.title,
            created_at: price.relationships.type.attributes.created_at,
            price: price.attributes.price,
          })) : [];
          Object.assign(product, {
            prices,
            imageUrl: product.relationships.images?.length ? product.relationships.images[0].attributes.url : '/uploads/images/photo-empty.png',
            count: 1,
          });
          selectedProducts.push(product);
        }
      });
    }
  });
  emit('action', { type: 'selectProducts', selectedProducts });
};

const selectItems = (event: any) => {
  const index = items.value.findIndex((item: any) => item.id === event.item.id);
  items.value[index].checked = event.checked;
  const selectedItems: object[] = [];
  items.value.forEach((item: any) => {
    if (item.checked) {
      selectedItems.push({
        id: Number(item.id),
        title: item.title,
        article: item.article,
        categories: item.categories,
        prices: item.prices,
        image: item.media?.url || null,
        tags: item.tags,
      });
    }
  });
  emit('action', { type: 'selectedItems', selectedItems });
};

/**
 * Remove products from items list
 *
 *  @function
 *  @param id
 *  @return void
 */
const removeProducts = (id: number) => {
  const index = items.value.findIndex((item: any) => item.id === id);
  items.value.splice(index + 1, 1);
};

/**
 * Toggle products in/from wishlist
 *
 *  @function
 *  @async
 *  @param event
 *  @return void
 */
const toggleWishlist = (async (event: any) => {
  const itemIndex = items.value.findIndex((item: any) => item.id === event.itemId);
  const productIndex = items.value[itemIndex + 1].expand.items.findIndex((item: any) => item.id === event.item.id);
  const index = wishlist.items.findIndex((item: any) => item.id === event.item.id);
  if (event.item.wishlist) {
    const { data, status } = await apiWishlistServices.removeItem({ id: wishlist.items[index].wishlistCurrentUser.id });
    if (status === 200) {
      items.value[itemIndex + 1].expand.items[productIndex].wishlist = false;
    }
  } else {
    const { status } = await apiWishlistServices.createItem(null, {
      type: 'product',
      id: event.item.id,
    });

    if (status > 200 || status < 300) {
      items.value[itemIndex + 1].expand.items[productIndex].wishlist = true;
    }
  }
  await wishlist.setWishlist();
});

const openItemStock = (async (event: any) => {
  modalProduct.value = [];
  let response: null | any;
  if (event.itemId) {
    response = await apiReportServices.getStockProduct({}, { product_id: event.item.id });
  } else {
    response = await apiReportServices.getStockItem({}, { item_id: event.item.id });
  }

  if (response.status.toString().startsWith('20')) {
    modalProduct.value = response;
    isOpenModal.value = true;
  } else {
    notifications.error(t('notification.error'), t('products.error.loadList'));
  }
});
const historyPrice = async (event: any) => {
  priceHistoryItem.value = event.item;
  priceHistoryList.value = event.item.priceHistory;
  isOpenModalPrice.value = true;
};
/**
 * Set product info
 *
 * @function
 * @param payload
 * @return object
 */
const setProductInfo = (payload: any) => {
  const itemIndex = items.value.findIndex((item: any) => item.id === payload.itemId);
  const productIndex = items.value[itemIndex].products.findIndex((product: any) => product.attributes.id === payload.item.id);
  const product = items.value[itemIndex].products[productIndex];
  const consistObj = items.value[itemIndex].properties?.length
    ? items.value[itemIndex].properties.find((prop: any) => prop.attributes.name === 'consist')
    : '';
  return {
    images: product.relationships.images?.length ? product.relationships.images : [],
    title: items.value[itemIndex].title,
    prices: product.relationships.prices ? product.relationships.prices.map((price: any) => ({
      title: price.relationships.type.attributes.title,
      created_at: price.relationships.type.attributes.created_at,
      price: price.attributes.price,
    })) : [],
    created_at: product.attributes.created_at,
    consist: consistObj?.pivots?.value ? consistObj.pivots.value : '',
    color: product.relationships.multicolors?.length ? product.relationships.multicolors[0].attributes.name : '',
    size: product.relationships.size ? product.relationships.size.attributes.value : '',
  };
};

const getUpdatedData = async (): Promise<void> => {
  infinityScrollOptions.page = 1;
  getItemsOptions.value['page[number]'] = 1;

  await getItems();
};

const tagSelectedItems:Ref = ref([]);

const updateTagSelectedItems = (event:any) => {
  if (tagSelectedItems.value.find((el:any) => el.id == event.item.id)) {
    tagSelectedItems.value = tagSelectedItems.value.filter((el:any) => el.id != event.item.id);
  } else {
    tagSelectedItems.value.push(event.item);
  }
};

/**
 * Handler table actions
 *
 *  @function
 *  @param event
 *  @return void
 */
const handlerTableAction = async (event: any) => {
  switch (event.type) {
    case 'select-single':
      updateTagSelectedItems(event);
      break;
    case 'expand':
      getProducts(event.id);
      break;
    case 'expandClose':
      removeProducts(event.id);
      break;
    case 'select':
      switch (props.selectType) {
        case SelectType.Items:
          selectItems(event);
          break;
        case SelectType.Products:
          selectProducts(event);
          break;
      }
      break;
    case 'openItemStock':
      openItemStock(event);
      break;
    case 'historyPrice':
      historyPrice(event);
      break;
    case 'toggleWishlist':
      toggleWishlist(event);
      break;
    case 'search':
      search.value = event.value;
      break;
    case 'info':
      const productInfo = setProductInfo(event);
      emit('action', { type: 'info', item: productInfo });
      break;
    case 'scroll-page':
      infinityScrollOptions.page = event.page;
      getItemsOptions.value['page[number]'] = event.page;
      await getInfinityScrollData();
      break;
    case 'click':
      if (event.td === 'media' && event.item.relationships?.images?.length) {
        imagesSliderShow(event.item.relationships.images);
      }
      break;
    case 'sortColumn':
      sortItems(event.column);
      break;
    default:
      break;
  }
};

const sortItems = (column: any) => {
  let headerIndex = 0;
  itemsHeaders.value.forEach((h: any, index: number) => {
    h.key === column.key ? headerIndex = index : h.sortOrder = '';
  });
  const { sortOrder } = itemsHeaders.value[headerIndex];
  useSorts.value = [column];
  itemsHeaders.value[headerIndex].sortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
  getItemsOptions.value.sort = itemsHeaders.value[headerIndex].sortOrder === 'asc' ? column.sort : `-${column.sort}`;
  getUpdatedData();
};

/**
 * Open images slider
 *
 * @function
 * @param images
 */
const imagesSliderShow = (images: object[]) => {
  sliderImages.value = images.map((i: any) => i.attributes);
  modal.value = {
    title: 'Галерея продукта',
    titleColor: '#fff',
    component: 'MediaSlider',
    isStandardButtons: false,
    width: '100vw',
    height: '100vh',
    background: 'rgba(0,0,0,0)',
  };
  isModalOpen.value = true;
};

const handlerChipsAction = (event: any) => {
  switch (event.type) {
    case 'remove':


      const index = filters.value.findIndex((f: any) => f.name === event.group.name);

      if (event.group.type === 'range') {
        delete useFilters.value[event.group.name];
        filters.value[index].options.value = [filters.value[index].options.min, filters.value[index].options.max];
      } else if (event.group.type === 'list') {
        useFilters.value[event.group.name] = useFilters.value[event.group.name].filter((f: any) => f !== event.chip.id);
        if (!useFilters.value[event.group.name].length) delete useFilters.value[event.group.name];
        filters.value[index].options.forEach((elem: any) => {
          if (elem.id === event.chip.id) elem.select = false;
        });
      } else if (event.group.type === 'sort') {
        useSorts.value.forEach((s: any, index: number) => {
          if (s.key === event.chip.name) useSorts.value.splice(index, 1);
        });
        itemsHeaders.value.forEach((h: any) => {
          h.sortOrder = '';
        });
        delete getItemsOptions.value.sort;
      }
      filterChips()
      getItemsOptions.value.filters = getFiltersHash(useFilters.value);
      localStorage.setItem('filters', JSON.stringify(useFilters.value));
      getUpdatedData();
      break;

    default:
      break;
  }
};

/**
 * Handler filters actions
 *
 *  @function
 *  @param event
 *  @return void
 */
const handlerFiltersAction = async (event: any) => {
  let filterName;
  let filterOptions;
  switch (event.type) {
    case 'filtersShow':
      getColors();
      getMulticolors()
      getWarehouses();
      getCategories();
      getTags();
      break;
    case 'range':
      filterName = filters.value[event.filterIndex].name;
      filterOptions = filters.value[event.filterIndex].options;
      if (event.range[0] === filterOptions.min && event.range[1] === filterOptions.max) {
        delete useFilters.value[filterName];
      } else {
        useFilters.value[filterName] = event.range;
      }
      break;
    case 'priceType':
      filters.value[event.filterIndex].priceTypeId = event.value.id;
      break;
    case 'select':
      filterName = filters.value[event.filterIndex].name;
      filterOptions = filters.value[event.filterIndex].options;
      const { select } = filterOptions[event.itemIndex];
      filterOptions[event.itemIndex].select = !select;
      if (!useFilters.value[filterName]) {
        Object.assign(useFilters.value, { [filterName]: [] });
      }
      if (select) {
        const optionId = filterOptions[event.itemIndex].id;
        useFilters.value[filterName] = useFilters.value[filterName].filter((id: any) => id !== optionId);
      } else {
        useFilters.value[filterName].push(filterOptions[event.itemIndex].id);
      }
      if (!useFilters.value[filterName].length) {
        delete useFilters.value[filterName];
      }
      break;
    case 'searchcolor':
      if(event.query.length > 3) {
        getColorsOptions.value.filters = hashString('color-search-filter', event.query);
        await getColors()
      }else{
        if(getColorsOptions.value.filters !=='') {
          getColorsOptions.value.filters = '';
          await getColors()
        }
      }
      break
    case 'searchmulticolor':
      if(event.query.length > 3) {
        getMulticolorsOptions.value.filters = hashString('multicolor-search-filter', event.query);
        await getMulticolors()
      }else{
        if(getMulticolorsOptions.value.filters !=='') {
          getMulticolorsOptions.value.filters = '';
          await getMulticolors()
        }
      }
      break
    case 'searchcategories':
      if(event.query.length > 3) {
        getCategoriesOptions.value.filters = hashString('category-search-filter', event.query);
        await getCategories()
      }else{
        if(getCategoriesOptions.value.filters !=='') {
          getCategoriesOptions.value.filters = '';
          await getCategories()
        }
      }
      break
    case 'reset-filters':
      resetFilters();

      break;
    case 'submit':
      filterChips()
      localStorage.setItem('filters', JSON.stringify(useFilters.value));
      if (filtersTimeout.value) clearTimeout(filtersTimeout.value);
      filtersTimeout.value = setTimeout(() => {
        getItemsOptions.value.filters = getFiltersHash(useFilters.value);
        getUpdatedData();
      }, 200);
      break;
    default:
      break;
  }
};

/**
 * Reset filters
 *
 *  @function
 *  @return void
 */
const resetFilters = () => {
  filters.value.forEach((item: any) => {
    if (item.type === 'range') {
      item.options.value = [item.options.min, item.options.max];
    }
    if (item.type === 'list') {
      item.options.forEach((elem: any) => elem.select = false);
    }
  });

  filtersTimeout.value = setTimeout(() => {
    useFilters.value = {};
    useFiltersChips.value = []
    getItemsOptions.value.filters = getFiltersHash(useFilters.value);
    localStorage.setItem('filters', JSON.stringify({}));
    getUpdatedData();
  }, 200);
};

/**
 * Get tags for filters
 *
 *  @function
 *  @async
 *  @return void
 */
const getTags = async () => {
  const params = {
    'page[size]': 500,
    'page[number]': 1,
    sort:'name'
  };
  if (filters.value[6].options.length) return;
  const { data } = await apiTagsServices.getList({}, { params });
  if (data) {
    filters.value[6].options = data.map((item: any) => ({
      id: item.id,
      name: item.attributes.name,
      select: localStorageFilters.value?.tags?.includes(item.id),
    }));
  }
};

/**
 * Get multicolors for filters
 *
 *  @function
 *  @async
 *  @return void
 */
const getMulticolors = async () => {
  const params = getMulticolorsOptions.value;
  filters.value[2].loader = true
  const { data } = await apiMulticolorsServicesV2.getList({}, { params });
  if (data) {
    filters.value[2].options = []
    filters.value[2].options = data.map((item: any) => ({
      id: item.attributes.id,
      name: item.relationships.codes.map(entry => entry.attributes.code).join(', ')+ " " +item.attributes.name,
      select: localStorageFilters.value?.color?.includes(item.attributes.id),
    }));
  }
  filters.value[2].loader = false
};

const getColors = async () => {
  const params = getColorsOptions.value;
  filters.value[1].loader = true
  const { data } = await apiColorServicesV2.getList({}, { params });
  if (data) {
    filters.value[1].options = []
    filters.value[1].options = data.map((item: any) => ({
      id: item.attributes.id,
      name: item.attributes.name,
      select: localStorageFilters.value?.color?.includes(item.attributes.id),
    }));
  }
  filters.value[1].loader = false
};
/**
 * Get warehouses for filters
 *
 *  @function
 *  @async
 *  @return void
 */
const getWarehouses = async () => {
  if (filters.value[4].options.length) return;
  const params = getWarehousesOptions.value;
  const { data } = await apiWarehousesServices.getList({}, { params });
  const userWarehouseId = user.value.relationships?.warehouse?.attributes.id || null;
  if (data) {
    filters.value[4].options = data.map((item: any) => ({
      id: item.attributes.id,
      name: item.attributes.name,
      // select: item.attributes.id === userWarehouseId,
      select: localStorageFilters.value?.warehouses?.includes(item.attributes.id),
    }));
  }
};

const getInfinityScrollData = async (): Promise<void> => {
  const response = await apiItemsServicesV2.getList({}, { params: getItemsOptions.value });
  let resValue = [];

  if (response.status === 200) {
    resValue = response.data.map((item: any) => {
      const ret = item.attributes;
      if (item.relationships.categories?.length) {
        ret.categories = item.relationships.categories.map((category: any) => category.attributes.title).join(', ');
      }
      ret.properties = item.relationships.properties?.length ? item.relationships.properties : [];
      const { products_count, stocks_total, stocks_reserve } = item.relationships.extraData;
      Object.assign(ret, { products_count, stock: stocks_total, reserve: stocks_reserve });

      if (props.selectType === SelectType.Items) {
        const prices: number[] = [];
        item.relationships?.products?.forEach((product: any) => {
          product.relationships?.prices?.forEach((price: any) => {
            if (price.attributes?.price) {
              prices.push(price.attributes?.price);
            }
          });
        });
        let pricesLabel = '';
        if (prices.length > 0) {
          const min = Math.min(...prices);
          const max = Math.max(...prices);
          if (min === max) {
            pricesLabel = `${min}`;
          } else {
            pricesLabel = `${Math.min(...prices)} - ${Math.max(...prices)}`;
          }
        }
        ret.prices = pricesLabel;
      }
      ret.media = item.relationships?.images[0] || null;
      ret.isOpen = false;
      return ret;
    });

    infinityScrollOptions.lastPage = response.meta?.last_page;
    items.value = [...items.value, ...resValue];
  } else {
    notifications.error(t('notification.error'), t('documentCashChecks.notification.load.error'));
  }
};

/**
 * Get categories for filters
 *
 *  @function
 *  @async
 *  @return void
 */
const getCategories = async () => {
  filters.value[5].loader = true
  const params = getCategoriesOptions.value;
  const { data } = await apiCategoriesServicesV2.getList({}, { params });
  if (data) {
    filters.value[5].options = data.map((item: any) => ({
      id: item.attributes.id,
      name: item.attributes.title,
      select: localStorageFilters.value?.categories?.includes(item.attributes.id),
    }));
  }
  filters.value[5].loader = false
};

/**
 * Get user
 *
 *  @function
 *  @async
 *  @return void
 */
const getUser = async () => {
  const { data, status } = await apiUsersServices.getItem({ id: storeUser.value.id }, { params: { include: 'warehouse,tags' } });
  if (status === 200) {
    user.value = data;
  }
};

const getPriceTypes = async () => {
  const res = await apiPriceTypesServices.getList(null, { params: { 'page[size]': 999 } });
  if (res.status === 200) {
    filters.value[0].priceTypes = res.data.map((t: any) => t.attributes);
  }
};

/**
 * Prepare filter
 *
 * @function
 * @param filterData
 * @return void
 */
const sendPriceHistoryFilter = (filterData: any): void => {
  const priceTypeFilter = filterData.find((f: any) => f.filterName === 'priceType');
  const idsFilter = priceTypeFilter.value.map((t: any) => t.id);
  idsFilter.length
    ? priceHistoryList.value = priceHistoryItem.value.priceHistory
      .filter((h: any) => idsFilter.includes(h.attributes.price_type_id))
    : priceHistoryList.value = priceHistoryItem.value.priceHistory;
};

/**
 * Vue mounted
 *
 *  @function
 *  @return void
 */
onMounted(async () => {
  isLoading.value = true;
  useFilters.value = JSON.parse(localStorage.getItem('filters')) || {}
  localStorageFilters.value = JSON.parse(localStorage.getItem('filters'));
  if (localStorageFilters.value?.tags) await getTags();
  if (localStorageFilters.value?.color) await getColors();
  if (localStorageFilters.value?.multicolor) await getMulticolors();
  if (localStorageFilters.value?.warehouses) await getWarehouses();
  if (localStorageFilters.value?.categories) await getCategories();

  if (props.selectType === SelectType.Items) {
    itemsHeaders.value.splice(itemsHeaders.value.length - 1, 0, { key: 'prices', label: 'Цены', width: 200 });
  }
  if (props.selectType === SelectType.Products) {
    itemsHeaders.value.unshift({ key: 'expand', width: 34 });
  }
  if (props.place === 'select') {
    await getUser();
    Object.assign(productsHeaders.value[1], { info: true });
    getItemsOptions.value.include += ',properties';
    getProductsOptions.value.include += ',images';

    let checkboxIndex = 1;
    if (props.selectType === SelectType.Items) {
      checkboxIndex = 0;
    }
    itemsHeaders.value.splice(checkboxIndex, 0, { key: 'checkbox', label: '', width: 32 });
    productsHeaders.value.unshift({ key: 'checkbox', label: '', width: 32 });
  } else {
    Object.assign(itemsHeaders.value[1], { link: true });
    Object.assign(productsHeaders.value[1], { link: true });
  }
  if (props.place == 'dir') {
    itemsHeaders.value.splice(0, 0, { key: true, label: '', type: 'checkbox-single' });
    // useFilters.value.stock = [1, 3000]
  }

  filters.value.forEach((p: any) => {
    if (p.name === "price" &&  useFilters?.value?.price) {
      p.options.min = 0
      p.options.max = 30000
      p.options.value[0] = useFilters.value?.price[0]
      p.options.value[1] = useFilters.value?.price[1]
    }
    if (p.name === "stock" &&  useFilters?.value?.stock) {
      p.options.min = 0
      p.options.max = 3000
      p.options.value[0] = useFilters.value?.stock[0]
      p.options.value[1] = useFilters.value?.stock[1]
    }
  });

  getItemsOptions.value.filters = getFiltersHash(useFilters.value);

  filterChips()
  await getPriceTypes();
  await getItems();
});

const localStorageFilters:Ref = ref({});

watch(() => productsWithImage.value, (value) => {
  if (value) {
    productsHeaders.value.isActiveunshift({
      key: 'media',
      label: t('cashierWorkplace.productsWithImage'),
      width: 50,
      clicked: true,
      style: 'cursor: pointer;',
    });
    itemsHeaders.value.unshift({
      key: 'media',
      label: t('cashierWorkplace.productsWithImage'),
      width: 50,
      clicked: true,
      style: 'cursor: pointer;',
    });
  } else {
    productsHeaders.value.shift();
    itemsHeaders.value.shift();
  }
});

watch(() => search.value, (value) => {
  if (!value.length || value.length > 3) {
    if (filtersTimeout.value) clearTimeout(filtersTimeout.value);
    filtersTimeout.value = setTimeout(() => {
      getItemsOptions.value.filters = getFiltersHash(useFilters.value);
      getUpdatedData();
    }, 100);
  }
});


const workWithTagsActions = (event:any) => {
  switch (event.type) {
    case 'clearAll':
      workWithTagsclearAllAction(event);
      break;
    case 'closeWorkWithTagsModal':
      isWorkWithTags.value = false;
      break;
    default:
      break;
  }
};

const workWithTagsclearAllAction = (event:any) => {
  if (event.type === 'clearAll') {
    items.value.map((el:any) => {
      el.checked = false;
    });
    tagSelectedItems.value = [];
    silentItemsUpdate(event.val);
  }
};

const silentItemsUpdate = async (itemsArr:any) => {
  for (const item of itemsArr) {
    const { data } = await apiItemsServicesV2.getItem(
      { id: item.itemId },
      { params: { include: 'tags' } },
    );

    if (data) {
      const searchItem = items.value.find((el:any) => item.itemId == el.id);
      searchItem.tags = data.relationships?.tags;
    }
  }
};

const hashString = (key: string, search: string): string => btoa(
  JSON.stringify([
    {
      key,
      value: { query: encodeURIComponent(search) },
    },
  ]),
);

const isWorkWithTags:Ref = ref(false);

</script>

<template>
  <Head :title="place === 'dir' ? `${$t('products.title.list')}` : ''">
    <template #controls>
      <Field
        v-model="search"
        type="text"
        size="sm"
        :mods="['b-gray']"
        :edit="true"
        :required="false"
        :placeholder="'Артикул, штрихкод, название'"
        class="--miw-600"
        iconLeft="magnifying-glass"
      />
      <Button
        size="md"
        :mods="['bg-secondary']"
        class="button-info-fetch"
        @click="getUpdatedData"
      >
        {{ $t('button.updateData') }}
        <TimeDifference v-if="!isLoading" />
      </Button>
      <Button
        v-if="place === 'dir'"
        size="md"
        :mods="['bg-secondary']"
        class="button"
        @click="$router.push({ name: 'ItemsCreate' })"
      >
        {{ $t('products.title.create') }}
      </Button>
    </template>
  </Head>
  <WorkWithTags
    v-if="isWorkWithTags && props.place == 'dir' && tagSelectedItems.length"
    :tagSelectedItems="tagSelectedItems"
    :workWithTags="isWorkWithTags"
    @action="workWithTagsActions"
  />
  <div class="flex justify-between items-center border-t border-gray-200 mt-3 py-3">
    <div>
      <Chips
        v-if="useFiltersChips.length"
        :chips="useFiltersChips"
        @action="handlerChipsAction"
      />
    </div>
    <div class="flex items-center gap-3">
      <div class="px-3 custom-checkbox flex gap-1 ml-auto">
        <ToggleSwitch
          :edit="true"
          :params="toggleSwitchParams"
          @action="toggleSwitchAction"
        />
      </div>
      <div
        v-if="props.place == 'dir' && tagSelectedItems.length"
      >
        <button
          class="button --xs bg-gray-200 w-[120px] h-6"
          :class="{ 'bg-cyan-600/[.6] text-white': isWorkWithTags }"
          @click="isWorkWithTags = !isWorkWithTags"
        >
          Работа с тегами
        </button>
      </div>
    </div>
  </div>
  <div
    class="items mt-1"
    :style="{ 'height': itemsHeight }"
  >
    <Loader
      v-if="isLoading"
      class="searcher__loader --center --show"
    />
    <div class="flex h-full">
      <div class="pr-3 border-r border-gray-200 mr-3">
        <ItemsFilter
          :filters="filters"
          :useFilters="useFilters"
          @action="handlerFiltersAction"
        />
      </div>
      <div class="overflow-x-auto w-full">
        <div
          class="h-full"
          :class="{ 'min-w-max': place === 'dir' }"
        >
          <div class="w-full h-full">
            <DataTable
              :headers="itemsHeaders"
              :items="items"
              :basket="basket"
              :infinityScroll="infinityScrollOptions"
              :place="place"
              table="items"
              @action="handlerTableAction"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="isOpenModal"
      class="modal-default"
    >
      <div class="modal-default__bg" />
      <div class="modal-default__form --mod-stock">
        <div
          class="modal-default__close"
          @click="isOpenModal = false"
        >
          &times;
        </div>
        <div class="product-info__block__list stocks-list">
          <ItemStocks :products="modalProduct" />
        </div>
        <div class="w-full modal-default__buttons flex">
          <Button
            size="sm"
            :mods="['bg-gray']"
            @click="isOpenModal = false"
          >
            {{ $t('button.close') }}
          </Button>
        </div>
      </div>
    </div>
    <div
      v-if="isOpenModalPrice"
      class="modal-default"
    >
      <div class="modal-default__bg" />
      <div class="modal-default__form --mod-stock">
        <div
          class="modal-default__close"
          @click="isOpenModalPrice = false"
        >
          &times;
        </div>
        <div class="product-info__block__list stocks-list">
          <div class="modal-default__heading flex">
            <div class="modal-default__heading__title">
              История цен
            </div>
          </div>
          <div>
            <List
              :table="historyHeader"
              :data="priceHistoryList"
              @filter="sendPriceHistoryFilter"
            />
          </div>
        </div>
        <div class="w-full modal-default__buttons flex">
          <Button
            size="sm"
            :mods="['bg-gray']"
            @click="isOpenModalPrice = false"
          >
            {{ $t('button.close') }}
          </Button>
        </div>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <Modal
      :open="isModalOpen"
      :title="modal.title"
      :titleColor="modal.titleColor"
      :buttonText="modal.button"
      :isStandardButtons="modal.isStandardButtons"
      :okDisabled="modal.okDisabled"
      :width="modal.width"
      :height="modal.height"
      :background="modal.background"
      @action="modalAction"
    >
      <MediaSlider
        v-if="modal.component === 'MediaSlider'"
        :images="sliderImages"
      />
    </Modal>
  </Teleport>
</template>
