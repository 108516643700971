import { createServices } from '@/utils/api/apiService';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiProductsServicesMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.productsV2.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.productsV2.item,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.list,
  },
  updateItem: {
    method: ApiRequestMethod.PUT,
    route: ApiRoutes.productsV2.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.productsV2.item,
  },
  attachTags: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.tags.attach,
  },
  detachTags: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.tags.detach,
  },
  syncTags: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.tags.sync,
  },
  attachImages: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.images.attach,
  },
  detachImages: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.images.detach,
  },
  syncImages: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.images.sync,
  },
  attachMulticolors: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.multicolors.attach,
  },
  detachMulticolors: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.multicolors.detach,
  },
  syncMulticolors: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.multicolors.sync,
  },
  createBarcode: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.barcodes.create,
  },
  deleteBarcode: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.productsV2.barcodes.delete,
  },
  xmlFile: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.productsV2.action.xml,
  },
};

const apiProductsServicesV2 = createServices(apiProductsServicesMap);

export default apiProductsServicesV2;
