import { createRouter, createWebHistory } from 'vue-router';

import { useUserSession } from '@/store/userSession';
import menuKeys from '@/utils/menu/menuKeys';
import MainPage from '@/pages/MainPage.vue';

const routes = [
  {
    name: 'Main',
    path: '/',
    component: MainPage,
    meta: { keyMenu: menuKeys.MAIN },
  },
  {
    name: 'Login',
    path: '/Login',
    component: () => import('@/pages/auth/login.vue'),
    meta: {
      zeroAuth: true,
      keyMenu: '',
    },
  },
  {
    name: 'Tools',
    path: '/tools',
    meta: { keyMenu: menuKeys.TOOLS },
    component: () => import('@/pages/tools/index.vue'),
  },
  {
    name: 'FileManager',
    path: '/file-manager',
    meta: { keyMenu: menuKeys.TOOLS },
    component: () => import('@/pages/tools/manager/index.vue'),
  },
  {
    name: 'CashierWorkplace',
    path: '/cashier-workplace',
    meta: { keyMenu: menuKeys.TOOLS },
    component: () => import('@/pages/tools/CashierWorkplace/index.vue'),
  },
  {
    name: 'Admin',
    path: '/admin',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/index.vue'),
  },
  {
    name: 'Report',
    path: '/report',
    meta: { keyMenu: menuKeys.REPORT },
    component: () => import('@/pages/report/index.vue'),
  },
  {
    name: 'Instructions',
    path: '/instructions',
    meta: { keyMenu: menuKeys.SITE_SETTINGS },
    component: () => import('@/pages/instructions/index.vue'),
  },
  {
    name: 'InstructionCreate',
    path: '/instructions-create',
    meta: { keyMenu: menuKeys.SITE_SETTINGS },
    component: () => import('@/pages/instructions/create.vue'),
  },
  {
    name: 'Instruction',
    path: '/instructions/:slug',
    meta: { keyMenu: menuKeys.SITE_SETTINGS },
    component: () => import('@/pages/instructions/item.vue'),
  },
  {
    name: 'Users',
    path: '/users',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/users/index.vue'),
  },
  {
    name: 'User',
    path: '/users/:id',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/users/item.vue'),
  },
  {
    name: 'UserCreate',
    path: '/users-create',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/users/create.vue'),
  },
  {
    name: 'Roles',
    path: '/roles',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/roles/index.vue'),
  },
  {
    name: 'Role',
    path: '/roles/:id',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/roles/item.vue'),
  },
  {
    name: 'Directories',
    path: '/directories',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/DirectoriesPage.vue'),
  },
  {
    name: 'Docs',
    path: '/docs',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/index.vue'),
  },
  {
    name: 'Reports',
    path: '/reports',
    meta: { keyMenu: menuKeys.REPORT },
    component: () => import('@/pages/docs/index.vue'),
  },
  {
    name: 'Clients',
    path: '/clients',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/clients/index.vue'),
  },
  {
    name: 'ClientCreate',
    path: '/clients-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/clients/create.vue'),
  },
  {
    name: 'Client',
    path: '/clients/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/clients/item.vue'),
  },
  {
    name: 'ItemsCreate',
    path: '/items/create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/items/create.vue'),
  },
  {
    name: 'Items',
    path: '/items',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/items/items.vue'),
  },
  {
    name: 'Item',
    path: '/items/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/items/item.vue'),
  },
  {
    name: 'Categories',
    path: '/categories',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/categories/index.vue'),
  },
  {
    name: 'Category',
    path: '/categories/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/categories/item.vue'),
  },
  {
    name: 'CategoryCreate',
    path: '/category-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/categories/create.vue'),
  },
  {
    name: 'Contracts',
    path: '/contracts',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/contracts/index.vue'),
  },
  {
    name: 'ContractCreate',
    path: '/contract-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/contracts/create.vue'),
  },
  {
    name: 'Contract',
    path: '/contracts/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/contracts/item.vue'),
  },
  {
    name: 'Structures',
    path: '/structures',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/structures/index.vue'),
  },
  {
    name: 'StructureCreate',
    path: '/structures-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/structures/create.vue'),
  },
  {
    name: 'Structure',
    path: '/structures/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/structures/item.vue'),
  },
  {
    name: 'Warehouses',
    path: '/warehouses',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/warehouses/index.vue'),
  },
  {
    name: 'WarehouseCreate',
    path: '/warehouses-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/warehouses/create.vue'),
  },
  {
    name: 'Warehouse',
    path: '/warehouses/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/warehouses/item.vue'),
  },
  {
    name: 'Prices',
    path: '/prices',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/prices/index.vue'),
  },
  {
    name: 'Price',
    path: '/prices/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/prices/item.vue'),
  },
  {
    name: 'Banks',
    path: '/banks',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/banks/index.vue'),
  },
  {
    name: 'Bank',
    path: '/banks/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/banks/item.vue'),
  },
  {
    name: 'CountryCreate',
    path: '/countries-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/countries/create.vue'),
  },
  {
    name: 'Countries',
    path: '/countries',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/countries/index.vue'),
  },
  {
    name: 'Country',
    path: '/countries/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/countries/item.vue'),
  },
  {
    name: 'BankCreate',
    path: '/banks-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/banks/create.vue'),
  },
  {
    name: 'Currencies',
    path: '/currencies',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/currencies/index.vue'),
  },
  {
    name: 'Currency',
    path: '/currencies/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/currencies/item.vue'),
  },
  {
    name: 'CurrencyCreate',
    path: '/currencies-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/currencies/create.vue'),
  }, {
    name: 'BanksAccounts',
    path: '/bank-accounts',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/banksAccounts/index.vue'),
  },
  {
    name: 'BanksAccount',
    path: '/bank-accounts/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/banksAccounts/item.vue'),
  },
  {
    name: 'BanksAccountCreate',
    path: '/bank-accounts-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/banksAccounts/create.vue'),
  },
  {
    name: 'BuyerOrders',
    path: '/buyer-orders/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/buyerOrder/index.vue'),
  },
  {
    name: 'BuyerOrderCreate',
    path: '/buyer-orders-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/buyerOrder/create.vue'),
  },
  {
    name: 'BuyerOrder',
    path: '/buyer-orders/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/buyerOrder/item.vue'),
  },
  {
    name: 'DeliveryOrders',
    path: '/delivery-orders',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/deliveryOrders/index.vue'),
  },
  {
    name: 'DeliveryOrderCreate',
    path: '/delivery-orders-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/deliveryOrders/create.vue'),
  },
  {
    name: 'DeliveryOrder',
    path: '/delivery-orders/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/deliveryOrders/item.vue'),
  },
  {
    name: 'ReceiptInvoices',
    path: '/receipt-invoices/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/receiptInvoice/index.vue'),
  },
  {
    name: 'ReceiptInvoice',
    path: '/receipt-invoices/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/receiptInvoice/item.vue'),
  },
  {
    name: 'ReceiptInvoiceCreate',
    path: '/receipt-invoices-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/receiptInvoice/create/index.vue'),
  },
  {
    name: 'ReceiptInvoiceCreateWithID',
    path: '/receipt-invoices-create/:supplierOrderID',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/receiptInvoice/create/index.vue'),
  },
  {
    name: 'ReceiptOrders',
    path: '/receipt-orders/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/receiptOrder/index.vue'),
  },
  {
    name: 'ReceiptOrder',
    path: '/receipt-orders/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/receiptOrder/item.vue'),
  },
  {
    name: 'ReceiptOrderCreate',
    path: '/receipt-orders-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/receiptOrder/create.vue'),
  },
  {
    name: 'ReceiptOrderCreateWithID',
    path: '/receipt-orders-create/:movementID',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/receiptOrder/create.vue'),
  },
  {
    name: 'ShiftClosures',
    path: '/shift-closures/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/shiftClosures/index.vue'),
  },
  {
    name: 'ShiftClosure',
    path: '/shift-closures/:id',
    component: () => import('@/pages/docs/shiftClosures/item.vue'),
  },
  {
    name: 'ShiftClosuresCreate',
    path: '/shift-closures-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/shiftClosures/create.vue'),
  },
  {
    name: 'ShiftClosuresCreateWithID',
    path: '/shift-closures-create/:retailMoneyStorageId/:structureId',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/shiftClosures/create.vue'),
  },
  {
    name: 'ExpenditureOrders',
    path: '/expenditure-orders/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/expenditureOrder/index.vue'),
  },
  {
    name: 'ExpenditureOrder',
    path: '/expenditure-orders/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/expenditureOrder/item.vue'),
  },
  {
    name: 'ExpenditureOrderCreate',
    path: '/expenditure-orders-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/expenditureOrder/create.vue'),
  },
  {
    name: 'ExpenditureOrderCreateWithID',
    path: '/expenditure-orders-create/:movementID',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/expenditureOrder/create.vue'),
  },
  {
    name: 'SupplierOrders',
    path: '/supplier-orders/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/supplierOrder/index.vue'),
  },
  {
    name: 'SupplierOrder',
    path: '/supplier-orders/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/supplierOrder/item.vue'),
  },
  {
    name: 'SupplierOrderCreate',
    path: '/supplier-orders-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/supplierOrder/create.vue'),
  },
  {
    name: 'Inventory',
    path: '/inventory-of-goods/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/inventory/index.vue'),
  },
  {
    name: 'InventoryItem',
    path: '/inventory-of-goods/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/inventory/item.vue'),
  },
  {
    name: 'InventoryCreate',
    path: '/inventory-of-goods-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/inventory/create.vue'),
  },
  {
    name: 'SalesPlan',
    path: '/sales-plan/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/salesPlan/index.vue'),
  },
  {
    name: 'SalesPlanItem',
    path: '/sales-plan/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/salesPlan/item.vue'),
  },
  {
    name: 'SalesPlanCreate',
    path: '/sales-plan-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/salesPlan/create.vue'),
  },
  {
    name: 'ConfirmBalance',
    path: '/confirm-balance-products/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/confirmBalanceProducts/index.vue'),
  },
  {
    name: 'ConfirmBalanceItem',
    path: '/confirm-balance-products/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/confirmBalanceProducts/item.vue'),
  },
  {
    name: 'ConfirmBalanceCreate',
    path: '/confirm-balance-products-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/confirmBalanceProducts/create.vue'),
  },
  {
    name: 'RecalculationProducts',
    path: '/recalculation-products/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/recalculationProduct/index.vue'),
  },
  {
    name: 'RecalculationProductsItem',
    path: '/recalculation-products/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/recalculationProduct/item.vue'),
  },
  {
    name: 'RecalculationProductsCreate',
    path: '/recalculation-products-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/recalculationProduct/create.vue'),
  },
  {
    name: 'ExpenseInvoices',
    path: '/expense-invoices/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/expenseInvoice/index.vue'),
  },
  {
    name: 'ExpenseInvoice',
    path: '/expense-invoices/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/expenseInvoice/item.vue'),
  },
  {
    name: 'ExpenseInvoiceCreate',
    path: '/expense-invoices-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/expenseInvoice/create.vue'),
  },
  {
    name: 'ExpenseInvoiceCreateWithDocID',
    path: '/expense-invoices-create/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/expenseInvoice/create.vue'),
  },
  {
    name: 'ReturnInvoices',
    path: '/return-invoices/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/returnInvoice/index.vue'),
  },
  {
    name: 'ReturnInvoice',
    path: '/return-invoices/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/returnInvoice/item.vue'),
  },
  {
    name: 'ReturnInvoiceCreate',
    path: '/return-invoices-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/returnInvoice/create.vue'),
  },
  {
    name: 'MovementProducts',
    path: '/movement-products/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movementProduct/index.vue'),
  },
  {
    name: 'MovementProductsCreate',
    path: '/movement-products-create/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movementProduct/create.vue'),
  },
  {
    name: 'MovementProductCreateWithID',
    path: '/movement-products-create/:internalOrderID',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movementProduct/create.vue'),
  },
  {
    name: 'MovementProduct',
    path: '/movement-products/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movementProduct/item.vue'),
  },
  {
    name: 'StorageCell',
    path: '/storage-cells/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/storageCells/item.vue'),
  },
  // {
  //   name: 'PaymentFromBuyers',
  //   path: '/payment-from-buyers/',
  //   component: () => import('@/pages/docs/paymentFromBuyer/index.vue')
  // },
  // {
  //   name: 'PaymentFromBuyer',
  //   path: '/payment-from-buyers/:id',
  //   component: () => import('@/pages/docs/paymentFromBuyer/item.vue')
  // },
  // {
  //   name: 'PaymentOrders',
  //   path: '/payment-orders/',
  //   component: () => import('@/pages/docs/paymentOrder/index.vue')
  // },
  // {
  //   name: 'PaymentOrder',
  //   path: '/payment-orders/:id',
  //   component: () => import('@/pages/docs/paymentOrder/item.vue')
  // },
  {
    name: 'PriceSettings',
    path: '/price-settings/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/priceSettings/index.vue'),
  },
  {
    name: 'PriceSettingCreate',
    path: '/price-settings-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/priceSettings/create.vue'),
  },
  {
    name: 'PriceSetting',
    path: '/price-settings/:id(\\d+)',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/priceSettings/item.vue'),
  },
  {
    name: 'Product',
    path: '/products/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/products/item.vue'),
  },
  {
    name: 'Multicolors',
    path: '/colors',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/muilticolors/index.vue'),
  },
  {
    name: 'Multicolor',
    path: '/colors/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/muilticolors/item.vue'),
  },
  {
    name: 'MulticolorCreate',
    path: '/colors-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/muilticolors/create.vue'),
  },
  {
    name: 'BaseColors',
    path: '/base-colors',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/basecolors/index.vue'),
  },
  {
    name: 'BaseColor',
    path: '/base-colors/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/basecolors/item.vue'),
  },
  {
    name: 'BaseColorCreate',
    path: '/base-colors-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/basecolors/create.vue'),
  },
  {
    name: 'Organizations',
    path: '/organizations',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/organizations/index.vue'),
  },
  {
    name: 'Organization',
    path: '/organizations/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/organizations/item.vue'),
  },
  {
    name: 'OrganizationCreate',
    path: '/organization-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/organizations/create.vue'),
  },
  {
    name: 'Employees',
    path: '/employees',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/employees/index.vue'),
  },
  {
    name: 'Employee',
    path: '/employees/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/employees/item.vue'),
  },
  {
    name: 'EmployeeCreate',
    path: '/employees-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/employees/create.vue'),
  },
  {
    name: 'Sizes',
    path: '/sizes',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/sizes/index.vue'),
  },
  {
    name: 'Size',
    path: '/sizes/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/sizes/item.vue'),
  },
  {
    name: 'SizeCreate',
    path: '/sizes-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/sizes/create.vue'),
  },
  {
    name: 'Tags',
    path: '/tags',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/tags/index.vue'),
  },
  {
    name: 'Tag',
    path: '/tags/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/tags/item.vue'),
  },
  {
    name: 'TagCreate',
    path: '/tags-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/tags/create.vue'),
  },
  {
    name: 'Materials',
    path: '/materials',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/materials/index.vue'),
  },
  {
    name: 'Material',
    path: '/materials/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/materials/item.vue'),
  },
  {
    name: 'MaterialCreate',
    path: '/materials-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/materials/create.vue'),
  },
  {
    name: 'CompositionCreate',
    path: '/compositions-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/compositions/create.vue'),
  },
  {
    name: 'Compositions',
    path: '/compositions',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/compositions/index.vue'),
  },
  {
    name: 'Composition',
    path: '/compositions/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/compositions/item.vue'),
  },

  {
    name: 'StorageRooms',
    path: '/storage-rooms',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/rooms/index.vue'),
  },
  {
    name: 'StorageRoom',
    path: '/storage-rooms/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/rooms/item.vue'),
  },
  {
    name: 'StorageRoomsCreate',
    path: '/storage-rooms-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/rooms/create.vue'),
  },
  {
    name: 'StorageRows',
    path: '/storage-rows',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/rows/index.vue'),
  },
  {
    name: 'StorageRow',
    path: '/storage-rows/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/rows/item.vue'),
  },
  {
    name: 'StorageRowsCreate',
    path: '/storage-rows-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/rows/create.vue'),
  },
  {
    name: 'StorageRacks',
    path: '/storage-racks',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/racks/index.vue'),
  },
  {
    name: 'StorageRack',
    path: '/storage-racks/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/racks/item.vue'),
  },
  {
    name: 'StorageRacksCreate',
    path: '/storage-racks-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/racks/create.vue'),
  },
  {
    name: 'StorageShelves',
    path: '/storage-shelves',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/shelves/index.vue'),
  },
  {
    name: 'StorageShelve',
    path: '/storage-shelves/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/shelves/item.vue'),
  },
  {
    name: 'StorageShelvesCreate',
    path: '/storage-shelves-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/shelves/create.vue'),
  },
  {
    name: 'StoragePlaces',
    path: '/storage-places',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/places/index.vue'),
  },
  {
    name: 'StoragePlace',
    path: '/storage-places/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/places/item.vue'),
  },
  {
    name: 'StoragePlacesCreate',
    path: '/storage-places-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/places/create.vue'),
  },
  {
    name: 'CashChecks',
    path: '/document-cash-checks/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/documentCashChecks/index.vue'),
  },
  {
    name: 'CashCheck',
    path: '/document-cash-checks/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/documentCashChecks/item.vue'),
  },
  {
    name: 'CashCheckCreate',
    path: '/document-cash-checks-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/documentCashChecks/create.vue'),
  },
  {
    name: 'CashCheckCreateWithID',
    path: '/document-cash-checks-create/:expenseInvoiceID',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/documentCashChecks/create.vue'),
  },
  {
    name: 'CashOrders',
    path: '/document-cash-orders/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/cashOrders/index.vue'),
  },
  {
    name: 'CashOrder',
    path: '/document-cash-orders/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/cashOrders/item.vue'),
  },
  {
    name: 'CashOrderCreate',
    path: '/document-cash-orders-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/cashOrders/create.vue'),
  },
  {
    name: 'MovementsCashBetweenMoneyStorages',
    path: '/document-movements-cash-between-money-storages/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movementsCashBetweenMoneyStorages/index.vue'),
  },
  {
    name: 'MovementsCashBetweenMoneyStorage',
    path: '/document-movements-cash-between-money-storages/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movementsCashBetweenMoneyStorages/item.vue'),
  },
  {
    name: 'MovementsCashBetweenMoneyStoragesCreate',
    path: '/document-movements-cash-between-money-storages-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movementsCashBetweenMoneyStorages/create.vue'),
  },
  {
    name: 'WriteOffGoods',
    path: '/write-off-goods/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/writeOffGoods/index.vue'),
  },
  {
    name: 'WriteOffGood',
    path: '/write-off-goods/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/writeOffGoods/item.vue'),
  },
  {
    name: 'WriteOffGoodsCreate',
    path: '/write-off-goods-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/writeOffGoods/create.vue'),
  },
  {
    name: 'PostingOfGoods',
    path: '/posting-of-goods/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/postingOfGoods/index.vue'),
  },
  {
    name: 'PostingOfGood',
    path: '/posting-of-goods/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/postingOfGoods/item.vue'),
  },
  {
    name: 'PostingOfGoodCreate',
    path: '/posting-of-goods-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/postingOfGoods/create.vue'),
  },
  {
    name: 'MovingBetweenStorageCells',
    path: '/moving-between-storage-cells/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movingBetweenStorageCells/index.vue'),
  },
  {
    name: 'MovingBetweenStorageCellsItem',
    path: '/moving-between-storage-cells/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movingBetweenStorageCells/item.vue'),
  },
  {
    name: 'MovingBetweenStorageCellsCreate',
    path: '/moving-between-storage-cells-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/movingBetweenStorageCells/create.vue'),
  },
  {
    name: 'InternalOrders',
    path: '/internal-orders/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/internalOrder/index.vue'),
  },
  {
    name: 'InternalOrder',
    path: '/internal-orders/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/internalOrder/item.vue'),
  },
  {
    name: 'InternalOrderCreate',
    path: '/internal-orders-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/internalOrder/create.vue'),
  },
  {
    name: 'DispatchProducts',
    path: '/dispatch-products/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/dispatchProducts/index.vue'),
  },
  {
    name: 'DispatchProduct',
    path: '/dispatch-products/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/dispatchProducts/item.vue'),
  },
  {
    name: 'DispatchProductCreate',
    path: '/dispatch-products-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/dispatchProducts/create.vue'),
  },
  {
    name: 'DeliveryRoutes',
    path: '/delivery-routes/',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/deliveryRoutes/index.vue'),
  },
  {
    name: 'DeliveryRoute',
    path: '/delivery-routes/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/deliveryRoutes/item.vue'),
  },
  {
    name: 'Tasks',
    path: '/tasks/',
    keyMenu: menuKeys.TASKS,
    component: () => import('@/pages/tasks/index.vue'),
  },
  {
    name: 'Task',
    path: '/tasks/:id',
    keyMenu: menuKeys.TASKS,
    component: () => import('@/pages/tasks/item.vue'),
  },
  {
    name: 'DeliveryCompanies',
    path: '/delivery-companies',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/deliveryCompanies/index.vue'),
  },
  {
    name: 'DeliveryCompany',
    path: '/delivery-companies/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/deliveryCompanies/item.vue'),
  },
  {
    name: 'DeliveryCompaniesCreate',
    path: '/delivery-companies-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/deliveryCompanies/create.vue'),
  },
  {
    name: 'RouteSheets',
    path: '/route-sheets/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/routeSheets/index.vue'),
  },
  {
    name: 'RouteSheet',
    path: '/route-sheets/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/routeSheets/item.vue'),
  },
  {
    name: 'RouteSheetsCreate',
    path: '/route-sheets-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/routeSheets/create.vue'),
  },
  {
    name: 'Document-Editings',
    path: '/document-editings',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/documentEditings/index.vue'),
  },
  {
    name: 'Model-Editings',
    path: '/model-editings',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/modelEditings/index.vue'),
  },
  {
    name: 'Constants',
    path: '/constants',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/constants/index.vue'),
  },
  {
    name: 'SiteSettings',
    path: '/site-settings',
    meta: { keyMenu: menuKeys.SITE_SETTINGS },
    component: () => import('@/pages/siteSettings/index.vue'),
  },
  {
    name: 'MainPageEditor',
    path: '/main-page-editor',
    meta: { keyMenu: menuKeys.SITE_SETTINGS },
    component: () => import('@/pages/siteSettings/mainPageEditor/index.vue'),
  },
  {
    name: 'ShopCategories',
    path: '/shop-categories',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/shopCategories/index.vue'),
  },
  {
    name: 'ShopCategory',
    path: '/shop-categories/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/shopCategories/item.vue'),
  },
  {
    name: 'ShopCategoryCreate',
    path: '/shop-categories-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/shopCategories/create.vue'),
  },
  {
    name: 'RetailMoneyStorages',
    path: '/retail-money-storages',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/retailMoneyStorages/index.vue'),
  },
  {
    name: 'RetailMoneyStorage',
    path: '/retail-money-storage/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/retailMoneyStorages/item.vue'),
  },
  {
    name: 'RetailMoneyStorageCreate',
    path: '/retail-money-storage-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/retailMoneyStorages/create.vue'),
  },
  {
    name: 'VisitorCounterDevices',
    path: '/visitor-counter-devices',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/visitorCounterDevices/index.vue'),
  },
  {
    name: 'VisitorCounterDevice',
    path: '/visitor-counter-device/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/visitorCounterDevices/item.vue'),
  },
  {
    name: 'VisitorCounterDeviceCreate',
    path: '/visitor-counter-device-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/visitorCounterDevices/create.vue'),
  },
  {
    name: 'CostItems',
    path: '/cost-items',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/costItems/index.vue'),
  },
  {
    name: 'CostItem',
    path: '/cost-item/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/costItems/item.vue'),
  },
  {
    name: 'CostItemCreate',
    path: '/cost-item-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/costItems/create.vue'),
  },
  {
    name: 'VisitorCounterAccounts',
    path: '/visitor-counter-accounts',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/visitorCounterAccounts/index.vue'),
  },
  {
    name: 'VisitorCounterAccount',
    path: '/visitor-counter-account/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/visitorCounterAccounts/item.vue'),
  },
  {
    name: 'VisitorCounterAccountCreate',
    path: '/visitor-counter-account-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/visitorCounterAccounts/create.vue'),
  },
  {
    name: 'Promocodes',
    path: '/promocodes',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/promocodes/index.vue'),
  },
  {
    name: 'PromocodeItem',
    path: '/promocodes/:id',
    component: () => import('@/pages/promocodes/item.vue'),
  },
  {
    name: 'PromocodeCreate',
    path: '/promocodes-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/promocodes/create.vue'),
  },
  {
    name: 'Certificates',
    path: '/certificates',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/certificates/index.vue'),
  },
  {
    name: 'CertificateItem',
    path: '/certificates/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/certificates/item.vue'),
  },
  {
    name: 'CertificateCreate',
    path: '/certificates-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/certificates/create.vue'),
  },
  {
    name: 'Discounts',
    path: '/discounts',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/discounts/index.vue'),
  },
  {
    name: 'DiscountItem',
    path: '/discounts/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/discounts/item.vue'),
  },
  {
    name: 'DiscountCreate',
    path: '/discounts-create',
    component: () => import('@/pages/discounts/create.vue'),
  },
  {
    name: 'ReasonReturns',
    path: '/reason-returns',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/reasonReturns/index.vue'),
  },
  {
    name: 'ReasonReturnItem',
    path: '/reason-returns/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/reasonReturns/item.vue'),
  },
  {
    name: 'ReasonReturnCreate',
    path: '/reason-returns-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/reasonReturns/create.vue'),
  },
  {
    name: 'ReturnRequests',
    path: '/return-requests',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/returnRequests/index.vue'),
  },
  {
    name: 'ReturnRequestItem',
    path: '/return-requests/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/returnRequests/item.vue'),
  },
  {
    name: 'ReturnRequestCreate',
    path: '/return-requests-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/returnRequests/create.vue'),
  },
  {
    name: 'CreditApplications',
    path: '/credit-applications',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/creditApplications/index.vue'),
  },
  {
    name: 'CreditApplicationItem',
    path: '/credit-applications/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/creditApplications/item.vue'),
  },
  {
    name: 'CreditApplicationCreate',
    path: '/credit-applications-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/creditApplications/create.vue'),
  },
  {
    name: 'MoneyStorage',
    path: '/money-storage',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/moneyStorage/index.vue'),
  },
  {
    name: 'MoneyStorageItem',
    path: '/money-storage/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/moneyStorage/item.vue'),
  },
  {
    name: 'MoneyStorageCreate',
    path: '/money-storage-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/moneyStorage/create.vue'),
  },
  {
    name: 'StaticPageSettings',
    path: '/static-page-setting',
    meta: { keyMenu: menuKeys.SITE_SETTINGS },
    component: () => import('@/pages/staticPageSettings/index.vue'),
  },
  {
    name: 'StaticPageSetting',
    path: '/static-page-setting/:id',
    meta: { keyMenu: menuKeys.SITE_SETTINGS },
    component: () => import('@/pages/staticPageSettings/item.vue'),
  },
  {
    name: 'InstructionSettings',
    path: '/instruction-setting',
    meta: { keyMenu: menuKeys.SITE_SETTINGS },
    component: () => import('@/pages/instructionSettings/index.vue'),
  },
  {
    name: 'InstructionSetting',
    path: '/instruction-setting/:id',
    meta: { keyMenu: menuKeys.SITE_SETTINGS },
    component: () => import('@/pages/instructionSettings/item.vue'),
  },
  {
    name: 'PaymentBuyerPaymentCards',
    path: '/document-payment-buyer-cards/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/paymentBuyerPaymentCards/index.vue'),
  },
  {
    name: 'PaymentBuyerPaymentCard',
    path: '/document-payment-buyer-cards/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/paymentBuyerPaymentCards/item.vue'),
  },
  {
    name: 'PaymentBuyerPaymentCardCreate',
    path: '/document-payment-buyer-cards-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/paymentBuyerPaymentCards/create.vue'),
  },
  {
    name: 'Coupons',
    path: '/coupons',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/coupons/index.vue'),
  },
  {
    name: 'CouponsItem',
    path: '/coupons/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/coupons/item.vue'),
  },
  {
    name: 'CouponsCreate',
    path: '/coupons-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/coupons/create.vue'),
  },
  {
    name: 'itemsNames',
    path: '/items-name',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/itemsName/index.vue'),
  },
  {
    name: 'itemsName',
    path: '/items-name/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/itemsName/item.vue'),
  },
  {
    name: 'itemsNameCreate',
    path: '/items-name-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/itemsName/create.vue'),
  },
  // test route
  // {
  //   name: 'InfinityScroll',
  //   path: '/infinity-scroll',
  //   component: () => import('@/pages/docs/_infinityScrollTest/index.vue')
  // },
  {
    name: 'CreditRequest',
    path: '/credit-application/:id',
    meta: { keyMenu: menuKeys.TOOLS },
    component: () => import('@/pages/docs/creditApplications/item.vue'),
  },
  {
    name: 'Menu',
    path: '/menu',
    meta: { keyMenu: menuKeys.ADMIN },
    component: () => import('@/pages/admin/menu/index.vue'),
  },
  {
    name: 'StorekeeperWorkplace',
    path: '/storekeeper-workplace',
    meta: { keyMenu: menuKeys.TOOLS },
    component: () => import('@/pages/tools/storekeeperWorkplace/index.vue'),
  },

  {
    name: 'DocumentSaleByDeliveryCompanies',
    path: '/document-sale-by-delivery-companies',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/saleByDeliveryCompanies/index.vue'),
  },
  {
    name: 'DocumentSaleByDeliveryCompaniesItem',
    path: '/document-sale-by-delivery-companies/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/saleByDeliveryCompanies/item.vue'),
  },
  {
    name: 'DocumentSaleByDeliveryCompaniesCreate',
    path: '/document-sale-by-delivery-companies-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/saleByDeliveryCompanies/create.vue'),
  },
  {
    name: 'DocumentHonestMarksFactoriesCreate',
    path: '/document-sending-honest-marks-factories-create',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/sendingHonestMarksToFactories/create.vue'),
  },
  {
    name: 'DocumentHonestMarksFactories',
    path: '/document-sending-honest-marks-factories',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/sendingHonestMarksToFactories/index.vue'),
  },
  {
    name: 'DocumentHonestMarksFactoriesItem',
    path: '/document-sending-honest-marks-factories/:id',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/sendingHonestMarksToFactories/item.vue'),
  },

  {
    name: 'SubscriberGroups',
    path: '/subscriber-groups',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/subscriberGroups/index.vue'),
  },
  {
    name: 'SubscriberGroupsItem',
    path: '/subscriber-groups/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/subscriberGroups/item.vue'),
  },
  {
    name: 'SubscriberGroupsCreate',
    path: '/subscriber-groups-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/subscriberGroups/create.vue'),
  },
  {
    name: 'FiscalRecorders',
    path: '/fiscal-recorders',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/fiscalRecorders/index.vue'),
  },
  {
    name: 'FiscalRecordersItem',
    path: '/fiscal-recorders/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/fiscalRecorders/item.vue'),
  },
  {
    name: 'FiscalRecordersCreate',
    path: '/fiscal-recorders-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/fiscalRecorders/create.vue'),
  },
  {
    name: 'PodeliAccounts',
    path: '/podeli-accounts',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/podeliAccounts/index.vue'),
  },
  {
    name: 'PodeliAccountsItem',
    path: '/podeli-accounts/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/podeliAccounts/item.vue'),
  },
  {
    name: 'PodeliAccountsCreate',
    path: '/podeli-accounts-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/podeliAccounts/create.vue'),
  },
  {
    name: 'DolyameAccounts',
    path: '/dolyame-accounts',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/dolyameAccounts/index.vue'),
  },
  {
    name: 'DolyameAccountsItem',
    path: '/dolyame-accounts/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/dolyameAccounts/item.vue'),
  },
  {
    name: 'DolyameAccountsCreate',
    path: '/dolyame-accounts-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/dolyameAccounts/create.vue'),
  },
  {
    name: 'LabelPrinting',
    path: '/label-printing',
    meta: { keyMenu: menuKeys.TOOLS },
    component: () => import('@/pages/tools/labelPrinting/index.vue'),
  },
  // Page 404
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*', // if nothing has been found
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/NotFound.vue'),
  },
  {
    name: 'AddingTags',
    path: '/adding-tags',
    meta: { keyMenu: menuKeys.TOOLS },
    component: () => import('@/pages/tools/addingTags/index.vue'),
  },
  {
    name: 'CzkEmissionOrders',
    path: '/document-czk-emission-orders',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/czkEmissionOrders/index.vue'),
  },
  {
    name: 'CzkEmissionOrdersItem',
    path: '/document-czk-emission-orders/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/docs/czkEmissionOrders/item.vue'),
  },
  {
    name: 'CzkEmissionOrdersCreate',
    path: '/document-czk-emission-orders-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/docs/czkEmissionOrders/create.vue'),
  },
  {
    name: 'Gtins',
    path: '/gtins',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/gtin/index.vue'),
  },
  {
    name: 'GtinsItem',
    path: '/gtins/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/gtin/item.vue'),
  },
  {
    name: 'GtinsCreate',
    path: '/gtins-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/gtin/create.vue'),
  },
  {
    name: 'ManagingDownloads',
    path: '/managing-downloads-hm',
    meta: { keyMenu: menuKeys.TOOLS },
    component: () => import('@/pages/tools/managingDownloads/index.vue'),
  },
  {
    name: 'EdmOperators',
    path: '/edm-operators',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/edmOperators/index.vue'),
  },
  {
    name: 'EdmOperatorsItem',
    path: '/edm-operators/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/edmOperators/item.vue'),
  },
  {
    name: 'EdmOperatorsCreate',
    path: '/edm-operators-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/edmOperators/create.vue'),
  },
  {
    name: 'Measurements',
    path: '/measurements',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/measurements/index.vue'),
  },
  {
    name: 'MeasurementsItem',
    path: '/measurements/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/measurements/item.vue'),
  },
  {
    name: 'MeasurementsCreate',
    path: '/measurements-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/measurements/create.vue'),
  },
  {
    name: 'Acquirers',
    path: '/acquirers',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/acquirers/index.vue'),
  },
  {
    name: 'AcquirersItem',
    path: '/acquirers/:id',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/acquirers/item.vue'),
  },
  {
    name: 'AcquirersCreate',
    path: '/acquirers-create',
    meta: { keyMenu: menuKeys.DIR },
    component: () => import('@/pages/acquirers/create.vue'),
  },
  {
    name: 'ShiftClosuresTerminals',
    path: '/shift-closures-terminals/',
    meta: { keyMenu: menuKeys.DOCS },
    component: () => import('@/pages/docs/shiftClosuresTerminal/index.vue'),
  },
  {
    name: 'ShiftClosureTerminal',
    path: '/shift-closures-terminals/:id',
    component: () => import('@/pages/docs/shiftClosuresTerminal/item.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const userSession = useUserSession();

  if (to.meta.zeroAuth === undefined && !userSession.isLoggedIn) next({ name: 'Login' });
  else next();
});

export default router;
