<template
  v-if="workWithTags"
>
  <div class="workWithTag">
    <Teleport to="body">
      <Modal
        :open="workWithTags"
        :isStandardButtons="false"
        height="80%"
        width="1000px"
        :hideHeaderLine="true"
      >
        <div ref="tagsBlock" class="h-full">
          <div class="flex justify-between p-2 items-center">
            <div class="h1 workWithTag__head">
              {{ $t('addingTags.modal.tagManagementTitle') }}
            </div>
            <div class="flex gap-2">
              <Button
                size="sm"
                :mods="['bg-gray']"
                @click="closeWorkWithTagsModal"
              >
                {{ $t('addingTags.buttons.cancel') }}
              </Button>
              <Button
                size="sm"
                :mods="['bg-secondary']"
                @click="syncTagsHandler"
              >
                {{ $t('addingTags.buttons.apply') }}
              </Button>
            </div>
          </div>
          <div class="flex border-b rounded-tl">
            <div class="rounded-tl overflow-hidden">
              <Button
                size="s"
                :mods="[isAttach ? 'bg-secondary' : 'bg-gray', 'noBorderRadius']"
                @click="isAttachChangeHandler"
              >
                <span class="px-1">Добавить</span>
              </Button>
            </div>
            <div class="rounded-tr overflow-hidden">
              <Button
                size="s"
                :mods="[isAttach ? 'bg-gray' : 'bg-secondary', 'noBorderRadius']"
                @click="isAttachChangeHandler"
              >
                <span class="px-1">Отменить</span>
              </Button>
            </div>
          </div>

          <template v-if="isAttach">
            <div class="py-2 flex justify-between py-3">
              <div class="w-5/12 h-full">
                <TagsListColumn
                  :tagsList="searchTagsComputed"
                  columnType="attach"
                  columnSide="left"
                  :title="t('addingTags.modal.availableTags')"
                  :tagsListBlockHeight="tagsListBlockHeight"
                  :searchFields="searchFields"
                  @action="tagsListColumnActionsHandler"
                />
              </div>
              <div class="w-2/12 p-2 flex items-center">
                <div class="flex flex-col gap-4 min-w-full">
                  <div
                    class=" w-24 self-center flex items-center justify-center bg-gray-200 hover:bg-gray-100 transition-all h-20 rounded-md cursor-pointer"
                    @click="moveTagsBetweenColumns('attach', 'right', 'left')"
                  >
                    <Icon
                      name="tags-arrow"
                      class="h-13 text-cyan-500/[.7] rotate-180"
                    />
                  </div>
                  <div
                    class="w-24 self-center flex items-center justify-center bg-gray-200 hover:bg-gray-100 transition-all h-20 rounded-md cursor-pointer"
                    @click="moveTagsBetweenColumns('attach', 'left', 'right')"
                  >
                    <Icon
                      name="tags-arrow"
                      class="h-13 text-orange-500/[.7]"
                    />
                  </div>
                </div>
              </div>
              <div class="w-5/12 h-full">
                <TagsListColumn
                  :tagsList="searchTagsToAttachComputed"
                  columnType="attach"
                  columnSide="right"
                  title="Добавленные теги"
                  :tagsListBlockHeight="tagsListBlockHeight"
                  :searchFields="searchFields"
                  @action="tagsListColumnActionsHandler"
                />
              </div>
            </div>
          </template>
          <template v-if="!isAttach">
            <div class="py-2 flex justify-between py-3">
              <div class="w-5/12 h-full">
                <TagsListColumn
                  :tagsList="searchTagsToDetachComputed"
                  columnType="detach"
                  columnSide="left"
                  title="Отмененные теги"
                  :tagsListBlockHeight="tagsListBlockHeight"
                  :searchFields="searchFields"
                  @action="tagsListColumnActionsHandler"
                />
              </div>
              <div class="w-2/12 p-2 flex items-center">
                <div class="flex flex-col gap-4 min-w-full">
                  <div
                    class="w-24 self-center flex items-center justify-center bg-gray-200 hover:bg-gray-100 transition-all h-20 rounded-md cursor-pointer"
                    @click="moveTagsBetweenColumns('detach', 'right', 'left')"
                  >
                    <Icon
                      name="tags-arrow"
                      class="h-13 text-cyan-500/[.7] rotate-180"
                    />
                  </div>
                  <div
                    class="w-24 self-center flex items-center justify-center bg-gray-200 hover:bg-gray-100 transition-all h-20 rounded-md cursor-pointer"
                    @click="moveTagsBetweenColumns('detach', 'left', 'right')"
                  >
                    <Icon
                      name="tags-arrow"
                      class="h-13 text-orange-500/[.7]"
                    />
                  </div>
                </div>
              </div>
              <div class="w-5/12 h-full">
                <TagsListColumn
                  :tagsList="searchItemTagsComputed"
                  columnType="detach"
                  columnSide="right"
                  title="Активные теги"
                  :tagsListBlockHeight="tagsListBlockHeight"
                  :searchFields="searchFields"
                  @action="tagsListColumnActionsHandler"
                />
              </div>
            </div>
          </template>
        </div>
        <Loader
          v-if="state.isLoading"
          class="searcher__loader --show"
        />
      </Modal>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted,
  ref,
  Ref,
  computed,
  onActivated,
} from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useNotifications } from '@/store/notifications';
import apiTagsServices from '@/utils/api/services/apiTagsServices';
import Loader from '@/components/share/Loader/Loader.vue';
import Modal from '@/components/share/Modal/Modal.vue';
import Button from '@/components/form/Button/Button.vue';
import TagsListColumn from '@/components/share/Items/inc/tagsListColumn.vue';
import apiItemsServicesV2 from '@/utils/api/services/apiItemsServicesV2';
import Icon from '@/components/share/Icon/Icon.vue';

const route = useRoute();
const { t } = useI18n();
const notifications = useNotifications();

const tagsBlock:Ref = ref(null);
const tagsListBlockHeight:Ref = ref(600);

const props = defineProps({
  tagSelectedItems: {
    type: Object,
    default: () => [],
  },
  workWithTags: {
    type: Boolean,
    default: false,
  },
});

const searchFields:Ref = ref({
  tags: '',
  tagsToAttach: '',
  tagsToDetach: '',
  itemsTags: '',
});

const state:Ref = ref({ isLoading: false });

const tags:Ref = ref([]);
const tagsComputed = computed(() => {
  if (tags.value.length) {
    const tagList:any = [];
    tags.value.forEach((el:any) => {
      if (el.toAttach === false) {
        tagList.push(el);
      }
    });
    return tagList;
  }
  return [];
});

const searchTagsComputed = computed(() => {
  if (searchFields.value.tags !== '') {
    return tagsComputed.value.filter((el:any) => el.name.toString().toLowerCase().includes(searchFields.value.tags.toString().toLowerCase()));
  }
  return tagsComputed.value;
});

const tagsToAttachComputed = computed(() => {
  if (tags.value.length) {
    const tagList:any = [];
    tags.value.forEach((el:any) => {
      if (el.toAttach === true) {
        tagList.push(el);
      }
    });
    return tagList;
  }
  return [];
});

const searchTagsToAttachComputed = computed(() => {
  if (searchFields.value.tagsToAttach !== '') {
    return tagsToAttachComputed.value.filter((el:any) => el.name.toString().toLowerCase().includes(searchFields.value.tagsToAttach.toLowerCase()));
  }
  return tagsToAttachComputed.value;
});

const itemTags:Ref = ref([]);

const itemTagsComputed = computed(() => {
  if (itemTags.value.length) {
    const tagList:any = [];
    itemTags.value.forEach((el:any) => {
      if (!el.toDetach) {
        tagList.push(el);
      }
    });
    return tagList;
  }
  return [];
});

const searchItemTagsComputed = computed(() => {
  if (searchFields.value.tagsToDetach !== '') {
    return itemTagsComputed.value.filter((el:any) => el.name.toString().toLowerCase().includes(searchFields.value.tagsToDetach.toString().toLowerCase()));
  }
  return itemTagsComputed.value;
});

const tagsToDetachComputed = computed(() => {
  if (itemTags.value.length) {
    const tagList:any = [];
    itemTags.value.forEach((el:any) => {
      if (el.toDetach) {
        tagList.push(el);
      }
    });
    return tagList;
  }
  return [];
});

const searchTagsToDetachComputed = computed(() => {
  if (searchFields.value.itemsTags !== '') {
    return tagsToDetachComputed.value.filter((el:any) => el.name.toString().toLowerCase().includes(searchFields.value.itemsTags.toString().toLowerCase()));
  }
  return tagsToDetachComputed.value;
});

const isAttach:Ref = ref(true);

const isAttachChangeHandler = () => {
  isAttach.value = !isAttach.value;
};

const syncTagsHandler = async () => {
  state.value.isLoading = true;

  const tagsToAttach = getTagsToAttach();
  const tagsToDetach = getTagsToDetach();

  const items = props.tagSelectedItems.map((item:any) => ({
    itemId: item.id,
    itemTagsIds: item.tags.map((tag:any) => Number(tag.id)),
  }));

  items.map((item:any) => {
    const tagsIds = Array.from(new Set([...item.itemTagsIds, ...tagsToAttach]));

    const prepareTags = [] as any;
    tagsIds.forEach((tagId:any) => {
      if (!tagsToDetach.includes(tagId)) {
        prepareTags.push(tagId);
      }
    });
    item.itemTagsIds = prepareTags;
  });

  for (const item of items) {
    await apiItemsServicesV2.syncTags({ id: item.itemId }, { tags: item.itemTagsIds });
  }
  state.value.isLoading = false;

  closeWorkWithTagsModal();
  uncheckSelectedItemsAndSilentItemsUpdate(items);
};

const getTagsToAttach = () => tags.value.filter((el:any) => el.toAttach === true).map((tag:any) => tag.id);

const getTagsToDetach = () => itemTags.value.filter((el:any) => el.toDetach === true).map((tag:any) => tag.id);

const moveTagsBetweenColumns = (type:string, from:string, to:string) => {
  if (type === 'attach') {
    tags.value.map((el:any) => {
      if (el.selected) {
        from === 'left' ? el.toAttach = true : el.toAttach = false;
      }
      el.selected = false;
    });
  }
  if (type === 'detach') {
    itemTags.value.map((el:any) => {
      if (el.selected) {
        from === 'right' ? el.toDetach = true : el.toDetach = false;
      }
      el.selected = false;
    });
  }
};

const tagsListColumnActionsHandler = (event:any) => {
  switch (event.type) {
    case 'checkTag':
      checkTag(event);
      break;
    case 'selectAll':
      selectAllHandler(event);
      break;
    case 'deselectAll':
      deselectAllHandler(event);
      break;
    case 'updateSearchInput':
      updateSearchInput(event);
    default:
      break;
  }
};

const updateSearchInput = (event:any) => {
  if (event.value?.columnType === 'attach') {
    if (event.value.columnSide == 'left') {
      searchFields.value.tags = event.value.value;
    }
    if (event.value.columnSide == 'right') {
      searchFields.value.tagsToAttach = event.value.value;
    }
  }
  if (event.value?.columnType === 'detach') {
    if (event.value.columnSide == 'left') {
      searchFields.value.itemsTags = event.value.value;
    }
    if (event.value.columnSide == 'right') {
      searchFields.value.tagsToDetach = event.value.value;
    }
  }
};

const checkTag = (event:any) => {
  if (event.value?.columnType === 'attach') {
    const item = tags.value.find((el:any) => event.value?.tag?.id == el.id);
    if (item) item.selected = !item.selected;
  }
  if (event.value?.columnType === 'detach') {
    const item = itemTags.value.find((el:any) => event.value?.tag?.id == el.id);
    if (item) item.selected = !item.selected;
  }
};

const selectAllHandler = (event:any) => {
  if (event.value.columnType == 'attach') {
    if (event.value.columnSide == 'left') {
      if (searchFields.value.tags != '') {
        const searchTagsIds = searchTagsComputed.value.map((ele:any) => ele.id);
        tags.value.map((el:any) => {
          if (el.toAttach === false && searchTagsIds.includes(el.id)) {
            el.selected = true;
          }
        });
      } else {
        tags.value.map((el:any) => {
          if (el.toAttach === false) {
            el.selected = true;
          }
        });
      }
    }
    if (event.value.columnSide == 'right') {
      if (searchFields.value.tagsToAttach != '') {
        const searchTagsIds = searchTagsToAttachComputed.value.map((ele:any) => ele.id);
        tags.value.map((el:any) => {
          if (el.toAttach === true && searchTagsIds.includes(el.id)) {
            el.selected = true;
          }
        });
      } else {
        tags.value.map((el:any) => {
          if (el.toAttach === true) {
            el.selected = true;
          }
        });
      }
    }
  }
  if (event.value.columnType == 'detach') {
    if (event.value.columnSide == 'left') {
      if (searchFields.value.itemsTags != '') {
        const searchTagsIds = searchTagsToDetachComputed.value.map((ele:any) => ele.id);
        itemTags.value.map((el:any) => {
          if (el.toDetach === true && searchTagsIds.includes(el.id)) {
            el.selected = true;
          }
        });
      } else {
        itemTags.value.map((el:any) => {
          if (el.toDetach === true) {
            el.selected = true;
          }
        });
      }
    }
    if (event.value.columnSide == 'right') {
      if (searchFields.value.tagsToDetach != '') {
        const searchTagsIds = searchItemTagsComputed.value.map((ele:any) => ele.id);
        itemTags.value.map((el:any) => {
          if (el.toDetach === false && searchTagsIds.includes(el.id)) {
            el.selected = true;
          }
        });
      } else {
        itemTags.value.map((el:any) => {
          if (el.toDetach === false) {
            el.selected = true;
          }
        });
      }
    }
  }
};

const deselectAllHandler = (event:any) => {
  if (event.value.columnType == 'attach') {
    if (event.value.columnSide == 'left') {
      tags.value.map((el:any) => {
        if (el.toAttach === false) {
          el.selected = false;
        }
      });
    }
    if (event.value.columnSide == 'right') {
      tags.value.map((el:any) => {
        if (el.toAttach === true) {
          el.selected = false;
        }
      });
    }
  }
  if (event.value.columnType == 'detach') {
    if (event.value.columnSide == 'left') {
      itemTags.value.map((el:any) => {
        if (el.toDetach === true) {
          el.selected = false;
        }
      });
    }
    if (event.value.columnSide == 'right') {
      itemTags.value.map((el:any) => {
        if (el.toDetach === false) {
          el.selected = false;
        }
      });
    }
  }
};

const tagSelectedItemsListHandler = () => {
  const list = [] as any;
  props.tagSelectedItems?.map((el:any) => {
    el.tags.forEach((tag:any) => {
      if (!list.find((t:any) => t.id == tag.id)) {
        list.push(
          {
            id: Number(tag.id),
            name: tag.attributes.name,
            isBlocked: false,
            selected: false,
            toDetach: false,
          },
        );
      }
    });
  });
  itemTags.value = list;
};

const closeWorkWithTagsModal = () => {
  emit('action', { type: 'closeWorkWithTagsModal', val: true });
};

const uncheckSelectedItemsAndSilentItemsUpdate = (items:any) => {
  emit('action', { type: 'clearAll', val: items });
};

const emit = defineEmits(['action']);

/**
 * Get data from the api
 *
 * @function
 * @async
 */
const getTags = async () => {
  state.value.isLoading = true;

  const { data } = await apiTagsServices.getList({}, { params: { 'page[size]': 999 } });

  if (data) {
    tags.value = data.map((t: any) => ({
      id: Number(t.id),
      name: t.attributes.name,
      isBlocked: false,
      selected: false,
      toAttach: false,
    }));
  } else {
    notifications.error(t('notification.error'), t('tags.error.loadList'));
  }

  state.value.isLoading = false;
};

onMounted(async () => {
  await getTags();
  tagSelectedItemsListHandler();
  resizeTagsListBlockHeight();
});

const resizeTagsListBlockHeight = () => {
  tagsListBlockHeight.value = tagsBlock.value!.clientHeight;
  window.addEventListener('resize', resizeTagsListBlockHeight);
};

onActivated(() => {
  resizeTagsListBlockHeight();
});

</script>

<style lang="scss" scoped>
.attach-links{
  margin-top: 20px;
}
.attach-link{
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}
.active-link{
  text-decoration: underline;
}
.workWithTag{
  &__arrow{
  }
}
</style>
