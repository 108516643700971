import { defineStore } from 'pinia';

export const useEnums = defineStore('enums', {
  persist: true,
  state: () => ({ enums: {} as any }),
  getters: { enumsIsLoad: (state) => !!Object.keys(state.enums).length },
  actions: {
    setEnums(payload: any) {
      const obj: any = {};

      for (const enumKey in payload) {
        const keys = enumKey.replace('App\\Restify\\Enums\\', '').split('\\');
        let temp: any = obj;

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (!temp[key]) {
            if (i + 1 === keys.length) {
              Object.assign(temp, { [key]: payload[enumKey] });
            } else {
              Object.assign(temp, { [key]: {} });
            }
          }
          temp = temp[key];
        }

        Object.assign(obj, temp);
      }
      for (const o in obj) {
        if (!Number.isNaN(Number(o))) delete obj[o];
      }
      this.enums = obj;
    },
    getEnums(args: string[]) {
      // Можно же через filter...
      let obj = this.enums;
      for (const i in args) {
        obj = obj[args[i]] ? obj[args[i]] : '';
      }
      return obj;
    },
    getEnum(name: string) {
      const optionArray: string[] = name.split('.');
      let result: any = null;

      optionArray.forEach((el: any) => {
        result = this.enums[el] && result === null ? this.enums[el] : result[el];
      });

      return result;
    },
  },
});
