<template>
  <div
    v-if="open"
    class="modal-wrap flex justify-center items-center"
  >
    <div
      class="modal flex flex-col"
      :style="`width: ${width}; height: ${height}; background-color: ${background};`"
    >
      <div
        :class="{ hidden: hideHeaderLine }"
        class="header flex justify-between items-center"
      >
        <div
          v-if="title"
          class="title p-3"
        >
          <Head
            :title="title"
            :color="titleColor"
          />
        </div>
        <div v-else />
        <div class="close m-3 h-4 w-4 rounded-full bg-white">
          <Icon
            name="x"
            @click="onClick('cancel')"
          />
        </div>
      </div>
      <div
        class="content p-3 grow"
      >
        <slot />
      </div>
      <div
        v-if="isStandardButtons"
        class="footer flex justify-end gap-1 p-3"
      >
        <Button
          size="sm"
          :mods="['bg-gray']"
          @click="onClick('cancel')"
        >
          {{ buttonCancel || $t('button.cancel') }}
        </Button>
        <Button
          v-if="buttonText"
          size="sm"
          :disabled="okDisabled"
          :mods="['bg-secondary']"
          @click="onClick('ok')"
        >
          {{ buttonText }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import Head from '@/components/share/Head/Head.vue';
import Icon from '@/components/share/Icon/Icon.vue';
import Button from '@/components/form/Button/Button.vue';

const { t } = useI18n();

const props = defineProps(['hideHeaderLine', 'open', 'title', 'titleColor', 'buttonText', 'buttonCancel', 'isStandardButtons', 'okDisabled', 'width', 'height', 'background']);

const emit = defineEmits(['action']);

const onClick = (action: string) => {
  emit('action', action);
};
</script>

<style lang="scss" scoped>
.modal-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: auto;
  background-color: rgba(0, 0, 0, .5);

  .modal {
    width: 500px;
    position: relative;
    background: #ffffff;
    padding: 5px;
    border-radius: 5px;

    .header {
      .close {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }

  }
}</style>
