<template>
  <div class="flex gap-3 flex-wrap my-1">
    <div
      v-for="group in chips"
      :key="group.name"
      class="flex items-center gap-3"
    >
      <div v-if="group.label">
        {{ group.label }}
      </div>
      <div
        v-for="chip in group.items"
        :key="chip.id"
      >
        <div
          class="flex items-center gap-1 px-3 py-2"
          :class="chip.mod ? chip.mod : chipDefault.mod"
        >
          <div class="text-xs">
            {{ chip.content }}
          </div>
          <div
            v-if="chip.isRemove"
            class="flex items-center bg-white rounded-full p-1 text-lg cursor-pointer"
            @click="onClickRemove(group, chip)"
          >
            <Icon
              name="x"
              class="h-3"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from '@/components/share/Icon/Icon.vue';

const props = defineProps(['chips']);
const emit = defineEmits(['action']);

const chipDefault = { mod: 'bg-gray-200 rounded-full' };

const onClickRemove = (group: any, chip: any) => {
  emit('action', { type: 'remove', group, chip });
};

</script>
