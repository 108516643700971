import { defineStore } from 'pinia';
// import { toRaw } from "vue"

export const useSearcher = defineStore('searcher', {
  state: () => ({
    value: [] as any,
    selectedProducts: [] as any,
    component: '',
    isShow: false,
    itemEdit: false,
    page: null,
  }),
  actions: {
    addItem(item: object) {
      this.selectedProducts.push(item); // toRaw()
    },
    removeItem(item: any) {
      // const product = toRaw(item)
      const index = this.selectedProducts.findIndex((e: any) => e.id == item.id);

      this.selectedProducts.splice(index, 1);
    },
    show() {
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    toggle() {
      this.isShow = !this.isShow;
    },
    remove() {
      this.value = [];
      this.component = '';
      this.isShow = false;
    },
  },
  getters: { getSelectedProducts: (state) => state.selectedProducts },
});
