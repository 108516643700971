<template>
  <div class="flex gap-2 text-xs">
    <div
      v-if="meta.current_page !== 1"
      class="flex items-center justify-center min-w-fit w-8 h-8 cursor-pointer rounded bg-slate-200"
      @click="onChangePage(1)"
    >
      <Icon
        name="double-arrow"
        class="rotate180 w-4 h-4"
      />
    </div>
    <div
      v-if="links.prev"
      class="flex items-center justify-center min-w-fit w-8 h-8 cursor-pointer rounded bg-slate-200"
      @click="onChangePage(getPage(links.prev))"
    >
      <Icon
        name="caret"
        class="rotate90 w-4 h-4"
      />
    </div>
    <template
      v-for="page of pages"
      :key="page"
    >
      <div
        class="flex items-center justify-center min-w-fit w-8 h-8 cursor-pointer rounded"
        :class="page === meta.current_page ? 'bg-cyan-500 text-white' : 'bg-slate-200'"
        @click="onChangePage(page)"
      >
        {{ page }}
      </div>
    </template>
    <div
      v-if="links.next"
      class="flex items-center justify-center min-w-fit w-8 h-8 cursor-pointer rounded bg-slate-200"
      @click="onChangePage(getPage(links.next))"
    >
      <Icon
        name="caret"
        class="rotate-90 w-4 h-4"
      />
    </div>
    <div
      v-if="meta.current_page !== meta.last_page"
      class="flex items-center justify-center min-w-fit w-8 h-8 cursor-pointer rounded bg-slate-200"
      @click="onChangePage(meta.last_page)"
    >
      <Icon
        name="double-arrow"
        class="w-4 h-4"
      />
    </div>
    <Field
      v-if="isPageInput"
      v-model="meta.current_page"
      type="number"
      size="sm"
      :mods="['b-gray']"
      :edit="true"
      :required="false"
      @change="onChangePage(Number($event.target.value))"
    />
  </div>
</template>

<script setup lang="ts">
import {
  ref,
  watch,
  onMounted,
} from 'vue';

import Icon from '@/components/share/Icon/Icon.vue';
import Field from '@/components/form/Field/Field.vue';

const props = defineProps({
  meta: {
    type: Object,
    required: true,
  } as any,
  links: {
    type: Object,
    required: true,
  } as any,
  isPageInput: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['changePage']);

const pages: any = ref([]);

watch(() => props.meta, () => {
  setPagination();
}, { deep: true });

const setPagination = () => {
  pages.value = [];
  let start = 1;
  let end = props.meta.last_page;
  if (props.meta.last_page > 9) {
    if (props.meta.current_page > 4) {
      start = props.meta.current_page - 4;
    }
    if (props.meta.last_page - props.meta.current_page > 4) {
      end = props.meta.current_page + 4;
    }
    if (props.meta.last_page - props.meta.current_page <= 4) {
      start = props.meta.last_page - 8;
      end = props.meta.last_page;
    }
    if (props.meta.current_page <= 4) {
      end = 9;
    }
  }

  for (let page = start; page <= end; page++) {
    pages.value.push(page);
  }
};

const onChangePage = (page: number) => {
  emit('changePage', page);
};

const getPage = (url: string) => Number(new URL(url).searchParams.get('page'));

onMounted(() => {
  setPagination();
});

</script>
