import { defineStore } from 'pinia';

export const useItemSearcher = defineStore('itemSearcher', {
  persist: true,
  state: (): {
    isShow: boolean,
    items: object[],
    route: string
  } => ({
    isShow: false as boolean,
    items: [],
    route: '',
  }),
  actions: {
    toggleVisibility(route = ''): void {
      if (route) {
        this.route = route;
      }
      this.isShow = !this.isShow;
    },

    remove(): void {
      this.isShow = false;
    },

    removeAll() {
      this.items = [];
    },

    addItems(items: []) {
      this.items = [...new Map([...this.items, ...items].map((item: any) => [item.id, item])).values()];
    },

    removeItem(item: any) {
      this.items = this.items.filter((_: any) => _.id !== item.id);
    },
  },
});
