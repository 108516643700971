import { DateTime } from 'luxon';

export const toDateFormatFull = (date: string) => DateTime.fromISO(date).setLocale('ru').toLocaleString(DateTime.DATE_FULL);

export const toDateFormatTime = (date: string) => {
  const dt: number = new Date(date).getTime();
  return DateTime.fromMillis(dt).toFormat('dd.MM.yyyy HH:mm', { locale: 'ru' });
};
// Дата для отправки на бєк с таймзоной Москвы
export const toDateFormatMoscowTimeZone = (date: string) => {
  const dt: number = new Date(date).getTime();
  return date ? DateTime.fromMillis(dt).setZone('Europe/Moscow').toISO() : date;
};
// Дата для пользователя в его тайм зоне
export const toDateFormatLocalTimeZone = (date: string) => {
  const dt: number = new Date(date).getTime();
  return date ? DateTime.fromMillis(dt).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone).toFormat('dd.MM.yyyy HH:mm', { locale: 'ru' }) : date;
};
// Дата для календаря в тайм зоне пользвателя но в ISO формате
export const toDateFormatLocalTimeZoneISO = (date: string) => {
  const dt: number = new Date(date).getTime();
  return date ? DateTime.fromMillis(dt).setZone(Intl.DateTimeFormat().resolvedOptions().timeZone).toISO() : date;
};

export const toTimeFormat = (date: string) => {
  const dt: number = new Date(date).getTime();
  return DateTime.fromMillis(dt).toFormat('HH:mm', { locale: 'ru' });
};

export const toDateFormatTimeUS = (date: string | number) => {
  const dt: number = new Date(date).getTime();
  return DateTime.fromMillis(dt).toFormat('yyyy-MM-dd HH:mm:ss', { locale: 'ru' });
};

export const toDateFormatRU = (date: string | number) => {
  const dt: number = new Date(date).getTime();
  return DateTime.fromMillis(dt).toFormat('dd.MM.yyyy', { locale: 'ru' });
};

export const toDateFormat = (date: string | number) => {
  const dt: number = new Date(date).getTime();
  return DateTime.fromMillis(dt).toFormat('yyyy-MM-dd', { locale: 'ru' });
};

export const compareDates = (date1: string, date2: string): boolean => Date.parse(date1) === Date.parse(date2);

export const diffMiliseconds = (date1: string, date2: string): number => Date.parse(date1) - Date.parse(date2);

export const toMonthFormat = (date: string | number) => {
  const dt: number = new Date(date).getTime();
  return DateTime.fromMillis(dt).toFormat('yyyy-MM', { locale: 'ru' });
};

export const toLastDayOfMonthFormat = (date: string | number) => {
  const dt: number = new Date(date).getTime();
  return DateTime.fromMillis(dt).endOf('month').toFormat('yyyy-MM-dd', { locale: 'ru' });
};
