import 'vuefinder/dist/style.css';
import '@vuepic/vue-datepicker/dist/main.css';
import '@/styles/app.scss';
import { createApp } from 'vue';
import VueFinder from 'vuefinder/dist/vuefinder';
import vClickOutside from 'click-outside-vue3';
import VueDatePicker from '@vuepic/vue-datepicker';
import ScanbotSDK from 'scanbot-web-sdk';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { useSentry } from '@/App/application/useSentry';
import router from '@/router';
import { i18n } from '@/i18n';
import store from '@/store';
import AppMainLayout from '@/components/layouts/AppMainLayout/index.vue';
import App from '@/App/index.vue';
import Popper from "vue3-popper";
const { sentryInit } = useSentry();
const app = createApp(App);
const scanbotSDK = ScanbotSDK.initialize({ licenseKey: import.meta.env.VITE_SCANBOT_LICENSE_KEY ?? '' });

sentryInit(app);
store.use(piniaPluginPersistedstate);

app
  .component('AppMainLayout', AppMainLayout)
  // TODO: Глобально установлен, но где-то используется локально через импорт.
  //       Оставить один вариант
  .component('VueDatePicker', VueDatePicker)
  .component("Popper", Popper)
  .provide('scanbotSDK', scanbotSDK)
  .use(router)
  .use(i18n)
  .use(VueFinder)
  .use(store)
  .use(vClickOutside)
  .mount('#app');
