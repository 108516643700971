import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiWishlistMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.wishlist.list,
    properties: false,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.wishlist.list,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.wishlist.item,
  },
};

const apiWishlistServices = ApiGateway.createServices(apiWishlistMap);

export default apiWishlistServices;
