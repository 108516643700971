<template>
  <div class="font-bold size-2 mb-2">
    {{ title }}
  </div>
  <div class="h-full p-2 border-2 border-gray-200 rounded-md">
    <Field
      ch="w-[120px] min-w-[120px]"
      type="text"
      size="sm"
      :mods="['col', 'b-gray']"
      :min="1"
      :max="100"
      :edit="true"
      :required="false"
      iconRight="magnifying-glass"
      placeholder="Скидка, акция..."
      :modelValue="searchFieldValue"
      @input="updateSearch($event)"
    />
    <div class="flex gap-2 pb-2 mt-2 mb-2 border-b border-gray-200">
      <Button
        size="xs"
        :mods="['bg-secondary']"
        @click="selectAll"
      >
        Выделить все
      </Button>
      <Button
        size="xs"
        :mods="['bg-gray']"
        @click="deselectAll"
      >
        Снять все
      </Button>
    </div>
    <div class="overflow-x-auto h-full" :style="{ height: tagsListBlockHeightComputed + 'px' }">
      <template
        v-for="tag in tagsList"
        :key="tag.id"
      >
        <div
          class="my-2 "
        >
          <div
            class="flex gap-2 items-center"
            @click="checkTagHandler(tag)"
          >
            <div
              :for="columnType + '-check-box-' + tag.id"
              class="custom-checkbox cursor-pointer flex gap-2 items-center"
            >
              <input
                :id="columnType + '-check-box-' + tag.id"
                type="checkbox"
                :checked="tag.selected"
                class="cursor-pointer"
              />
              {{ tag.name }}
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

import { useNotifications } from '@/store/notifications';
import { useProducts } from '@/store/products';
import Field from '@/components/form/Field/Field.vue';
import Button from '@/components/form/Button/Button.vue';

const { t } = useI18n();
const productsStore = useProducts();
const notifications = useNotifications();
const router = useRouter();

const props = defineProps({
  tagsList: {
    type: Array,
    default: () => [],
  },
  columnType: {
    type: String,
    default: 'attach',
  },
  columnSide: {
    type: String,
    default: 'right',
  },
  title: {
    type: String,
    default: 'List',
  },
  tagsListBlockHeight: {
    type: Number || NaN,
    default: 200,
  },
  searchFields: {
    type: Object,
    default: '',
  },
});

const searchFieldValue = computed(() => {
  if (props.columnType === 'attach' && props.columnSide === 'left') return props.searchFields.tags;
  if (props.columnType === 'attach' && props.columnSide === 'right') return props.searchFields.tagsToAttach;
  if (props.columnType === 'detach' && props.columnSide === 'right') return props.searchFields.tagsToDetach;
  if (props.columnType === 'detach' && props.columnSide === 'left') return props.searchFields.itemsTags;
});

const updateSearch = (event:any) => {
  const val = {
    columnType: props.columnType,
    columnSide: props.columnSide,
    value: event.target.value,
  };
  emit('action', { type: 'updateSearchInput', value: val });
};

const emit = defineEmits(['action']);

const checkTagHandler = (tag:any) => {
  const val = {
    tag,
    columnType: props.columnType,
    columnSide: props.columnSide,
  };
  emit('action', { type: 'checkTag', value: val });
};

const selectAll = () => {
  const val = {
    columnType: props.columnType,
    columnSide: props.columnSide,
  };
  emit('action', { type: 'selectAll', value: val });
};

const deselectAll = () => {
  const val = {
    columnType: props.columnType,
    columnSide: props.columnSide,
  };
  emit('action', { type: 'deselectAll', value: val });
};

const tagsListBlockHeightComputed = computed(() => props.tagsListBlockHeight - 234);

</script>
