<template>
  <perfect-scrollbar>
    <div class="nav-aside" :class="{ 'hide': !navAsideIsShow }">
      <div class="nav-aside__company">
        <Logo class="nav-aside__logo" />
        <div class="nav-aside__slogan">
          clothes ERP SYSTEM ©️
        </div>
      </div>

      <Search class="nav-aside__search" />
      <div id="nav-aside-links" class="nav-aside__links" />
    </div>
  </perfect-scrollbar>
</template>
<script src="./NavAside.ts" lang="ts"></script>
<style src="./NavAside.scss" lang="scss"></style>
