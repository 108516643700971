import {
  ComputedRef,
  computed,
  ref,
} from 'vue';

import { EToggleState } from '@/constants';

export type TSetToggleStateAs = Record<EToggleState, (value?: unknown) => void>;

export type TUseToggleState = {
  isOff: ComputedRef<boolean>,
  isOn: ComputedRef<boolean>,
  isInitial: ComputedRef<boolean>,
  setToggleStateAs: TSetToggleStateAs,
};

// TODO: Написать тест
export const useToggleState = (initialState: unknown): TUseToggleState => {
  const state = ref<boolean>(Boolean(initialState));

  const setToggleState = (newState: boolean) => {
    state.value = newState;
  };

  const setToggleStateAs: TSetToggleStateAs = {
    on: () => {
      setToggleState(true);
    },
    off: () => {
      setToggleState(false);
    },
    booleanFrom: (newState: unknown) => {
      setToggleState(Boolean(newState));
    },
    inverted: () => {
      setToggleState(!state.value);
    },
    initial: () => {
      setToggleState(Boolean(initialState));
    },
  };

  const isOff = computed(() => state.value === false);
  const isOn = computed(() => state.value === true);
  const isInitial = computed(() => state.value === Boolean(initialState));

  return {
    setToggleStateAs,
    isOff,
    isOn,
    isInitial,
  };
};
