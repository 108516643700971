import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiCategoriesMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.categoriesV2.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.categoriesV2.item,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.categoriesV2.list,
  },
  updateItem: {
    method: ApiRequestMethod.PUT,
    route: ApiRoutes.categoriesV2.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.categories.item,
  },
};

const apiCategoriesServicesV2 = ApiGateway.createServices(apiCategoriesMap);

export default apiCategoriesServicesV2;
