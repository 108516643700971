import { onMounted } from 'vue';

import { i18n } from '@/i18n';
import { ELocale } from '@/constants';

const useI18n = () => {
  onMounted(() => {
    i18n.global.locale.value = ELocale.ru;
  });
};

export default useI18n;
