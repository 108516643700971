<template>
  <div class="relative flex gap-4 items-center border-l border-l-slate-300 h-7">
    <div class="absolute border-b border-b-slate-300 w-4" />
    <div
      class="ml-5 text-cyan-500 cursor-pointer"
      :class="{ 'font-bold': current.id === branch.id && current.type === branch.type }"
      @click="onClickDoc(name, branch.attributes.id)"
    >
      {{ docNames.get(name)?.label }} № {{ branch.attributes.id }}
    </div>
    <div>{{ toDateFormatTime(branch.attributes.created_at) }}</div>
  </div>
  <div v-if="branch.relationships">
    <div
      v-for="relationship of Object.keys(branch.relationships)"
      class="relative ml-7"
    >
      <div v-if="Array.isArray(branch.relationships[relationship])">
        <div
          v-for="item of branch.relationships[relationship]"
          :key="item.attributes.id"
        >
          <TreeBranch
            :name="relationship"
            :branch="item"
            :current="current"
            @actions="handlerBranchActions"
          />
        </div>
      </div>
      <div v-else>
        <TreeBranch
          v-if="branch.relationships[relationship]"
          :name="relationship"
          :branch="branch.relationships[relationship]"
          :current="current"
          @actions="handlerBranchActions"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

import { toDateFormatTime } from '@/utils/date/toDateFormat';

const router = useRouter();

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  branch: {
    type: Object,
    default: {},
  },
  current: {
    type: Object,
    default: {},
  },
});

const emit = defineEmits(['actions']);

const docNames = new Map([
  ['documentBuyerOrder', { page: 'BuyerOrder', label: 'Заказ покупателя' }],
  ['documentInternalOrderMovementProducts', { page: 'InternalOrder', label: 'Внутренний заказ' }],
  ['documentInternalOrderMovementProduct', { page: 'InternalOrder', label: 'Внутренний заказ' }],
  ['documentMovementProducts', { page: 'MovementProduct', label: 'Перемещение товара' }],
  ['documentExpenditureOrders', { page: 'ExpenditureOrder', label: 'Расходный ордер' }],
  ['documentReceiptOrders', { page: 'ReceiptOrder', label: 'Приходный ордер' }],
  ['documentExpenseInvoices', { page: 'ExpenseInvoice', label: 'Расходная накладная' }],
  ['documentExpenseInvoice', { page: 'ExpenseInvoice', label: 'Расходная накладная' }],
  ['documentCashChecks', { page: 'CashCheck', label: 'Чек ККМ' }],
  ['documentRecalculationProducts', { page: 'RecalculationProductsItem', label: 'Пересчет товаров' }],
  ['documentWriteOffGoods', { page: 'WriteOffGood', label: 'Списание товаров' }],
  ['documentPostingOffGoods', { page: 'PostingOfGood', label: 'Оприходование товаров' }],
  ['documentReturnInvoice', { page: 'ReturnInvoice', label: 'Возвратная накладная' }],
  ['returnDocumentCashChecks', { page: 'DocumentCashChecks', label: 'Чек возврата' }],
  ['documentReturnRequest', { page: 'ReturnRequestItem', label: 'Заявление на возврат' }],
  ['sourceDocumentCashCheck', { page: 'CashCheck', label: 'Чек ККМ источник' }],
  ['documentInventoryOfGoods', { page: 'InventoryItem', label: 'Инветаризация товаров' }],
  ['documentDeliveryOrders', { page: 'DeliveryOrder', label: 'Заказ на доставку' }],
  ['documentPaymentBuyerPaymentCards', { page: 'PaymentBuyerPaymentCard', label: 'Оплата от покупателя платежной картой' }],
]);

const handlerBranchActions = (event: any) => {
  switch (event.type) {
    case 'clickDoc':
      emit('actions', { type: 'clickDoc' });
      break;

    default:
      break;
  }
};

/**
 * Handler click document name
 *
 * @function
 * @param name
 * @param id
 */
const onClickDoc = (name: string, id: number) => {
  emit('actions', { type: 'clickDoc' });
  const page = docNames.get(name)?.page;

  if (page) router.push({ name: page, params: { id } });
};
</script>
