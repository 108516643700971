import { Ref, ref } from 'vue';

import menuKeys from '@/utils/menu/menuKeys';
import tt from '@/i18n/translateText';

export interface INavPrimaryLink {
  title: string,
  keyMenu: string,
  route: {
    name: string,
    params?: {}
    query?: {}
  },
  current: boolean
}

/**
 * Create Link
 *
 * @param link
 * @return  {INavPrimaryLink}
 */
const createLink = (link: INavPrimaryLink): INavPrimaryLink => link;

export const links: Ref = ref([
  createLink({
    title: tt('nav.primary.dashboard'),
    keyMenu: menuKeys.MAIN,
    route: { name: 'Main' },
    current: false,
  }),
  createLink({
    title: tt('nav.primary.directory'),
    keyMenu: menuKeys.DIR,
    route: { name: 'Directories' },
    current: false,
  }),
  createLink({
    title: tt('nav.primary.documents'),
    keyMenu: menuKeys.DOCS,
    route: { name: 'Docs' },
    current: false,
  }),
  createLink({
    title: tt('admin.title'),
    keyMenu: menuKeys.ADMIN,
    route: { name: 'Admin' },
    current: false,
  }),
  createLink({
    title: tt('report.title'),
    keyMenu: menuKeys.REPORT,
    route: { name: 'Report' },
    current: false,
  }),
  createLink({
    title: tt('tools.title'),
    keyMenu: menuKeys.TOOLS,
    route: { name: 'Tools' },
    current: false,
  }),
  createLink({
    title: tt('tasks.title.list'),
    keyMenu: menuKeys.TASKS,
    route: { name: 'Tasks' },
    current: false,
  }),
  createLink({
    title: tt('nav.primary.siteSettings'),
    keyMenu: menuKeys.SITE_SETTINGS,
    route: { name: 'SiteSettings' },
    current: false,
  }),
]);

/**
 * Find Current Menu
 *
 * @function
 * @param menuKey
 * @return voind
 */
export const findCurrentMenu = (menuKey: string): void => {
  const currentMenuItem = links.value.find((item: INavPrimaryLink): boolean => item.title === menuKey);

  if (currentMenuItem) {
    links.value.forEach((item: any) => item.current = false);
    currentMenuItem.current = true;
  }
};
