import {
  watch,
  defineComponent,
  ref,
  provide,
  Ref,
  computed,
  onMounted,
} from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

import { useProductSearcher } from '@/store/product-searcher';
import { useProducts } from '@/store/products';
import Button from '@/components/form/Button/Button.vue';
import Icon from '@/components/share/Icon/Icon.vue';
import Field from '@/components/form/Field/Field.vue';
import Items from '@/components/share/Items/Items.vue';
import { useScanner } from '@/store/scanner';

export default defineComponent({
  name: 'ProductSearcher',
  components: {
    Items,
    Field,
    Button,
    Icon,
  },
  setup(_, context) {
    const router = useRouter();
    const currentRoute: string = String(router.currentRoute.value.name);
    const productsSearcherStore: any = useProductSearcher();
    const productsStore: any = useProducts();
    const card: Ref = ref([]);
    const scanner = useScanner();
    const productInfo: Ref = ref(null);
    const selectedProducts: Ref = ref([]);
    const { t } = useI18n();
    const currentText: Ref = ref('');
    const blocksState: Ref = ref({
      card: false as boolean,
      productInfo: false as boolean,
      isShowModalRequire: false as boolean,
      currentText: '' as any,
    });
    const quantityTotal = computed(() => card.value.reduce((acc: number, cur: any) => acc + cur.count, 0));
    const sumTotal = computed(() => card.value.reduce((acc: number, cur: any) => acc + cur.price * cur.count, 0));

    /**
     * Close the component
     *
     * @function
     * @return void
     */
    const close = () => productsSearcherStore.toggleVisibility();

    /**
     * Toggle blocks: filter, products, card
     *
     * @function
     * @param name
     * @return void
     * */
    const toggleBlocks = (name: string): void => {
      if (name in blocksState.value) blocksState.value[name] = !blocksState.value[name];
      if (name === 'card') blocksState.value.productInfo = false;
      if (name === 'productInfo') blocksState.value.card = false;
    };

    /**
     * Add selected items to the card
     *
     * @function
     * @return void
     */
    const addToCard = (): void => {
      const selected = selectedProducts.value.map((item: any) => {
        const pItem = item.prices.find((p: any) => p.id === 3); // TODO почему так захардкожено p.id === 3?
        item.price = pItem ? pItem.price : '';
        return item;
      });

      selected.forEach((itemSelected: any) => {
        const found = card.value.find((item: any) => itemSelected.id === item.id);

        found
          ? found.count++
          : card.value.push(itemSelected);
      });

      selectedProducts.value = [];
      productsStore.setClearCheckedProducts(true);
      blocksState.value.card = true;
    };

    /**
     * Remove an item from the card
     *
     * @function
     * @param item
     * @return void
     */
    const removeItemCard = (item: any): void => {
      const index = card.value.map((el: any) => el.id).indexOf(item.id);

      card.value.splice(index, 1);
    };

    /**
     * Remove all the items from the card
     *
     * @function
     * @return void
     */
    const removeCard = (): void => {
      card.value = [];

      toggleBlocks('card');
    };

    const closeModalRequired = () => {
      blocksState.value.isShowModalRequired = false;
      currentText.value = '';
      addItemsCardToStore();
    };

    /**
     * Check Honest Mark
     *
     * @function
     * @return boolean
     */
    const honestMarkCheck = (): boolean => {

      if (
        currentRoute === 'CashierWorkplace'
          || currentRoute === 'MovementProduct'
          || currentRoute === 'LabelPrinting'
          || currentRoute === 'MovementProductsCreate'
          || currentRoute === 'RecalculationProductsItem'
          || currentRoute === 'RecalculationProductsCreate'
      ) {
        const requiredHonestCard = card.value.filter((item: any) => item?.relationships?.item?.attributes?.honest_mark_is_required);

        card.value = card.value.filter((item: any) => !item?.relationships?.item?.attributes?.honest_mark_is_required);

        if (requiredHonestCard.length) {
          const result = requiredHonestCard.map((obj:any) => obj?.item?.title).join(', ');

          blocksState.value.isShowModalRequired = true;
          blocksState.value.currentText = t('honestMarks.error.honestMarkChecking', { result });

          // return false
        }
      }
      return true;
    };

    /**
     * Add item to the current store
     *
     * @function
     * @return void
     */
    const addItemsCardToStore = (): void => {
      const honestMarkRequired = honestMarkCheck();

      if (honestMarkRequired) {
        card.value.forEach((item: any) => {
          productsStore.findPresence(currentRoute, item);
          item.isCreate = true;
          item.oldPrice = 0;
          item.show_old_price = 0;
          item.countInventory = 0;

          if (currentRoute === 'BuyerOrderCreate') {
            item.stock_warehouse_id = null;
            item.shipping_warehouse_id = null;
            item.delivery_method_id = null;
            item.delivery_company_id = null;
            item.delivery_address = null;
          }
          if (currentRoute === 'RecalculationProductsCreate' || currentRoute == 'RecalculationProductsItem') {
            item.countInventory = item?.relationships?.stocks.filter((item: any) => item.relationships.warehouse.id == scanner.warehouseId)[0]?.attributes?.total || 0;
          }
          const items = currentRoute === 'CashierWorkplace' ? productsStore.itemsCreate.CashierWorkplace : productsStore.products;

          const isIncludeBarcodes = items.some((itemValue: any) => itemValue.barcode == item.barcode || itemValue?.attributes?.barcode == item.barcode);
          if (isIncludeBarcodes) {
            for (let i = 0; i < items.length; i++) {
              if (items[i].barcode == item.barcode || items[i]?.attributes?.barcode == item.barcode) {
                if (productsStore.acceptanceMode) {
                  if (!items[i].receipt_quantity) {
                    items[i].receipt_quantity = 1;
                  } else {
                    items[i].receipt_quantity = items[i].receipt_quantity + 1;
                  }
                }
                items[i].count = items[i].count + 1;
                if (
                  currentRoute === 'RecalculationProductsCreate'
                  || currentRoute == 'RecalculationProductsItem'
                  || currentRoute == 'LabelPrinting'
                  || currentRoute == 'InventoryItem'
                ) {
                  items[i].countDiscrepancy = items[i].countInventory - items[i].count;
                }
              }
            }
          } else {
            productsStore.addItem(item);
          }
        });

        productsSearcherStore.toggleVisibility();
      }
    };

    const handlerItemsAction = (event: any) => {
      switch (event.type) {
        case 'selectProducts':
          selectedProducts.value = event.selectedProducts;
          break;
        case 'info':
          productInfo.value = event.item;
          break;
      }
    };

    provide('productInfoShow', productInfo);

    onMounted(() => {
      if (currentRoute === 'CashierWorkplace') {
        productsStore.addKeyToItemsCreate(currentRoute);
      }
    });

    watch(() => productInfo.value, (val) => {
      blocksState.value.card = false;
      blocksState.value.productInfo = true;
    });

    return {
      card,
      blocksState,
      productInfo,
      selectedProducts,
      quantityTotal,
      sumTotal,
      close,
      closeModalRequired,
      addToCard,
      removeCard,
      toggleBlocks,
      removeItemCard,
      addItemsCardToStore,
      handlerItemsAction,
    };
  },
});
