import { App } from 'vue';
import * as Sentry from '@sentry/vue';

import { ESentryEnvironment } from '@/constants';

export const useSentry = () => {
  const sentryDsn = import.meta.env.VITE_SENTRY_DSN || null;
  const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT || null;
  const replaysSessionSampleRate = import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || null;
  const replaysOnErrorSampleRate = import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || null;
  const tracesSampleRate = import.meta.env.VITE_SENTRY_TRACERS_SAMPLE_RATE || null;

  const sentryInit = (app: App) => {
    if (import.meta.env.VITE_SENTRY_ENABLED === 'true' && sentryDsn) {
      switch (environment) {
        case ESentryEnvironment.production:
          Sentry.init({
            app,
            logErrors: true,
            dsn: sentryDsn,
            environment,
            tracesSampleRate: Number(tracesSampleRate),
            replaysSessionSampleRate: Number(replaysSessionSampleRate),
            replaysOnErrorSampleRate: Number(replaysOnErrorSampleRate),
          });
          break;
        // TODO: Добавить staging в sentry
        case ESentryEnvironment.staging:
          Sentry.init({
            app,
            logErrors: true,
            dsn: sentryDsn,
            environment,
            tracesSampleRate: Number(tracesSampleRate),
          });
          break;
        default:
          break;
      }
    }
  };

  return { sentryInit };
};
