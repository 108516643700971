import { defineStore } from 'pinia';

import { useNotifications } from '@/store/notifications';
import apiWishlistServices from '@/utils/api/services/apiWishlistServices';
import apiProductsServices from '@/utils/api/services/apiProductsServices';

export const useWishlist = defineStore('wishlist', {
  state: () => ({
    items: [] as any,
    isUpdated: false as boolean,
  }),
  actions: {
    async setWishlist(): Promise<void> {
      const notifications: any = useNotifications();
      const { data, status } = await apiWishlistServices.getList();

      if (status === 200) {
        const productsTemp: any = [];

        for await (const item of data) {
          const { data: product } = await apiProductsServices.getItem({ id: item.model_id }, { params: { relationships: 'wishlistCurrentUser;size' } });

          productsTemp.push(product);
        }

        this.items = productsTemp;
        this.isUpdated = false;
      } else {
        notifications.error('Ошибка', 'Ошибка загрузки избранного, попробуйте позже');
      }
    },
    async removeItemFromWishlist(id: any): Promise<void> {
      const notifications: any = useNotifications();
      const { status } = await apiWishlistServices.removeItem({ id });

      this.isUpdated = true;

      status === 200
        ? notifications.info('Удален', 'Товар успешно удален из избранного')
        : notifications.error('Error', 'Something went wrong');

      await this.setWishlist();
    },
  },
});
