<template>
  <div
    ref="tooltip"
    class="tooltip"
  >
    <div
      ref="triangle"
      class="triangle"
    />
    <div class="content">
      {{ text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Ref,
  ref,
  onMounted,
} from 'vue';

const tooltip: Ref = ref(null);
const triangle: Ref = ref(null);

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  side: {
    type: String,
    default: 'bottom',
  },
});

const setTooltipPosition = () => {
  const parentWidth = tooltip.value.parentElement.clientWidth;
  const parentHeight = tooltip.value.parentElement.clientHeight;
  switch (props.side) {
    case 'top':
      tooltip.value.style.bottom = `${parentHeight + 14}px`;
      tooltip.value.style.left = `${parentWidth / 2 - tooltip.value.clientWidth / 2}px`;
      triangle.value.style.borderTop = '10px solid #869096';
      triangle.value.style.top = '100%';
      break;
    case 'bottom':
      tooltip.value.style.top = `${parentHeight + 14}px`;
      tooltip.value.style.left = `${parentWidth / 2 - tooltip.value.clientWidth / 2}px`;
      triangle.value.style.borderBottom = '10px solid #869096';
      triangle.value.style.bottom = '100%';
      break;
    default:
      break;
  }
};

onMounted(() => {
  setTooltipPosition();
});

</script>

<style lang="scss" scoped>
.tooltip {
  position: absolute;
  width: max-content;
  border-radius: 7px;
  background-color: #869096;
  color: #fff;
  box-shadow: 0 0 3px 1px #aaa;
  opacity: 0;
  animation: ani 1s forwards;
  z-index: 999;

  .content {
    padding: 4px 12px;
  }

  .triangle {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
}

@keyframes ani {
  10% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
