import { defineComponent } from 'vue';
import { mapState } from 'pinia';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

import Logo from '@/components/share/Logo/Logo.vue';
import Search from '@/components/share/Search/Search.vue';
import { useNavAside } from '@/store/nav-aside';

export default defineComponent({
  name: 'NavAside',
  components: {
    Logo,
    Search,
    PerfectScrollbar,
  },
  computed: { ...mapState(useNavAside, ['navAsideIsShow']) },
});
