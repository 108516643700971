import { createI18n } from 'vue-i18n';

import { ELocale } from '@/constants';

import localeTextTranslationsRu from './translations/localeTextTranslationsRu';

export const i18n = createI18n({
  fallbackLocale: ELocale.ru,
  locale: ELocale.ru,
  messages: { ru: localeTextTranslationsRu },
  legacy: false,
});
