const apiRoutes = {
  PUT: '?_method=PUT',
  system: {
    getUserInfo: '/v2/me',
    me: '/user',
    login: '/v1/auth/login',
    initApp: '/initApp',
    menus: '/v2/me/menus/{key}',
    menusSync: '/v2/me/menus/{key}/user-menu-items/bilk-sync',
  },
  items: {
    list: '/items',
    item: '/items/{id}',
  },
  itemsV2: {
    list: '/v2/items',
    item: '/v2/items/{id}',
    tags: {
      attach: '/v2/items/{id}/attach/tags',
      detach: '/v2/items/{id}/detach/tags',
      sync: '/v2/items/{id}/sync/tags',
    },
    categories: { sync: '/v2/items/{id}/sync/categories' },
    properties: {
      create: '/v2/items/{id}/properties',
      update: '/v2/items/{id}/properties/{property}',
    },
  },
  products: {
    list: '/products',
    item: '/products/{id}',
    properties: { item: '/products/{id}/properties/{property}' },
    images: { item: '/products/{id}/images/{image}' },
  },
  productsV2: {
    list: '/v2/products',
    item: '/v2/products/{id}',
    tags: {
      attach: '/v2/products/{id}/attach/tags',
      detach: '/v2/products/{id}/detach/tags',
      sync: '/v2/products/{id}/sync/tags',
    },
    multicolors: {
      attach: '/v2/products/{id}/attach/multicolors',
      detach: '/v2/products/{id}/detach/multicolors',
      sync: '/v2/products/{id}/sync/multicolors',
    },
    barcodes: {
      create: '/v2/products/{id}/barcodes',
      delete: '/v2/products/{id}/barcodes/{barcodeId}',
    },
    images: {
      attach: '/v2/products/{id}/attach/images',
      detach: '/v2/products/{id}/detach/images',
      sync: '/v2/products/{id}/sync/images',
    },
    action: { xml: '/v2/products/{id}/actions?action=product-create-honest-marks-from-file-action' },
  },
  barcodes: {
    list: '/v2/barcodes',
    item: '/v2/barcodes/{id}',
  },
  warehouses: {
    list: '/v2/warehouses',
    item: '/v2/warehouses/{id}',
  },
  warehousesOld: {
    list: '/warehouses',
    item: '/warehouses/{id}',
  },
  storageCells: {
    list: '/v2/storage-cells',
    item: '/v2/storage-cells/{id}',
    types: {
      list: '/v2/storage-cell-types',
      item: '/v2/storage-cell-types/{id}',
    },
  },
  multicolors: {
    list: '/multicolors',
    item: '/multicolors/{id}',
    update: '/multicolors/{id}?_method=PUT',
  },
  multicolorsV2: {
    list: '/v2/multicolors',
    item: '/v2/multicolors/{id}',
    colors: {
      attach: '/v2/multicolors/{id}/attach/colors',
      detach: '/v2/multicolors/{id}/detach/colors',
    },
    codes: {
      list:'/v2/multicolor-codes',
      item:'/v2/multicolor-codes/{id}',
      sync: '/v2/multicolors/{id}/sync/codes',
      attach: '/v2/multicolors/{id}/attach/codes',
      detach: '/v2/multicolors/{id}/detach/codes',
    },
  },
  colors: {
    list: '/colors',
    item: '/colors/{id}',
  },
  colorsV2: {
    list: '/v2/colors',
    item: '/v2/colors/{id}',
  },
  files: {
    list: '/v2/files',
    item: '/v2/files/{id}',
  },
  tags: {
    list: '/v2/tags',
    item: '/v2/tags/{id}',
    filters: '/v2/tags/filters',
  },
  storageRows: {
    list: '/v2/storage-rows',
    item: '/v2/storage-rows/{id}',
    filters: '/v2/storage-rows/filters',
  },
  storageRooms: {
    list: '/v2/storage-rooms',
    item: '/v2/storage-rooms/{id}',
    filters: '/v2/storage-rooms/filters',
  },
  storageRacks: {
    list: '/v2/storage-racks',
    item: '/v2/storage-racks/{id}',
    filters: '/v2/storage-racks/filters',
  },
  storageShelves: {
    list: '/v2/storage-shelves',
    item: '/v2/storage-shelves/{id}',
    filters: '/v2/storage-shelves/filters',
  },
  storagePlaces: {
    list: '/v2/storage-places',
    item: '/v2/storage-places/{id}',
    filters: '/v2/storage-places/filters',
  },
  clients: {
    list: '/clients',
    item: '/clients/{id}',
  },
  clientsV2: {
    list: '/v2/clients',
    item: '/v2/clients/{id}',
    actions: {
      smsSend: '/v2/clients/{id}/actions?action=sms-code-send-action',
      smsCheck: '/v2/clients/{id}/actions?action=sms-code-check-action',
    },
  },
  categories: {
    list: '/categories',
    item: '/categories/{id}',
  },
  categoriesV2: {
    list: '/v2/categories',
    item: '/v2/categories/{id}',
  },
  contracts: {
    list: '/contracts',
    item: '/contracts/{id}',
  },
  contractsV2: {
    list: '/v2/contracts',
    item: '/v2/contracts/{id}',
  },
  supplier: {
    list: '/v2/document-contractor-orders',
    item: '/v2/document-contractor-orders/{id}',
    rows: {
      createRows: '/v2/document-contractor-order-rows',
      updateRows: '/v2/document-contractor-order-rows/{id}',
      deleteRows: '/v2/document-contractor-order-rows/{id}',
    },
    actions: {
      done: '/v2/document-contractor-orders/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-contractor-orders/{id}/actions?action=status-to-draft-action',
      pull: '/v2/document-contractor-orders/actions/pull-data-from-file-action',
    },
  },
  retailMoneyStorages: {
    list: '/v2/retail-money-storages',
    item: '/v2/retail-money-storages/{id}',
  },
  visitorCounterDevices: {
    list: '/v2/visitorcounter-devices',
    item: '/v2/visitorcounter-devices/{id}',
  },
  costItems: {
    list: '/v2/cost-items',
    item: '/v2/cost-items/{id}',
  },
  visitorCounterAccounts: {
    list: '/v2/visitorcounter-accounts',
    item: '/v2/visitorcounter-accounts/{id}',
  },
  moneyStorages: {
    list: '/v2/money-storages',
    item: '/v2/money-storages/{id}',
  },
  currencies: {
    list: '/v2/currencies',
    item: '/v2/currencies/{id}',
  },
  bankAccounts: {
    list: '/v2/bank-accounts',
    item: '/v2/bank-accounts/{id}',
  },
  documentCashChecks: {
    list: '/v2/document-cash-checks',
    item: '/v2/document-cash-checks/{id}',
    rows: {
      createRows: '/v2/document-cash-check-rows/bulk',
      updateRows: '/v2/document-cash-check-rows/bulk/update',
      deleteRows: '/v2/document-cash-check-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-cash-checks/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-cash-checks/{id}/actions?action=status-to-draft-action',
      cancel: '/v2/document-cash-checks/{id}/actions?action=status-to-cancel-action',
      sentToPos: '/v2/document-cash-checks/{id}/actions?action=sent-to-pos-action',
    },
    paymentMethods: {
      list: '/v2/document-cash-check-payment-methods',
      createItem: '/v2/document-cash-check-payment-methods',
      create: '/v2/document-cash-check-payment-methods/bulk',
    },
    certificate: { create: '/v2/document-cash-check-certificates' },
    useCertificate: {
      list: '/v2/document-cash-check-use-certificates',
      create: '/v2/document-cash-check-use-certificates',
      bulk: '/v2/document-cash-check-use-certificates/bulk',
      update: '/v2/document-cash-check-use-certificates/{id}',
    },
    bankTerminalRequests: {
      attach: '/v2/document-cash-checks/{id}/attach/bank-terminal-requests',
      detach: '/v2/document-cash-checks/{id}/detach/bank-terminal-requests',
      sync: '/v2/document-cash-checks/{id}/sync/bank-terminal-requests',
    }
  },
  documentCreditApplications: {
    list: '/v2/document-credit-applications',
    item: '/v2/document-credit-applications/{id}',
    rows: {
      create: '/v2/document-credit-application-rows/bulk',
      update: '/v2/document-credit-application-rows/bulk/update',
      delete: '/v2/document-credit-application-rows/{id}',
    },
    actions: {
      sendSms: '/v2/document-credit-applications/{id}/actions?action=send-sms-to-client-action',
      cancelApplication: '/v2/document-credit-applications/{id}/actions?action=set-cancel-status-in-bank-action',
      sendQueryToBankAction: '/v2/document-credit-applications/{id}/actions?action=send-query-to-bank-action',
      commitToBankAction: '/v2/document-credit-applications/{id}/actions?action=commit-to-bank-action',
    },
    statuses: {
      list: '/v2/document-credit-application-statuses',
      item: '/v2/document-credit-application-statuses/{id}',
    },
  },
  documentPaymentBuyerPaymentCards: {
    list: '/v2/document-payment-buyer-payment-cards',
    item: '/v2/document-payment-buyer-payment-cards/{id}',
  },
  stocks: {
    list: '/v2/stocks',
    item: '/v2/stocks/{id}',
  },
  priceTypes: {
    list: '/v2/price-types',
    item: '/v2/price-types/{id}',
  },
  structures: {
    list: '/structures',
    item: '/structures/{id}',
  },
  structuresV2: {
    list: '/v2/structures',
    item: '/v2/structures/{id}',
  },
  orderStatuses: { list: '/v2/order-statuses' },
  organizations: {
    list: '/organizations',
    item: '/organizations/{id}',
  },
  organizationsV2: {
    list: '/v2/organizations',
    item: '/v2/organizations/{id}',
  },
  employees: {
    list: '/v2/employees',
    item: '/v2/employees/{id}',
  },
  nds: {
    list: '/v2/nds-values',
    item: '/v2/nds-values/{id}',
  },
  sizes: {
    list: '/v2/property-sizes',
    item: '/v2/property-sizes/{id}',
  },
  documentPriceSettings: {
    list: '/documentPriceSettings',
    item: '/documentPriceSettings/{id}',
    rows: {
      list: '/documentPriceSettings/{id}/rows',
      item: '/documentPriceSettings/{id}/rows/{row}',
      attachFile: '/documentPriceSettingsRow/createFromFile/{id}',
    },
    createRows: '/documentPriceSettings/{id}/relationships/rows',
    updateRows: '/documentPriceSettings/{id}/relationships/rows',
    removeRows: '/documentPriceSettings/{id}/relationships/rows',
  },
  documentPriceSettingsV2: {
    list: '/v2/document-price-settings',
    item: '/v2/document-price-settings/{id}',
    rows: {
      getRows: '/v2/document-price-setting-rows',
      createRows: '/v2/document-price-setting-rows/bulk',
      updateRows: '/v2/document-price-setting-rows/bulk/update',
      deleteRows: '/v2/document-price-setting-rows/bulk/delete',
    },
    actions: {
      attachFile: '/v2/document-price-settings/actions/pull-data-from-file-action',
      attachFile2: '/v2/document-price-settings/actions/pull-data-from-file-action-v2',
      done: '/v2/document-price-settings/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-price-settings/{id}/actions?action=status-to-draft-action',
    },
  },
  documentReceiptInvoice: {
    list: '/v2/document-receipt-invoices',
    item: '/v2/document-receipt-invoices/{id}',
    rows: {
      createRows: '/v2/document-receipt-invoice-rows/bulk',
      updateRows: '/v2/document-receipt-invoice-rows/bulk/update',
      deleteRows: '/v2/document-receipt-invoice-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-receipt-invoices/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-receipt-invoices/{id}/actions?action=status-to-draft-action',
    },
  },
  documentBuyerOrder: {
    list: '/v2/document-buyer-orders',
    item: '/v2/document-buyer-orders/{id}',
    rows: {
      createRows: '/v2/document-buyer-order-rows/bulk',
      updateRows: '/v2/document-buyer-order-rows/bulk/update',
      deleteRows: '/v2/document-buyer-order-rows/bulk/delete',
      statusesRows: '/v2/document-buyer-order-row-statuses',
    },
    orderStatuses: '/v2/document-buyer-order-statuses',
    actions: {
      done: '/v2/document-buyer-orders/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-buyer-orders/{id}/actions?action=status-to-draft-action',
      grastin: '/v2/document-buyer-orders/{id}/actions?action=send-delivery-grastin-action',
      logsis: '/v2/document-buyer-orders/{id}/actions?action=send-delivery-logsis-action',
    },
  },
  documentDeliveryOrder: {
    list: '/v2/document-delivery-orders',
    item: '/v2/document-delivery-orders/{id}',
    status: '/v2/document-delivery-order-statuses',
    rows: {
      createRows: '/v2/document-delivery-order-rows/bulk',
      updateRows: '/v2/document-delivery-order-rows/bulk/update',
      deleteRows: '/v2/document-delivery-order-rows/bulk/delete',
    },
    actions: '/v2/document-delivery-orders/{id}/actions',
  },
  documentExpenseInvoice: {
    list: '/v2/document-expense-invoices',
    item: '/v2/document-expense-invoices/{id}',
    rows: {
      createRows: '/v2/document-expense-invoice-rows/bulk',
      updateRows: '/v2/document-expense-invoice-rows/bulk/update',
      deleteRows: '/v2/document-expense-invoice-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-expense-invoices/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-expense-invoices/{id}/actions?action=status-to-draft-action',
    },
  },
  documentReturnInvoice: {
    list: '/v2/document-return-invoices',
    item: '/v2/document-return-invoices/{id}',
    rows: {
      createRows: '/v2/document-return-invoice-rows/bulk',
      updateRows: '/v2/document-return-invoice-rows/bulk/update',
      deleteRows: '/v2/document-return-invoice-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-return-invoices/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-return-invoices/{id}/actions?action=status-to-draft-action',
    },
  },
  documentCashOrder: {
    list: '/documentCashOrder',
    item: '/documentCashOrder/{id}',
  },
  documentMovementsCashBetweenMoneyStorages: {
    list: '/v2/document-movements-cash-between-money-storages',
    item: '/v2/document-movements-cash-between-money-storages/{id}',
    actions: { done: '/v2/document-movements-cash-between-money-storages/{id}/actions?action=status-to-done-action' },
  },
  documentPaymentOrder: {
    list: '/documentPaymentOrder',
    item: '/documentPaymentOrder/{id}',
  },
  documentMovementProduct: {
    list: '/documentMovementProduct',
    item: '/documentMovementProduct/{id}',
    rows: {
      list: '/documentMovementProduct/{id}/rows',
      item: '/documentMovementProduct/{id}/rows/{row}',
    },
  },
  documentMovementProductV2: {
    list: '/v2/document-movement-products',
    item: '/v2/document-movement-products/{id}',
    orderedRows: {
      get: '/v2/document-movement-product-ordered-rows',
      create: '/v2/document-movement-product-ordered-rows/bulk',
      update: '/v2/document-movement-product-ordered-rows/bulk/update',
      delete: '/v2/document-movement-product-ordered-rows/bulk/delete',
    },
    shippedRows: {
      get: '/v2/document-movement-product-shipped-rows',
      create: '/v2/document-movement-product-shipped-rows/bulk',
      update: '/v2/document-movement-product-shipped-rows/bulk/update',
      delete: '/v2/document-movement-product-shipped-rows/bulk/delete',
    },
    acceptedRows: {
      get: '/v2/document-movement-product-accepted-rows',
      create: '/v2/document-movement-product-accepted-rows/bulk',
      update: '/v2/document-movement-product-accepted-rows/bulk/update',
      delete: '/v2/document-movement-product-accepted-rows/bulk/delete',
    },
    rows: {
      createRows: '/v2/document-movement-product-rows/bulk',
      updateRows: '/v2/document-movement-product-rows/bulk/update',
      deleteRows: '/v2/document-movement-product-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-movement-products/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-movement-products/{id}/actions?action=status-to-draft-action',
      checkHonestMarkOwner: '/v2/document-movement-products/{id}/actions?action=check-honest-mark-owner-action',
      pullData: '/v2/document-movement-products/actions/pull-data-from-file-honest-mark',
    },
  },
  documentPaymentByPaymentCardFromBuyer: {
    list: '/paymentByPaymentCardFromBuyer',
    item: '/paymentByPaymentCardFromBuyer/{id}',
  },
  documentReceiptOrder: {
    list: '/v2/document-receipt-orders',
    item: '/v2/document-receipt-orders/{id}',
    rows: {
      createRows: '/v2/document-receipt-order-rows/bulk',
      updateRows: '/v2/document-receipt-order-rows/bulk/update',
      deleteRows: '/v2/document-receipt-order-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-receipt-orders/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-receipt-orders/{id}/actions?action=status-to-draft-action',
    },
  },
  documentShiftClosuresTerminals: {
    list: '/v2/document-terminal-reconciliations',
    item: '/v2/document-terminal-reconciliations/{id}',
    rows: {
      createRows: '/v2/document-terminal-reconciliation-rows/bulk',
      updateRows: '/v2/document-terminal-reconciliation-rows/bulk/update',
      deleteRows: '/v2/document-terminal-reconciliation-rows/bulk/delete',
    },
  },
  documentShiftClosures: {
    list: '/v2/document-shift-closures',
    item: '/v2/document-shift-closures/{id}',
    rows: {
      createRows: '/v2/document-shift-closure-rows/bulk',
      updateRows: '/v2/document-shift-closure-rows/bulk/update',
      deleteRows: '/v2/document-shift-closure-rows/bulk/delete',
    },
    checkReport: '/v2/document-shift-closures/check-report',
    actions: {
      done: '/v2/document-shift-closures/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-shift-closures/{id}/actions?action=status-to-draft-action',
      sentToPos: '/v2/document-shift-closures/{id}/actions?action=sent-to-pos-action',
    },
  },
  documentExpenditureOrder: {
    list: '/v2/document-expenditure-orders',
    item: '/v2/document-expenditure-orders/{id}',
    rows: {
      createRows: '/v2/document-expenditure-order-rows/bulk',
      updateRows: '/v2/document-expenditure-order-rows/bulk/update',
      deleteRows: '/v2/document-expenditure-order-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-expenditure-orders/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-expenditure-orders/{id}/actions?action=status-to-draft-action',
    },
  },
  documentInventory: {
    list: '/v2/document-inventory-of-goods',
    item: '/v2/document-inventory-of-goods/{id}',
    rows: {
      getRows: '/v2/document-inventory-of-good-rows',
      createRows: '/v2/document-inventory-of-good-rows/bulk',
      updateRows: '/v2/document-inventory-of-good-rows/bulk/update',
      deleteRows: '/v2/document-inventory-of-good-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-inventory-of-goods/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-inventory-of-goods/{id}/actions?action=status-to-draft-action',
    },
    attachDocs: '/v2/document-inventory-of-goods/{id}/attach/document-recalculation-products',
    detachDocs: '/v2/document-inventory-of-goods/{id}/detach/document-recalculation-products',
  },
  documentConfirmBalanceProducts: {
    list: '/v2/document-confirm-balance-products',
    item: '/v2/document-confirm-balance-products/{id}',
    rows: {
      getRows: '/v2/document-confirm-balance-product-rows',
      createRows: '/v2/document-confirm-balance-product-rows/bulk',
      updateRows: '/v2/document-confirm-balance-product-rows/bulk/update',
      deleteRows: '/v2/document-confirm-balance-product-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-confirm-balance-products/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-confirm-balance-products/{id}/actions?action=status-to-draft-action',
    },
  },
  documentRecalculationProducts: {
    list: '/v2/document-recalculation-products',
    item: '/v2/document-recalculation-products/{id}',
    rows: {
      list: '/v2/document-recalculation-product-rows',
      createRows: '/v2/document-recalculation-product-rows/bulk',
      updateRows: '/v2/document-recalculation-product-rows/bulk/update',
      deleteRows: '/v2/document-recalculation-product-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-recalculation-products/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-recalculation-products/{id}/actions?action=status-to-draft-action',
    },
  },
  users: {
    list: '/v2/users',
    item: '/v2/users/{id}',
    filters: '/v2/users/filters',
    // item: '/v2/users/{id}',
  },
  usersOld: {
    list: '/users',
    item: '/users/{id}',
  },
  user: { item: '/v1/auth/me' },
  roles: {
    list: '/roles',
    item: '/roles/{id}',
  },
  rolesV2: {
    list: '/v2/roles',
    item: '/v2/roles/{id}',
  },
  wishlist: {
    list: '/wishlists',
    item: '/wishlists/{id}',
  },
  fileManager: { files: '/vuefinder' },
  pinCode: {
    generating: '/v1/auth/me/action/generate-pincode',
    login: '/v1/auth/login-by-pincode',
  },
  documentWriteOffGoods: {
    list: '/v2/document-write-off-goods',
    item: '/v2/document-write-off-goods/{id}',
    rows: {
      createRows: '/v2/document-write-off-good-rows/bulk',
      updateRows: '/v2/document-write-off-good-rows/bulk/update',
      deleteRows: '/v2/document-write-off-good-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-write-off-goods/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-write-off-goods/{id}/actions?action=status-to-draft-action',
    },
  },
  documentPostingOfGoods: {
    list: '/v2/document-posting-of-goods',
    item: '/v2/document-posting-of-goods/{id}',
    rows: {
      createRows: '/v2/document-posting-of-good-rows/bulk',
      updateRows: '/v2/document-posting-of-good-rows/bulk/update',
      deleteRows: '/v2/document-posting-of-good-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-posting-of-goods/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-posting-of-goods/{id}/actions?action=status-to-draft-action',
    },
  },
  documentMovingBetweenStorageCells: {
    list: '/v2/document-moving-between-storage-cells',
    item: '/v2/document-moving-between-storage-cells/{id}',
    rows: {
      createRows: '/v2/document-moving-between-storage-cell-rows/bulk',
      updateRows: '/v2/document-moving-between-storage-cell-rows/bulk/update',
      deleteRows: '/v2/document-moving-between-storage-cell-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-moving-between-storage-cells/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-moving-between-storage-cells/{id}/actions?action=status-to-draft-action',
    },
    typeOfOperation: '/v2/document-moving-between-storage-cells-type-operations',
  },
  documentReturnRequests: {
    list: '/v2/document-return-requests',
    item: '/v2/document-return-requests/{id}',
    rows: {
      createRows: '/v2/document-return-request-rows/bulk',
      updateRows: '/v2/document-return-request-rows/bulk/update',
      deleteRows: '/v2/document-return-request-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-return-requests/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-return-requests/{id}/actions?action=status-to-draft-action',
      refund: '/v2/document-return-requests/{id}/actions?action=yoo-kassa-create-refund-action',
      refundDolyame: '/v2/document-return-requests/{id}/actions?action=dolyame-create-refund-action',
      refundYooKassa: '/v2/document-return-requests/{id}/actions?action=yoo-kassa-create-refund-action',
      refundPodeli: '/v2/document-return-requests/{id}/actions?action=podeli-create-refund-action',
    },
    attach: { files: '/v2/document-return-requests/{id}/attach/files' },
    detach: { files: '/v2/document-return-requests/{id}/detach/files' },
    sync: { files: '/v2/document-return-requests/{id}/sync/files' },
    statuses: { list: '/v2/document-return-request-statuses' },
    reasonReturns: '/v2/document-return-request-return-reason-details',
  },
  internalOrder: {
    list: '/v2/document-internal-order-movement-products',
    item: '/v2/document-internal-order-movement-products/{id}',
    rows: {
      createRows: '/v2/document-internal-order-movement-product-rows/bulk',
      updateRows: '/v2/document-internal-order-movement-product-rows/bulk/update',
      deleteRows: '/v2/document-internal-order-movement-product-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-internal-order-movement-products/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-internal-order-movement-products/{id}/actions?action=status-to-draft-action',
      pull: '/v2/document-internal-order-movement-products/actions/pull-data-from-file-action',
      pullArticle: '/v2/document-internal-order-movement-products/actions/pull-data-from-file-article-and-multicolor',
    },
  },
  dispatchProducts: {
    list: '/v2/document-dispatch-products',
    item: '/v2/document-dispatch-products/{id}',
    rows: {
      createRows: '/v2/document-dispatch-product-rows/bulk',
      updateRows: '/v2/document-dispatch-product-rows/bulk/update',
      deleteRows: '/v2/document-dispatch-product-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-dispatch-products/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-dispatch-products/{id}/actions?action=status-to-draft-action',
    },
  },
  deliveryRoutes: {
    list: '/v2/delivery-routes',
    item: '/v2/delivery-routes/{id}',
    warehouses: {
      attach: '/v2/delivery-routes/{id}/attach/warehouses',
      detach: '/v2/delivery-routes/{id}/detach/warehouses',
      sync: '/v2/delivery-routes/{id}/sync/warehouses',
    },
  },
  deliveryCompanies: {
    list: '/v2/delivery-companies',
    item: '/v2/delivery-companies/{id}',
    deliveryMethods: {
      create: '/v2/delivery-company-delivery-methods/bulk',
      update: '/v2/delivery-company-delivery-methods/bulk/update',
      delete: '/v2/delivery-company-delivery-methods/bulk/delete',
    },
    paymentMethods: {
      create: '/v2/delivery-company-payment-methods/bulk',
      update: '/v2/delivery-company-payment-methods/bulk/update',
      delete: '/v2/delivery-company-payment-methods/bulk/delete',
    },
    filters: '/v2/delivery-companies/filters',
  },
  enums: { list: '/v2/enums' },
  tasks: {
    list: '/v2/tasks',
    item: '/v2/tasks/{id}',
  },
  routeSheets: {
    list: '/v2/document-route-sheets',
    item: '/v2/document-route-sheets/{id}',
    rows: {
      createRows: '/v2/document-route-sheet-rows/bulk',
      updateRows: '/v2/document-route-sheet-rows/{id}',
      deleteRows: '/v2/document-route-sheet-rows/{id}',
    },
    actions: {
      done: '/v2/document-route-sheets/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-route-sheets/{id}/actions?action=status-to-draft-action',
    },
  },
  constants: {
    list: '/v2/constants',
    item: '/v2/constants/{id}',
  },
  documentEdit: {
    getList: '/v2/document-editings',
    check: '/v2/document-editings/check-lock-document',
    unlock: '/v2/document-editings/unlock-document',
    lock: '/v2/document-editings/lock-document',
    getDocTypes: '/v2/document-editings/get-documents-types',
  },
  modelEdit: {
    getList: '/v2/model-editings',
    check: '/v2/model-editings/check-lock',
    unlock: '/v2/model-editings/unlock',
    lock: '/v2/model-editings/lock',
    getDocTypes: '/v2/model-editings/get-types',
  },
  atol: {
    addTask: { reportX: '/v2/atol/{id}/add-task/reportX' },
    checkTaskStatus: '/v2/atol/{id}/check-task-status-to-queue',
    checkDeviceStatus: '/v2/atol/{id}/check-device-status',
  },
  siteSettings: {
    pages: {
      getList: '/page-settings',
      getItem: '/page-settings/{slug}',
      update: '/page-settings/{slug}',
      uploadBanner: '/page-settings/banners',
      removeBanner: '/page-settings/banners/{id}',
    },
  },
  shopCategoriesV2: {
    list: '/v2/shop-categories',
    item: '/v2/shop-categories/{id}',
    categories: {
      attach: '/v2/shop-categories/{id}/attach/categories',
      detach: '/v2/shop-categories/{id}/detach/categories',
    },
    items: {
      attach: '/v2/shop-categories/{id}/attach/items',
      detach: '/v2/shop-categories/{id}/detach/items',
      sort: '/v2/shop-categories/{id}/items/sort',
    },
  },
  deviceFiscalRecorders: {
    list: '/v2/device-fiscal-recorders',
    item: '/v2/device-fiscal-recorders/{id}',
    actions: '/v2/device-fiscal-recorders/{id}/actions',
  },
  measurements: {
    list: '/v2/measurements',
    item: '/v2/measurements/{id}',
    updateBulk: '/v2/measurements/bulk/update',
  },
  measurementProductValues: {
    list: '/v2/measurement-product-values',
    item: '/v2/measurement-product-values/{id}',
    updateBulk: '/v2/measurement-product-values/bulk/update',
    createBulk: '/v2/measurement-product-values/bulk',
  },
  documentCashOrders: {
    list: '/v2/document-cash-orders',
    item: '/v2/document-cash-orders/{id}',
    actions: { done: '/v2/document-cash-orders/{id}/actions?action=status-to-done-action' },
  },
  documentSalesPlan: {
    list: '/v2/document-sales-plans',
    item: '/v2/document-sales-plans/{id}',
    rows: {
      getRows: '/v2/document-sales-plan-rows',
      createRows: '/v2/document-sales-plan-rows/bulk',
      updateRows: '/v2/document-sales-plan-rows/bulk/update',
      deleteRows: '/v2/document-sales-plan-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-sales-plans/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-sales-plans/{id}/actions?action=status-to-draft-action',
    },
  },
  acquirers: {
    list: '/v2/acquirers',
    item: '/v2/acquirers/{id}',
  },
  promocodes: {
    list: '/v2/promo-codes',
    item: '/v2/promo-codes/{id}',
  },
  certificates: {
    list: '/v2/certificates',
    item: '/v2/certificates/{id}',
    actions: {
      sendEmail: '/v2/certificates/{id}/actions?action=send-email-action',
      generatePdf: '/v2/certificates/{id}/actions?action=generate-pdf-file-action',
    },
  },
  banks: {
    list: '/v2/banks',
    item: '/v2/banks/{id}',
  },
  countries: {
    list: '/v2/countries',
    item: '/v2/countries/{id}',
  },
  discounts: {
    list: '/v2/discounts',
    item: '/v2/discounts/{id}',
  },
  reasonReturns: {
    list: '/v2/reason-returns',
    item: '/v2/reason-returns/{id}',
  },
  edmOperators: {
    list: '/v2/edm-operators',
    item: '/v2/edm-operators/{id}',
  },
  coupons: {
    list: '/v2/coupons',
    item: '/v2/coupons/{id}',
  },
  honestMarks: {
    list: '/v2/honest-marks',
    item: '/v2/honest-marks/{id}',
    actions: { cisesSearchByOrderIds: '/v2/honest-marks/actions/cises-search-by-order-ids' },
  },
  honestMarksActuals: {
    list: '/v2/honest-marks-actuals',
    item: '/v2/honest-marks-actuals/{id}',
  },
  itemNames: {
    list: '/v2/item-names',
    item: '/v2/item-names/{id}',
    attachMeasurements: '/v2/item-names/{id}/attach/measurements',
    detachMeasurements: '/v2/item-names/{id}/detach/measurements',
    syncMeasurements: '/v2/item-names/{id}/sync/measurements',
    rows: {
      list: '/v2/item-name-rows',
      item: '/v2/item-name-rows/{id}',
    },
  },
  itemTypes: {
    list: '/v2/item-types',
    item: '/v2/item-types/{id}',
  },
  itemMulticolorTitles: {
    list: '/v2/item-multicolor-titles',
    item: '/v2/item-multicolor-titles/{id}',
  },
  menu: {
    list: '/v2/menus',
    item: '/v2/menus/{id}',
    attachGroups: '/v2/menus/{id}/attach/groups',
    syncMenuItems: '/v2/menus/{id}/sync/items',
  },
  menuGroups: {
    list: '/v2/menu-groups',
    item: '/v2/menu-groups/{id}',
  },
  menuItems: {
    list: '/v2/menu-items',
    item: '/v2/menu-items/{id}',
  },
  sms: { send: '/v2/send-sms' },
  reports: {
    sales: '/v2/reports/count-sales-and-amount-sales-for-month-in-the-structure',
    cash: '/v2/reports/structure-money-storage-balances',
    visit: '/v2/reports/visits-for-structures',
    stockItem: '/v2/reports/stock-for-item-report',
    stockProduct: '/v2/reports/stock-for-product-report',
  },
  plans: {
    list: '/v2/plans',
    item: '/v2/plans/{id}',
  },
  dolyameAccounts: {
    list: '/v2/dolyame-accounts',
    item: '/v2/dolyame-accounts/{id}',
  },
  podeliAccounts: {
    list: '/v2/podeli-accounts',
    item: '/v2/podeli-accounts/{id}',
  },
  instructions: {
    list: '/v2/instructions/',
    item: '/v2/instructions/{id}',
  },
  shoppingCarts: {
    list: '/v2/shopping-carts',
    item: '/v2/shopping-carts/{id}',
    rows: { createRows: '/v2/shopping-cart-cells/bulk' },
    actions: { clearShoppingCart: '/v2/shopping-carts/{id}/actions?action=shopping-cart-clear-action' },
  },
  documentSaleByDeliveryCompanies: {
    list: '/v2/document-sale-by-delivery-companies',
    item: '/v2/document-sale-by-delivery-companies/{id}',
    rows: {
      createRows: '/v2/document-sale-by-delivery-company-rows/bulk',
      updateRows: '/v2/document-sale-by-delivery-company-rows/bulk/update',
      deleteRows: '/v2/document-sale-by-delivery-company-rows/bulk/delete',
    },
    actions: {
      //
    },
    filters: {
      //
    },
  },
  documentSendingHonestMarksFactories: {
    list: '/v2/document-sending-honest-marks-to-the-factories',
    item: '/v2/document-sending-honest-marks-to-the-factories/{id}',
    rows: {
      createRows: '/v2/document-sending-honest-marks-to-the-factory-rows/bulk',
      updateRows: '/v2/document-sending-honest-marks-to-the-factory-rows/bulk/update',
      deleteRows: '/v2/document-sending-honest-marks-to-the-factory-rows/bulk/delete',
    },
    actions: {
      done: '/v2/document-sending-honest-marks-to-the-factories/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-sending-honest-marks-to-the-factories/{id}/actions?action=status-to-draft-action',
    },
    filters: {
      //
    },
  },
  subscribers: {
    list: '/v2/subscribers',
    item: '/v2/subscribers/{id}',
  },
  subscriberGroups: {
    list: '/v2/subscriber-groups',
    item: '/v2/subscriber-groups/{id}',
    attachSubscribers: '/v2/subscriber-groups/{id}/attach/subscriber',
    syncSubscribers: '/v2/subscriber-groups/{id}/sync/subscriber',
  },
  mailings: {
    list: '/v2/mailings',
    item: '/v2/mailings/{id}',
    attachSubscriberGroups: '/v2/mailings/{id}/attach/subscriber-group',
    detachSubscriberGroups: '/v2/mailings/{id}/detach/subscriber-group',
  },
  addresses: {
    list: '/v2/addresses',
    item: '/v2/addresses/{id}',
  },
  bankTerminals: {
    list: '/v2/bank-terminals',
    item: '/v2/bank-terminals/{id}',
    status: '/v2/bank-terminal-statuses',
    actions: '/v2/bank-terminals/{id}/actions',
  },
  bankTerminalRequests: {
    list: '/v2/bank-terminal-requests',
    item: '/v2/bank-terminal-requests/{id}',
  },
  bankTerminalResponses: {
    list: '/v2/bank-terminals-responses',
    item: '/v2/bank-terminals-responses/{id}',
  },
  getters: { list: '/v2/getters/certificate-documents' },
  documentCzkEmissionOrders: {
    list: '/v2/document-czk-emission-orders',
    item: '/v2/document-czk-emission-orders/{id}',
    docStatuses: '/v2/document-czk-emission-order-statuses',
    rows: {
      createRows: '/v2/document-czk-emission-order-rows/bulk',
      updateRows: '/v2/document-czk-emission-order-rows/bulk/update',
      deleteRows: '/v2/document-czk-emission-order-rows/bulk/delete',
      statuses: '/v2/document-czk-emission-order-row-statuses',
    },
    actions: {
      done: '/v2/document-czk-emission-orders/{id}/actions?action=status-to-done-action',
      draft: '/v2/document-czk-emission-orders/{id}/actions?action=status-to-draft-action',
    },
    filters: {
      //
    },
  },
  materials: {
    list: '/v2/materials',
    item: '/v2/materials/{id}',
  },
  compositions: {
    list: '/v2/product-compositions',
    item: '/v2/product-compositions/{id}',
    sync: '/v2/product-compositions/{id}/sync/materials',
  },
  gtins: {
    list: '/v2/gtins',
    item: '/v2/gtins/{id}',
    actions: { downloadByTruApi: '/v2/gtins/actions/download-by-true-api' },
  },
  deliveryCompanyDalliAccounts: {
    list: '/v2/delivery-company-dalli-accounts',
    item: '/v2/delivery-company-dalli-accounts/{id}',
  },
  documentPuttingIntoCirculations: {
    list: '/v2/document-putting-into-circulation-inrtoduce-goods',
    rows: { createRows: '/v2/document-putting-into-circulation-rows/bulk' },
  },
};

export default apiRoutes;
