import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiStocksMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.items.list,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.items.item,
  },
};

const apiItemsServices = ApiGateway.createServices(apiStocksMap);

export default apiItemsServices;
