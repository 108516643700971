<template>
  <div
    class="dynamic-navigation-tabs"
    :class="{ 'dynamic-navigation-tabs_empty': !hasBranchedTabs }"
  >
    <div class="dynamic-navigation-tabs__holder">
      <div
        ref="dynamicNavigationTabsLineRef"
        class="dynamic-navigation-tabs__line"
      >
        <div
          v-for="tab in tabsBranch"
          :key="tab.title"
          :ref="(element) => setTabElementRef(element as HTMLDivElement, tab.title)"
          class="dynamic-navigation-tabs__tab-holder"
          :class="{ 'dynamic-navigation-tabs__tab-holder_active': tab === activeTab }"
          @click="routeTo(tab)"
        >
          <div class="dynamic-navigation-tabs__tab" :title="tab.title">
            {{ tab.title }}
          </div>
          <!-- TODO: Нужны кнопки-иконки -->
          <div
            class="dynamic-navigation-tabs__tab-close"
            @click.prevent.stop="removeTabFromBranch(tab)"
          >
            <Icon name="x" />
          </div>
        </div>
      </div>

      <div
        v-click-outside="setTabsStashToggleStateAs.off"
        class="dynamic-navigation-tabs__assets"
        :class="{ 'dynamic-navigation-tabs__assets_disabled': !hasStashedTabs }"
        @mouseover.stop="setTabsStashToggleStateAs.on"
      >
        <div class="dynamic-navigation-tabs__toggle-stash-holder">
          <div
            class="dynamic-navigation-tabs__toggle-stash"
            :class="{ 'dynamic-navigation-tabs__toggle-stash_disabled': !hasStashedTabs }"
          >
            <Icon class="dynamic-navigation-tabs__toggle-stash-icon" name="expand" />
          </div>
        </div>
        <transition name="fade">
          <div
            v-if="canShowStash"
            class="dynamic-navigation-tabs__stash-holder"
          >
            <ul class="dynamic-navigation-tabs__stash">
              <li
                v-for="tab in tabsStash"
                :key="tab.title"
                :title="tab.title"
                class="dynamic-navigation-tabs__stash-tab"
                :class="{ 'dynamic-navigation-tabs__stash-tab_active': tab === activeTab }"
                @click="handleStashedTabClick(tab)"
              >
                {{ tab.title }}
              </li>
            </ul>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
} from 'vue';
import { useRouter } from 'vue-router';

import Icon from '@/components/share/Icon/Icon.vue';
import { useMouseWheelScrollByX } from '@/composables/useMouseWheelScrollByX';
import { TDynamicNavigationTab } from '@/store/dynamicNavigationTabs';

import { useDynamicNavigationTabs } from './application/useDynamicNavigationTabs';
import { useView } from './application/useView';

export default defineComponent({
  name: 'DynamicNavigationTabs',
  components: { Icon },
  setup() {
    const router = useRouter();
    const dynamicNavigationTabsLineRef = ref<HTMLDivElement | null>(null);
    const renderedTabsWidthMap = ref<Map<string, number>>(new Map());

    const setTabElementRef = computed(() => (element: HTMLDivElement, title: string) => {
      setTimeout(() => {
        renderedTabsWidthMap.value.set(title, element?.getBoundingClientRect().width || 0);
      }, 20);
    });

    const getRenderedTabsWidth = () => {
      const values = Array.from(renderedTabsWidthMap.value.values());
      return values.reduce((acc: number, cu: number) => acc + cu, 0);
    };

    const getIsTabsLineFull = () => {
      const tabsLineWidth = dynamicNavigationTabsLineRef.value?.getBoundingClientRect().width || 0;
      const renderedTabsWidth = getRenderedTabsWidth();
      return renderedTabsWidth >= tabsLineWidth;
    };

    const routeTo = (tab: TDynamicNavigationTab) => {
      if (tab?.fullPath) {
        router.push(tab.fullPath);
      }
    };

    const handleStashedTabClick = (tab: TDynamicNavigationTab) => {
      setTabsStashToggleStateAs.off();
      routeTo(tab);
    };

    const {
      subscribeOnMouseWheel,
      unsubscribeOnMouseWheel,
    } = useMouseWheelScrollByX<HTMLDivElement>(dynamicNavigationTabsLineRef);

    const {
      tabsBranch,
      tabsStash,
      hasStashedTabs,
      hasBranchedTabs,
      activeTab,

      removeTabFromBranch,
      removeTabFromStash,
      setTabsLineFull,
    } = useDynamicNavigationTabs();

    const {
      isTabsStashOn,
      canShowStash,
      setTabsStashToggleStateAs,
    } = useView(hasStashedTabs);

    watch(
      () => tabsBranch,
      () => {
        setTimeout(() => {
          setTabsLineFull(getIsTabsLineFull());
        }, 40);
      },
      { immediate: true, deep: true },
    );

    onMounted(subscribeOnMouseWheel);
    onBeforeUnmount(unsubscribeOnMouseWheel);

    return {
      tabsBranch,
      tabsStash,
      activeTab,
      hasStashedTabs,
      hasBranchedTabs,
      canShowStash,
      isTabsStashOn,
      dynamicNavigationTabsLineRef,
      setTabElementRef,
      setTabsStashToggleStateAs,

      removeTabFromBranch,
      removeTabFromStash,
      routeTo,
      handleStashedTabClick,
    };
  },
});
</script>

<style src="./styles.scss" lang="scss"></style>
