import { defineStore } from 'pinia';

export const useProductSearcher = defineStore('productSearcher', {
  state: () => ({ isShow: false as boolean }),
  actions: {

    toggleVisibility(): void {
      this.isShow = !this.isShow;
    },

    remove(): void {
      this.isShow = false;
    },
  },
});
