<template>
  <div class="flex items-center gap-3">
    <span v-if="params.label">{{ params.label }}</span>
    <label class="switch">
      <input v-model="state" type="checkbox" :checked="params.state" :disabled="!edit" />
      <span class="slider round" />
    </label>
  </div>
</template>

<script setup lang="ts">
import {
  Ref,
  ref,
  watch,
} from 'vue';

const props = defineProps(['edit', 'params']);
const emit = defineEmits(['action']);

const state: Ref = ref(props.params.state);

watch(state, () => emit('action', { state: state.value }));
</script>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--color-secondary);
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.slider.round {
  border-radius: 22px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
