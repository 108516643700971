import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Section',
  props: {
    inTab: {
      type: Boolean,
      default: false,
    },
  },
});
