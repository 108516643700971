import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiMap: any = {
  getCountSales: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.reports.sales,
  },
  getCashBalance: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.reports.cash,
  },
  getVisitsForStructures: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.reports.visit,
  },
  getStockItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.reports.stockItem,
  },
  getStockProduct: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.reports.stockProduct,
  },
};

const apiServices = ApiGateway.createServices(apiMap);

export default apiServices;
