<template>
  <div class="area">
    <Panel />
    <div class="pb-2">
      <DynamicNavigationTabs />
    </div>
    <slot />
    <ScrollToTop @action="scrollToTop" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Panel from '@/components/share/Panel/Panel.vue';
import ScrollToTop from '@/components/share/ScrollToTop/ScrollToTop.vue';
import DynamicNavigationTabs from '@/components/DynamicNavigationTabs/index.vue';

export default defineComponent({
  name: 'Area',
  components: {
    Panel,
    ScrollToTop,
    DynamicNavigationTabs,
  },
  setup() {
    const scrollToTop = () => {
      // TODO: Плохо
      //       Вместо ref используем .querySelector
      //       Достаем .list__table из slot если он там есть
      //       Решение: Вынести из Area работу с вложенными элементами в слоте, слот - не его зона ответственности
      const table = document.querySelector('.list__table') || document.querySelector('.data-table');

      if (table) {
        table.scrollIntoView();
      }
    };

    return { scrollToTop };
  },
});
</script>

<style src="./styles.scss" lang="scss"></style>
