<template>
  <div class="loader">
    <div class="loader__icon">
      <Icon name="circle-notch" />
    </div>
    <div class="loader__bg" />
  </div>
</template>
<script src="./Loader.ts" lang="ts"></script>
<style src="./Loader.scss" lang="scss"></style>
