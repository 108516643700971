<template>
  <tr
    :class="{
      selected: row.isSelected,
    }"
    v-bind="$attrs"
    @click="handleClickRow"
  >
    <td v-if="selectable">
      <Checkbox
        ref="searcherCheckbox"
        type="boolean"
        size="sm"
        :mods="['b-gray', 'bg-white']"
        :edit="true"
        @click.prevent.stop="handleClickItemCheckbox"
      />
    </td>
    <td v-if="draggable" class="list__drag-handler">
      <Icon
        name="dots-three-outline"
        @click.stop=""
      />
    </td>
    <template>
      <img :src="image" alt="alt" width="100" />
      {{ image }}
    </template>
    <td v-for="(cell, cellIndex) in table" :key="cellIndex">
      <div
        v-if="row.iconLeft?.cellValue && cell.value === row.iconLeft.cellValue"
        class="h-4 w-4 float-left mx-1"
      >
        <template v-if="row.iconLeft?.type === 'image'">
          <svg v-if="row.iconLeft.image.mime_type.includes('svg')" width="16px" height="16px" xmlns="http://www.w3.org/2000/svg">
            <image :xlink:href="row.iconLeft.image.url" x="0" y="0" height="16" width="16" />
          </svg>
          <img v-else :src="row.iconLeft.image.url" :alt="row.iconLeft.image.original_name">
        </template>
        <Icon v-else :name="row.iconLeft.iconName" class="h-4 w-4 float-left mx-1" />
      </div>
      <template v-if="cell.type === 'itemEdit'">
        <Icon name="pencil" class="h-4 w-4" @click.stop.prevent="handlerClickItemEdit" />
      </template>
      <template v-if="cell.type == 'redBell'">
        <template v-if="get(row, cell.value)">
          <div class="notification">
            <Icon
              class="w-4 min-w-4 text-red-400 stroke-red-400 notification__img"
              name="bell"
              style="color: green"
              @click.prevent.stop="showItemNotifications(row.id)"
            />
            <span class="notification__total">
              {{ String(get(row, cell.value)) }}
            </span>
          </div>
        </template>
      </template>
      <template v-if="cell.type == 'string'">
        {{ get(row, cell.value) ? String(get(row, cell.value)) : '' }}
      </template>
      <template v-if="cell.type == 'route'">
        <div
          class="text-cyan-500 underline cursor-pointer"
          @click.stop="handlerClickRoute({
            row,
            cell
          })"
        >
          {{ get(row, cell.value) ? String(get(row, cell.value)) : '' }}
        </div>
      </template>
      <template v-if="cell.type == 'inventory'">
        <a
          v-if="get(row, cell.value) "
          class=" text-cyan-600 underline"
          @click.stop.prevent="router.push({
            name: 'InventoryItem',
            params: { id: get(row, cell.value) }
          })"
        >
          {{ get(row, cell.value) ? 'Инв. №' + String(get(row, cell.value)) : '' }}
        </a>
      </template>
      <template v-if="cell.type == 'pageLink'">
        <a
          v-if="row[cell.value]?.page && row[cell.value]?.id && row[cell.value]?.text"
          class=" text-cyan-600 underline"
          @click.stop.prevent="router.push({
            name: row[cell.value].page,
            params: { id: row[cell.value].id }
          })"
        >
          {{ row[cell.value].text }}
        </a>
      </template>
      <template v-if="cell.type == 'recalculation'">
        <div>
          <a
            v-for="item in get(row, cell.value)"
            class="grid text-cyan-600 underline"
            @click.stop.prevent="router.push({
              name: 'RecalculationProductsItem',
              params: { id: item.id }
            })"
          >
            {{ '№ ' + item.id + ' от ' + toDateFormatTime(item.attributes.date) }}
          </a>
        </div>
      </template>
      <template v-if="cell.type == 'number'">
        {{ String(get(row, cell.value)) }}
      </template>
      <template v-else-if="cell.type == 'date'">
        {{ get(row, cell.value) ? getDate(get(row, cell.value)) : '' }}
      </template>
      <template v-else-if="cell.type == 'month'">
        {{ get(row, cell.value) ? getMonth(get(row, cell.value)) : '' }}
      </template>
      <template v-else-if="cell.type == 'money'">
        {{ get(row, cell.value) ? Number(get(row, cell.value)).toLocaleString('ru') : 0 }}
        ₽
      </template>
      <template v-else-if="cell.type == 'barcode'">
        <div v-for="barcode of row.barcodes" :key="barcode.id">
          {{ barcode.barcode }}
        </div>
      </template>
      <template v-else-if="cell.type == 'strings'">
        <div v-if="cell.isFlex" class="flex">
          <div v-for="(value, valueIndex) in get(row, cell.value)" :key="valueIndex">
            {{ value[cell.prop]
            }}{{ get(row, cell.value).length - 1 > valueIndex ? ', ' : '' }}
          </div>
        </div>
        <div v-for="(value, valueIndex) in get(row, cell.value)" v-else :key="valueIndex">
          {{ value[cell.prop] }}
        </div>
      </template>
      <template v-else-if="cell.type == 'checkbox'">
        <Checkbox
          v-if="row['attributes'] && cell.value === 'attributes.display_in_erp'"
          type="boolean"
          size="sm"
          :mods="['b-gray', 'bg-white']"
          :checked="row['attributes']['display_in_erp'] == true"
          :edit="false"
        />
        <Checkbox
          v-else-if="row['attributes'] && cell.value === 'attributes.display_in_shop'"
          type="boolean"
          size="sm"
          :mods="['b-gray', 'bg-white']"
          :checked="row['attributes']['display_in_shop'] == true"
          :edit="false"
        />
        <Checkbox
          v-else-if="cell.value === 'attributes.canceled'"
          type="boolean"
          size="sm"
          :mods="['b-gray', 'bg-white']"
          :checked="row.attributes.canceled == 1"
          :edit="false"
        />
        <Checkbox
          v-else-if="cell.value === 'attributes.archived'"
          type="boolean"
          size="sm"
          :mods="['b-gray', 'bg-white']"
          :checked="row.attributes.archived"
          :edit="false"
        />
        <Checkbox
          v-else-if="cell.value === 'attributes.blocked'"
          type="boolean"
          size="sm"
          :mods="['b-gray', 'bg-white']"
          :checked="row.attributes.blocked"
          :edit="false"
        />
        <Checkbox
          v-else-if="cell.value === 'attributes.use_only_once_per_customer'"
          type="boolean"
          size="sm"
          :mods="['b-gray', 'bg-white']"
          :checked="row.attributes.use_only_once_per_customer"
          :edit="false"
        />
        <Checkbox
          v-else-if="cell.value === 'chesniy_znak'"
          type="boolean"
          size="sm"
          :mods="['b-gray', 'bg-white']"
          :checked="row.attributes.chesniy_znak"
          :edit="false"
        />
        <Checkbox
          v-else
          type="boolean"
          size="sm"
          :mods="['b-gray', 'bg-gray']"
          :checked="row[cell.value] == 1"
          :edit="!!cell.editable"
          :modelValue="row[cell.value]"
          @click.stop=""
          @update:model-value="onCheckboxChange(cell, $event)"
        />
      </template>
      <template v-else-if="cell.type == 'messengers'">
        <template v-for="(messenger, messengerIndex) in cell.value" :key="messengerIndex">
          <div v-if="row[messenger.value]" class="list__messenger">
            <Icon class="list__messenger-icon" :name="messenger.icon" />
            <div class="list__messenger-value">
              {{ row[messenger.value] }}
            </div>
          </div>
        </template>
      </template>
      <template v-else-if="cell.nameValue">
        <div class="link-name-value" @click="onClickLink($event, row.id)">
          {{ cell.value }}
        </div>
      </template>
      <template v-else-if="cell.type == 'payment'">
        <div class="flex">
          <div
            v-for="(payment, paymentIndex) of row.paymentMethod"
            class="payment-icon-holder"
            @mouseover="setTooltip(cellIndex, paymentIndex)"
            @mouseleave="hideTooltip()"
          >
            <Icon
              alt="www"
              class="payment-icon mr-3 :last-mr-0"
              :name="paymentIconMap[payment.id]"
            />
            <Tooltip
              v-if="
                isTooltipVisible
                  && currentCellIndex === cellIndex
                  && currentPaymentIndex === paymentIndex
              "
              :text="payment.name"
              side="top"
            />
          </div>
        </div>
      </template>
      <template v-else-if="cell.type == 'products.colors'">
        <div
          v-for="(color, colorIndex) of row.colors"
          :key="colorIndex"
          class="mr-1 :last-mr-0"
        >
          {{ color.name }}
        </div>
      </template>
      <template v-else-if="cell.type == 'image'">
        <img
          v-if="image || row[cell.value]"
          :src="
            !isObject(image)
              ? image
              : row[cell.value]
                ? row[cell.value] + '?h=50&fit=contain'
                : '/uploads/images/photo-empty.png'
          "
          alt="alt"
          width="44"
        />
      </template>
      <template v-else-if="cell.type == 'stocks'">
        <div v-if="stocks.length > 0" class="flex items-center mb-1 last:mb-0">
          <div>{{ getTotal(stocks) }}</div>
        </div>
        <div v-else>
          0
        </div>
      </template>
      <template v-else-if="cell.type == 'prices'">
        <div
          v-for="(price, priceIndex) in prices"
          :key="priceIndex"
          class="flex items-center mb-1 last:mb-0"
        >
          <div v-if="price.type.id === 3 || price.type.id === 1" class="mr-1">
            {{ price.type.title }}:
          </div>
          <div v-if="price.type.id === 3 || price.type.id === 1" class="ml-auto">
            {{ price.price }} ₽
          </div>
        </div>
      </template>
      <template v-else-if="cell.type == 'priceWholesale'">
        <div
          v-for="(price, priceIndex) in prices"
          v-if="prices.length > 0"
          :key="priceIndex"
          class="flex items-center mb-1 last:mb-0"
        >
          <div v-if="price.type.id === 1">
            {{ price.price }} ₽
          </div>
        </div>
        <div v-else>
          0 ₽
        </div>
      </template>
      <template v-else-if="cell.type == 'priceRetail'">
        <div
          v-for="(price, priceIndex) in prices"
          v-if="prices.length > 0"
          :key="priceIndex"
          class="flex items-center mb-1 last:mb-0"
        >
          <div v-if="price.type.id === 3">
            {{ price.price }} ₽
          </div>
        </div>
        <div v-else>
          0 ₽
        </div>
      </template>
      <template v-else-if="cell.type == 'status'">
        <div
          :class="[
            get(row, cell.value).type == 'success' ? 'text-green-700' : '',
            get(row, cell.value).type == 'error' ? 'text-red-700' : '',
          ]"
        >
          {{ row[cell.value].title }}
        </div>
      </template>
      <template v-else-if="cell.type === 'statusIcon'">
        <Status :type="row[cell.value]" />
      </template>
      <template v-else-if="cell.type === 'statusIcon2'">
        <Status :type="row.attributes.status" />
      </template>
      <template v-else-if="cell.type === 'statusHonest'">
        <div
          class="tooltip-honest"
          :class="honestStatus(row.relationships.electronicDocuments)"
        >
          <Icon
            name="honest-status"
            class="honest-status"
            :class="honestStatus(row.relationships.electronicDocuments)"
          />
          <div class="absolute z-10 inline-block px-3 py-2 text-gray-900 bg-white border border-gray-200 rounded-lg shadow-sm tooltip">
            {{ getHonestMarkTooltip(honestStatus(row.relationships.electronicDocuments)) }}
            <div class="tooltip-arrow" data-popper-arrow />
          </div>
        </div>
      </template>
      <template v-else-if="cell.type == 'priceLoadHistories'">
        <template v-if="getPriceLoadHistoriesProperty(get(row, cell.value), cell.item)">
          <Icon name="check" class="w-4" style="color: green" />
        </template>
        <template v-else>
          <Icon name="x" class="w-4 text-red-400" />
        </template>
      </template>
      <template v-else-if="cell.type == 'print'" class="w-4 h-4">
        <Icon
          v-if="
            row.attributes.type_of_operation === 'sale'
              || row.attributes.type_of_operation === 'returns'
          "
          class="w-4 min-w-5"
          :class="{
            [cell.class]: !!cell.class,
            [cell.modifer]: row[cell.key]
          }"
          :style="cell.style ? cell.style : ''"
          :name="cell.icon"
          :color="cell.color"
        />
      </template>
      <template v-else-if="cell.type === 'icon'">
        <Icon
          v-if="cell.values.includes(row[cell.field])"
          class="text-orange-400 h-4"
          :name="cell.icon"
          @click.stop="handlerClickIcon({
            action: cell.action,
            row
          })"
        />
      </template>
      <template v-else-if="cell.type === 'buttonWithIcon' && row.icon">
        <div class="flex items-center">
          <button
            class="button --xs --bg-outline-secondary p-1"
            @click.stop="handlerClickButton({
              action: cell.action,
              row
            })"
          >
            <Icon :name="row.icon" class="text-cyan-500 h-4" />
          </button>
        </div>
      </template>
      <template v-else-if="cell.type === 'button'">
        <div class="flex items-center">
          <button
            v-if="row[cell.key]"
            class="button --xs --bg-outline-secondary"
            @click.stop="handlerClickButton({
              action: cell.action,
              row
            })"
          >
            {{ row[cell.key] }}
          </button>
          <div v-else>
            <div
              v-if="
                cell.extend?.type === 'loader'
                  && cell.extend.values.includes(row[cell.extend.field])
              "
            >
              <Icon name="circle-notch" class="text-cyan-500 animate-spin h-4" />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="cell.type === 'link-button'">
        <div class="flex items-center gap-1">
          <div v-if="cell.extend">
            <div v-if="cell.extend.type === 'checkbox'" class="py-2 px-2 custom-checkbox">
              <input
                type="checkbox"
                disabled
                :checked="row.attributes[cell.extend.field]"
              />
            </div>
          </div>
          <div
            class="cursor-pointer text-cyan-500"
            @click.stop="handlerClickButton({
              action: cell.action,
              row
            })"
          >
            {{ row[cell.key] }}
          </div>
        </div>
      </template>
      <template v-else-if="cell.type === 'link-button-checkbox'">
        <Icon
          class="icon__checkbox-default ml-2"
          :class="{
            '--checked': row[cell.value]
          }"
          name="check"
          @click.stop="handlerClickButton({
            action: cell.action,
            value: {
              row,
              cell
            }
          })"
        />
      </template>
    </td>
    <td v-if="subtable || remove">
      <Icon
        v-if="subtable"
        class="w-5 min-w-5"
        :class="isShowSub ? 'rotate-180' : ''"
        name="caret"
      />
      <Icon
        v-if="remove && !row.attributes?.archived"
        class="w-5 min-w-5 text-red-400"
        name="trash"
        @click.prevent.stop="removeItem(row.id)"
      />
    </td>
  </tr>
  <tr v-if="subtable" class="subtable" :class="isShowSub ? '--show' : ''">
    <td colspan="100">
      <table>
        <thead>
          <th width="20" />
          <th v-for="(cell, index) in subtable" :key="index" :width="cell.width">
            {{ cell.title }}
          </th>
        </thead>
        <tbody>
          <ListItem
            v-for="(row, rowIndex) in row.products"
            :key="rowIndex"
            :checks="checks"
            :table="subtable"
            :row="row"
            :selectable="true"
          />
        </tbody>
      </table>
    </td>
  </tr>
  <Teleport to="body">
    <Modal
      :open="isModalOpen"
      :title="modal.title"
      :buttonText="modal.button"
      :isStandardButtons="modal.isStandardButtons"
      :okDisabled="modal.okDisabled"
      :width="modal.width"
      @action="modalAction"
    >
      <div v-if="modal.component === 'atolDescriptions'">
        <div v-for="(description, index) in modal.content" :key="index">
          {{ description }}
        </div>
      </div>
    </Modal>
  </Teleport>
</template>

<script setup lang="ts">
// TODO сократить ненужные типы ячеек и переписать их в разделах, где они используются

import {
  onMounted,
  ref,
  Ref,
} from 'vue';
import getValue from 'lodash.get';
import { useRouter } from 'vue-router';
import { isObject } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

import { useSearcher } from '@/store/searcher';
import { useProducts } from '@/store/products';
import { useNotifications } from '@/store/notifications';
import {
  toDateFormatTime,
  toDateFormatLocalTimeZone,
  toMonthFormat,
} from '@/utils/date/toDateFormat';
import Checkbox from '@/components/share/Checkbox/Checkbox.vue';
import Icon from '@/components/share/Icon/Icon.vue';
import Status from '@/components/share/Status/Status.vue';
import Tooltip from '@/components/share/Tooltip/Tooltip.vue';
import Modal from '@/components/share/Modal/Modal.vue';

const props = defineProps([
  'item',
  'checks',
  'selectable',
  'remove',
  'page',
  'row',
  'table',
  'openItemStock',
  'subtable',
  'link',
  'draggable',
]);
const emit = defineEmits([
  'getRow',
  'showItemNotifications',
  'remove',
  'openItemStock',
  'action',
]);
const router = useRouter();
const searcher = useSearcher();
const products = useProducts();
const notification = useNotifications();
const { t } = useI18n();
const isModalOpen = ref(false);
const modalDefault = {
  title: '',
  isStandardButtons: false,
  okDisabled: false,
  button: '',
  text: '',
  component: '',
  width: '',
  content: null,
};
const modal = ref(structuredClone(modalDefault));
const properties: Ref = ref({});
const searcherCheckbox: Ref = ref({ state: { isChecked: false } });
const image: Ref = ref({});
const stocks: Ref = ref([]);
const prices: Ref = ref([]);
const stocksTotal: Ref = ref(0);
const paymentIconMap: Ref = ref({ 3: 'certificate', 1: 'cash', 2: 'payment_card' });
const isShowSub: Ref = ref(false);
const isTooltipVisible: Ref = ref(false);
const currentCellIndex: Ref = ref(null);
const currentPaymentIndex: Ref = ref(null);

const modalAction = (event: any) => {
  switch (event) {
    case 'cancel':
      closeModal();
      break;
    default:
      break;
  }
};

const closeModal = () => {
  modal.value = modalDefault;
  isModalOpen.value = false;
};

const handlerClickIcon = (event: any) => {
  switch (event.action) {
    case 'showAtolResponseDescriptions':
      modal.value.title = t('cashierWorkplace.deviceDescriptionTitle');
      modal.value.width = '640px';
      modal.value.component = 'atolDescriptions';
      modal.value.content = event.row.atolDescriptions;
      isModalOpen.value = true;
      break;

    default:
      break;
  }
};

const handlerClickButton = (event: any) => {
  switch (event.action) {
    case 'reprint':
      emit('action', { action: event.action, id: event.row.id });
      break;

    case 'dismiss-toggle':
      emit('action', { action: event.action, id: event.row.id });
      break;

    case 'dragTo':
      emit('action', { action: event.action, row: event.row });
      break;

    case 'linkTo':
      router.push({ path: `/${event.row?.path}/${event.row?.id}` });
      break;

    case 'getCellAndRow':
      emit('action', { action: event.action, value: event.value });

    default:
      break;
  }
};

const handlerClickRoute = (event: any) => {
  router.push({ name: event.cell.page, params: { id: event.row.id } });
};

const setTooltip = (cellIndex: any, paymentIndex: any) => {
  isTooltipVisible.value = true;
  currentCellIndex.value = cellIndex;
  currentPaymentIndex.value = paymentIndex;
};

const hideTooltip = () => {
  isTooltipVisible.value = false;
  currentCellIndex.value = currentPaymentIndex.value = null;
};

const onClickLink = (e: Event, id: string) => {
  e.stopPropagation();
  emit('openItemStock', id);
};

const onCheckboxChange = (cell: any, event: any) => {
  console.log('🚀 ~ cell: any, event: any:', cell, event);
  if (typeof cell.onChange === 'function') {
    cell.onChange(props.row, event);
  }
  props.row[cell.value] = event;
};

const get = (obj: any, key: string) => getValue(obj, key, '');

const getTotal = (stocks: any) => {
  if (stocks.length > 0) {
    return Object.values(stocks)
      .filter((s: any) => s.warehouse.display_in_erp)
      .reduce((t: any, { total }: any) => t + total, 0);
  }
};

const handlerClickItemEdit = () => {
  searcher.isShow = false;
  router.push({ name: props.page, params: { id: props.row.id } });
};

const handleClickItemCheckbox = () => {
  const currentRoute: any = router.currentRoute.value.name;

  if (searcherCheckbox.value.state.isChecked) {
    if (products.isDuplicate(props.row) >= 0) {
      searcherCheckbox.value.state.isChecked = false;
      notification.warn(
        'Дубликат товара',
        'Вы не можете добавить два одинаковых товара',
        4000,
      );
    } else {
      products.findPresence(currentRoute, props.row);
      props.row.isCreate = true;
      products.addItem(props.row);
    }
  } else {
    products.removeItem(props.row);
  }
};

const handleClickRow = (e: Event) => {
  if (document.getSelection()?.type === 'Range') {
    e.stopPropagation();
  } else {
    if (props.subtable) {
      isShowSub.value = !isShowSub.value;
      return false;
    }

    if (props.item) {
      emit('getRow', props.row);
    }

    if (props.page) {
      const routerParams = props.page === 'Instruction' ? { slug: props.row.slug } : { id: props.row.id };
      const queryParams = props.page === 'Instruction' ? { id: props.row.id } : { };

      router.push({ name: props.page, params: routerParams, query: queryParams });
    }

    if (props.link) {
      router.push({ name: 'Product', params: { id: props.row?.id } });
    }

    if (!props.row.item) {
      if (!props.row?.name) {
        props.row.name = props.row?.attributes?.name
          || props.row?.attributes?.title
          || props.row?.attributes?.comment;
      }

      searcher.value = props.row;
    }
  }
};
const honestStatus = (arr: any): string => {
  const currentRoute = String(router.currentRoute.value.name);

  const relationArray = currentRoute !== 'CashChecks'
    ? arr.slice(Math.max(arr.length - 2, 0)).filter((item: any) => item?.attributes.type_of_operation === 'acceptance' || item?.attributes.type_of_operation === 'shipment')
    : arr.filter((item: any) => item?.attributes.type_of_operation === 'withdrawal_from_turnover_by_check' || item?.attributes.type_of_operation === 'return_to_circulation');

  const statusAccepted = relationArray.length ? relationArray.every((el: any) => el.attributes.status === 'accepted') : false;

  const statusError = currentRoute !== 'CashChecks'
    ? relationArray.some((el: any) => el.attributes.status === 'error')
    : relationArray.length ? relationArray.every((el: any) => el.attributes.status === 'error') : false;

  const statusAcceptedError = currentRoute !== 'CashChecks'
    ? relationArray.some((el: any) => el.attributes.status !== 'error' || el.attributes.status !== 'accepted')
    : relationArray.some((el: any) => el.attributes.status !== 'accepted');

  const statusErrorProcessed = currentRoute !== 'CashChecks'
    ? false
    : relationArray.some((el: any) => el.attributes.status === 'error');

  return statusAccepted
    ? 'green'
    : statusError
      ? 'red'
      : statusErrorProcessed
        ? 'yellow'
        : statusAcceptedError
          ? 'black'
          : 'gray';
};

const getHonestMarkTooltip = (colorStatus: string) => {
  const tooltipMap: any = {
    green: t('honestMarks.status.green'),
    red: t('honestMarks.status.red'),
    yellow: t('honestMarks.status.yellow'),
    black: t('honestMarks.status.black'),
    gray: t('honestMarks.status.gray'),
  };

  return tooltipMap[colorStatus] || t('honestMarks.status.error');
};

const isObject = (obj: any): boolean => typeof obj === 'object' && obj !== null && isEmptyObject(obj);

const isEmptyObject = (obj: any): boolean => {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
};

const initItemProducts = () => {
  if (!(props.row.properties || props.row.products)) return false;

  const consist = null;
  const collection = null;
  const stocksAny: any[] = [];
  let stocksTotal = 0;
  const firstImage: any = null;
  const defaultImage = '/uploads/images/photo-empty.png';
  const sizes: number[] = [];
  const colors: string[] = [];
  const prices: string[] = [];
  const barcodes: string[] = [];

  if (props.row.products) {
    for (const product of props.row.products) {
      setProperties(
        product,
        consist,
        sizes,
        colors,
        barcodes,
        collection,
        stocksAny,
        stocksTotal,
        firstImage,
        prices,
        defaultImage,
      );

      product?.stocks?.forEach((stock: any) => {
        stocksTotal += stock.total;
      });
    }
  } else {
    const product = props.row;

    setProperties(
      product,
      consist,
      sizes,
      colors,
      barcodes,
      collection,
      stocksAny,
      stocksTotal,
      firstImage,
      prices,
      defaultImage,
    );

    product?.stocks?.forEach((stock: any) => {
      stocks.value.push(stock);
    });
  }
};

const setProperties = (
  product: any,
  consist: any,
  sizes: any[],
  colors: string[],
  barcodes: any[],
  collection: any,
  stocks: any[],
  stocksTotalVal: number,
  pricesVal: any[],
  firstImage: string | null | any,
  defaultImage: string,
) => {
  product?.properties?.forEach((property: any) => {
    switch (property.slug) {
      case 'consist':
        consist = property.value;
        break;
      case 'size':
        if (sizes.indexOf(property.value) < 0) {
          sizes.push(property.value);
        }
        break;
      case 'color':
        if (colors.indexOf(property.value) < 0) {
          colors.push(property.value);
        }
        break;
      case 'collection':
        collection = property.value;
        break;
    }
  });

  product?.images?.forEach((image: any) => {
    firstImage = image;
  });

  properties.value = {
    consist,
    sizes,
    colors,
    barcodes,
    collection,
  };

  stocksTotal.value = stocksTotalVal;
  image.value = firstImage?.url ?? defaultImage;
  prices.value = product.prices;
};

const removeItem = (id: number) => {
  emit('remove', id);
};

const showItemNotifications = (id: number) => {
  emit('showItemNotifications', id);
};

const getDate = (str: string) => toDateFormatLocalTimeZone(str);

const getMonth = (str: string) => toMonthFormat(str);
const getPriceLoadHistoriesProperty = (arr: any, prop: any) => {
  const el = arr.find((el: any) => el.type == prop);

  return el && el.status === 1;
};

onMounted(() => {
  initItemProducts();
});
</script>

<style lang="scss">
.notification {
  position: relative;

  &__img {
  }

  &__total {
    font-size: 12px;
    position: absolute;
    right: -5px;
    top: -5px;
  }
}

.list__table {
  tr {
    &.selected,
    &.sortable-selected{
      background: var(--color-light-blue);

      &:nth-of-type(even) {
        background: var(--color-light-blue);
      }
    }
  }
}

</style>
