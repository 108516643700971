import { Ref } from 'vue';

export type TUseMouseWheelScrollByX = {
  subscribeOnMouseWheel: () => void,
  unsubscribeOnMouseWheel: () => void,
};

export const useMouseWheelScrollByX = <T extends HTMLElement>(containerRef: Ref<T | null>): TUseMouseWheelScrollByX => {
  const handleOnWheel = (event: WheelEvent) => {
    event.preventDefault();

    if (containerRef.value) {
      // eslint-disable-next-line no-param-reassign
      containerRef.value.scrollLeft += event.deltaY;
    }
  };

  const subscribeOnMouseWheel = () => {
    if (containerRef.value) {
      containerRef.value.addEventListener('wheel', handleOnWheel);
    }
  };

  const unsubscribeOnMouseWheel = () => {
    if (containerRef.value) {
      containerRef.value.removeEventListener('wheel', handleOnWheel);
    }
  };

  return {
    subscribeOnMouseWheel,
    unsubscribeOnMouseWheel,
  };
};
