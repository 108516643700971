import ApiGateway from '@/utils/api';
import ApiRoutes from '@/utils/api/apiRoutes';
import ApiRequestMethod from '@/utils/api/apiRequestMethod';

const apiOrganizationsMap: any = {
  getList: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.organizations.list,
    properties: false,
  },
  getItem: {
    method: ApiRequestMethod.GET,
    route: ApiRoutes.organizations.item,
    properties: true,
  },
  createItem: {
    method: ApiRequestMethod.POST,
    route: ApiRoutes.organizations.list,
  },
  updateItem: {
    method: ApiRequestMethod.PUT,
    route: ApiRoutes.organizations.item,
  },
  removeItem: {
    method: ApiRequestMethod.DELETE,
    route: ApiRoutes.organizations.item,
  },
};

const apiOrganizationsServices = ApiGateway.createServices(apiOrganizationsMap);

export default apiOrganizationsServices;
