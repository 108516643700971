import { defineComponent } from 'vue';
import { mapActions } from 'pinia';

import Logo from '@/components/share/Logo/Logo.vue';
import NavPrimary from '@/components/nav/NavPrimary/NavPrimary.vue';
import Icon from '@/components/share/Icon/Icon.vue';
import Loader from '@/components/share/Loader/Loader.vue';
import Button from '@/components/form/Button/Button.vue';
import { useNavAside } from '@/store/nav-aside';
import { useUserSession } from '@/store/userSession';
import { useWishlist } from '@/store/wishlist';
import { useNotifications } from '@/store/notifications';
import apiPinCode from '@/utils/api/services/apiPinCode';
import apiSystemServices from '@/utils/api/services/apiSystemServices';

export default defineComponent({
  name: 'Panel',
  components: {
    Loader,
    Logo,
    NavPrimary,
    Icon,
    Button,
  },
  data() {
    const wishlist = useWishlist();
    const notifications = useNotifications();
    return {
      wishlist,
      notifications,
      favorites: null,
      isFavoritesOpen: false,
      isFavoritesLoading: false,
      isUserMenu: false,
      isPincodeLoading: false,
      isPincodeUpdating: false,
      isActive: false,
      inputsPinCode: '',
      inputsPinCodeArray: [],
    };
  },
  computed: {
    user() {
      return JSON.parse(useUserSession().user);
    },
  },
  methods: {
    ...mapActions(useNavAside, ['toggleNavAside']),
    ...mapActions(useUserSession, ['logoutUser']),
    getInitials(name: string) {
      return name ? name.split(' ').map((l: string) => l.charAt(0)).join('') : 'E';
    },
    handleClickButtonCopy() {
      navigator.clipboard.writeText(this.inputsPinCode);
      this.notifications.success('Пин код', 'Ваш пин код скопирован');
    },
    toggleUserMenu() {
      this.isUserMenu = !this.isUserMenu;
    },
    closeUserMenu() {
      this.isUserMenu = false;
    },
    closeWishlist() {
      this.isFavoritesOpen = this.isFavoritesLoading = false;
    },
    userLogout() {
      this.logoutUser();
    },
    async getWishlist() {
      // if (this.wishlist.items.length) return
      this.isFavoritesOpen = true;
      this.isFavoritesLoading = true;

      await this.wishlist.setWishlist();

      this.isFavoritesLoading = false;
    },

    async removeFromWishlist(id: number) {
      this.isFavoritesLoading = true;

      await this.wishlist.removeItemFromWishlist(id);

      this.isFavoritesLoading = false;
    },

    async getPinCode() {
      this.isPincodeLoading = true;

      const { data } = await apiSystemServices.getInfo();

      if (data) {
        this.isUserMenu = this.isPincodeLoading = false;
        this.inputsPinCode = data.attributes.pincode;
        this.inputsPinCodeArray = data.attributes.pincode.split('');
        this.isActive = true;
      }
    },

    async updatePinCode() {
      this.isPincodeUpdating = true;

      const { data } = await apiPinCode.generatePinCode();

      if (data) {
        this.isUserMenu = this.isPincodeUpdating = false;
        this.inputsPinCode = data.pincode;
        this.inputsPinCodeArray = data.pincode.split('');
        this.isActive = true;
        // alert(`Ваш пин-код: ${data.pincode}`)
      }
    },

    close() {
      this.isActive = false;
      this.inputsPinCode = 'data.pincode';
      this.inputsPinCodeArray = [];
    },
  },
  // async mounted() {
  //   await this.getWishlist()
  // }
});
