import {
  defineComponent,
  ref,
  Ref,
  computed,
  ComputedRef,
} from 'vue';

import { useItemSearcher } from '@/store/item-searcher';
import Button from '@/components/form/Button/Button.vue';
import Icon from '@/components/share/Icon/Icon.vue';
import Field from '@/components/form/Field/Field.vue';
import Items from '@/components/share/Items/Items.vue';
import { useProducts } from '@/store/products';

export default defineComponent({
  name: 'ItemSearcher',
  components: {
    Items,
    Field,
    Button,
    Icon,
  },
  setup(_, context) {
    const itemsSearcherStore: any = useItemSearcher();
    const productsStore: any = useProducts();
    const selectedItems: Ref = ref([]);
    const showItems: Ref = ref(false as boolean);

    const items: ComputedRef<object[]> = computed(() => itemsSearcherStore.items);

    /**
     * Close the component
     *
     * @function
     * @return void
     */
    const close = () => itemsSearcherStore.toggleVisibility('');

    /**
     * Add selected items to the product store
     *
     * @function
     * @return void
     */

    const addItemsToProductStore = (selectedItems: any) => {
      selectedItems.map((el:any) => {
        const prod = {
          id: el.id,
          tags: el.tags,
          item: {
            article: el.article,
            title: el.title,
            id: el.id,
          },
          attributes: {
            article: el.article,
            title: el.title,
            id: el.id,
          },
        };
        productsStore.addItem(prod);
      });
    };

    /**
     * Add selected items to the card
     *
     * @function
     * @return void
     */
    const addToStore = (): void => {
      if (itemsSearcherStore.route === 'addingTags') {
        addItemsToProductStore(selectedItems.value);
        showItems.value = true;
        close();
      }
      itemsSearcherStore.addItems(selectedItems.value);
      showItems.value = true;

      productsStore.setClearCheckedProducts(true);
    };

    /**
     * Remove an item from the card
     *
     * @function
     * @param item
     * @return void
     */
    const removeItem = (item: any): void => {
      itemsSearcherStore.removeItem(item);
    };

    const removeAll = () => {
      itemsSearcherStore.removeAll();
    };

    const handlerItemsAction = (event: any) => {
      switch (event.type) {
        case 'selectedItems':
          selectedItems.value = event.selectedItems;
          break;
        default:
          break;
      }
    };

    return {
      items,
      showItems,
      removeAll,
      close,
      addToStore,
      removeItem,
      handlerItemsAction,
    };
  },
});
